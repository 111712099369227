import moment from 'moment'
import React from 'react'
import { useState } from 'react'

const AdminLoginInfoTable = ({current, i}) => {
    const [showAccordion, setShowAccordion] = useState(false)
    const openAccordion = () => {
        setShowAccordion((state) => !state)
    }
    return (
        <>
            <tr onClick={openAccordion} key={i}  style={{cursor: "pointer"}} >
                <td style={{minWidth: "16.66%",maxWidth: "16.66%"}}>{current.company.companyName}</td>
                {/* <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><i className="fa fa-chevron-circle-down "></i></td> */}
            </tr>
            {
                current.loggedInInfo && 
                current.loggedInInfo.length > 0 &&
                current.loggedInInfo.map((item, it) => {
                // company.adminLoginInfo &&
                // company.adminLoginInfo.length > 0 &&
                // company.adminLoginInfo.map((current, i) => {
                    return (
                        showAccordion === true &&
                        <tr >
                            <td>{current.company.companyName}</td>
                            <td>{item.userId.firstName + " " + item.userId.lastName}</td>
                            <td>{item.userId.role}</td>
                            <td>{item.country}</td>
                            <td>{item.ipAddress}</td>
                            <td>{moment(item.date.toString().substring(0,10)).format('MMMM Do YYYY') +", "} {item.date.substring(11, 16)} {parseInt(item.date.substring(11, 13)) > 12 ? "PM" : "AM"}</td>
                        </tr>
                    )
                }) 
            }
            {/* <tr className={`${showAccordion ? 'show' : 'hide'} `}>
            <td >{current.company.companyName}</td>
            <td>{current.companycompanyName}</td>
            <td>{current.userId.firstName + " " + current.userId.lastName}</td>
            <td>{current.userId.role}</td>
            <td>{current.country}</td>
            <td>{current.ipAddress}</td>
            <td>{moment(current.date.toString().substring(0,10)).format('MMMM Do YYYY') +", "} {current.date.substring(11, 16)} {parseInt(current.date.substring(11, 13)) > 12 ? "PM" : "AM"}</td>
            </tr> */}
        </>
    )
}

export default AdminLoginInfoTable