import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getMailByMailId } from "../../actions/mailActions";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import SuperAdminMailView from "../../components/superAdminCompoents/superAdminMessageCompoents/SuperAdminMailView";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
const SuperAdminMailViewPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (params.id) {
      // console.log("dispatch")
      dispatch(getMailByMailId({ mailId: params.id }, navigate));
    }
  }, [params.id]);
  return (
    <>
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="View Message" />
            <SuperAdminMailView />
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminMailViewPage;
