import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux' 
import { removeTraining, getAllTraining } from '../../../actions/trainingActions'

const DeleteTrainingModal = ({ showDeleteDropDown, openDeleteDropDown, current }) => {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)
    const [dataUpdate, setDataUpdate] = useState({
        _id: current.id,
        delete: true,
    })
    const valueUpdate = () => {
        if(current !== undefined){
            setDataUpdate({
                _id: current.id,
                delete: true,
            })
        }
    }
    useEffect(() => {
        valueUpdate()
    }, [current])
    const allUsersData = async () =>{
        dispatch(getAllTraining({companyId: auth.user.companyId}))
    }
    const handleSubmit = async () => {
        dispatch(removeTraining(dataUpdate))
        openDeleteDropDown()
        setTimeout(() => 
            allUsersData()
        , 1000);
    }
    return (
        <>
            <div className={`modal-backdrop fade ${showDeleteDropDown ? 'show' : 'hide'}`}></div>
            <div className={`modal custom-modal fade ${showDeleteDropDown ? 'show' : 'hide'}`} id="delete_warn" role="dialog"> 
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-header">
                                <h3>Delete Training</h3>
                                <p>Are you sure want to delete?</p>
                            </div>
                            <div className="modal-btn delete-action">
                                <div className="row">
                                    <div className="col-6">
                                        <a className="btn btn-primary continue-btn" onClick={handleSubmit}>Delete</a>
                                    </div>
                                    <div className="col-6">
                                        <a data-dismiss="modal" className="btn btn-primary cancel-btn" onClick={() => openDeleteDropDown()}>Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DeleteTrainingModal