import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import cake from "../../../assetsAdmin/img/cakeundraw_Birthday_cake_re_bsw5.png";
import shift1 from "../../../assetsAdmin/img/undraw_Time_management_re_tk5w.png";
import { BUCKET_BASE_URL } from "../../../constants";
const EmployeeDetails = () => {
  const admin = useSelector((state) => state.admin);
  let i = 0;
  return (
    <>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-xl-4 d-flex">
          {/* style={{maxHeight: "415px"}}  neeche for fixed size */}
          <div className="card flex-fill">
            <div className="card-body">
              <h4 className="card-title">
                Annual Anniversaries
                <span className="badge bg-inverse-danger ml-2">
                  {admin.AnnualAnniversary &&
                  admin.AnnualAnniversary.length > 0 ? (
                    admin.AnnualAnniversary.map((it, ind) => {
                      if (it.user.length > 0) {
                        i += it.user.length;
                      }
                      return (
                        ind === admin.AnnualAnniversary.length - 1 && <>{i}</>
                      );
                    })
                  ) : (
                    <>{i}</>
                  )}
                </span>
              </h4>
              {admin.AnnualAnniversary && admin.AnnualAnniversary.length > 0 ? (
                admin.AnnualAnniversary.map((current, i) => {
                  return (
                    current.user.length > 0 && (
                      <>
                        {current.user.map((e, index) => {
                          return (
                            <div className="leave-info-box py-2">
                              <div className="media align-items-center">
                                <NavLink
                                  to={`/profile/${e.id}`}
                                  className="avatar"
                                >
                                  {e.profilePic && e.profilePic.fileUrl ? (
                                    <img
                                      alt="profilePic"
                                      src={
                                        BUCKET_BASE_URL + e.profilePic.fileUrl
                                      }
                                      style={{
                                        objectFit: "fill",
                                        height: "100%",
                                      }}
                                    />
                                  ) : (
                                    <img alt="profilePic" src={defaultImg} />
                                  )}
                                </NavLink>
                                <div className="media-body">
                                  <div className="text-sm my-0">
                                    {e.firstName}, {e.lastName}
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center mt-2">
                                <div className="col-6">
                                  <h6 className="mb-0">
                                    {parseInt(
                                      e.startingDate.substring(0, 4)
                                    ) ===
                                    parseInt(moment().format().substring(0, 4))
                                      ? "Recently Joined"
                                      : parseInt(
                                          moment().format().substring(0, 4)
                                        ) -
                                        parseInt(
                                          e.startingDate.substring(0, 4)
                                        ) +
                                        " Year Completed"}
                                  </h6>
                                  <span className="text-sm text-muted"></span>
                                </div>
                                <div className="col-6 text-right">
                                  <span className="badge bg-primary text-white">
                                    {i === 0
                                      ? "Today"
                                      : i === 1
                                      ? " Tomorrow"
                                      : " In " + (i + 1) + " days"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    )
                  );
                })
              ) : (
                <>
                  <br />
                  <br />
                  <div className="dash-stats-list">
                    <img
                      alt=""
                      src={cake}
                      style={{ width: "110px", opacity: "0.8" }}
                    />
                    <br />
                    <p style={{ opacity: "0.5" }}>No Annual Anniversary</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* {   
                user.annivarsary &&
                user.annivarsary.length > 0 &&
                <section className="dash-section">
                <h1 className="dash-sec-title">Annual Anniversaries</h1>
                {
                user.annivarsary.map((current,i) => {
                return(
                    current.length > 0 &&
                        <div className="dash-sec-content" key={i}>
                            <div className="dash-info-list">
                                {
                                    current.map((e, index)=> {
                                        console.log(e)
                                        return(
                                            <div className="dash-card" key={index}>
                                                <div className="dash-card-container">
                                                    <div className="dash-card-icon">
                                                        <i className="fa fa-suitcase"></i>
                                                    </div>
                                                    <div className="dash-card-content miminctbx">
                                                        <p>{e.firstName}, {e.lastName}</p>
                                                        <div className="mimintpbx"><span><i className="fa fa-clock-o"></i></span>
                                                        { i === 0 ? " Today" : i === 1 ? " Tomorrow" : " In " +(i+1)+ " days" } <span><i className="fa fa-hourglass-2"></i></span> <NavLink to="/location-view"> { parseInt(e.startingDate.substring(0,4)) === parseInt(moment().format().substring(0,4)) ? "Joined Recently": (parseInt(moment().format().substring(0,4)) - parseInt(e.startingDate.substring(0,4))) + " Year"}</NavLink></div>
                                                    </div>
                                                    <div className="dash-card-avatars miminbtbx mianibx">
                                                        <NavLink to="#" className="e-avatar"><img src={avatar5} alt="" /></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>  
                        )
                    })
                }
            </section>
            }  */}

        {admin.LateComing && admin.LateComing.length > 0 ? (
          <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
            <div className="card flex-fill">
              <div className="card-body">
                <h4 className="card-title">
                  Late Coming
                  <span className="badge bg-inverse-danger ml-2">
                    {admin.LateComing && admin.LateComing.length}
                  </span>
                </h4>
                {admin.LateComing &&
                  admin.LateComing.length > 0 &&
                  admin.LateComing.map((current, i) => {
                    return (
                      <>
                        <div className="leave-info-box">
                          <div className="media align-items-center">
                            <NavLink to="/profile" className="avatar">
                              {current.user &&
                              current.user.userId &&
                              current.user.userId.profilePic &&
                              current.user.userId.profilePic.fileUrl ? (
                                <img
                                  alt="profilePic"
                                  src={
                                    BUCKET_BASE_URL +
                                    current.user.userId.profilePic.fileUrl
                                  }
                                  style={{ objectFit: "fill", height: "100%" }}
                                />
                              ) : (
                                <img alt="profilePic" src={defaultImg} />
                              )}
                            </NavLink>
                            <div className="media-body">
                              <div className="text-sm my-0">
                                {current.user.userId.firstName}{" "}
                                {current.user.userId.lastName}
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mt-3">
                            <div className="col-6">
                              <h6 className="mb-0">
                                {current.user
                                  ? current.user?.location?.name
                                  : ""}
                              </h6>
                              <span className="text-sm text-muted">
                                Timings :{" "}
                                {current.user
                                  ? current.user.startTime.substring(11, 16) +
                                    " - " +
                                    current.user.endTime.substring(11, 16)
                                  : "without shift"}
                              </span>
                              {/* shift time ha */}
                              {/* <span className="text-sm text-muted">{current.startTime.substring(15, 00)}</span> */}
                            </div>
                            <div className="col-6 text-right">
                              <span className="badge bg-danger text-white">
                                {current.late} late
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                {/* <div className="load-more text-center">
                                <NavLink className="text-dark" to="">Take Action</NavLink>
                            </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
            <div className="card flex-fill">
              <div className="card-body">
                <h4 className="card-title">
                  Late Coming
                  <span className="badge bg-inverse-danger ml-2">
                    {admin.LateComing && admin.LateComing.length}
                  </span>
                </h4>
                <br />
                <div className="dash-stats-list">
                  <img
                    alt=""
                    src={shift1}
                    style={{ width: "110px", opacity: "0.8" }}
                  />
                  <br />
                  <p style={{ opacity: "0.5" }}>No Late Comers</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col-md-12 col-lg-6 col-xl-4 d-flex">
          <div className="card flex-fill">
            <div className="card-body">
              <h4 className="card-title">
                Today Absent{" "}
                <span className="badge bg-inverse-danger ml-2">5</span>
              </h4>
              <div className="leave-info-box">
                <div className="media align-items-center">
                  <NavLink to="/profile" className="avatar">
                    <img alt="" src={defaultImg} />
                  </NavLink>
                  <div className="media-body">
                    <div className="text-sm my-0">Martin Lewis</div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-6">
                    <h6 className="mb-0">4 Sep 2021</h6>
                    <span className="text-sm text-muted">Leave Date</span>
                  </div>
                  <div className="col-6 text-right">
                    <span className="badge bg-inverse-danger">Pending</span>
                  </div>
                </div>
              </div>
              <div className="leave-info-box">
                <div className="media align-items-center">
                  <NavLink to="/profile" className="avatar">
                    <img alt="" src={defaultImg} />
                  </NavLink>
                  <div className="media-body">
                    <div className="text-sm my-0">Martin Lewis</div>
                  </div>
                </div>
                <div className="row align-items-center mt-3">
                  <div className="col-6">
                    <h6 className="mb-0">4 Sep 2021</h6>
                    <span className="text-sm text-muted">Leave Date</span>
                  </div>
                  <div className="col-6 text-right">
                    <span className="badge bg-inverse-success">Approved</span>
                  </div>
                </div>
              </div>
              {/* <div className="load-more text-center">
                                <NavLink className="text-dark" to="">Load More</NavLink>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeDetails;
