import React, { useState } from 'react'
import DeleteBRModal from './DeleteBRModal'
import EditModalBRTable from './EditModalBRTable'

const BreakRulesTableRow = ({current, i}) => {
    const [showDropDown, setshowDropDown] = useState(false)
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    }
    // console.log(showDropDown)
        
    const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    const openDeleteDropDown = () => {
        setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
    }
    return (
        <div key={i} className="card card-table mb-5 milocpmn">
            <div className="card-header">
                <h5 className="card-title mb-3">{current.breakName}</h5>
                {/* <h5>Description</h5> */}
                {/* <p>Applies to paid and unpaid breaks for shifts that last up to 15 hours for employees based in California. One 30-minute unpaid break is created for daily shifts.</p> */}
            </div>
            <div className="card-body">
                {/* <div className="table-responsive"> */}
                    <table className="table table-hover table-center mb-0">
                        <thead>
                            <tr>
                                <th>&nbsp;Break Type</th>
                                <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Min Shift Length</th>
                                <th></th>
                                <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Break Duration</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><span className="btn btn-warning"> {current.breakType}</span></td>
                                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{current.minimumShiftDuration}</td>
                                <td></td>
                                <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{current.breakDuration}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <div className="dropdown dropdown-action">
                                        <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></a>
                                        <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></a>
                                    </div>
                                </td>
                            </tr>
                            <EditModalBRTable current={current} showDropDown={showDropDown} openDropDown={openDropDown} />
                            <DeleteBRModal current={current} showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown} />
                        </tbody>
                    </table>
            </div>
        </div>
        // </div>
    )
}
export default BreakRulesTableRow