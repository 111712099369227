// import moment from 'moment'
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { getSAdminCompanyById } from "../../actions/superAdminCompanyActions";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SuperAdminClientProfileCover from "../../components/superAdminCompoents/superAdminClientProfileCompany/SuperAdminClientProfileCover";
import SuperAdminProfileNavBar from "../../components/superAdminCompoents/superAdminClientProfileCompany/SuperAdminProfileNavBar";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
const SuperAdminClientProfile = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // console.log("params==>", params.id);
  useEffect(() => {
    if (params.id) {
      dispatch(getSAdminCompanyById({ companyId: params.id }));
    }
  }, [params.id]);
  // const user = useSelector((state) => state.user);
  const company = useSelector((state) => state.company);
  return (
    <>
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="Client Profile" />
            {company.superAdminCompanyCPC &&
              company.superAdminCompanyCPC !== null && (
                <>
                  <SuperAdminClientProfileCover />
                  <SuperAdminProfileNavBar />
                </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminClientProfile;
