import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addLeave } from '../../../actions/leaveActions'

const ModalCreateLeave = ({ showDropDown, openDropDown}) => {
    const auth = useSelector((state) => state.auth);
    const [ values, setValues ] = useState({
        companyId: '',
        name: '',
        numberOfDays: '',
        everyYear: '',
        paidLeave: '',
        status: ''
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    // console.log(values)
    const dispatch = useDispatch()
    const leaveData = {
        companyId: auth.user.companyId,
        name: values.name,
        numberOfDays: values.numberOfDays,
        everyYear: values.everyYear === "true" ? true : false ,
        paidLeave: values.paidLeave === "true" ? true : false ,
        status: values.status === "true" ? true : false ,
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        dispatch(addLeave(leaveData))
        openDropDown()
        setValues({
            companyId: '',
            name: '',
            numberOfDays: '',
            everyYear: '',
            paidLeave: '',
            status: ''
        })
    }
    return (
        <>
            <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
            <div id="add_leave" className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Leave</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Leave Type <span className="text-danger">*</span></label>
                                    <input className="form-control" required type="text"
                                    autoComplete="false" value={values.name}
                                    onChange={handleChange('name')}/>
                                </div>
                                <div className="form-group">
                                    <label>Number of days <span className="text-danger">*</span></label>
                                    <input className="form-control" required type="text" 
                                    autoComplete="false" value={values.numberOfDays}
                                    onChange={handleChange('numberOfDays')}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Paid Leave <span className="text-danger">*</span></label>
                                    {
                                        values.paidLeave === "" ?
                                        <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                        autoComplete="false" value={values.paidLeave = "true"}
                                        onChange={handleChange('paidLeave')}>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        :
                                        <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                        autoComplete="false" value={values.paidLeave}
                                        onChange={handleChange('paidLeave')}>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Continue this Leave Category after 1 year<span className="text-danger">*</span></label>
                                    {
                                        values.everyYear === "" ?
                                        <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                        autoComplete="false" value={values.everyYear = "true"}
                                        onChange={handleChange('everyYear')}>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        :
                                        <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                        autoComplete="false" value={values.everyYear}
                                        onChange={handleChange('everyYear')}>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Status<span className="text-danger">*</span></label>
                                    {
                                        values.status === "" ?
                                        <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                        autoComplete="false" value={values.status = "true"}
                                        onChange={handleChange('status')}>
                                            <option value="true">On</option>
                                            <option value="false">Off</option>
                                        </select>
                                        :
                                        <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                        autoComplete="false" value={values.status}
                                        onChange={handleChange('status')}>
                                            <option value="true">On</option>
                                            <option value="false">Off</option>
                                        </select>
                                    }
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModalCreateLeave