import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateBreakrule } from '../../../actions/breakruleAction'

const EditModalBRTable = ({ showDropDown, openDropDown, current}) => {
   const [ values, setValues ] = useState({
      id: '',
      breakName: '',
      breakType: '',
      minimumShiftDuration: '',
      breakDuration: '',
   })
   const handleChange = (fieldName) => (event) =>{
      setValues({
         ...values, [fieldName]:
         event.target.value
      })
   }
   const currentBreakRule = async () => {
      try {
         setValues({
            id: '',
            breakName: '',
            breakType: '',
            minimumShiftDuration: '',
            breakDuration: '',
         })
         setValues({
            id: current.id,
            breakName: current.breakName,
            breakType: current.breakType,
            minimumShiftDuration: current.minimumShiftDuration,
            breakDuration: current.breakDuration,
         })
      } catch (error) {
         console.log(error)
      }
   }
   useEffect(() => {
      currentBreakRule()
   }, [])
   const dispatch = useDispatch()
   const updateBreakRuleData = {
      _id: values.id,
      breakName: values.breakName,
      breakType: values.breakType,
      minimumShiftDuration: values.minimumShiftDuration,
      breakDuration: values.breakDuration,
   }
   const handleSubmit = async (event) => {
      event.preventDefault()
      dispatch(updateBreakrule(updateBreakRuleData))
      // openDropDown()
      setValues({
         id: '',
         breakName: '',
         breakType: '',
         minimumShiftDuration: '',
         breakDuration: '',
      })
   }
   // console.log(values, "breakruletpes")
   return (
      <>
         <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
         <div id="edit_plan" className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-md modal-dialog-centered">
               <div className="modal-content">
                  <button type="button" className="close" data-dismiss="modal" onClick={() => openDropDown()}><i className="fa fa-close"></i></button>
                  <div className="modal-body">
                     <h5 className="modal-title text-center mb-3">Edit Break Rule</h5>
                     <form onSubmit={handleSubmit}>
                        <div className="row">

                        </div>
                        <div className="form-group">
                           <label>Break Name <span className="text-danger">*</span></label>
                           <input className="form-control" type="text"
                           // autoComplete="false" 
                           value={values.breakName}
                           onChange={handleChange('breakName')}/>
                        </div>
                        <div className="form-group">
                           <label>Break Type <span className="text-danger">* &nbsp;</span></label>
                           <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                              autoComplete='false' 
                              value={values.breakType}
                              onChange={handleChange('breakType')}>
                              {/* <option></option> */}
                              <option value="Unpaid">Unpaid Break</option>
                              <option value="Paid">Paid Break</option>
                           </select>
                        </div>
                        <div className="form-group">
                           <label>Minimum Shift Duration <span className="text-danger">*</span></label>
                           <input className="form-control" type="text"
                           // autoComplete="false" 
                           value={values.minimumShiftDuration}
                           onChange={handleChange('minimumShiftDuration')}/>
                        </div>
                        <div className="form-group">
                           <label>Break Duration In Minutes<span className="text-danger">*</span></label>
                           <input className="form-control" type="text" 
                           // autoComplete="false" 
                           value={values.breakDuration}
                           onChange={handleChange('breakDuration')}/>
                        </div>
                        <div className="m-t-20 text-center">
                           <button className="btn btn-primary submit-btn">Submit</button>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
export default EditModalBRTable