import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { reportTimeClockWeekly } from '../../../actions/adminActions'
import { useDispatch, useSelector } from 'react-redux';
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"
import { BUCKET_BASE_URL } from '../../../constants';

const TimeClockReportWeeklySearchTable = () => {
    const auth = useSelector((state) => state.auth);
    const admin = useSelector((state) => state.admin);
    const [ today, setToday ] = useState(moment())
    let date = moment(today.format('YYYY-MM-DD'))
    let dateForJS = moment(today.format('YYYY-MM-DD'))
    let compArr = []
    let dateDay = [0,1,2,3,4,5,6]
    for(let e of dateDay)(
        compArr[e] = dateForJS.startOf('week').add(++e, 'days').format('DD')
    )
    // console.log(compArr)
    const dispatch = useDispatch()
    const weeklyTimeClockReportData = () => {
        dispatch(reportTimeClockWeekly({companyId: auth.user.companyId, 
            date: moment().format('YYYY-MM-DD'),
            type: "Weekly"}))
    }
    useEffect(() => {
        weeklyTimeClockReportData()
    }, [])

    return (
        <>
        <br />
            <div className="row miordr2">
                <div className="col-md-12">
                    <div className="table-responsive misftsch">
                        <table className="table table-striped custom-table datatable ">
                            <thead >
                                <tr style={{backgroundColor:"#fff"}}>
                                    {/* <th colSpan="2">All Employees / Positions</th> */}
                                    <th colSpan="23">All Employees / Positions</th>
                                    {/* <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th> */}
                                </tr>
                                {/* </thead>
                                <thead> */}
                                <tr className="report-tbhdrw2" style={{backgroundColor:"#F1F6FF", width: "100%"}}>
                                    <th>Name</th><th className="report-tbhdtt1" style={{backgroundColor:"#F1F6FF"}} colSpan="22">Total</th>
                                    {/* <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th> */}
                                    {/* <th></th> */}
                                    {/* <th className="report-tbhdst1 report-tbhdfst">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th>
                                    <th className="report-tbhdst1">Start</th><th className="report-tbhden1">End</th><th className="report-tbhdhr1">Hrs</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {   
                                admin.searchReportTimeClock &&
                                admin.searchReportTimeClock.length > 0 &&
                                admin.searchReportTimeClock.map((current, i ) => {
                                    return(    
                                        <tr key={i}>
                                        {
                                            current.vary &&
                                            current.vary.length > 0 &&
                                            <>
                                    <td>
                                        <h2 className="table-avatar">

                                            <NavLink to={`/profile/${current.e.id}`} className="avatar">
                                                {
                                                    
                                                    current.e &&
                                                    current.e.profilePic &&
                                                    current.e.profilePic.fileUrl ?
                                                        <img alt="profilePic" src={BUCKET_BASE_URL + current.e.profilePic.fileUrl} style={{objectFit:"fill", height:"100%"}}/>
                                                        :
                                                        <img alt="profilePic" src={defaultImg} />
                                                }
                                            </NavLink>
                                            <NavLink to={`/profile/${current.e.id}`}>{current.e.firstName +", "+ current.e.lastName}<span>{current.e.jobTitle}</span></NavLink>
                                        </h2>
                                    </td>
                                    <td className="report-tbhdtt1">{Math.round(current.totalHours * 100) / 100 }</td>

                                    {
                                        current.vary &&
                                        current.vary.length > 0
                                        &&
                                        current.vary.map((e, j)=>{
                                            return(
                                            <>
                                                {
                                                    Object.keys(e).length != 0
                                                    // ?
                                                    &&
                                                    <>
                                                        <td className="report-tbhdst1 report-tbhdfst" style={{width: "auto", textAlign:"center", borderTop: "2px solid #000", borderBottom: "2px solid #000"}}>Start<br/>{e.dayClockIn.toString().substring(0,10)} <br/> {e.dayClockIn.toString().substring(11,16)}</td>
                                                        <td className="report-tbhden1" style={{width: "auto", textAlign:"center", borderTop: "2px solid #000", borderBottom: "2px solid #000"}}>End<br/>{e.dayClockIn.toString().substring(0,10)} <br/> {e.dayClockOut.toString().substring(11,16)}</td>
                                                        <td className="report-tbhdhr1" style={{width: "auto", textAlign:"center", borderTop: "2px solid #000", borderBottom: "2px solid #000"}}>Hrs<br/>{e.hoursUsed}</td>
                                                    </>
                                                    // :
                                                    // <>
                                                    //     <td className="report-tbhdst1 report-tbhdfst"></td>
                                                    //     <td className="report-tbhden1"></td>
                                                    //     <td className="report-tbhdhr1"></td>
                                                    // </>
                                                }
                                            </>
                                            )
                                        })
                                        // :
                                        // <td></td>
                                        // [0,0,0,0,0,0,0].map(g=>{
                                        //     return(
                                        //         <>
                                        //             <td className="report-tbhdst1 report-tbhdfst"></td>
                                        //             <td className="report-tbhden1"></td>
                                        //             <td className="report-tbhdhr1"></td>
                                        //         </>
                                        //     )
                                        //     })
                                    }
                    
                                            </>
                                }
                                </tr>
                            )
                        })}
                            </tbody>
                        </table>

                        {/* <div className="row">
                            <div className="col-sm-12 col-md-5">
                                <label> Showing 1 to 4 of 4 entries </label>
                            </div>
                            <div className="col-sm-12 col-md-7">
                                <div className="avatar-pagination">
                                    <ul className="pagination" style={{ justifyContent: 'flex-end' }}>
                                        <li className="page-item">
                                            <NavLink className="page-link" to="#" aria-label="Previous">
                                                <span aria-hidden="true">Previous</span>
                                                <span className="sr-only">Previous</span>
                                            </NavLink>
                                        </li>
                                        <li className="page-item active"><NavLink className="page-link" to="#">1</NavLink></li>
                                        <li className="page-item"><NavLink className="page-link" to="#">2</NavLink></li>
                                        <li className="page-item">
                                            <NavLink className="page-link" to="#" aria-label="Next">
                                                <span aria-hidden="true">Next</span>
                                                <span className="sr-only">Next</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="dataTables_length" id="DataTables_Table_0_length">
                                    <label>Show &nbsp;
                                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                                            className="select">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> entries
                                    </label>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </div>
        </>
    )
}

export default TimeClockReportWeeklySearchTable


{/* <tr>
    <td>
        <h2 className="table-avatar">
            <NavLink to="/profile" className="avatar"><img alt="" src="assetsAdmin/img/avatar-02.jpg" /></NavLink>
            <NavLink to="/profile">Ali, Abdirahman <span>Un-Armed Officer</span></NavLink>
        </h2>
    </td>
    <td className="report-tbhdtt1">40.00</td>

    <td className="report-tbhdst1 report-tbhdfst">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
</tr>
<tr>
    <td>
        <h2 className="table-avatar">
            <NavLink to="/profile" className="avatar"><img alt="" src="assetsAdmin/img/avatar-02.jpg" /></NavLink>
            <NavLink to="/profile">Bennet, Ishiae <span>Un-Armed Officer</span></NavLink>
        </h2>
    </td>
    <td className="report-tbhdtt1">40.00</td>

    <td className="report-tbhdst1 report-tbhdfst">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
</tr>
<tr>
    <td>
        <h2 className="table-avatar">
            <NavLink to="/profile" className="avatar"><img alt="" src="assetsAdmin/img/avatar-02.jpg" /></NavLink>
            <NavLink to="/profile">Bowman, Luvell <span>Un-Armed Officer</span></NavLink>
        </h2>
    </td>
    <td className="report-tbhdtt1">40.00</td>

    <td className="report-tbhdst1 report-tbhdfst">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>

    <td className="report-tbhdst1">20:00</td>
    <td className="report-tbhden1">03:30</td>
    <td className="report-tbhdhr1">7.50</td>
</tr> */}