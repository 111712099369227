import React, { useState } from 'react'
import { addDeduction } from '../../../actions/payrollAction'
import { useDispatch, useSelector } from 'react-redux'
const AddDeductionModal = ({showDropDown, openDropDown}) => {
    const auth = useSelector((state) => state.auth)
    const [ values, setValues ] = useState({
        companyId: '',
        name: '',
        amount: '',
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    // console.log(values)
    const dispatch = useDispatch()
    const deductionData = {
        companyId: auth.user.companyId,
        name: values.name,
        amount: parseInt(values.amount),
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        dispatch(addDeduction(deductionData))
        // openDropDown()
        setValues({
            companyId: '',
            name: '',
            amount: ''
        })
    }
    return (
        <>
        <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
            <div id="add_deduction" className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} role="dialog">
                {/* modal custom-modal fade commit krhe hoon takay display hosky  */}
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Deduction</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Name <span className="text-danger">*</span></label>
                                    <input className="form-control" type="text"
                                    autoComplete="false" value={values.name}
                                    onChange={handleChange('name')}/>
                                </div>
                                
                                <div className="form-group">
                                    <label>Unit Amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input type="text" className="form-control"
                                        autoComplete="false" value={values.amount}
                                        onChange={handleChange('amount')}/>
                                        <div className="input-group-append">
                                            <span className="input-group-text">.00</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddDeductionModal