import React, { useEffect, useState }from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateOvertimeType } from '../../../actions/payrollAction';
const EditModalOvertimePayroll = ({ showDropDown, openDropDown, current }) => {
  const [ values, setValues ] = useState({
    id: '',
    name: '',
    rateType: '',
    rateMultiplier: '',
  })
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values, [fieldName]:
        event.target.value
    })
  }
  const params = useParams()
  const currentOvertime = async () => {
    try {
      setValues({
        id: '',
        name: '',
        rateType: '',
        rateMultiplier: '',
      })
      setValues({
        id: current.id,
        name: current.name,
        rateType : current.rateType,
        rateMultiplier: current.rateMultiplier,
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    currentOvertime()
  }, [])
  const dispatch = useDispatch()
  const updateOvertimeData = {
    _id: values.id,
    name: values.name,
    rateType : values.rateType,
    rateMultiplier: values.rateMultiplier,
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch(updateOvertimeType(updateOvertimeData))
    setValues({
      id: '',
      name: '',
      rateType: '',
      rateMultiplier: '',
    })
  }
  return (
    <>
      <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
      <div className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} id="edit_overtime" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Overtime</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
              <div className="row"></div>
                <div className="form-group">
                  <label>Name <span className="text-danger">*</span></label>
                  <input className="form-control" type="text"
                  value={values.name}
                  onChange={handleChange('name')}/>
                </div>
                <div className="form-group">
                  <label>Rate Type <span className="text-danger">*</span></label>
                  <br />
                  <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                    autoComplete='false'
                    value={values.rateType}
                    onChange={handleChange('rateType')}>
                    <option>-</option>
                    <option>Daily Rate</option>
                    <option>Hourly Rate</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Rate <span className="text-danger">*</span></label>
                  <input className="form-control" type="text"
                  value={values.rateMultiplier}
                  onChange={handleChange('rateMultiplier')}/>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default EditModalOvertimePayroll