import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import DeleteModal from '../adminAllEmployeeComponents/DeleteModal'
import EditModalProfileCover from '../profileComponent/EditModalProfileCover'
import moment from 'moment'
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"
import { BUCKET_BASE_URL } from '../../../constants'
const AdminUserRow = ({current, i, dropDownRow, setDropDownRow}) => {
    // const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const [showDropDown, setshowDropDown] = useState(false)
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    const openMoreVertDropDown = () => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    }
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
        setshowMoreVertDropDown(false)
    }
    const openDeleteDropDown = () => {
        setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
        setshowMoreVertDropDown(false)
    }
    // const [state, setstate] = useState({currentId:""})
    // const changeState = () => {  
    //     setstate({currentId:current.id})
    // }
    return (
        <>
            <tr key={i} onMouseLeave = {() => setshowMoreVertDropDown(false)}>
                <td>
                    <h2 className="table-avatar">
                        <NavLink to={`/profile/${current.id}`} className="avatar">
                            {
                            current.profilePic &&
                            current.profilePic.fileUrl ?
                                <img alt="profilePic" src={BUCKET_BASE_URL + current.profilePic.fileUrl} style={{objectFit:"fill", height:"100%"}}/>
                                :
                                <img alt="profilePic" src={defaultImg} />
                            }
                        </NavLink>
                        <NavLink to={`/profile/${current.id}`}>{current.firstName + " " + current.lastName}<span>{current.jobTitle}</span></NavLink>
                    </h2>
                </td>
                <td>{current.email}</td>
                <td>{auth.company[0].company.companyName}</td>
                <td>{moment(current.startingDate).format("Do MMMM YYYY")}</td>
                <td>
                    <span className="badge bg-inverse-success">Employee</span>
                </td>
                {/* <td className="text-right">
                    <div className="dropdown dropdown-action">
                        <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={openMoreVertDropDown} aria-expanded="false"><i className="material-icons">more_vert</i></a>
                        <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                            <a className="dropdown-item" data-toggle="modal" onClick={openDropDown} data-target="#edit_user"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                            <a className="dropdown-item" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_user"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                        </div>
                    </div>
                </td> */}
                <td className="text-right">
                    <div className="dropdown dropdown-action">
                        <a className="action-icon dropdown-toggle" data-toggle="dropdown" aria-expanded="false" onClick={() => {
                            openMoreVertDropDown()
                            setDropDownRow(i)}} style={{cursor: 'pointer'}}><i className="material-icons">more_vert</i></a>
                            {
                            dropDownRow === i &&
                            <>
                                <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                                {/* <a className="dropdown-item" data-toggle="modal" onClick={openDropDown} data-target="#edit_employee"><i className="fa fa-pencil m-r-5"></i> Edit</a> */}
                                    <NavLink to={`/users/${current.id}`} className="dropdown-item" data-toggle="modal" onClick={openDropDown} data-target="#edit_employee"><i className="fa fa-pencil m-r-5"></i> Edit</NavLink>
                                    <a className="dropdown-item" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_employee" style={{cursor: 'pointer'}}><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                    <a className="dropdown-item" data-toggle="modal"  href={`/profile/${current.id}`} data-target="#view_employee"><i className="fa fa-eye"></i> View</a>
                                {/* <button onClick={changeState}></button>
                                <Aaaa abc={state.abc} /> */}
                                </div>
                            </>
                            }
                    </div>
                </td>
            </tr>
            {/* <EditModalUser showDropDown={showDropDown} openDropDown={openDropDown} /> */}
            {/* <EditModalProfileCover current={current} showDropDown={showDropDown} openDropDown={openDropDown} /> */}
            <EditModalProfileCover showDropDown={showDropDown} openDropDown={openDropDown} />
            <DeleteModal current={current} showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown}/>
        </>
    )
}
export default AdminUserRow