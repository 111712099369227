import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  BUCKET_ACCESS_KEY_ID,
  BUCKET_BASE_URL,
  BUCKET_ENDPOINT,
  BUCKET_NAME,
  BUCKET_REGION,
  SERVERADDRESS,
} from "../../../constants";
import { updateUserByAdmin } from "../../../actions/userActions";
import AWS from "aws-sdk";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import moment from "moment";
import { toast } from "react-hot-toast";

// const S3_BUCKET = "riyoworkportal";
// // const REGION ='YOUR_DESIRED_REGION_HERE';

// AWS.config.update({
//   accessKeyId: "AKIAWVZVUJ2JJRJGBNPC",
//   secretAccessKey: "FYAbfPHSd6pJJQ9Rnxe5+gLihOJx8lxHs6gzDwkl",
// });

// const myBucket = new AWS.S3({
//   params: { Bucket: S3_BUCKET },
//   // region: REGION,
// });

const s3 = new AWS.S3({
  // accessKeyId: "42517f1906a1e312810ee9ed7a0307ab",
  // secretAccessKey: "5dcddf9ff5cb2705f6ac2fbf65712c825b9969278e4a1efb551bd8f98e79f2ee",
  region: BUCKET_REGION,
  endpoint: BUCKET_ENDPOINT,
  credentials: {
    accessKeyId: BUCKET_ACCESS_KEY_ID,
    secretAccessKey: BUCKET_ENDPOINT,
  },
});

const EditModalProfileCover = ({ showDropDown, openDropDown }) => {
  // console.log("abc", abc)
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location);
  const [theDate, setTheDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  // const [ theNewDate, setTheNewDate ] = useState(new Date(moment().format('YYYY-MM-DD')))
  // const [ dd, setDD ] = useState(false)
  // const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  // const [user22, setUser22] = useState({
  //     userById: null,
  // })
  // console.log(currentId, "currentId")
  const DOB = forwardRef(({ value, onClick }, ref) => (
    <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
      {/* {console.log("value=>", value)} */}
      <div className="cal-icon">
        <input
          className="form-control floating datatimepicker"
          type="text"
          value={value}
        />
      </div>
      <label className="focus-label">Date</label>
    </div>
  ));

  const handlePhone = (fieldName) => (event) => {
    // console.log(event.target.value.length, event.target.value, event.target.value !== "(", values.phone.substring(4,values.phone.length), values.phone.length, values.phone.substring(0,3))
    if (event.target.value.length === 1) {
      if (event.target.value !== "(") {
        setValues({
          ...values,
          [fieldName]: "(" + event.target.value,
        });
      }
    } else if (event.target.value.length === 4) {
      if (event.target.value !== ")") {
        setValues({
          ...values,
          [fieldName]: event.target.value + ")-",
        });
      }
    } else if (event.target.value.length === 9) {
      if (event.target.value !== "-") {
        setValues({
          ...values,
          [fieldName]: event.target.value + "-",
        });
      }
    } else {
      setValues({
        ...values,
        [fieldName]: event.target.value,
      });
    }
  };

  const [values, setValues] = useState({
    userId: "",
    // companyId:'',
    firstName: "",
    lastName: "",
    // employeeId: '',
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    jobTitle: "",
    dateOfBirth: "",
    gender: "",
    hourlyWage: "",
    startingDate: "",
    employeeType: "",
    payType: "",
    address: "",
    state: "",
    // country:'',
    pinCode: "",
    timeZone: "",
    location: [],
    availability: {
      monday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      tuesday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      wednesday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      thursday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      friday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      saturday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
      sunday: {
        morningShift: false,
        eveningShift: false,
        nightShift: false,
      },
    },
  });

  //     useEffect(() => {
  //         console.log("ghhhhhhhhhhhhhhhhhhhhh",user.userById)
  //         const useeeee= user.userId
  //         const timer = setTimeout(() =>
  // {
  //         if (!user.userById){
  //             if (Object.keys(user.userById).length != 0) {
  //                 console.log("user.userById.user", user.userById)
  //                 if(user.userById !== undefined){

  //                     if (!user.userById.user ){
  //                     console.log("useeeeeeeeeeeeeeeeeeee",useeeee)
  //                 setValues({
  //                     firstName: user.userById.user.firstName,
  //                     lastName: user.userById.user.lastName,
  //                     employeeId: user.userById.user.employeeId,
  //                     email: user.userById.user.email,
  //                     password: user.userById.user.password,
  //                     phone: user.userById.user.phone,
  //                     jobTitle: user.userById.user.jobTitle,
  //                     dateOfBirth: user.userById.user.dateOfBirth,
  //                     gender: user.userById.user.gender,
  //                     hourlyWage: user.userById.user.hourlyWage,
  //                     employeeType: user.userById.user.employeeType,
  //                     payType: user.userById.user.payType,
  //                     address: user.userById.user.address,
  //                     state: user.userById.user.state,
  //                     country: user.userById.user.country,
  //                     pinCode: user.userById.user.pinCode,
  //                     timeZone: user.userById.user.timeZone,
  //                     location: user.userById.user.location,
  //                     availability: user.userById.user.availability,
  //                     })
  //                 }
  //                 }
  //                }
  //                console.log("timer runs")
  //             }
  //         , 5000);

  //         }
  //         }, [])
  //         yeh commit kiya toh chalay
  //         }, [user.userById])
  const params = useParams();
  const [idForUpload, setIdForUpload] = useState();
  const [disabledValue, setDisabledValue] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  // const changeLocValues = () => {
  //     if (values.locationRequired === "false"){
  //         setValues({...values, location: []})
  //         setLocObj([])
  //     }
  // }
  const userData = async () => {
    try {
      setValues({
        userId: "",
        // companyId:'',
        firstName: "",
        lastName: "",
        // employeeId: '',
        email: "",
        password: "",
        confirmPassword: "",
        phone: "",
        jobTitle: "",
        dateOfBirth: "",
        gender: "",
        hourlyWage: "",
        startingDate: "",
        employeeType: "",
        payType: "",
        address: "",
        state: "",
        // country:'',
        pinCode: "",
        timeZone: "",
        location: [],
        locationRequired: "",
        disable: "",
        availability: {
          monday: {
            morningShift: false,
            eveningShift: false,
            nightShift: false,
          },
          tuesday: {
            morningShift: false,
            eveningShift: false,
            nightShift: false,
          },
          wednesday: {
            morningShift: false,
            eveningShift: false,
            nightShift: false,
          },
          thursday: {
            morningShift: false,
            eveningShift: false,
            nightShift: false,
          },
          friday: {
            morningShift: false,
            eveningShift: false,
            nightShift: false,
          },
          saturday: {
            morningShift: false,
            eveningShift: false,
            nightShift: false,
          },
          sunday: {
            morningShift: false,
            eveningShift: false,
            nightShift: false,
          },
        },
      });
      // console.log("params for modal",params.id)
      if (params.id) {
        const res = await axios.post(`${SERVERADDRESS}/v1/users/userById`, {
          userId: params.id,
        });
        // console.log(res.data)
        let abc = res.data.user.location;
        // console.log('abc', abc)
        let arr = [];
        let arr2 = [];
        for (const e of abc) {
          for (const f of location.location) {
            if (e.id === f.id) {
              arr.push({ id: e.id, name: e.name });
              arr2.push(e.id);
            }
          }
        }

        // const no = res.data.user.phone
        // let noArr = no.split(" ")
        setValues({
          userId: res.data.user.id,
          firstName: res.data.user.firstName,
          lastName: res.data.user.lastName,
          // employeeId: res.data.user.employeeId,
          email: res.data.user.email,
          // password: res.data.user.password,
          phone: res.data.user.phone,
          jobTitle: res.data.user.jobTitle,
          dateOfBirth: res.data.user.dateOfBirth,
          gender: res.data.user.gender,
          hourlyWage: res.data.user.hourlyWage,
          employeeType: res.data.user.employeeType,
          payType: res.data.user.payType,
          address: res.data.user.address,
          state: res.data.user.state,
          // country: res.data.user.country,
          pinCode: res.data.user.pinCode,
          timeZone: res.data.user.timeZone,
          locationRequired: res.data.user.locationRequired,
          disable: res.data.user.disable,
          location: arr2,
          availability: {
            monday: {
              morningShift: res.data.user.availability.monday.morningShift,
              eveningShift: res.data.user.availability.monday.eveningShift,
              nightShift: res.data.user.availability.monday.nightShift,
            },
            tuesday: {
              morningShift: res.data.user.availability.tuesday.morningShift,
              eveningShift: res.data.user.availability.tuesday.eveningShift,
              nightShift: res.data.user.availability.tuesday.nightShift,
            },
            wednesday: {
              morningShift: res.data.user.availability.wednesday.morningShift,
              eveningShift: res.data.user.availability.wednesday.eveningShift,
              nightShift: res.data.user.availability.wednesday.nightShift,
            },
            thursday: {
              morningShift: res.data.user.availability.thursday.morningShift,
              eveningShift: res.data.user.availability.thursday.eveningShift,
              nightShift: res.data.user.availability.thursday.nightShift,
            },
            friday: {
              morningShift: res.data.user.availability.friday.morningShift,
              eveningShift: res.data.user.availability.friday.eveningShift,
              nightShift: res.data.user.availability.friday.nightShift,
            },
            saturday: {
              morningShift: res.data.user.availability.saturday.morningShift,
              eveningShift: res.data.user.availability.saturday.eveningShift,
              nightShift: res.data.user.availability.saturday.nightShift,
            },
            sunday: {
              morningShift: res.data.user.availability.sunday.morningShift,
              eveningShift: res.data.user.availability.sunday.eveningShift,
              nightShift: res.data.user.availability.sunday.nightShift,
            },
          },
        });
        setDisabledValue(res.data.user.disable);
        // setTheDate(new Date("'"+res.data.user.dateOfBirth.substring(8,10)+"/"+res.data.user.dateOfBirth.substring(5,7)+"/"+res.data.user.dateOfBirth.substring(0,4)+"'"))
        setTheDate(
          new Date(
            res.data.user.dateOfBirth.substring(0, 4),
            parseInt(res.data.user.dateOfBirth.substring(5, 7)) - 1,
            res.data.user.dateOfBirth.substring(8, 10)
          )
        );
        // setTheDate(new Date(2014,8,14))
        setLocObj(arr);
        setIdForUpload(res.data.user.id);
        // console.log("locObj", locObj, res.data.user.profilePic)
        if (res.data.user.profilePic !== undefined) {
          setSelectedImage(res.data.user.profilePic.fileUrl);
        } else {
          setSelectedImage(defaultImg);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // const userData2 = async() => {
  //     try {
  //         const res = await axios.post(`${SERVERADDRESS}/v1/users/userById`,
  //         {userId: currentId})
  //         console.log(res.data)
  //         let abc = res.data.user.location
  //         console.log('abc', abc)
  //         let arr = []
  //         let arr2 = []
  //         for (const e of abc){
  //             for (const f of location.location){
  //                 if(e.id === f.id){
  //                     arr.push({id:e.id, name: e.name})
  //                     arr2.push(e.id)
  //                 }
  //             }
  //         }
  //         setValues({
  //             firstName: res.data.user.firstName,
  //             lastName: res.data.user.lastName,
  //             employeeId: res.data.user.employeeId,
  //             email: res.data.user.email,
  //             password: res.data.user.password,
  //             phone: res.data.user.phone,
  //             jobTitle: res.data.user.jobTitle,
  //             dateOfBirth: res.data.user.dateOfBirth,
  //             gender: res.data.user.gender,
  //             hourlyWage: res.data.user.hourlyWage,
  //             employeeType: res.data.user.employeeType,
  //             payType: res.data.user.payType,
  //             address: res.data.user.address,
  //             state: res.data.user.state,
  //             country: res.data.user.country,
  //             pinCode: res.data.user.pinCode,
  //             timeZone: res.data.user.timeZone,
  //             location: arr2,
  //             availability: {
  //                 monday: {
  //                     morningShift: res.data.user.availability.monday.morningShift,
  //                     eveningShift: res.data.user.availability.monday.eveningShift,
  //                     nightShift: res.data.user.availability.monday.nightShift,
  //                 },
  //                 tuesday: {
  //                     morningShift: res.data.user.availability.tuesday.morningShift,
  //                     eveningShift: res.data.user.availability.tuesday.eveningShift,
  //                     nightShift: res.data.user.availability.tuesday.nightShift,
  //                 },
  //                 wednesday: {
  //                     morningShift: res.data.user.availability.wednesday.morningShift,
  //                     eveningShift: res.data.user.availability.wednesday.eveningShift,
  //                     nightShift: res.data.user.availability.wednesday.nightShift,
  //                 },
  //                 thursday: {
  //                     morningShift: res.data.user.availability.thursday.morningShift,
  //                     eveningShift: res.data.user.availability.thursday.eveningShift,
  //                     nightShift: res.data.user.availability.thursday.nightShift,
  //                 },
  //                 friday: {
  //                     morningShift: res.data.user.availability.friday.morningShift,
  //                     eveningShift: res.data.user.availability.friday.eveningShift,
  //                     nightShift: res.data.user.availability.friday.nightShift,
  //                 },
  //                 saturday: {
  //                     morningShift: res.data.user.availability.saturday.morningShift,
  //                     eveningShift: res.data.user.availability.saturday.eveningShift,
  //                     nightShift: res.data.user.availability.saturday.nightShift,
  //                 },
  //                 sunday: {
  //                     morningShift: res.data.user.availability.sunday.morningShift,
  //                     eveningShift: res.data.user.availability.sunday.eveningShift,
  //                     nightShift: res.data.user.availability.sunday.nightShift,
  //                 },
  //             }
  //         })
  //         setTheDate(new Date("'"+res.data.user.dateOfBirth.substring(8,10)+"/"+res.data.user.dateOfBirth.substring(5,7)+"/"+res.data.user.dateOfBirth.substring(0,4)+"'"))
  //         setLocObj(arr)
  //         console.log("locObj", locObj)
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }
  useEffect(() => {
    // if(user.userById !== undefined){
    //     setUser22({
    //         userById: user.userById
    //     })
    // }
    // if (!user.userById){
    //         if(user.userById !== undefined){
    //             if (!user.userById.user ){
    //             setValues({
    //                 firstName: user.userById.user.firstName,
    //                 lastName: user.userById.user.lastName,
    //                 employeeId: user.userById.user.employeeId,
    //                 email: user.userById.user.email,
    //                 password: user.userById.user.password,
    //                 phone: user.userById.user.phone,
    //                 jobTitle: user.userById.user.jobTitle,
    //                 dateOfBirth: user.userById.user.dateOfBirth,
    //                 gender: user.userById.user.gender,
    //                 hourlyWage: user.userById.user.hourlyWage,
    //                 employeeType: user.userById.user.employeeType,
    //                 payType: user.userById.user.payType,
    //                 address: user.userById.user.address,
    //                 state: user.userById.user.state,
    //                 country: user.userById.user.country,
    //                 pinCode: user.userById.user.pinCode,
    //                 timeZone: user.userById.user.timeZone,
    //                 location: user.userById.user.location,
    //                 availability: user.userById.user.availability,
    //                 })
    //             }
    //         }
    //     }
    userData();
    // console.log("working")
  }, [openDropDown]);

  const [locObj, setLocObj] = useState([]);
  const handleLocationChange = (fieldName) => (event) => {
    // console.log(event.target.value)
    const abc = JSON.parse(event.target.value);
    if (abc !== null) {
      if (values.location.includes(abc.id) === false) {
        // console.log(abc)
        setLocObj([...locObj, abc]);
        setValues({
          ...values,
          [fieldName]: [...values.location, abc.id],
        });
      }
    }
  };
  const deleteHandler = (id) => {
    setLocObj(locObj.filter((x) => x.id !== id));
    const index = values.location.indexOf(id);
    let arr = values.location;
    if (index > -1) {
      arr.splice(index, 1);
      // console.log(arr, "sss")
      setValues({ ...values, location: arr });
    }
  };
  const emptyLocationHandler = () => {
    setLocObj([]);
    setValues({ ...values, location: [] });
  };
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  // locatiion k liya handleloc and deletehandler

  const profilecoverData = {
    userId: values.userId,
    firstName: values.firstName,
    lastName: values.lastName,
    // employeeId: values.employeeId,
    email: values.email,
    password: values.password,
    phone: values.phone,
    jobTitle: values.jobTitle,
    dateOfBirth: theDate.toISOString().substring(0, 10),
    gender: values.gender,
    hourlyWage: values.hourlyWage,
    // startingDate: theNewDate.toISOString().substring(0,10),
    employeeType: values.employeeType,
    payType: values.payType,
    address: values.address,
    state: values.state,
    locationRequired: values.locationRequired === "true" ? true : false,
    disable: values.disable === "true" ? true : false,
    // country: values.country,
    pinCode: values.pinCode,
    location: values.location,
    availability: values.availability,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (disabledValue === profilecoverData.disable) {
      delete profilecoverData.disable;
      // console.log(profilecoverData);
    }
    if (values.password === values.passwordConfirm) {
      dispatch(updateUserByAdmin(profilecoverData));
    } else {
      // alert("Password, don't match");
      toast.error("Password, don't match");
    }
  };
  // console.log("values=>", profilecoverData)
  const [progress, setProgress] = useState(0);
  const [awsURL, setAwsURL] = useState(null);
  // const config = {
  //     onUploadProgress: progressEvent => console.log(progressEvent.loaded, "progressEvent.loadedprogressEvent.loaded")
  // }
  // const picFunc = async () => {
  //     const formData = new FormData();
  //     formData.append('file', "D:/Coding Work/IconicTek/RiyoWorkEmployee/src/assetsAdmin/img/avatar-02.jpg");
  //     console.log(formData,"555555555")
  //     await axios.post(`${SERVERADDRESS}/v1/mail/profilePicUpload`,
  //     {
  //         userId:"62e2bf7c9ffba739f9e8d3ed",
  //         companyId:"62e2bea09ffba739f9e8d3dd",
  //         file : formData.file
  //     }
  //     , config);
  // }
  // useEffect(() => {
  //     picFunc()
  // }, [])

  // const uploadFile = async (file) => {

  //     const params = {
  //         Bucket: S3_BUCKET,
  //         Key: "data/"+auth.company.companyName+"/ProfilePic/"+idForUpload+"/"+file.name,
  //         Body: file,
  //         ACL: 'public-read'
  //     };
  //     var upload = myBucket.upload(params)
  //     .on('httpUploadProgress',(evt) => {
  //         setProgress(Math.round((evt.loaded / evt.total) * 100))
  //     })
  //     .promise();

  //     await upload.then(function(data, err) {
  //         // alert("File uploaded successfully.");
  //         try {
  //             const url = data.Location
  //             console.log(url)
  //         } catch (error) {
  //             console.log(error, err, "ee");
  //         }
  //     });
  // }

  const alphabets = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
    "+",
    "-",
    "/",
    "*",
    "!",
    "@",
    "#",
    "$",
    "%",
    "^",
    "&",
    "_",
    "(",
    ")",
  ];
  const handleChangePic = async (event) => {
    // console.log(event.target.files[0])
    // const dddddd =event.target.files[0]
    // setSelectedImage(event.target.files[0])
    // const formData = new FormData();
    // formData.append('File', event.target.files[0]);
    // formData.append("userId","62e2bf7c9ffba739f9e8d3ed");
    // formData.append("companyId","62e2bea09ffba739f9e8d3dd");
    // console.log(dddddd.name, formData)
    // // picFunc()
    // axios.post(`${SERVERADDRESS}/v1/mail/profilePicUpload`,
    // {
    //     userId:"62e2bf7c9ffba739f9e8d3ed",
    //     companyId:"62e2bea09ffba739f9e8d3dd",
    //     file :formData.file
    // }
    // // formData
    // , config);
    // uploadFile(event.target.files[0])
    // const params = {
    //     Bucket: S3_BUCKET,
    //     Key: "data/"+auth.company.companyName+"/ProfilePic/"+idForUpload+"/"+event.target.files[0].name,
    //     Body: event.target.files[0],
    //     ACL: 'public-read'
    // };
    // var upload = myBucket.upload(params)
    // .on('httpUploadProgress',(evt) => {
    //     setProgress(Math.round((evt.loaded / evt.total) * 100))
    // })
    // .promise();
    // let url;
    // await upload.then(function(data, err) {
    //     // alert("File uploaded successfully.");
    //     try {
    //         url = data.Location
    //         setAwsURL(url)
    //         console.log(url)
    //     } catch (error) {
    //         console.log(error, err, "ee");
    //     }
    // });

    const today = new Date();
    const tsForUnique = today.getTime();
    //   const { name } = req.body;
    const filePath =
      "data/" +
      auth.company.companyName +
      "/ProfilePic/" +
      idForUpload +
      "/" +
      event.target.files[0].name;

    const params = {
      Bucket: BUCKET_NAME,
      Key: filePath,
      Body: event.target.files[0],

      // ACL: 'public-read',
    };

    await s3
      .upload(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
        //   console.log("pro==>", Math.round((evt.loaded / evt.total) * 100));
      })
      .promise();
    // let url="";
    // await multipartUpload();
    const url = filePath;
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/mail/profilePicUpload`,
      {
        url: url,
        file: event.target.files[0].name,
        type: event.target.files[0].type,
        userId: values.userId,
        companyId: auth.company.id,
      }
    );
    // console.log(pic)
    dispatch(updateUserByAdmin({ userId: values.userId, profilePic: data.id }));
    setSelectedImage(url);
  };
  // console.log(values, "<-values")
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        id="profile_info "
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Basic Information</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                {
                  // user.userById &&
                  // user.userById.user &&
                  values && (
                    <>
                      <div className="row">
                        <div className="col-md-12">
                          {/* <div>Native SDK File Upload Progress is {progress}%</div> */}
                          <div className="profile-img-wrap edit-img">
                            {selectedImage && (
                              <img
                                className="inline-block"
                                src={BUCKET_BASE_URL + selectedImage}
                                alt="Profile Pic"
                              />
                            )}
                            <div className="fileupload btn">
                              <span className="btn-text">edit</span>
                              <input
                                className="upload"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                onChange={handleChangePic}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>First Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={values.firstName}
                                  onChange={handleChange("firstName")}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={values.lastName}
                                  onChange={handleChange("lastName")}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Birth Date</label>
                                <br />
                                {/* {
                                                                            dd === false ?
                                                                            <div className="form-group form-focus focused">
                                                                                <div className="cal-icon">
                                                                                    <input className="form-control floating datetimepicker" type="text" value={values.dateOfBirth} onClick={()=>{
                                                                                        setDD(true)
                                                                                        // setTheDate(new Date("'"+values.dateOfBirth.toString().substring(8,10)+"/"+values.dateOfBirth.substring(5,7)+"/"+values.dateOfBirth.substring(0,4)+"'"))
                                                                                        // setTheDate(new Date('14/08/2014'))
                                                                                    }}/>
                                                                                </div>
                                                                                <label className="focus-label">Date</label>
                                                                            </div>
                                                                            :
                                                                            <DatePicker
                                                                                selected={theDate}
                                                                                onChange={(date) => setTheDate(date)}
                                                                                customInput={<DOB />}
                                                                            />
                                                                        } */}
                                <DatePicker
                                  selected={theDate}
                                  onChange={(date) => setTheDate(date)}
                                  customInput={<DOB />}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Gender</label>
                                <select
                                  className="form-select"
                                  style={{ height: "44px", fontSize: "14px" }}
                                  value={values.gender}
                                  onChange={handleChange("gender")}
                                >
                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Address</label>
                            <input
                              type="text"
                              className="form-control"
                              value={values.address}
                              onChange={handleChange("address")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>State</label>
                            <input
                              type="text"
                              className="form-control"
                              value={values.state}
                              onChange={handleChange("state")}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Country</label>
                                                <input type="text" className="form-control" value={values.country} onChange={handleChange('country')}  />
                                            </div>
                                        </div> */}
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Pin Code</label>
                            <input
                              type="number"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                              className="form-control"
                              value={values.pinCode}
                              onChange={handleChange("pinCode")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Phone Number</label>

                            {/* <input type="number"
                                                onKeyDown={(e) => {
                                                    if (alphabets.includes(e.key)) {
                                                        e.preventDefault();
                                                    } 
                                                }}
                                                className="form-control" value={values.phone} onChange={handleChange('phone')}/>
                                            </div> */}
                            <input
                              className="form-control"
                              minLength="14"
                              maxLength="14"
                              required
                              // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
                              onKeyDown={(e) => {
                                if (alphabets.includes(e.key)) {
                                  e.preventDefault();
                                }
                                if (
                                  e.code === "Backspace" &&
                                  values.phone.length === 6
                                ) {
                                  setValues({
                                    ...values,
                                    phone: values.phone.substring(0, 4),
                                  });
                                }
                                if (
                                  e.code === "Backspace" &&
                                  values.phone.length === 2
                                ) {
                                  setValues({
                                    ...values,
                                    phone: "",
                                  });
                                }
                                if (
                                  e.code === "Backspace" &&
                                  values.phone.length === 10
                                ) {
                                  setValues({
                                    ...values,
                                    phone: values.phone.substring(0, 9),
                                  });
                                }
                              }}
                              name="phone"
                              value={values.phone}
                              onChange={handlePhone("phone")}
                              placeholder="(123)-456-7890"
                              autocomplete="new-mobileNunmber"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Disable </label>
                            <br />
                            <select
                              className="form-select"
                              style={{ height: "44px", fontSize: "14px" }}
                              // autoComplete='false'
                              defaultValue={values.disable}
                              value={values.disable}
                              onChange={handleChange("disable")}
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Does User Required Location to Clock In?{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <br />
                            <select
                              className="form-select"
                              style={{ height: "44px", fontSize: "14px" }}
                              // autoComplete='false'
                              // defaultValue={values.locationRequired.toString()}
                              value={values.locationRequired}
                              onChange={handleChange("locationRequired")}
                              onClick={() =>
                                values.locationRequired === "false"
                                  ? emptyLocationHandler()
                                  : console.log("already no location added")
                              }
                            >
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Primary Location{" "}
                            </label>
                            <br />
                            {/* <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                                    autoComplete='false'
                                                    onChange={handleLocationChange('location')} >
                                                        <option value="null">Select</option>
                                                        { 
                                                            location.location &&
                                                            location.location.length > 0 &&
                                                            location.location.map((current, i) => {
                                                                return (
                                                                    <option value={JSON.stringify({id:current.id, name:current.name})} key={i+1}>{current.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select> */}
                            {values.locationRequired &&
                            values.locationRequired.toString() === "true" ? (
                              <select
                                className="form-select"
                                style={{ height: "44px", fontSize: "14px" }}
                                autoComplete="false"
                                onChange={handleLocationChange("location")}
                              >
                                <option value="null">Select</option>
                                {location.location &&
                                  location.location.length > 0 &&
                                  location.location.map((current, i) => {
                                    return (
                                      <option
                                        value={JSON.stringify({
                                          id: current.id,
                                          name: current.name,
                                        })}
                                        key={i + 1}
                                      >
                                        {current.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            ) : (
                              // setLocObj([]) &&
                              <select
                                className="form-select"
                                style={{ height: "44px", fontSize: "14px" }}
                                autoComplete="false"
                                disabled
                                onChange={handleLocationChange("location")}
                              >
                                <option value="null">Select</option>
                                {location.location &&
                                  location.location.length > 0 &&
                                  location.location.map((current, i) => {
                                    return (
                                      <option
                                        value={JSON.stringify({
                                          id: current.id,
                                          name: current.name,
                                        })}
                                        key={i + 1}
                                      >
                                        {current.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label className="col-form-label">
                              Selected Location
                            </label>
                            <ul>
                              {locObj.map((elem, ind) => {
                                return (
                                  <li key={ind}>
                                    {elem.name}
                                    <i
                                      style={{ float: "right" }}
                                      className="fa fa-trash"
                                      onClick={() => deleteHandler(elem.id)}
                                    ></i>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive m-t-15">
                        <table className="table table-striped custom-table">
                          <thead>
                            <tr>
                              <th>Available Timings</th>
                              <th className="text-center">Mon</th>
                              <th className="text-center">Tue</th>
                              <th className="text-center">Wed</th>
                              <th className="text-center">Thu</th>
                              <th className="text-center">Fri</th>
                              <th className="text-center">Sat</th>
                              <th className="text-center">Sun</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Morning Shift</td>
                              {/* <td className="text-center">
                                                        <input checked="" type="checkbox" />
                                                    </td> */}
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.monday.morningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        monday: {
                                          ...values.availability.monday,
                                          morningShift:
                                            !values.availability.monday
                                              .morningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.tuesday.morningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        tuesday: {
                                          ...values.availability.tuesday,
                                          morningShift:
                                            !values.availability.tuesday
                                              .morningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.wednesday.morningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        wednesday: {
                                          ...values.availability.wednesday,
                                          morningShift:
                                            !values.availability.wednesday
                                              .morningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.thursday.morningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        thursday: {
                                          ...values.availability.thursday,
                                          morningShift:
                                            !values.availability.thursday
                                              .morningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.friday.morningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        friday: {
                                          ...values.availability.friday,
                                          morningShift:
                                            !values.availability.friday
                                              .morningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.saturday.morningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        saturday: {
                                          ...values.availability.saturday,
                                          morningShift:
                                            !values.availability.saturday
                                              .morningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.sunday.morningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        sunday: {
                                          ...values.availability.sunday,
                                          morningShift:
                                            !values.availability.sunday
                                              .morningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Evening Shift</td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.monday.eveningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        monday: {
                                          ...values.availability.monday,
                                          eveningShift:
                                            !values.availability.monday
                                              .eveningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.tuesday.eveningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        tuesday: {
                                          ...values.availability.tuesday,
                                          eveningShift:
                                            !values.availability.tuesday
                                              .eveningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.wednesday.eveningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        wednesday: {
                                          ...values.availability.wednesday,
                                          eveningShift:
                                            !values.availability.wednesday
                                              .eveningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.thursday.eveningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        thursday: {
                                          ...values.availability.thursday,
                                          eveningShift:
                                            !values.availability.thursday
                                              .eveningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.friday.eveningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        friday: {
                                          ...values.availability.friday,
                                          eveningShift:
                                            !values.availability.friday
                                              .eveningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.saturday.eveningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        saturday: {
                                          ...values.availability.saturday,
                                          eveningShift:
                                            !values.availability.saturday
                                              .eveningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.sunday.eveningShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        sunday: {
                                          ...values.availability.sunday,
                                          eveningShift:
                                            !values.availability.sunday
                                              .eveningShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Night Shift</td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.monday.nightShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        monday: {
                                          ...values.availability.monday,
                                          nightShift:
                                            !values.availability.monday
                                              .nightShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.tuesday.nightShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        tuesday: {
                                          ...values.availability.tuesday,
                                          nightShift:
                                            !values.availability.tuesday
                                              .nightShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.wednesday.nightShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        wednesday: {
                                          ...values.availability.wednesday,
                                          nightShift:
                                            !values.availability.wednesday
                                              .nightShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.thursday.nightShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        thursday: {
                                          ...values.availability.thursday,
                                          nightShift:
                                            !values.availability.thursday
                                              .nightShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.friday.nightShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        friday: {
                                          ...values.availability.friday,
                                          nightShift:
                                            !values.availability.friday
                                              .nightShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.saturday.nightShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        saturday: {
                                          ...values.availability.saturday,
                                          nightShift:
                                            !values.availability.saturday
                                              .nightShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                              <td className="text-center">
                                <input
                                  type="checkbox"
                                  checked={
                                    values.availability.sunday.nightShift
                                  }
                                  onClick={() =>
                                    setValues({
                                      ...values,
                                      availability: {
                                        ...values.availability,
                                        sunday: {
                                          ...values.availability.sunday,
                                          nightShift:
                                            !values.availability.sunday
                                              .nightShift,
                                        },
                                      },
                                    })
                                  }
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="submit-section">
                        {user.error === "ACC-400-LIMIT-REACHED" && (
                          <>
                            <span
                              className="text-danger"
                              style={{ fontSize: "12px" }}
                            >
                              Account Limit Reached. Upgrade Your Plan
                            </span>
                            <br />
                          </>
                        )}
                        <button className="btn btn-primary submit-btn">
                          Submit
                        </button>
                      </div>
                    </>
                  )
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditModalProfileCover;
