import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
// import { NavLink } from 'react-router-dom'
const AdminSubsPlanTransactions = () => {
    const admin = useSelector((state) => state.admin)
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-table mb-0">
                        <div className="card-header">
                            <h4 className="card-title mb-0">Plan Transactions</h4>
                        </div>
                        <div className="card-body">
                            {/* <div className="table-responsive"> */}
                                <table className="table table-hover table-center mb-0">
                                    <thead>
                                        <tr>
                                            <th>Plan</th>
                                            <th>Users</th>
                                            <th>Plan Duration</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Amount</th>
                                            <th>Update Plan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {   admin.paymentInvoice &&
                                            admin.paymentInvoice.data &&
                                            admin.paymentInvoice.data.length > 0 &&
                                            admin.paymentInvoice.data.map((current, i) => {
                                                // console.log(current.lines.data[i]) // agae jakr data i se sath krna paregah yah phir index ki pheli value lehkr previous data.lines.data peh map
                                                return(
                                                    <tr key={i}>
                                                        <td>Starter</td>
                                                        <td>20 Users</td>
                                                        <td>1 Month</td>
                                                        <td>{moment.unix(current.lines.data[0].period.start).format('DD MMM YYYY')}</td>
                                                        <td>{moment.unix(current.lines.data[0].period.end).format('DD MMM YYYY')}</td>
                                                        <td>$159.00</td>
                                                        <td><a className="btn btn-primary btn-sm" >Change Plan</a></td>
                                                    </tr>
                                        )})}
                                    </tbody>
                                </table>
                            </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminSubsPlanTransactions

// href="javascript:void(0);"