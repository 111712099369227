import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBreakrule } from "../../../actions/breakruleAction";

const AddBRModal = ({ showDropDown, openDropDown }) => {
  const auth = useSelector((state) => state.auth);
  const [values, setValues] = useState({
    companyId: "",
    breakName: "",
    breakType: "",
    minimumShiftDuration: "",
    breakDuration: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values)
  const dispatch = useDispatch();
  const breakruleData = {
    companyId: auth.user.companyId,
    breakName: values.breakName,
    breakType: values.breakType,
    minimumShiftDuration: parseInt(values.minimumShiftDuration),
    breakDuration: parseInt(values.breakDuration),
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    dispatch(addBreakrule(breakruleData));
    // openDropDown()
    setValues({
      companyId: "",
      breakName: "",
      breakType: "",
      minimumShiftDuration: "",
      breakDuration: "",
    });
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${showDropDown ? "show" : "hide"}`}
      ></div>
      <div
        id="add_plan"
        className={`modal custom-modal fade ${showDropDown ? "show" : "hide"}`}
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => openDropDown()}
            >
              <i className="fa fa-close"></i>
            </button>
            <div className="modal-body">
              <h5 className="modal-title text-center mb-3">Add Break Rule</h5>
              <form onSubmit={handleSubmit}>
                <div className="row"></div>
                <div className="form-group">
                  <label>
                    Break Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="false"
                    value={values.breakName}
                    onChange={handleChange("breakName")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Break Type <span className="text-danger">*</span>
                  </label>
                  {values.breakType === "" ? (
                    <select
                      className="form-select"
                      style={{ height: "44px", fontSize: "14px" }}
                      autoComplete="false"
                      value={(values.breakType = "Unpaid Break")}
                      onChange={handleChange("breakType")}
                    >
                      <option value="Unpaid Break">Unpaid Break</option>
                      <option value="Paid Break">Paid Break</option>
                    </select>
                  ) : (
                    <select
                      className="form-select"
                      style={{ height: "44px", fontSize: "14px" }}
                      autoComplete="false"
                      value={values.breakType}
                      onChange={handleChange("breakType")}
                    >
                      <option value="Unpaid">Unpaid Break</option>
                      <option value="Paid">Paid Break</option>
                    </select>
                  )}
                </div>
                <div className="form-group">
                  <label>
                    Minimum Shift Duration{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="false"
                    value={values.minimumShiftDuration}
                    onChange={handleChange("minimumShiftDuration")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    Break Duration In Minutes
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    autoComplete="false"
                    value={values.breakDuration}
                    onChange={handleChange("breakDuration")}
                  />
                </div>
                <div className="m-t-20 text-center">
                  <button className="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddBRModal;
