import moment from 'moment/moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { createCompanyAndAdmin } from '../../../actions/superAdminCompanyActions'

const CreateCompanyNewLeads = ({ showDropDown, openDropDown, current }) => {
    const [values, setValues] = useState({
        companyEmail: '',
        companyPhone: '',
        companyName: '',
        numberOfEmployees: '',
        industry: '',
        email: '',
        firstName: '',
        lastName: '',
        year: '',
        phone: '',
        role: '',
        cardNumber: '',
        expMonthYear: '',
        cvc: '',
        packageId: '',
        currentSubscriptionPackageType: '',
        trial_period_days: '',
        date: ''
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
           ...values, [fieldName]:
           event.target.value
        })
    }

    const handlePhone = (fieldName) => (event) =>{  
        // console.log(event.target.value.length, event.target.value, event.target.value !== "(", values.phone.substring(4,values.phone.length), values.phone.length, values.phone.substring(0,3))
        if(event.target.value.length === 1){
            if(event.target.value !== "("){
                setValues({
                    ...values, [fieldName]:
                    "(" +event.target.value
                })
            }
        }
        else if (event.target.value.length === 4){
            if(event.target.value !== ")"){
                setValues({
                    ...values, [fieldName]:
                    event.target.value+")-" 
                })
            }
        }
        else if (event.target.value.length === 9){
            if(event.target.value !== "-"){
                setValues({
                    ...values, [fieldName]:
                    event.target.value+"-" 
                })
            }
        }
        else {
            setValues({
               ...values, [fieldName]:
               event.target.value
            })
        }
    }

    const alphabets = [
        'a','b','c','d','f','g','h','i','j','k','l', 'm','n','o','p','q','r','s','t',
        'u','v','w','x','y','z','A','B','C','D', 'F','G','H','I','J',
        'K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','+','-',
        '/','*','!','@','#','$','%','^','&','_','(',')',
        ];
    // const currentTraining = async () => {
    //     try {
    //        setValues({
    //         companyEmail: '',
    //         companyPhone: '',
    //         companyName: '',
    //         numberOfEmployees: '',
    //         industry: '',
    //         email: '',
    //         firstName: '',
    //         lastName: '',
    //         year: '',
    //         phone: '',
    //         role: '',
    //         cardNumber: '',
    //         expMonthYear: '',
    //         cvc: '',
    //         packageId: '',
    //         currentSubscriptionPackageType: '',
    //         trial_period_days: '',
    //         date: ''
    //         })
    //        setValues({
    //         companyEmail: current.companyEmail,
    //         companyPhone: current.companyPhone,
    //         companyName: current.companyName,
    //         numberOfEmployees: current.numberOfEmployees,
    //         industry: current.industry,
    //         email: current.email,
    //         firstName: current.firstName,
    //         lastName: current.lastName,
    //         phone: current.phone,
    //         year: moment().format('YY'),
    //         role: 'admin',
    //         cardNumber: current.cardNumber,
    //         expMonthYear: current.expMonthYear,
    //         cvc: current.cvc,
    //         packageId: current.packageId.id,
    //         currentSubscriptionPackageType: current.currentSubscriptionPackageType,
    //         trial_period_days: 0,
    //         date: current.date.toString().substring(0,10)
    //        })
    //     //    console.log(values, "llok")
    //     } catch (error) {
    //       console.log(error)
    //     }
    // }
    useEffect(() => {
        // currentTraining()
        setValues({
            companyEmail: current.companyEmail,
            companyPhone: current.companyPhone,
            companyName: current.companyName,
            numberOfEmployees: current.numberOfEmployees,
            industry: current.industry,
            email: current.email,
            firstName: current.firstName,
            lastName: current.lastName,
            phone: current.phone,
            year: moment().format('YY'),
            role: 'admin',
            cardNumber: current.cardNumber,
            expMonthYear: current.expMonthYear,
            cvc: current.cvc,
            packageId: current.packageId.id,
            currentSubscriptionPackageType: current.currentSubscriptionPackageType,
            trial_period_days: 0,
            date: current.date.toString().substring(0,10)
        })
    }, [current])
    const dispatch = useDispatch()
    const updateData = {
        companyEmail: values.companyEmail,
        companyPhone: values.companyPhone,
        companyName: values.companyName,
        numberOfEmployees: values.numberOfEmployees,
        industry: values.industry,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        year: moment().format('YY'),
        role: 'admin',
        cardNumber: values.cardNumber,
        expMonthYear: values.expMonthYear,
        cvc: values.cvc,
        packageId: values.packageId,
        currentSubscriptionPackageType: values.currentSubscriptionPackageType,
        trial_period_days: parseInt(values.trial_period_days),
        date: values.date
    }
    // console.log(updateData)
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (updateData.trial_period_days === NaN || updateData.trial_period_days === 0){
            delete updateData.trial_period_days
        }
        dispatch(createCompanyAndAdmin(updateData))
        openDropDown()
    }

    return (
        <>
            <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
            <div id="add_client" className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirm Customer</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Company Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" disabled
                                            autoComplete="false" value={values.companyName}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Company Email <span className="text-danger">*</span></label>
                                            <input className="form-control floating" type="email" disabled
                                            autoComplete="false" value={values.companyEmail}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Company Phone <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" disabled
                                            autoComplete="false" value={values.companyPhone}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Number of Employees <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" disabled
                                            autoComplete="false" value={values.numberOfEmployees}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Industry <span className="text-danger">*</span></label>
                                            <input className="form-control floating" type="text" disabled
                                            autoComplete="false" value={values.industry}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Request Date <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" disabled
                                            autoComplete="false" value={values.date}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">First Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" disabled
                                            autoComplete="false" value={values.firstName}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Last Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" disabled
                                            autoComplete="false" value={values.lastName}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">User Email <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" disabled
                                            autoComplete="false" value={values.email}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">User Phone <span className="text-danger">*</span></label>
                                            {/* <input className="form-control" type="text" disabled
                                            autoComplete="false" value={values.phone}/> */}
                                        <input className="form-control"
                                            minLength="14"
                                            maxLength="14"
                                            required
                                            // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
                                            onKeyDown={(e) => {
                                                if (alphabets.includes(e.key)) {
                                                    e.preventDefault();
                                                } 
                                                if (e.code === "Backspace" && values.phone.length === 6){
                                                    setValues({
                                                    ...values, phone:
                                                    values.phone.substring(0,4)
                                                    })
                                                }
                                                if (e.code === "Backspace" && values.phone.length === 2){
                                                    setValues({
                                                    ...values, phone:
                                                    ''
                                                    })
                                                }
                                                if (e.code === "Backspace" && values.phone.length === 10){
                                                    setValues({
                                                    ...values, phone:
                                                    values.phone.substring(0,9)
                                                    })
                                                }
                                            }} name="phone" 
                                            value={values.phone}
                                            onChange={handlePhone('phone')}
                                            placeholder="(123)-456-7890" autocomplete="new-mobileNunmber"/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Package Name <span className="text-danger">*</span></label>
                                            {
                                                current &&
                                                <input className="form-control" type="text" disabled
                                                autoComplete="false" value={current.packageId.planName}
                                            />}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Package Type <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" disabled
                                            autoComplete="false" value={values.currentSubscriptionPackageType}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Trial Period Days <span className="text-danger">*</span></label>
                                            <input className="form-control" type="number"
                                            onKeyDown={(e) => {
                                                if (alphabets.includes(e.key)) {
                                                    e.preventDefault();
                                                } 
                                            }}
                                            autoComplete="false" value={values.trial_period_days}
                                            onChange={handleChange('trial_period_days')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn">Approved</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateCompanyNewLeads