import {
    // USER_LOGOUT_SUCCESS,
    // GET_USER_FAIL,
    // GET_USER_REQUEST,
    // GET_USER_SUCCESS,
    ADMIN_DASHBOARD_CARD_REQUEST,
    ADMIN_DASHBOARD_CARD_SUCCESS,
    ADMIN_DASHBOARD_CARD_FAIL,
    ADMIN_REPORT_SHIFT_WEEKLY_REQUEST,
    ADMIN_REPORT_SHIFT_WEEKLY_SUCCESS,
    ADMIN_REPORT_SHIFT_WEEKLY_FAIL,
    ADMIN_REPORT_SHIFT_DAILY_REQUEST,
    ADMIN_REPORT_SHIFT_DAILY_SUCCESS,
    ADMIN_REPORT_SHIFT_DAILY_FAIL,
    ADMIN_REPORT_TIME_CLOCK_WEEKLY_REQUEST,
    ADMIN_REPORT_TIME_CLOCK_WEEKLY_SUCCESS,
    ADMIN_REPORT_TIME_CLOCK_WEEKLY_FAIL,
    ADMIN_REPORT_TIME_CLOCK_DAILY_REQUEST,
    ADMIN_REPORT_TIME_CLOCK_DAILY_SUCCESS,
    ADMIN_REPORT_TIME_CLOCK_DAILY_FAIL,
    ADMIN_USERPAYMENTINFO_REQUEST,
    ADMIN_USERPAYMENTINFO_SUCCESS,
    ADMIN_USERPAYMENTINFO_FAIL,
    ADMIN_SHIFTALLUSERS_REQUEST,
    ADMIN_SHIFTALLUSERS_SUCCESS,
    ADMIN_SHIFTALLUSERS_FAIL,
    SHIFT_UPDATE_REQUEST,
    SHIFT_UPDATE_SUCCESS,
    SHIFT_UPDATE_FAIL,
    SEARCH_EMPLOYEE_REQUEST,
    SEARCH_EMPLOYEE_SUCCESS,
    SEARCH_EMPLOYEE_FAIL,
    SHIFT_CREATE_REQUEST,
    SHIFT_CREATE_SUCCESS,
    SHIFT_CREATE_FAIL,
    SEARCH_REPORT_TIMECLOCK_REQUEST,
    SEARCH_REPORT_TIMECLOCK_SUCCESS,
    SEARCH_REPORT_TIMECLOCK_FAIL,
    SEARCH_SALARYCALENDAR_DATA_REQUEST,
    SEARCH_SALARYCALENDAR_DATA_SUCCESS,
    SEARCH_SALARYCALENDAR_DATA_FAIL,
    SEARCH_REPORT_SHIFT_REQUEST,
    SEARCH_REPORT_SHIFT_SUCCESS,
    SEARCH_REPORT_SHIFT_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

// const initialState = {
//     auth: {
//         tokens: localStorage.getItem("tokens")
//             ? JSON.parse(localStorage.getItem("tokens"))
//             : null,
//         user: null,
//         loading: false,        
//     },
// };

export const adminReducer = (
    state = {
        dashboard: {
            // user: null,
            // tokens: null,
            LocationCount: null,
            UserCount: null,
            TrainingCount: null,
            CurrTimeIn: null,
            ActiveUser: null,
            UpcomingShift: null,
            AnnualAnniversary: null,
            LateComing: null,
            shiftReportWeekly : null,
            shiftReportDaily: null,
            timeClockReportWeekly : null,
            timeClockReportDaily: null,
            paymentInvoice: null,
            usersShifts: null,
            searchEmployee: null,
            loading: false,
            searchReportTimeClock: null,
            searchSalaryCalendarData: null,
            searchReportShift: null,
        },
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                LocationCount: null,
                UserCount: null,
                TrainingCount: null,
                CurrTimeIn: null,
                ActiveUser: null,
                UpcomingShift: null,
                AnnualAnniversary: null,
                LateComing: null,
                shiftReportWeekly : null,
                shiftReportDaily: null,
                timeClockReportWeekly : null,
                timeClockReportDaily: null,
                paymentInvoice: null,
                usersShifts: null,
                searchEmployee: null,
                loading: false,
                searchReportTimeClock: null,
                searchSalaryCalendarData: null,
                searchReportShift: null,
            };

        // ADMIN DASHBOARD CARD
        case ADMIN_DASHBOARD_CARD_REQUEST : 
            return {
                ...state,
                loading: true,
            };
        case ADMIN_DASHBOARD_CARD_SUCCESS :
            return {
                ...state,
                loading: false,
                // user: action.payload.user,
                // tokens: action.payload.tokens,
                LocationCount: action.payload[0].LocationCount,
                UserCount: action.payload[0].UserCount,
                TrainingCount: action.payload[0].TrainingCount,
                CurrTimeIn: action.payload[0].CurrTimeIn,
                ActiveUser: action.payload[0].ActiveUser,
                UpcomingShift: action.payload[0].UpcomingShift,
                AnnualAnniversary: action.payload[0].AnnualAnniversary,
                LateComing: action.payload[0].LateComing
            };
        case ADMIN_DASHBOARD_CARD_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        
        // ADMIN SHIFT REPORT WEEKLY
        case ADMIN_REPORT_SHIFT_WEEKLY_REQUEST : 
            return {
                ...state,
                loading: true,
            };
        case ADMIN_REPORT_SHIFT_WEEKLY_SUCCESS :
            return {
                ...state,
                loading: false,
                shiftReportWeekly: action.payload,
            };
        case ADMIN_REPORT_SHIFT_WEEKLY_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        // ADMIN SHIFT REPORT DAILY
        case ADMIN_REPORT_SHIFT_DAILY_REQUEST : 
            return {
                ...state,
                loading: true,
            };
        case ADMIN_REPORT_SHIFT_DAILY_SUCCESS :
            return {
                ...state,
                loading: false,
                shiftReportDaily: action.payload,
            };
        case ADMIN_REPORT_SHIFT_DAILY_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        // ADMIN TIME_CLOCK REPORT WEEKLY
        case ADMIN_REPORT_TIME_CLOCK_WEEKLY_REQUEST : 
            return {
                ...state,
                loading: true,
            };
        case ADMIN_REPORT_TIME_CLOCK_WEEKLY_SUCCESS :
            return {
                ...state,
                loading: false,
                timeClockReportWeekly: action.payload,
            };
        case ADMIN_REPORT_TIME_CLOCK_WEEKLY_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        // ADMIN TIME_CLOCK REPORT DAILY
        case ADMIN_REPORT_TIME_CLOCK_DAILY_REQUEST : 
            return {
                ...state,
                loading: true,
            };
        case ADMIN_REPORT_TIME_CLOCK_DAILY_SUCCESS :
            return {
                ...state,
                loading: false,
                timeClockReportDaily: action.payload,
            };
        case ADMIN_REPORT_TIME_CLOCK_DAILY_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        // ADMIN PAYMENT INFO
        case ADMIN_USERPAYMENTINFO_REQUEST : 
            return {
                ...state,
                loading: true,
            };
        case ADMIN_USERPAYMENTINFO_SUCCESS :
            return {
                ...state,
                loading: false,
                paymentInvoice: action.payload,
            };
        case ADMIN_USERPAYMENTINFO_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        // ADMIN SHIFTS USERS
        case ADMIN_SHIFTALLUSERS_REQUEST : 
            return {
                ...state,
                loading: true,
            };
        case ADMIN_SHIFTALLUSERS_SUCCESS :
            return {
                ...state,
                loading: false,
                usersShifts: action.payload,
            };
        case ADMIN_SHIFTALLUSERS_FAIL :
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        // UPDATE SHIFT

        case SHIFT_UPDATE_REQUEST : 
            return {
                ...state,
            };
        case SHIFT_UPDATE_SUCCESS :
            return {
                ...state,
                // usersShifts: state.usersShifts.map((x)=> 
                //     // x.dailyShift.map((y)=>
                //     //     y.id === action.payload.id ? {...y, dailyShift : action.payload} : y 
                //     // ) 
                //     // &&
                //                 // x.weeklyShift.map((z)=>
                //                 //     z.id === action.payload.id ? [...x,{...x, weeklyShift : action.payload}] : z
                //                 // )
                //     // &&
                //     // x.monthlyShift.map((e)=>
                //     //     e.id === action.payload.id ? {...e, monthlyShift : action.payload} : e 
                //     // )
                //     // &&
                //     // x.weeklyShift.id === action.payload.id ? {user: x.user, dailyShift: x.dailyShift, monthlyShift:x.monthlyShift, weeklyShift : action.payload} : x &&
                //     // x.monthlyShift.id === action.payload.id ? {user: x.user, weeklyShift: x.weeklyShift, monthlyShift:x.monthlyShift, dailyShift : action.payload} : x
                // )
            };
        case SHIFT_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        // ADMIN EMPLOYEE SEARCH

        case SEARCH_EMPLOYEE_REQUEST : 
            return {
                ...state,
            };
        case SEARCH_EMPLOYEE_SUCCESS :
            return {
                ...state,
                searchEmployee: action.payload,
            };
        case SEARCH_EMPLOYEE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case SHIFT_CREATE_REQUEST : 
            return {
                ...state,
            };
        case SHIFT_CREATE_SUCCESS :
            if (action.nnIndex){
                return {
                    ...state,
                    usersShifts: state.usersShifts.map((x) =>
                    x.user.id === action.payload.id ? {user: x.user, dailyShift: x.dailyShift, monthlyShift: x.monthlyShift, 
                        weeklyShift: x.weeklyShift.map((w, wi) => 
                            wi === action.nnIndex ? action.payload : w
                        )}
                        : 
                        x
                    ),
                };
            }
            else {
                return {
                    ...state,
                    usersShifts: state.usersShifts.map((x) =>
                    x.user.id === action.payload.id ? "remove" : x)
                };
            }
        case SHIFT_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        
        // ADMIN TIME CLOCK REPORT SEARCH 

        case SEARCH_REPORT_TIMECLOCK_REQUEST : 
            return {
                ...state,
            };
        case SEARCH_REPORT_TIMECLOCK_SUCCESS :
            return {
                ...state,
                searchReportTimeClock: action.payload,
            };
        case SEARCH_REPORT_TIMECLOCK_FAIL :
            return {
                ...state,
                error: action.payload,
            };

           
        // ADMIN SALARY CALENDAR SEARCH 

        case SEARCH_SALARYCALENDAR_DATA_REQUEST : 
            return {
                ...state,
            };
        case SEARCH_SALARYCALENDAR_DATA_SUCCESS :
            return {
                ...state,
                searchSalaryCalendarData: action.payload,
            };
        case SEARCH_SALARYCALENDAR_DATA_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // ADMIN SHIFT REPORT SEARCH 

        case SEARCH_REPORT_SHIFT_REQUEST : 
            return {
                ...state,
            };
        case SEARCH_REPORT_SHIFT_SUCCESS :
            return {
                ...state,
                searchReportShift: action.payload,
            };
        case SEARCH_REPORT_SHIFT_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        default:
            return state;
    }
}