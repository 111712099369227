import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBreakrule } from "../../actions/breakruleAction";
import { createGroupAction } from "../../actions/chatActions";

const CreateGroupModal = ({ openModal, setOpenModal }) => {
  // const auth = useSelector((state) => state.auth);
  // const [values, setValues] = useState({
  //   companyId: "",
  //   breakName: "",
  //   breakType: "",
  //   minimumShiftDuration: "",
  //   breakDuration: "",
  // });
  // const handleChange = (fieldName) => (event) => {
  //   setValues({
  //     ...values,
  //     [fieldName]: event.target.value,
  //   });
  // };
  // console.log(values)
  const dispatch = useDispatch();
  // const breakruleData = {
  //   companyId: auth.user.companyId,
  //   breakName: values.breakName,
  //   breakType: values.breakType,
  //   minimumShiftDuration: parseInt(values.minimumShiftDuration),
  //   breakDuration: parseInt(values.breakDuration),
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   // console.log("first")
  //   dispatch(addBreakrule(breakruleData));
  //   // openDropDown()
  //   setValues({
  //     companyId: "",
  //     breakName: "",
  //     breakType: "",
  //     minimumShiftDuration: "",
  //     breakDuration: "",
  //   });
  // };

  const mail = useSelector((state) => state.mail);
  const user = useSelector((state) => state.user);
  const auth = useSelector((state) => state.auth);
  const [values, setValues] = useState({
    userId: auth.user.id,
    company: auth.user.companyId,
    chatName: "",
    isGroup: true,
    isCompanyGroup: false,
    users: [],
    members: [],
    latestMessage: null,
    groupAdmin: auth.user.id,
    groupType: "Selected Users",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const [membersObj, setMembersObj] = useState([]);
  const handleMembersChange = (fieldName) => (event) => {
    const abc = JSON.parse(event.target.value);
    // console.log(event.target.value, values.members.includes(abc.id) === false)
    if (abc !== null) {
      if (values.members.includes(abc.id) === false) {
        // console.log(abc)
        setMembersObj([...membersObj, abc]);
        setValues({
          ...values,
          [fieldName]: [...values.members, abc.id],
        });
      }
    }
  };
  // console.log(values, "ssaaaaaaaaaaassasssasasasasasa", membersObj)
  const deleteHandler = (id) => {
    setMembersObj(membersObj.filter((x) => x.id !== id));
    const index = values.members.indexOf(id);
    let arr = values.members;
    if (index > -1) {
      arr.splice(index, 1);
      // console.log(arr, "sss")
      setValues({ ...values, members: arr });
    }
  };
  const groupData = {
    ...values,
    users: values.members,
  };
  // console.log("mail data==>", groupData);
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    dispatch(createGroupAction(groupData, successHandler));
  };

  const successHandler = () => {
    setOpenModal(false);
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${openModal ? "show" : "hide"}`}
      ></div>
      <div
        id="add_plan"
        className={`modal custom-modal fade ${openModal ? "show" : "hide"}`}
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setOpenModal(false)}
            >
              <i className="fa fa-close"></i>
            </button>
            <div className="modal-body">
              <h5 className="modal-title text-center mb-3">Create Group</h5>
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label className="col-form-label">Group Name: </label>
                          <input
                            type="text"
                            placeholder="Group Name"
                            className="form-control"
                            value={values.chatName}
                            onChange={handleChange("chatName")}
                          />
                        </div>
                        {auth.user.role === "superAdmin" && (
                          <div className="form-group">
                            {/* <input type="email" 
                                    placeholder="To" className="form-control" 
                                    /> */}
                            <label className="col-form-label">
                              Group Type:{" "}
                            </label>
                            <select
                              className="form-select"
                              style={{ height: "44px", fontSize: "14px" }}
                              autoComplete="false"
                              value={values.groupType}
                              onChange={handleChange("groupType")}
                            >
                              <option value="">Select Group Type</option>
                              <option value={"Selected Users"}>
                                Selective Users
                              </option>{" "}
                              <option value={"All Admins"}>All Admins</option>{" "}
                              <option value={"All Users"}>All Users </option>
                            </select>
                          </div>
                        )}
                        {values.groupType === "Selected Users" && (
                          <div className="form-group">
                            {/* <input type="email" 
                                    placeholder="To" className="form-control" 
                                    /> */}
                            <label className="col-form-label">
                              Add Member:{" "}
                            </label>
                            <select
                              className="form-select"
                              style={{ height: "44px", fontSize: "14px" }}
                              autoComplete="false"
                              onChange={handleMembersChange("members")}
                            >
                              <option value="">Select Members</option>
                              {user.allUsersWithoutPaginate &&
                                user.allUsersWithoutPaginate.length > 0 &&
                                user.allUsersWithoutPaginate.map(
                                  (current, i) => {
                                    return (
                                      <option
                                        value={JSON.stringify({
                                          id: current.id,
                                          name:
                                            current.firstName +
                                            " " +
                                            current.lastName,
                                          email: current.email,
                                          jobTitle:
                                            current.jobTitle.toUpperCase(),
                                        })}
                                        key={i + 1}
                                      >
                                        {current.firstName +
                                          " " +
                                          current.lastName}
                                        {` ( ${current.jobTitle.toUpperCase()} )`}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                            <div className="form-group">
                              <br />
                              <label className="col-form-label">
                                Group Members:{" "}
                              </label>
                              <ul
                                className="group-members-list list-unstyled bg-gray p-2
"
                              >
                                {membersObj.length === 0 ? (
                                  <p className="text-center mb-0">
                                    Members are not selected!
                                  </p>
                                ) : (
                                  <>
                                    {membersObj.map((elem, ind) => {
                                      return (
                                        <li
                                          className="p-2 border my-1 bg-white"
                                          key={ind}
                                        >
                                          {elem.name} {`( ${elem.jobTitle} )`}
                                          <span role="button">
                                            <i
                                              style={{ float: "right" }}
                                              className="fa fa-trash"
                                              onClick={() =>
                                                deleteHandler(elem.id)
                                              }
                                            ></i>
                                          </span>
                                        </li>
                                      );
                                    })}
                                  </>
                                )}
                              </ul>
                            </div>
                          </div>
                        )}
                        {/* <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="email" placeholder="Cc" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="email" placeholder="Bcc" className="form-control" />
                                        </div>
                                    </div>
                                </div> */}
                        {/* <div className="form-group">
                          <input
                            type="text"
                            placeholder="Subject"
                            className="form-control"
                            value={values.subject}
                            onChange={handleChange("subject")}
                          />
                        </div> */}
                        {/* message k liya */}
                        {/* <div className="form-group"> 
                                <Editor
                                    placeholder="Enter your message here"
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName" />
                                </div> */}
                        {/* <div className="form-group">
                          <textarea
                            rows="4"
                            className="form-control"
                            placeholder="Type a message..."
                            autoComplete="false"
                            value={values.message}
                            onChange={handleChange("message")}
                          ></textarea>
                        </div> */}
                        <div className="form-group mb-0">
                          <div className="text-center">
                            <button className="btn btn-primary">
                              <i className="fa fa-plus m-r-5"></i>
                              <span>Create</span>{" "}
                            </button>
                            {/* <button className="btn btn-success m-l-5" type="button"><span>Delete</span> <i className="fa fa-trash-o m-l-5"></i></button> */}
                            {/* <button className="btn btn-success m-l-5" type="button"><span>Draft</span> <i className="fa fa-floppy-o m-l-5"></i></button> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CreateGroupModal;
