import React from 'react'

const AdminAnnualPlan = () => {
    return (
        <div className="tab-content">
            <div className="tab-pane fade active show" id="monthly">
                <div className="row mb-30 equal-height-cards">
                    <div className="col-md-4">
                        <div className="card pricing-box text-center">
                            <div className="card-body d-flex flex-column">
                                <h3 className="title">Starter</h3>
                                <p className="mx-auto mt-3 mb-4">Simple workforce management for small and medium-sized businesses</p>
                                <span className="display-4 mb-1">$4.99</span>
                                <small className="mx-auto">Per user per month billed monthly <br /><span className="text-dark">USD (local taxes may apply)</span></small>
                                <ul className="mt-4 mx-auto">
                                    <li>Includes <strong className="mifs-1">20</strong> users</li>
                                </ul>
                                <a href="#" className="btn btn-lg btn-success mt-auto">Current Plan</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card pricing-box">
                            <div className="card-body d-flex flex-column">
                                <h3 className="title">Premium</h3>
                                <p className="mx-auto mt-3 mb-4">Professional scheduling for larger or complex businesses</p>
                                <span className="display-4 mb-1">$555.99</span>
                                <small className="mx-auto">Per user per month billed monthly <br /><span className="text-dark">USD (local taxes may apply)</span></small>
                                <ul className="mt-4 mx-auto">
                                    <li>Includes <strong className="mifs-1">50</strong> users</li>
                                </ul>
                                <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card pricing-box">
                            <div className="card-body d-flex flex-column">
                                <h3 className="title">Enterprise</h3>
                                <p className="mx-auto mt-3 mb-4">Fully customized scheduling platform for very large businesses</p>
                                <span className="display-4 mb-1">&nbsp;</span>
                                <small className="mx-auto">Custom pricing<br />for enterprise clients</small>
                                <ul className="mt-4 mx-auto">
                                    <li>&nbsp;</li>
                                </ul>
                                <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Call Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-pane fade" id="annual">
                <div className="row mb-30 equal-height-cards">
                    <div className="col-md-4">
                        <div className="card pricing-box text-center">
                            <div className="card-body d-flex flex-column">
                                <h3 className="title">Starter</h3>
                                <p className="mx-auto mt-3 mb-4">Simple workforce management for small and medium-sized businesses</p>
                                <span className="display-4 mb-1">$4.99</span>
                                <small className="mx-auto">Per user per month <br /><span className="text-dark">billed annually (local taxes may apply) <br /> save up to 11%</span></small>
                                <ul className="mt-4 mx-auto">
                                    <li>Includes <strong className="mifs-1">20</strong> users</li>
                                </ul>
                                <a href="#" className="btn btn-lg btn-success mt-auto">Current Plan</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card pricing-box">
                            <div className="card-body d-flex flex-column">
                                <h3 className="title">Premium</h3>
                                <p className="mx-auto mt-3 mb-4">Professional scheduling for larger or complex businesses</p>
                                <span className="display-4 mb-1">$5.99</span>
                                <small className="mx-auto">Per user per month <br /><span className="text-dark">billed annually (local taxes may apply) <br /> save up to 11%</span></small>
                                <ul className="mt-4 mx-auto">
                                    <li>Includes <strong className="mifs-1">50</strong> users</li>
                                </ul>
                                <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Upgrade</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card pricing-box">
                            <div className="card-body d-flex flex-column">
                                <h3 className="title">Enterprise</h3>
                                <p className="mx-auto mt-3 mb-4">Fully customized scheduling platform for very large businesses</p>
                                <span className="display-4 mb-1">&nbsp;</span>
                                <small className="mx-auto">Custom pricing<br />for enterprise clients</small>
                                <ul className="mt-4 mx-auto">
                                    <li>&nbsp;</li>
                                </ul>
                                <a href="#" className="btn btn-lg btn-outline-secondary mt-auto">Call Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AdminAnnualPlan