// import React, { useState } from 'react'
import React from 'react'
// import DeleteLocationModal from './DeleteLocationModal'
// import { useEffect } from 'react'
// import EditModalLPTable from './EditModalLPTable'
// import { NavLink } from 'react-router-dom'
const LocationPositionCardRow = ({current, i}) => {
    // const [showDropDown, setshowDropDown] = useState(false)
    // const [locId, setLocId] = useState({id:''})
    // const settingLocId = () => {
    //     setLocId({id: current.location.id})
    // }
    // useEffect(() => {
    //   settingLocId()
    // }, [])
    // const openDropDown = () => {
    //     setshowDropDown((showDropDown) => !showDropDown)
    // }
    // console.log("showDropDownshowDropDown", showDropDown)
    // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    // const openDeleteDropDown = () => {
    //     setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
    // }
    return (
        <div className="tab-content milocpmn" key={i}>
            <div className="tab-pane fade active show">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-table mb-0">
                            <div className="card-header">
                                <h4 className="card-title mb-0" >
                                  {/* href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i> */}
                                {current.location.name}
                                </h4 >
                                {/* <DeleteLocationModal current={current} showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown} /> */}
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                                        <div className="card dash-widget">
                                            <div className="card-body">
                                                <span className="dash-widget-icon"><i className="fa fa-map-marker"></i></span>
                                                <div className="dash-widget-info text-left">
                                                    <h3>Address:</h3>
                                                    <span>{current.location.address}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                                        <div className="card dash-widget">
                                            <div className="card-body">
                                                <span className="dash-widget-icon">
                                                    <i className="fa fa-clock-o"></i></span>
                                                <div className="dash-widget-info text-left">
                                                    <h3>Timezone:</h3>
                                                    {/* (UTC -06:00) America/Chicago         yeh span se andr likha hua tha  */}
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                                        <div className="card dash-widget">
                                            <div className="card-body">
                                                <span className="dash-widget-icon"><i className="fa fa-clock-o"></i></span>
                                                <div className="dash-widget-info text-left">
                                                    <h3>Break Rule:</h3>
                                                    {/* None         yeh span se andr likha hua tha  */}
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                {/* <div className="table-responsive"> */}
                                    <table className="table table-hover table-center mb-0">
                                        <thead>
                                            <tr>
                                                <th className='col-sm-9'>Name</th>
                                                <th className='col-sm-3'>Position</th>
                                                {/* <th className="text-right">Actions</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                current.users &&
                                                current.users.length > 0 &&
                                                current.users.map((e, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="sorting_1">
                                                                <h2 className="table-avatar">
                                                                    <a href="#" className="avatar">
                                                                        <div className="grnclr"></div>
                                                                    </a>
                                                                    <a>{e.firstName + " " + e.lastName}</a>
                                                                </h2>
                                                            </td>
                                                            <td>
                                                                {e.jobTitle}
                                                            </td>
                                                            {/* <td className="text-right">
                                                                <div className="dropdown dropdown-action">
                                                                    <a className="dropdown-item" data-toggle="modal" onClick={openDropDown} data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></a>
                                                                    <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></a>
                                                                    </div>
                                                            </td> */}
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    {/* <EditModalLPTable current={current} showDropDown={showDropDown} openDropDown={openDropDown} /> */}
                                    {/* <DeleteLocationModal current={current} showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown} /> */}
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LocationPositionCardRow