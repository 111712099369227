import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getClocksByMonthYearAndUserId } from '../../../actions/clockActions';
// import { NavLink } from 'react-router-dom'
const AdminTClockFilter = () => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const [values, setValues] = useState({
        companyId: auth.user.companyId,
        month: '',
        year: '',
    })
    const handleChange = (fieldName) => (event) => {
    setValues({
        ...values, [fieldName]:
            event.target.value
    })
    }
    let searchData;
    searchData = {
        userId: auth.user.id,
        date: values.year+"-"+values.month+"-02"
    }
    const [ isErr, setIsErr ] = useState(false)
    const handleClick = async (event) => {
        event.preventDefault()
        if (values.month === '' || values.year === '' ){
            setIsErr(true)
        }
        else{
            dispatch(getClocksByMonthYearAndUserId(searchData))
        }
    }
    return (
        <>
            <div className="row filter-row">
                <div className="col-sm-3">
                    <div className="form-group form-focus select-focus">
                        <div className="">
                            <input type="text" className="form-control floating datetimepicker" value={auth.user.firstName} disabled/>
                        </div>
                        <label className="focus-label" style={{fontSize:"13px"}}>Name</label>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group form-focus select-focus">
                        <select className="form-select" style={{ height: "50px", fontSize: "15px", paddingBottom: "0.01rem" }}
                        value={values.month} onChange={handleChange('month')}>
                            <option value=''>-</option>
                            <option value='01'>Jan</option>
                            <option value='02'>Feb</option>
                            <option value='03'>Mar</option>
                            <option value='04'>Apr</option>
                            <option value='05'>May</option>
                            <option value='06'>Jun</option>
                            <option value='07'>Jul</option>
                            <option value='08'>Aug</option>
                            <option value='09'>Sep</option>
                            <option value='10'>Oct</option>
                            <option value='11'>Nov</option>
                            <option value='12'>Dec</option>
                        </select>
                        <label className="focus-label" style={{fontSize:"13px"}}>Select Month
                        {
                            isErr === true && values.month === '' &&
                            <span className='text-danger'>&nbsp;<i className="fa fa-exclamation-circle"> Month can't be empty</i></span>
                        }
                        </label>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group form-focus select-focus">
                        <select className="form-select" style={{ height: "50px", fontSize: "15px", paddingBottom: "0.01rem" }}
                        value={values.year} onChange={handleChange('year')}>
                            <option value=''>-</option>
                            <option value='2022'>2022</option>
                            <option value='2023'>2023</option>
                            <option value='2024'>2024</option>
                            <option value='2025'>2025</option>
                        </select>
                        <label className="focus-label" style={{fontSize:"13px"}}>Select Year
                        {
                            isErr === true && values.year === '' &&
                            <span className='text-danger'>&nbsp;<i className="fa fa-exclamation-circle"> Year can't be empty</i></span>
                        }
                        </label>
                    </div>
                </div>
                <div className="col-sm-3">
                    <a to="#" onClick={handleClick} className="btn btn-success btn-block"> Search </a>
                </div>
            </div>
        </>
    )
}
export default AdminTClockFilter