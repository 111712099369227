import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clockExist } from '../../../actions/clockActions'
import moment from 'moment'
import axios from 'axios'
import { createUserLoginInfo } from '../../../actions/authActions'
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
// import { useState } from 'react'
import { Device } from '@capacitor/device';
// import useGeoLocation from "react-ipgeolocation";
 
// const fpPromise = FingerprintJS.load({
//     apiKey: "i7N9beoU1gfCTAClei8Q"
// })
const AdminDashboardCard = () => {
    // const [ fingerPrintToken, setFingerPrintToken ] = useState()
//     fpPromise
//   .then(fp => fp.get())
//   .then(result => setFingerPrintToken(result.visitorId))
//   console.log("fingerprint", fingerPrintToken)
    const admin = useSelector((state) => state.admin)
    const auth = useSelector((state) => state.auth)
    // clockHistory[clockHistory.length - 1].clockIn
    const dispatch = useDispatch()
    const dasboardTimeIn = async (e) => {
        // dispatch(clockExist({companyId: auth.user.companyId}))
        const dd = moment().format('YYYY-MM-DD')
        const todayRegex = dd.substring(0,10)
        // console.log(todayRegex)
        const iden = "newDate"+todayRegex+"user"+auth.user.id;
        dispatch(clockExist({dayIdentifier: iden}))
    }
    const locationOfAdmin = async () => {
        const info = await Device.getId();
        // console.log("capacitor", info)
        const res = await axios.get('https://geolocation-db.com/json/')
        // fpPromise
        //     .then(fp => fp.get())
        //     .then(result => 
        //             {
        //                 const currDate = moment().format()
        //                 const arr = currDate.split("+")
        //                 const newCurrDate = arr[0]+".000+00:00"
        //                 dispatch(createUserLoginInfo({
        //                     userId: auth.user.id,
        //                     ipAddress: res.data.IPv4,
        //                     companyId: auth.user.companyId,
        //                     country: res.data.country_name,
        //                     loginStatus:true,
        //                     date: newCurrDate,
        //                     token: result.visitorId
        //                 }))
        //             }
        //             // setFingerPrintToken(result.visitorId)
        //         )
        // const res = await axios.get('https://geolocation-db.com/json/')
        const currDate = moment().format()
                    // old logic
                    // const arr = currDate.split("+")
                    // const newCurrDate = arr[0]+".000+00:00"
        const arr = currDate.toString().substring(0,19)
        const newCurrDate = arr+".000+00:00"
        // console.log(newCurrDate, currDate.toString().substring(0,19), currDate,"currDatecurrDatecurrDatecurrDatecurrDate")
        dispatch(createUserLoginInfo({
            userId: auth.user.id,
            ipAddress: res.data.IPv4,
            companyId: auth.user.companyId,
            country: res.data.country_name,
            loginStatus:true,
            date: newCurrDate,
            fingerPrintUuid: info.uuid
        }))

        // await axios.post(`${SERVERADDRESS}/v1/userLoginInfo/createUserLoginInfo`, 
            // {
            //     userId: auth.user.id,
            //     ipAddress: res.data.IPv4,
            //     companyId: auth.user.companyId,
            //     country: res.data.country_name,
            //     loginStatus:true,
            //     date: newCurrDate
            // }
        // )
    }
//somewhere in your app/component
// const location = useGeoLocation();
// console.log("location=>",location.country);
    useEffect(() => {
        dasboardTimeIn()
        locationOfAdmin()
    },[])
    return (
        <>
            <div className="row">
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                        <div className="card-body">
                            <span className="dash-widget-icon"><i className="fa fa-cubes"></i></span>
                            <div className="dash-widget-info">
                                <h3> 
                                    { 
                                        admin.LocationCount !== null ?
                                        admin.LocationCount : 0 
                                    }
                                </h3>
                                <span>Locations</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                        <div className="card-body">
                            <span className="dash-widget-icon"><i className="fa fa-user"></i></span>
                            <div className="dash-widget-info">
                                <h3>
                                    {
                                        admin.UserCount !== null ?
                                        admin.UserCount : 0 
                                    }
                                </h3>
                                {/* <h3>218</h3> */}
                                <span>Staff</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                        <div className="card-body">
                            <span className="dash-widget-icon"><i className="fa fa-graduation-cap"></i></span>
                            <div className="dash-widget-info">
                                <h3>
                                    {
                                        admin.TrainingCount !== null ?
                                        admin.TrainingCount : 0
                                    }
                                </h3>
                                {/* <h3>8</h3> */}
                                <span>Recent Training</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                    <div className="card dash-widget">
                        <div className="card-body">
                            <span className="dash-widget-icon"><i className="fa fa-clock-o"></i></span>
                            <div className="dash-widget-info">
                                <h3>Time-In</h3>
                                <span>{moment(admin.CurrTimeIn).format('lll')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminDashboardCard