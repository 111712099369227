import React from 'react'
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminTittle from '../../components/adminComponents/AdminTittle'
import { useSelector } from 'react-redux'
import AdminTClockCards from '../../components/adminComponents/adminTimeClock/AdminTClockCards'
import AdminTClockFilter from '../../components/adminComponents/adminTimeClock/AdminTClockFilter'
import AdminTClockTable from '../../components/adminComponents/adminTimeClock/AdminTClockTable'
import AdminRecentClockIn from '../../components/adminComponents/adminTimeClock/AdminRecentClockIn'
import AdminEmpAttendanceSearch from '../../components/adminComponents/adminTimeClock/AdminEmpAttendanceSearch'
import AdminEmpAttendanceSheet from '../../components/adminComponents/adminTimeClock/AdminEmpAttendanceSheet'
// import AdminOTCards from '../../components/adminComponents/adminTimeClock/AdminOTCards'
// import AdminOTTable from '../../components/adminComponents/adminTimeClock/AdminOTTable'
import SearchAdminEmpAttendanceSheet from '../../components/adminComponents/adminTimeClock/SearchAdminEmpAttendanceSheet'

const AdminTClockView = () => {
    const myState = useSelector((state => state.changeTheTab))
    
    return (
    <>
        {/* <AdminHeader />
        <AdminSideBar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        {
                            myState.viewTClk === "timeclock" ?
                            <>
                                <AdminTittle title="Time Clock" timeclockpage />
                                <AdminTClockCards />
                                <AdminTClockFilter />
                                <AdminTClockTable />
                            </> 
                            :
                            myState.viewTClk === "recentlyclockedin" ?
                            <>
                                <AdminTittle title="Recent Clock-in" timeclockpage />
                                <AdminRecentClockIn />
                            </> 
                            :
                            myState.viewTClk === "attendance" ?
                            <>
                                <AdminTittle title="Attendance" timeclockpage />
                                <AdminEmpAttendanceSearch />
                                <AdminEmpAttendanceSheet />
                            </> 
                            // :
                            // myState.viewTClk === "timesheet" ?
                            // <>
                            // <AdminTittle title="Time Sheet" timeclockpage />
                            // <AdminEmpTimeSheet />
                            // </> 
                            :
                            myState.viewTClk === "attendanceSearch" ?
                            <>
                                <AdminTittle title="Attendance" timeclockpage />
                                <AdminEmpAttendanceSearch />
                                <SearchAdminEmpAttendanceSheet />
                            </> 
                            // :
                            // myState.viewTClk === "overtime" ?
                            // <>
                            //     <AdminTittle title="Overtime" timeclockpage />
                            //     <AdminOTCards />
                            //     <AdminOTTable />
                            // </> 
                            :
                            <div>Loading</div>
                        }
                </div>
            </div>
        </div>
    </>
    )
}
export default AdminTClockView