import {
    TRAINING_REQUEST,
    TRAINING_SUCCESS,
    TRAINING_FAIL,
    TRAINING_CREATE_REQUEST,
    TRAINING_CREATE_SUCCESS,
    TRAINING_CREATE_FAIL,
    TRAINING_UPDATE_REQUEST,
    TRAINING_UPDATE_SUCCESS,
    TRAINING_UPDATE_FAIL,
    REMOVE_TRAINING_REQUEST,
    REMOVE_TRAINING_SUCCESS,
    REMOVE_TRAINING_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const trainingReducer = (
    state = {
        training: {
            training: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                training: null,
            };

        // USER TRAINING TABLE
        case TRAINING_REQUEST : 
            return {
                ...state,
            };
        case TRAINING_SUCCESS :
            return {
                ...state,
                training: action.payload,
                // newRegisteration: action.payload.register ? true : false,
            };
        case TRAINING_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        // ADMIN CREATE TRAINING REDUCER
        case TRAINING_CREATE_REQUEST :
            return {
                ...state,
            }
        case TRAINING_CREATE_SUCCESS :
            return {
                ...state,
                training: [...state.training, action.payload],
            }
        case TRAINING_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            }

        case TRAINING_UPDATE_REQUEST :
            return {
                ...state,
            };
        case TRAINING_UPDATE_SUCCESS :
            return {
                ...state,
                // training: [...state.training, action.payload],
                training: {...state.training, results:state.training.results.map((x) =>
                x.id === action.payload.id ? action.payload : x
                )},
            };
        case TRAINING_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case REMOVE_TRAINING_REQUEST : 
            return {
                ...state,
            };
        case REMOVE_TRAINING_SUCCESS :
            return {
                ...state,
                training: state.training.filter((x) => x.id !== action.payload.id),
            };
        case REMOVE_TRAINING_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}