import axios from "axios"
import {
    BREAKRULE_CREATE_REQUEST,
    BREAKRULE_CREATE_SUCCESS,
    BREAKRULE_CREATE_FAIL,
    BREAKRULE_REQUEST,
    BREAKRULE_SUCCESS,
    BREAKRULE_FAIL,
    BREAKRULE_UPDATE_REQUEST,
    BREAKRULE_UPDATE_SUCCESS,
    BREAKRULE_UPDATE_FAIL,
    BREAKRULE_REMOVE_REQUEST,
    BREAKRULE_REMOVE_SUCCESS,
    BREAKRULE_REMOVE_FAIL,
    SERVERADDRESS,
} from "../constants"


// ADMIN ADD NEW BREAKRULE
export const addBreakrule = (obj) => async ( dispatch ) => {
    dispatch({
        type: BREAKRULE_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/breakrule/createBreak`, obj)
        dispatch({
            type: BREAKRULE_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: BREAKRULE_CREATE_FAIL,
            payload: err,
        })
    }
}


// BREAK RULE FOR SHIFT AND SCHEDULE AND BREAKRULE FOR ALL EMPLOYEE
export const getBreakruleByCompanyId = (obj) => async (dispatch) => {
    dispatch({
        type: BREAKRULE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/breakrule/getBreakruleByCompanyId`, obj);
        localStorage.setItem("breakRules", JSON.stringify(data));
        dispatch({
            type: BREAKRULE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: BREAKRULE_FAIL,
            payload: err,
        });
    }
};


// UPDATE BREAKRULE
export const updateBreakrule = (obj) => async (dispatch) => {
    dispatch({
        type: BREAKRULE_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/breakrule/updateBreakrule`, obj);
        // localStorage.setItem("breakRules", JSON.stringify(data));
        dispatch({
            type: BREAKRULE_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: BREAKRULE_UPDATE_FAIL,
            payload: err,
        });
    }
};


// REMOVE BREAKRULE
export const removeBreakrule = (obj) => async (dispatch) => {
    dispatch({
        type: BREAKRULE_REMOVE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/breakrule/updateBreakrule`, obj);
        // localStorage.setItem("breakRules", JSON.stringify(data));
        dispatch({
            type: BREAKRULE_REMOVE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: BREAKRULE_REMOVE_FAIL,
            payload: err,
        });
    }
};