import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { tabRecentClockin } from "../../../actions/tabActions";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";

const ShiftTime = () => {
  // const auth = useSelector((state) => state.auth)
  const admin = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  return (
    <>
      <div className="row">
        <div className="col-md-6 d-flex">
          <div className="card card-table flex-fill">
            <div className="card-header">
              <h3 className="card-title mb-0">
                Who's On Now{" "}
                <span className="badge badge-pill bg-success">
                  {admin.ActiveUser && admin.ActiveUser.length}
                </span>
              </h3>
            </div>
            <div className="card-body">
              {/* <div className="table-responsive"> */}
              <table className="table custom-table mb-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Time-In</th>
                    <th>Shift Timing</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {admin.ActiveUser &&
                    admin.ActiveUser.length > 0 &&
                    admin.ActiveUser.map((current, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td>
                              <h2 className="table-avatar">
                                <NavLink to="#" className="avatar">
                                  {/* <img alt="" src="assetsAdmin/img/avatar-19.jpg"/> */}
                                  {current.userId &&
                                  current.userId.profilePic &&
                                  current.userId.profilePic.fileUrl ? (
                                    <img
                                      alt="profilePic"
                                      src={
                                        BUCKET_BASE_URL +
                                        current.userId.profilePic.fileUrl
                                      }
                                      style={{
                                        objectFit: "fill",
                                        height: "100%",
                                      }}
                                    />
                                  ) : (
                                    <img alt="profilePic" src={defaultImg} />
                                  )}
                                </NavLink>
                                <NavLink to="/profile">
                                  {current.userId.firstName}
                                  <span>{current.userId.jobTitle}</span>
                                </NavLink>
                              </h2>
                            </td>
                            <td>{current.dayClockIn.substring(11, 16)}</td>
                            <td>
                              {current.shift
                                ? current.shift.startTime.substring(11, 16) +
                                  " - " +
                                  current.shift.endTime.substring(11, 16)
                                : "without shift"}
                            </td>
                            <td>
                              <span className="badge bg-inverse-warning">
                                {current.shift
                                  ? current.shift?.location?.name
                                  : ""}
                              </span>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              {/* </div> */}
            </div>
            <div className="card-footer">
              <NavLink
                to="/time-clock"
                onClick={() => dispatch(tabRecentClockin())}
              >
                View all Active Employees
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex">
          <div className="card card-table flex-fill">
            <div className="card-header">
              <h3 className="card-title mb-0">Upcoming Shifts</h3>
            </div>
            <div className="card-body">
              {/* <div className="table-responsive"> */}
              <table className="table custom-table mb-0">
                <thead>
                  <tr>
                    <th>Locations </th>
                    <th>Name</th>
                    <th>Start Date</th>
                    {/* <th>Work Duration</th> */}
                  </tr>
                </thead>
                <tbody>
                  {admin.UpcomingShift &&
                    admin.UpcomingShift.length > 0 &&
                    admin.UpcomingShift.map((current, i) => {
                      return (
                        <tr>
                          <td>
                            {current.location ? (
                              <h2>
                                <NavLink
                                  to={
                                    current.location
                                      ? `/location-view/${current.location.id}`
                                      : "#"
                                  }
                                >
                                  {current.location.name}
                                </NavLink>
                              </h2>
                            ) : (
                              <h2>
                                <strong>-</strong>
                              </h2>
                            )}
                            <small className="block text-ellipsis">
                              <span className="text-muted">Timings :</span>{" "}
                              <span>
                                {current.startTime.substring(11, 16)} -{" "}
                                {current.endTime.substring(11, 16)}
                              </span>
                            </small>
                          </td>
                          <td>
                            {current.userId.firstName} {current.userId.lastName}
                          </td>
                          <td>
                            {moment(current.startTime).format("DD-MMMM-YYYY")}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {/* </div> */}
            </div>
            <div className="card-footer">
              <NavLink to="/Locations">View all Locations</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ShiftTime;
