import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getLeaveStatus } from '../../../actions/leaveActions'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"

const AdminLRTable = () => {
    // const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const leave = useSelector((state) => state.leave)
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [limit, setLimit] = useState(10)
    const dispatch = useDispatch()
    const leaveReport = async (e) => {
        dispatch(getLeaveStatus({companyId: auth.user.companyId, date: moment().format('YYYY-MM-DD'), limit: limit, page: currentPageNumber }))
    }
    useEffect(()=>{
        leaveReport()
    }, [])
    const handleLimit = async (event) =>{
        setLimit(
            parseInt(event.target.value)
        )
        dispatch(getLeaveStatus({companyId: auth.user.companyId, date: moment().format('YYYY-MM-DD'), limit: event.target.value, page: 1 }))
        setCurrentPageNumber(1)
    }
    const onClickNext = async () => {
        let i= currentPageNumber
        i++;
        // console.log(leave.leaveReport[leave.leaveReport.length-1], i <= leave.leaveReport[leave.leaveReport.length-1][(leave.leaveReport[leave.leaveReport.length-1]-1)])
        if (i <= leave.leaveReport[leave.leaveReport.length-5]){
            setCurrentPageNumber(currentPageNumber + 1)
            dispatch(getLeaveStatus({companyId: auth.user.companyId, date: moment().format('YYYY-MM-DD'), limit: limit, page: currentPageNumber+1 }))
        }
    }
    const onClickPrev = async () => {
        let i= currentPageNumber
        i--;
        if (i >= 1){
            setCurrentPageNumber(currentPageNumber - 1)
            dispatch(getLeaveStatus({companyId: auth.user.companyId, date: moment().format('YYYY-MM-DD'), limit: limit, page: currentPageNumber-1 }))
        }
    }
    const handleChangeData = async (pageNo) => {
        setCurrentPageNumber(
            pageNo
        )
        dispatch(getLeaveStatus({companyId: auth.user.companyId, date: moment().format('YYYY-MM-DD'), limit: limit, page: pageNo }))
    }
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="dataTables_length" id="DataTables_Table_0_length">
                        <label>Show &nbsp;
                            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                                className="select" 
                                autoComplete='false'
                                // value={limit}
                                onChange={handleLimit}>
                                {
                                    [10,25,50,100].map((current, i) => {
                                        return(
                                            <option value={current}>{current}</option>
                                        )
                                    })
                                }
                            </select> entries
                        </label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {/* <div className="table-responsive"> */}
                        <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                                <tr>
                                    <th>Employee</th>
                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Remaining Leave</th>
                                    <th></th>
                                    <th></th>
                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Leaves</th>
                                    <th></th>
                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total Leave Taken</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                leave.leaveReport &&
                                leave.leaveReport.length > 0 &&
                                leave.leaveReport.map((current, i) => {
                                    return(
                                    i < (leave.leaveReport.length - 5) &&
                                    <tr key={i}>
                                        <td>
                                            <h2 className="table-avatar">
                                                <NavLink to={`/profile/${current.e.id}`} className="avatar">
                                                    {
                                                        current.e.profilePic &&
                                                        current.e.profilePic.fileUrl ?
                                                            <img alt="profilePic" src={current.e.profilePic.fileUrl} style={{objectFit:"fill", height:"100%"}}/>
                                                            :
                                                            <img alt="profilePic" src={defaultImg} />
                                                    }
                                                </NavLink>
                                                <NavLink to={`/profile/${current.e.id}`}> {current.e.firstName + " " + current.e.lastName}<span>{current.e.employeeId}</span></NavLink>
                                            </h2>
                                        </td>
                                        {/* <td>{current.e.employeeId}</td> */}
                                        {/* <td className="text-center">
                                                <button className="btn btn-outline-info btn-sm">Sick Leave</button>
                                            </td> */}
                                        <td className="text-center"><span className="btn btn-warning btn-sm"><b>{current.remainingLeaves}</b></span></td>
                                        <td></td>   
                                        <td></td>   
                                        <td className="text-center"><span className="btn btn-success btn-sm"><b>{current.totalLeaves}</b></span></td>
                                        <td></td>   
                                        <td className="text-center">{current.takenLeaves}</td>
                                    </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                {/* </div> */}
            </div>
            <br />
            <div className="row">
                <div className="col-sm-12 col-md-5">
                {
                    leave.leaveReport &&
                    leave.leaveReport.length > 0 &&
                    <label> Showing {1 + (limit * (currentPageNumber-1))} to {leave.leaveReport[leave.leaveReport.length-4] + (limit * (currentPageNumber-1))} of {leave.leaveReport[leave.leaveReport.length-2]} entries </label>
                }
                </div>
                <div className="col-sm-12 col-md-7">
                    <div className="avatar-pagination">
                        <ul className="pagination" style={{ justifyContent: 'flex-end' }}>
                            <li className="page-item">
                                <NavLink className="page-link" to="#" aria-label="Previous" onClick={onClickPrev}>
                                    <span aria-hidden="true">Previous</span>
                                    <span className="sr-only">Previous</span>
                                </NavLink>
                            </li>
                        {/* <li className="page-item active"><NavLink className="page-link" to="#">1</NavLink></li>
                            <li className="page-item"><NavLink className="page-link" to="#">2</NavLink></li> */}
                            {
                                leave.leaveReport &&
                                leave.leaveReport.length > 0 &&
                                leave.leaveReport[leave.leaveReport.length-1].map((current, i) => {
                                    return(
                                        currentPageNumber === current ?
                                        <li className="page-item active" key={i}><a className="page-link" value={current}>{current}</a></li>
                                        :
                                        <li className="page-item" key={i}><a className="page-link" 
                                        value={current} onClick={()=>handleChangeData(current)}
                                        >{current}</a></li>
                                    )
                                })
                            }
                            <li className="page-item">
                                <NavLink className="page-link" to="#" aria-label="Next" onClick={onClickNext}>
                                    <span aria-hidden="true">Next</span>
                                    <span className="sr-only">Next</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminLRTable