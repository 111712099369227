import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFile, updateFile } from '../../../actions/fileManagerActions';

const DeleteFileModal = ({ showDeleteDropDown, openDeleteDropDown, current}) => {
    const [ values, setValues ] = useState({
        id: '',
    })
    const currentFile = async () => {
        try {
            setValues({
                id: '',
            })
            setValues({
                id: current.id,
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        currentFile()
    }, [])
    const dispatch = useDispatch()
    const updateFileData = {
        _id: values.id,
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        dispatch(deleteFile(updateFileData))
        openDeleteDropDown()
    }
    return (
        <>
            <div className={`modal-backdrop fade ${showDeleteDropDown ? 'show' : 'hide'}`}></div>
            <div className={`modal custom-modal fade ${showDeleteDropDown ? 'show' : 'hide'}`} id="delete_warn" role="dialog"> 
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-header">
                                <h3>Delete File</h3>
                                <p>Are you sure want to delete?</p>
                            </div>
                            <div className="modal-btn delete-action">
                                <div className="row">
                                    <div className="col-6">
                                        <a className="btn btn-primary continue-btn" onClick={handleSubmit}>Delete</a>
                                    </div>
                                    <div className="col-6">
                                        <a data-dismiss="modal" className="btn btn-primary cancel-btn" onClick={() => openDeleteDropDown()}>Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DeleteFileModal