import React from 'react'
import { useSelector } from 'react-redux'
// import { useEffect } from 'react'
// import { useDispatch } from 'react-redux'
// import moment from 'moment';
// import { getUserAnnualStatusByUserId } from "../../../actions/leaveActions"

const LeavesCard = () => {
    // const auth = useSelector((state) => state.auth);
    const leave = useSelector((state) => state.leave);
    // const dispatch = useDispatch()
    // const annualLeaveStatusData = async (e) => {
    //     dispatch(getUserAnnualStatusByUserId({userId: auth.user.id, date:  moment().format()}))
    // }
    // useEffect(() => {
    //     annualLeaveStatusData()
    // },[])
    return (
        <>
            <div className="row">
                {   
                    leave.leaveAnnualStatus &&
                    leave.leaveAnnualStatus.length > 0 &&
                    leave.leaveAnnualStatus.map((current, i) => {
                        return(
                            <div className="col-md-3" key={i}>
                                <div className="stats-info">
                                    <h6>{current.leaveCategory.name}</h6>
                                    <h4>{current.remainingLeavesDays}</h4>
                                </div>
                            </div>
                        )
                })}
            </div>
        </>
    )
}
export default LeavesCard