import React, { useState } from 'react'
import moment from 'moment/moment'
import { NavLink } from 'react-router-dom'
import EditModalLeaveByAdmin from './EditModalLeaveByAdmin'
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"
import { BUCKET_BASE_URL } from '../../../constants'
// import DeleteLeaveByAdmin from './DeleteLeaveByAdmin'
// import { useSelector } from 'react-redux'
// import ModalDeleteWarning from '../ModalDeleteWarning'

const AdminLeaveRow = ({current, i, dropDownRow, setDropDownRow}) => {
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const [showEditModal, setshowEditModal] = useState(false)
    const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    const [showApproveDropDown, setshowApproveDropDown] = useState(false)
    const [showDropDown, setshowDropDown] = useState(false)
    const openMoreVertDropDown = () => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
        setshowApproveDropDown(false)
    }
    const openEditModal = () => {
        setshowEditModal((showEditModal) => !showEditModal)
        setshowMoreVertDropDown(false)
    }
    const openApproveDropDown = () => {
        setshowApproveDropDown((showApproveDropDown) => !showApproveDropDown)
    }
    // console.log(showApproveDropDown)
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
        setshowApproveDropDown(false)
    }
    // console.log(showDropDown)
    const openDeleteDropDown = () => {
        setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
        setshowMoreVertDropDown(false)
    }
    // console.log(showDeleteDropDown)
    return (
        <>
            {/* {
            leave.leaveDisplay &&
            leave.leaveDisplay.length > 0 &&
            leave.leaveDisplay.map((current, i) => {
                return( */}
            <tr key={i} onMouseLeave={()=>setshowMoreVertDropDown(false)}> 
                <td>
                    <h2 className="table-avatar">
                        <NavLink to={`/profile/${current.e.userId.id}`} className="avatar">
                            {/* <img alt="" src="assetsAdmin/img/avatar-09.jpg" /> */}
                            {
                                current.e.userId.profilePic &&
                                current.e.userId.profilePic.fileUrl ?
                                    <img alt="profilePic" src={BUCKET_BASE_URL + current.e.userId.profilePic.fileUrl} style={{objectFit:"fill", height:"100%"}}/>
                                    :
                                    <img alt="profilePic" src={defaultImg} />
                            }
                        </NavLink>
                        <NavLink to={`/profile/${current.e.userId.id}`}> {current.e.userId.firstName + " " + current.e.userId.lastName} <span>{current.e.userId.jobTitle}</span></NavLink>
                    </h2>
                </td>
                <td>{current.e.leaveType.name}</td>
                <td>{moment(current.e.from).format("DD MMMM YYYY")}</td>
                <td>{moment(current.e.to).format("DD MMMM YYYY")}</td>
                <td>{current.e.numberOfDays} days</td>
                <td>{current.e.leaveReason.length <= 15 ? current.e.leaveReason : current.e.leaveReason.substring(0,15)+"..."}</td>
                <td className="text-center">
                    {
                        current.e.status === "Pending" ?
                            <div className="action-label">
                                <NavLink to="#" className="btn btn-white btn-sm btn-rounded" > 
                                {/* //to="javascript:void(0);" */}
                                    <i className="fa fa-dot-circle-o text-purple"></i> Pending
                                </NavLink>
                            </div>
                        : 
                        current.e.status === "Accepted" ?
                            <div className="action-label">
                                <NavLink to="#" className="btn btn-white btn-sm btn-rounded">
                                    <i className="fa fa-dot-circle-o text-success"></i> Approved
                                </NavLink>
                            </div>
                        :
                        <div className="action-label">
                            <NavLink to="#" className="btn btn-white btn-sm btn-rounded" >
                                <i className="fa fa-dot-circle-o text-danger"></i> Declined
                            </NavLink>
                        </div>
                    }
                    {/* <div className="dropdown action-label"> */}
                    {/* <a className="btn btn-white btn-sm btn-rounded dropdown-toggle" href="#" data-toggle="dropdown" onClick={openApproveDropDown} aria-expanded="false"> */}
                    {/* <i className="fa fa-dot-circle-o text-purple"></i> {current.e.status} */}
                    {/* </a> */}
                    {/* <div className={`dropdown-menu dropdown-menu-right ${showApproveDropDown ? 'show' : 'hide'}`}> */}
                        {/* <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-purple"></i> New</a> */}
                        {/* <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-info"></i> Pending</a> */}
                        {/* <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#approve_leave"><i className="fa fa-dot-circle-o text-success"></i> Approved</a> */}
                        {/* <a className="dropdown-item" href="#"><i className="fa fa-dot-circle-o text-danger"></i> Declined</a> */}
                    {/* </div> */}
                    {/* </div> */}
                </td>
                <td className="text-right">
                    <div className="dropdown dropdown-action">
                        {/* <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={()=>{ */}
                        <a className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={()=>{
                        openMoreVertDropDown()
                        setDropDownRow(i)}}
                        aria-expanded="false" style={{cursor: 'pointer'}}><i className="material-icons">more_vert</i></a>
                        {
                            dropDownRow === i &&
                            <>
                                <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                                    <a href="#" className="dropdown-item" data-toggle="modal" onClick={openEditModal} data-target="#edit_leave"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                                    {/* <a href="#" className="dropdown-item" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i> Delete</a> */}
                                </div>
                            </>
                        }
                    </div>
                </td>
            </tr>
        {/* < showEditModal={showEditModal} openEditModal={openEditModal}/>
            < showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown} /> */}
            <EditModalLeaveByAdmin current={current} showEditModal={showEditModal} openEditModal={openEditModal}/>
            {/* <DeleteLeaveByAdmin current={current} showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown}/> */}
        </>
    )
}
export default AdminLeaveRow