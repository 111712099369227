import moment from 'moment';
// import React, { useState } from 'react'
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import shift2 from "../../../assetsAdmin/img/undraw_Site_stats_re_ejgy.png"
// import EditModalUpcomingShift from './EditModalUpcomingShift'
const ProfileCardUpcomingShift = () => {
    const user = useSelector((state) => state.user);
    // const [showDropDown, setshowDropDown] = useState(false)
    // const openDropDown = () => {
    //     setshowDropDown((showDropDown) => !showDropDown)
    // }
    // console.log(showDropDown)
    return (
        <>
            <div className="col-md-6 d-flex">
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                        <h3 className="card-title">Upcoming Shifts 
                            {/* <a href="#" className="edit-icon" data-toggle="modal" onClick={openDropDown} data-target="#add_schedule"><i className="fa fa-pencil"></i></a> */}
                        </h3>
                        {/* <EditModalUpcomingShift showDropDown={showDropDown} openDropDown={openDropDown} /> */}
                        <div className="experience-box miprcrl">
                            <ul className="experience-list ">
                            {
                            user.userById &&
                            user.userById.shift &&
                            user.userById.shift.length > 0 &&
                            user.userById.shift[0].upcoming &&
                            user.userById.shift[0].upcoming.length > 0 ?
                            user.userById.shift[0].upcoming.map((current, i) => {
                                return (
                                <li key={i} className="misftact">
                                    <div className="experience-user">
                                        <div className="before-circle"></div>
                                    </div>
                                    <div className="experience-content">
                                        <div className="timeline-content">
                                            <div>{moment(current.startTime.substring(0, 10)).format("DD-MMMM-YYYY")}</div>
                                                {
                                                current.location ?
                                                <NavLink to={`/location-view/${current.location.id}`} className="name">{current.location.name} - <strong>{user.userById.user.jobTitle}</strong></NavLink>
                                                :
                                                <NavLink to="#" className="name" style={{cursor:"default"}}><strong>{user.userById.user.jobTitle}</strong></NavLink>
                                                }
                                                <span className="time">{current.startTime.toString().substring(11, 16)} - {current.endTime.substring(11, 16)}</span>
                                                {
                                                    current.startTime.substring(0, 10) === moment().format().substring(0, 10) ?
                                                    <span className="btn add-btn">Ongoing</span>
                                                    :
                                                    <div></div>
                                                }
                                            </div>
                                        </div>
                                    </li>
                                )})
                            :
                            <>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <div className="dash-stats-list">
                                    <img alt="" src ={shift2} style={{width : "110px", opacity : "0.8"}}/>
                                    <br />
                                    <p style={{opacity : "0.5"}}>No Upcoming Shifts</p>
                                </div>    
                            </>
                            }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
} 
export default ProfileCardUpcomingShift