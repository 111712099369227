// import React, { useState } from 'react'
import React from 'react'
// import moment from 'moment';
import { useSelector } from 'react-redux';
import DailyTableRow from './DailyTableRow';
import PaginationAndNextPrev from './PaginationAndNextPrev';
// import EditModalSSEditScheduling from './EditModalSSEditScheduling'
// import { NavLink } from 'react-router-dom'

const AdminSSDailyTable = ({dateForTableData}) => {
    const admin = useSelector((state) => state.admin);
    // const [showDropDown, setshowDropDown] = useState(false)
    // const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    // const [ datePag, setDatePag ] = useState(moment(dateForTableData.format('YYYY-MM-DD')))
    
    // const openMoreVertDropDown = () => {
    //     setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    // }
    // const openDropDown = () => {
    //     setshowDropDown((showDropDown) => !showDropDown)
    // }
    // console.log(showDropDown)
    return (
        <>
            <div className="row miordr2">
                <div className="col-md-12">
                    <div className="table-responsive misftsch misftschdl">
                        <table className="table table-striped custom-table datatable schedul-rptbl shift-rptbl w-100" >
                            <thead>
                                <tr>
                                    <th>Scheduled Shift</th>
                                    <th style={{textAlign: "left"}}>{dateForTableData.format('ddd DD')}</th>
                                    {/* <th>00</th>
                                    <th>01</th>
                                    <th>02</th>
                                    <th>03</th>
                                    <th>23</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {   
                                admin.usersShifts &&
                                admin.usersShifts.length > 0 &&
                                admin.usersShifts.map((current, i ) => {
                                    return(
                                        i < (admin.usersShifts.length - 5) &&
                                        <>
                                            <DailyTableRow current={current} key={i} />
                                        </>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    < br/>
                    <PaginationAndNextPrev dateForPagination={dateForTableData}/>
                </div>
            </div>
        </>
    )
}
export default AdminSSDailyTable