import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useSelector } from 'react-redux';
import MonthlyTableRow from './MonthlyTableRow';
import PaginationAndNextPrev from './PaginationAndNextPrev';

const AdminSSMonthlyTable = ({dateForTableData}) => {
    // console.log(dateForTableData," date aarahi hai month mein")
   const admin = useSelector((state) => state.admin);
   // const [showDropDown, setshowDropDown] = useState(false)
   // const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
   // const openMoreVertDropDown = () => {
   //    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
   // }
   // const openDropDown = () => {
   //    setshowDropDown((showDropDown) => !showDropDown)
   // }
   // const [ datePag, setDatePag ] = useState(moment(dateForTableData.format('YYYY-MM-DD')))
   let [ today, setToday ] = useState(moment())
   let date = moment(today.format('YYYY-MM-DD'))
   let endOfMonth = date.endOf('month').format('DD')
   // let startOfMonth = date.startOf('month').format('DD')
   let datesArrLength = []
   for (let i=0; i<parseInt(endOfMonth); i++){
      datesArrLength.push(i)
   }
   useEffect(() => {
      setToday(moment(dateForTableData.format("YYYY-MM-DD")))
   }, [dateForTableData])
   let dateShift = date.startOf('month').format('YYYY-MM-DD')
//  if (dateShift.format('dddd') !== 'Sunday'){
   //   dateShift = dateShift.startOf('month').format('YYYY-MM-DD')
//  }
//  else{
//      dateShift = dateShift.startOf('week').add(-6, 'days').format('YYYY-MM-DD')
//  }
   // console.log(startOfMonth,endOfMonth,datesArrLength)
   return (
      <>
         <div className="row miordr2">
            <div className="col-md-12">
               <div className="table-responsive misftsch misftschmn">
                  <table className="table table-striped custom-table datatable">
                     <thead>
                        <tr>
                           <th>Scheduled Shift</th>
                           {
                              datesArrLength.length > 0 &&
                              datesArrLength.map((current,i) =>{
                                 return(
                                    <th key={i}>{date.startOf('month').add(i, 'days').format('ddd')}<br />{date.startOf('month').add(i, 'days').format('MMM')}<br />{date.startOf('month').add(i, 'days').format('DD')}</th>
                                 )
                              })
                           }
                        </tr>
                     </thead>
                     <tbody>
                        {   
                           admin.usersShifts &&
                           admin.usersShifts.length > 0 &&
                           admin.usersShifts.map((current, i ) => {
                              return(
                                 i < (admin.usersShifts.length - 5) &&
                                 <>
                                 <MonthlyTableRow current={current} i={i} datesArrLength={datesArrLength} date={dateShift}/>
                                 </>
                              )
                           })
                        }
                     </tbody>
                  </table>
               </div>
               <br />
               <PaginationAndNextPrev dateForPagination={dateForTableData}/>
            </div>
         </div>
      </>
   )
}
export default AdminSSMonthlyTable