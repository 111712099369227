// import React, { forwardRef, useState } from 'react'
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getShiftAllUsers, updateShift } from "../../../actions/adminActions";
import { useEffect } from "react";

const EditModalSSEditScheduling = ({
  showMoreVertDropDown,
  openMoreVertDropDown,
  current,
  valueIndex,
  typeStatus,
}) => {
  // const [shiftData, setShiftData] = useState({
  //     user: current.user,
  //     shift: current.weeklyShift,
  //     data: current.weeklyShift[valueIndex]
  // })
  // console.log("jhoota", valueIndex, current)
  const auth = useSelector((state) => state.auth);
  const [theDate, setTheDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  const [theEndDate, setTheEndDate] = useState(
    new Date(moment().format("YYYY-MM-DD"))
  );
  // console.log(theDate)
  // console.log(theEndDate)

  // const StartTime = forwardRef(({ value, onClick }, ref) => (
  //     <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
  //         <div className="cal-icon">
  //             <input className="form-control floating datatimepicker" type="text" value={value}/>
  //         </div>
  //         <label className="focus-label">Start Date</label>
  //     </div>
  // ))
  // const EndTime = forwardRef(({ value, onClick }, ref) => (
  //     <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
  //         <div className="cal-icon">
  //             <input className="form-control floating datatimepicker" type="text" value={value}/>
  //         </div>
  //         <label className="focus-label">End Date</label>
  //     </div>
  // ))

  const locationTemp = useSelector((state) => state.location);
  let location = locationTemp.location;
  const user = useSelector((state) => state.user);
  // const breakRulesTemp = useSelector((state) => state.breakrule);
  // let breakRules = breakRulesTemp.breakRules
  const [values, setValues] = useState({
    id: "",
    startTime: "",
    endTime: "",
    location: "",
    // breakRules:'',
    userId: "",
  });
  const updateArr = () => {
    if (typeStatus === "weekly" && valueIndex !== undefined) {
      // if ()
      if (current.weeklyShift[valueIndex].location !== undefined) {
        setValues({
          id: current.weeklyShift[valueIndex].id,
          startTime: current.weeklyShift[valueIndex].startTime,
          endTime: current.weeklyShift[valueIndex].endTime,
          location: current.weeklyShift[valueIndex].location.id,
          // breakRules:current.weeklyShift[valueIndex].breakRules,
          userId: current.user.id,
        });
        setTheDate(
          new Date(
            current.weeklyShift[valueIndex].startTime
              .toString()
              .substring(0, 19)
          )
        );
        setTheEndDate(
          new Date(
            current.weeklyShift[valueIndex].endTime.toString().substring(0, 19)
          )
        );
      } else {
        setValues({
          id: current.weeklyShift[valueIndex].id,
          startTime: current.weeklyShift[valueIndex].startTime,
          endTime: current.weeklyShift[valueIndex].endTime,
          location: values.location,
          // breakRules:current.weeklyShift[valueIndex].breakRules,
          userId: current.user.id,
        });
        setTheDate(
          new Date(
            current.weeklyShift[valueIndex].startTime
              .toString()
              .substring(0, 19)
          )
        );
        setTheEndDate(
          new Date(
            current.weeklyShift[valueIndex].endTime.toString().substring(0, 19)
          )
        );
      }
    } else if (typeStatus === "daily" && valueIndex !== undefined) {
      if (current.dailyShift[valueIndex].location !== undefined) {
        setValues({
          id: current.dailyShift[valueIndex].id,
          startTime: current.dailyShift[valueIndex].startTime,
          endTime: current.dailyShift[valueIndex].endTime,
          location: current.dailyShift[valueIndex].location.id,
          // breakRules:current.dailyShift[valueIndex].breakRules,
          userId: current.user.id,
        });
        setTheDate(
          new Date(
            current.dailyShift[valueIndex].startTime.toString().substring(0, 19)
          )
        );
        setTheEndDate(
          new Date(
            current.dailyShift[valueIndex].endTime.toString().substring(0, 19)
          )
        );
      } else {
        setValues({
          id: current.dailyShift[valueIndex].id,
          startTime: current.dailyShift[valueIndex].startTime,
          endTime: current.dailyShift[valueIndex].endTime,
          location: values.location,
          // breakRules:current.dailyShift[valueIndex].breakRules,
          userId: current.user.id,
        });
        setTheDate(
          new Date(
            current.dailyShift[valueIndex].startTime.toString().substring(0, 19)
          )
        );
        setTheEndDate(
          new Date(
            current.dailyShift[valueIndex].endTime.toString().substring(0, 19)
          )
        );
      }
    } else if (typeStatus === "monthly" && valueIndex !== undefined) {
      if (current.monthlyShift[valueIndex].location !== undefined) {
        setValues({
          id: current.monthlyShift[valueIndex].id,
          startTime: current.monthlyShift[valueIndex].startTime,
          endTime: current.monthlyShift[valueIndex].endTime,
          location: current.monthlyShift[valueIndex].location.id,
          // breakRules:current.monthlyShift[valueIndex].breakRules,
          userId: current.user.id,
        });
        setTheDate(
          new Date(
            current.monthlyShift[valueIndex].startTime
              .toString()
              .substring(0, 19)
          )
        );
        setTheEndDate(
          new Date(
            current.monthlyShift[valueIndex].endTime.toString().substring(0, 19)
          )
        );
      } else {
        setValues({
          id: current.monthlyShift[valueIndex].id,
          startTime: current.monthlyShift[valueIndex].startTime,
          endTime: current.monthlyShift[valueIndex].endTime,
          location: values.location,
          // breakRules:current.monthlyShift[valueIndex].breakRules,
          userId: current.user.id,
        });
        setTheDate(
          new Date(
            current.monthlyShift[valueIndex].startTime
              .toString()
              .substring(0, 19)
          )
        );
        setTheEndDate(
          new Date(
            current.monthlyShift[valueIndex].endTime.toString().substring(0, 19)
          )
        );
      }
    }
  };
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const dispatch = useDispatch();
  // useEffect(() => {
  //   updateArr()
  // }, [])
  useEffect(() => {
    updateArr();
  }, [valueIndex]);
  let shiftData;

  if (typeStatus === "weekly" && valueIndex !== undefined) {
    shiftData = {
      _id: current.weeklyShift[valueIndex].id,
      startTime: moment(theDate).format().substring(0, 19) + "+00:00",
      endTime: moment(theEndDate).format().substring(0, 19) + "+00:00",
      location: values.location,
      noLocation: values.location === "" ? true : false,
      location: values.location,
      // breakRules: values.breakRules,
      userId: values.userId,
    };
  } else if (typeStatus === "daily" && valueIndex !== undefined) {
    shiftData = {
      _id: current.dailyShift[valueIndex].id,
      startTime: moment(theDate).format().substring(0, 19) + "+00:00",
      endTime: moment(theEndDate).format().substring(0, 19) + "+00:00",
      location: values.location,
      noLocation: values.location === "" ? true : false,
      // breakRules: values.breakRules,
      userId: values.userId,
    };
  } else if (typeStatus === "monthly" && valueIndex !== undefined) {
    shiftData = {
      startTime: moment(theDate).format().substring(0, 19) + "+00:00",
      endTime: moment(theEndDate).format().substring(0, 19) + "+00:00",
      location: values.location,
      noLocation: values.location === "" ? true : false,
      // breakRules: values.breakRules,
      userId: values.userId,
    };
  }
  // console.log(shiftData)
  // const timer = setTimeout(() =>
  // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().format('YYYY-MM-DD')}))
  // , 5000);

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    if (shiftData.location === "") {
      delete shiftData.location;
    }
    dispatch(updateShift(shiftData));
    // openDropDown()
    // timer()
    setTimeout(
      () =>
        dispatch(
          getShiftAllUsers({
            companyId: auth.user.companyId,
            date: moment().format("YYYY-MM-DD"),
            limit: 10,
            page: 1,
          })
        ),
      3000
    );
    setValues({
      id: "",
      startTime: "",
      endTime: "",
      location: "",
      // breakRules:'',
      userId: "",
    });
  };
  return (
    <>
      <div
        className={`modal-backdrop fade ${
          showMoreVertDropDown ? "show" : "hide"
        }`}
      ></div>
      <div
        className={`modal custom-modal fade ${
          showMoreVertDropDown ? "show" : "hide"
        }`}
        id="edit_schedule"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Schedule</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openMoreVertDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                {typeof valueIndex === "number" && (
                  <>
                    <div className="row">
                      <div className="col-sm-5">
                        <div className="form-group">
                          <label
                            className="col-form-label"
                            style={{ float: "left" }}
                          >
                            Start Time
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          {/* <DatePicker 
                                                    selected={theDate}
                                                    onChange={(date) => setTheDate(date)}
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    showTimeInput
                                                    className="colo-icon form-control datetimepicker3"
                                                    /> */}
                          {/* <div className="colo-icon"><input className="form-control datetimepicker3" type="text" /></div> */}
                        </div>
                      </div>
                      <div className="col-sm-5">
                        <div className="form-group">
                          <label
                            className="col-form-label"
                            style={{ float: "left" }}
                          >
                            End Time
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          {/* <DatePicker 
                                                    selected={theEndDate}
                                                    onChange={(date) => setTheEndDate(date)}
                                                    timeInputLabel="Time:"
                                                    dateFormat="MM/dd/yyyy h:mm aa"
                                                    showTimeInput
                                                    className="colo-icon form-control datetimepicker3"
                                                    /> */}
                          {/* <div className="colo-icon"><input className="form-control datetimepicker3" type="text" /></div> */}
                        </div>
                      </div>
                      <div className="col-sm-5">
                        {/* <input type="text" className="form-control" value={values.startTime} disabled/> */}
                        <DatePicker
                          selected={theDate}
                          onChange={(date) => {
                            setTheDate(date);
                            const tempEndDate = new Date(date);
                            setTheEndDate(
                              new Date(
                                tempEndDate.setHours(tempEndDate.getHours() + 8)
                              )
                            );
                          }}
                          timeInputLabel="Time:"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          showTimeInput
                          className="colo-icon form-control datetimepicker3"
                          minDate={moment().toDate()}
                        />
                      </div>
                      <div className="col-sm-5">
                        {/* <input type="text" className="form-control" value={values.endTime} disabled/> */}
                        <DatePicker
                          selected={theEndDate}
                          onChange={(date) => setTheEndDate(date)}
                          timeInputLabel="Time:"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          showTimeInput
                          className="colo-icon form-control datetimepicker3"
                          minDate={theDate}
                        />
                      </div>
                      <div
                        className="col-sm-2"
                        style={{ float: "right", marginTop: "-30px" }}
                      >
                        <h4 className="mihour">
                          {(
                            (new Date(theEndDate) - new Date(theDate)) /
                            (1000 * 60 * 60)
                          )
                            .toString()
                            .substring(0, 3)}
                          h
                        </h4>
                      </div>
                      {/* <div className="col-sm-2">
                                            <h4 className="mihour">8.5h</h4>
                                        </div> */}
                      {/* <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="col-form-label" style={{float: "left"}}>Employee */}
                      {/* <span className="text-danger">*</span> */}
                      {/* </label> */}

                      {/* yahan aaway gah employeeewa ka code */}

                      {/* <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                                autoComplete="false" value={values.userId}
                                                onChange={handleChange('userId')}>
                                                    <option value="">Select</option>
                                                    { user.allUsersWithoutPaginate && user.allUsersWithoutPaginate.length > 0 &&
                                                    user.allUsersWithoutPaginate.map((current, i) => {
                                                    return (
                                                        <option value={current.id} key={i+1}>{current.firstName +" "+ current.lastName}</option>
                                                    )
                                                    })}
                                                </select>
                                            </div>
                                        </div> */}
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="col-form-label">Employee </label>
                          {/* yahan aaway gah employeeewa ka code */}
                          {current ? (
                            // <input type="text" disabled value={values.}
                            <select
                              className="form-select"
                              style={{ height: "44px", fontSize: "14px" }}
                              autoComplete="false"
                              value={values.userId}
                              onChange={handleChange("userId")}
                              disabled
                            >
                              <option value="">Select</option>
                              {user.allUsersWithoutPaginate &&
                                user.allUsersWithoutPaginate.length > 0 &&
                                user.allUsersWithoutPaginate.map(
                                  (current, i) => {
                                    return (
                                      <option value={current.id} key={i + 1}>
                                        {current.firstName +
                                          " " +
                                          current.lastName}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          ) : (
                            <select
                              className="form-select"
                              style={{ height: "44px", fontSize: "14px" }}
                              autoComplete="false"
                              value={values.userId}
                              onChange={handleChange("userId")}
                            >
                              <option value="">Select</option>
                              {user.allUsersWithoutPaginate &&
                                user.allUsersWithoutPaginate.length > 0 &&
                                user.allUsersWithoutPaginate.map(
                                  (current, i) => {
                                    return (
                                      <option value={current.id} key={i + 1}>
                                        {current.firstName +
                                          " " +
                                          current.lastName}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          )}
                        </div>
                      </div>

                      {/* <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="col-form-label" style={{float: "left"}}>Location  */}
                      {/* <span className="text-danger">*</span> */}
                      {/* </label> */}
                      {/* 
                                                autoComplete="false" value={values.location}
                                                onChange={handleChange('location')}>
                                                    <option value="">Select</option>
                                                    { location.length > 0 &&
                                                    location.map((current, i) => {
                                                    return (
                                                        <option value={current.id} key={i+1}>{current.name}</option>
                                                        )
                                                    })}    
                                                </select>
                                            </div>
                                        </div> */}
                      {current &&
                        current.user &&
                        current.user.locationRequired === true && (
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label className="col-form-label">
                                Location <span className="text-danger">*</span>
                              </label>
                              {current ? (
                                current.user &&
                                current.user.location && (
                                  <select
                                    className="form-select"
                                    style={{ height: "44px", fontSize: "14px" }}
                                    autoComplete="false"
                                    value={values.location}
                                    onChange={handleChange("location")}
                                  >
                                    <option value="">Select</option>
                                    {current.user.location &&
                                      current.user.location.length > 0 &&
                                      current.user.location.map((c, i) => {
                                        return (
                                          <option value={c.id} key={i + 1}>
                                            {c.name}
                                          </option>
                                        );
                                      })}
                                  </select>
                                )
                              ) : (
                                <select
                                  className="form-select"
                                  style={{ height: "44px", fontSize: "14px" }}
                                  autoComplete="false"
                                  value={values.location}
                                  onChange={handleChange("location")}
                                >
                                  <option value="">Select</option>
                                  {location &&
                                    location.length > 0 &&
                                    location.map((current, i) => {
                                      return (
                                        <option value={current.id} key={i + 1}>
                                          {current.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              )}
                            </div>
                          </div>
                        )}

                      {/* <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="col-form-label" style={{float: "left"}}>Break Rule  */}
                      {/* <span className="text-danger">*</span> */}
                      {/* </label> */}
                      {/* <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                                autoComplete="false" value={values.breakRules}
                                                onChange={handleChange('breakRules')}>
                                                    <option value="">Select</option>
                                                    { breakRules && 
                                                    breakRules.length > 0 &&
                                                    breakRules.map((current, i) => {
                                                    return (
                                                        <option value={current.id} key={i+1}>{current.breakName}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div> */}

                      <div className="col-sm-12 mb-3">
                        <div className="submit-section mt-1">
                          <button
                            className="btn btn-info submit-btn"
                            type="submit"
                          >
                            Edit Shift
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditModalSSEditScheduling;

// <div className="row">
// <div className="col-sm-8">
//     <div className="row">
//         <div className="col-sm-6">
//             <div className="form-group">
//                 <label className="col-form-label">From Date <span className="text-danger">*</span></label>
//                 <div className="cal-icon"><input className="form-control datetimepicker" type="text" value="26/08/2021" /></div>
//             </div>
//         </div>
//         <div className="col-sm-6">
//             <div className="form-group">
//                 <label className="col-form-label">To Date <span className="text-danger">*</span></label>
//                 <div className="cal-icon"><input className="form-control datetimepicker" type="text" value="28/08/2021" /></div>
//             </div>
//         </div>
//         <div className="col-sm-6">
//             <div className="form-group">
//                 <label className="col-form-label">Start Time <span className="text-danger">*</span></label>
//                 <div className="colo-icon"><input className="form-control datetimepicker3" type="text" value="07:00" /></div>
//             </div>
//         </div>
//         <div className="col-sm-6">
//             <div className="form-group">
//                 <label className="col-form-label">End Time <span className="text-danger">*</span></label>
//                 <div className="colo-icon"><input className="form-control datetimepicker3" type="text" value="15:00" /></div>
//             </div>
//         </div>
//         <div className="col-sm-6">
//             <div className="form-group">
//                 <label className="col-form-label">Repeat Options <span className="text-danger">*</span></label>
//                 {/* <select className="select"> */}
//                 {/* abh tak css se krhe tha */}
//                 <select className="select form-control">
//                     <option value="">Select Frequency</option>
//                     <option value="1" selected>Daily</option>
//                     <option value="2">Weekly</option>
//                     <option value="3">Mon-Fri</option>
//                     <option value="4">Mon-Thu</option>
//                 </select>
//             </div>
//         </div>
//         <div className="col-sm-6">
//             <div className="form-group">
//                 <label className="col-form-label">Break Rule <span className="text-danger">*</span></label>
//                 {/* <select className="select"> */}
//                 <select className="select form-control">
//                     <option value="">Select</option>
//                     <option value="1" selected>London Rule</option>
//                     <option value="2">Act 193</option>
//                 </select>
//             </div>
//         </div>
//         <div className="col-sm-12">
//             <div className="form-group">
//                 <label>Note <span className="text-danger">*</span></label>
//                 <textarea className="form-control" rows="4"></textarea>
//             </div>
//         </div>
//     </div>
// </div>
// <div className="contact-cat col-sm-4 mifrmul">
//     <div className="roles-menu">

//         <input type="text" className="form-control mt-1 mb-1" placeholder="Search Here" />
//         <h4 className="mt-2">Who's Working</h4>
//         <ul>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" checked /> Peter Khan</label></div></li>
//         </ul>
//         <h4 className="mt-2">Available</h4>

//         <ul className="milocmn">
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//             <li><div className="checkbox"><label><input type="checkbox" name="checkbox" /> Peter Khan</label></div></li>
//         </ul>
//     </div>
// </div>
// </div>
// <div className="submit-section col-12 mt-1">
//     <button className="btn btn-info submit-btn">Save</button>
//     <button className="btn btn-danger ml-auto submit-btn">Delete</button>
// </div>
