import React, { useState } from 'react'
import moment from 'moment';
import { useSelector } from 'react-redux'
import WeeklyTableRow from './WeeklyTableRow';
import PaginationAndNextPrev from './PaginationAndNextPrev';
import { useEffect } from 'react';
const AdminSSWeeklyTable = ({dateForTableData}) => {
    // const auth = useSelector((state) => state.auth);
    const admin = useSelector((state) => state.admin);
    // const [ displayEntries, setDisplayEntries ] = useState(10)
    const [ today, setToday ] = useState(moment())
    useEffect(() => {
        setToday(dateForTableData)
    }, [dateForTableData])    
    // const [ datePag, setDatePag ] = useState(moment(dateForTableData.format('YYYY-MM-DD')))
    // let date = moment(today.format('YYYY-MM-DD'))
    let dateShift = moment(today.format('YYYY-MM-DD'))
    if (dateShift.format('dddd') !== 'Sunday'){
        dateShift = dateShift.startOf('week').add(1, 'days').format('YYYY-MM-DD')
    }
    else{
        dateShift = dateShift.startOf('week').add(-6, 'days').format('YYYY-MM-DD')
    }
    return (
        <>
            <div className="row miordr2">
                <div className="col-md-12">
                    <div className="table-responsive misftsch">
                        <table className="table table-striped custom-table datatable schedul-rptbl shift-rptbl w-100" >
                            <thead>
                                <tr>
                                    <th>Scheduled Shift</th>
                                    {
                                        dateForTableData.format('dddd') !== 'Sunday' ?
                                        <>
                                            <th>{dateForTableData.startOf('week').add(1, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(2, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(3, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(4, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(5, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(6, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(7, 'days').format('ddd DD')}</th>
                                        </>
                                        :
                                        <>
                                            <th>{dateForTableData.startOf('week').add(-6, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(2, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(3, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(4, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(5, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(6, 'days').format('ddd DD')}</th>
                                            <th>{dateForTableData.startOf('week').add(7, 'days').format('ddd DD')}</th>
                                        </>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {   
                            admin.usersShifts &&
                            admin.usersShifts.length > 0 &&
                            admin.usersShifts.map((current, i ) => {
                                return(
                                    i < (admin.usersShifts.length - 5) &&
                                    <>
                                        {
                                            <WeeklyTableRow current={current} i={i} date={dateShift}/>
                                        }
                                    </>
                                )
                            })}
                        </tbody>
                        </table>
                    </div>
                    <br />
                    <PaginationAndNextPrev dateForPagination={dateForTableData}/>
                </div>
            </div>
        </>
    )
}
export default AdminSSWeeklyTable