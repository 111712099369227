import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import AdminSSAddShiftModal from './AdminSSAddShiftModal'
import EditModalSSEditScheduling from './EditModalSSEditScheduling'
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"
import { BUCKET_BASE_URL } from '../../../constants'

const DailyTableRow = ({current, i}) => {
    const [showDropDown, setshowDropDown] = useState(false)
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const [iii, setIII] = useState()
    const openMoreVertDropDown = (num, userShift, currentShift) => {
        setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
        setIII(num)
    }
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    }
    // const [state, setstate] = useState({currentId:""})
    // const changeState = () => {  
    //     setstate({currentId:current.id})
    //    };
    return (
        <>
            <tr key={i}>
                <td>
                    <h2 className="table-avatar">
                        <NavLink to={`/profile/${current.id}`} className="avatar">
                            {/* <img alt="" src="assetsAdmin/img/avatar-02.jpg" /> */}
                            {
                                current.user &&
                                current.user.profilePic &&
                                current.user.profilePic.fileUrl ?
                                    <img alt="profilePic" src={BUCKET_BASE_URL + current.user.profilePic.fileUrl} style={{objectFit:"fill", height:"100%"}}/>
                                    :
                                    <img alt="profilePic" src={defaultImg} />
                            }
                        </NavLink>
                        <NavLink to={`/profile/${current.id}`}>{current.user.firstName +" "+ current.user.lastName}<span>{current.user.jobTitle}</span></NavLink>
                    </h2>
                </td>
                {
                    current.dailyShift.length > 0 
                    ?
                    current.dailyShift.map((e,j) => {
                        let shiftType;
                        let shiftTypeCss = "miclsun";
                        let shiftTypeIcon = "fa fa-sun-o";
                        let empty = true
                        let temp;
                        // let start, end;
                        let stAmPm = "am";
                        let enAmPm = "am";
                        let date;
                        // console.log(Object.keys(e).length)
                        if (Object.keys(e).length != 0){
                            let stStart = parseInt(e.startTime.substring(11, 13))
                            let stStartMin = parseInt(e.startTime.substring(14, 16))
                            let enEnd = parseInt(e.endTime.substring(11, 13))
                            let enEndMin = parseInt(e.endTime.substring(14, 16))
                            if (stStartMin < 10) {
                                stStartMin = "0"+stStartMin
                            }
                            if (enEndMin < 10){
                                enEndMin = "0"+enEndMin
                            }
                            if (stStart === 0){
                                stStart = 12
                            }
                            if (enEnd === 0){
                                enEnd = 12
                            }
                            if (stStart > 12){
                                stStart = stStart - 12
                                stAmPm = "pm"
                            }
                            if (enEnd > 12){
                                enEnd = enEnd - 12
                                enAmPm = "pm"
                            }
                            // console.log(stStart, stAmPm)
                            if((stStart === 12 || stStart <= 5) && stAmPm == "am")
                            {
                                shiftType = "night"
                                shiftTypeCss="miclsmn"
                                shiftTypeIcon = "fa fa-moon-o"
                            }
                            else if (stStart >= 6 && stAmPm == "am"){
                                shiftType = "morning"
                                shiftTypeCss="miclsun" 
                                shiftTypeIcon = "fa fa-sun-o"
                            }
                            else if (stStart >= 0 && stStart <= 7 && stAmPm == "pm"){
                                shiftType = "evening"
                                shiftTypeCss="miclscl"
                                shiftTypeIcon = "fa fa-cloud" 
                            }
                            else if (stStart >= 8 && stAmPm == "pm"){
                                shiftType = "night"
                                shiftTypeCss="miclsmn"
                                shiftTypeIcon = "fa fa-moon-o"
                            }
                            else if (stStart >= 0 && stAmPm == "am"){
                                shiftType = "night"
                                shiftTypeCss="miclsmn"
                                shiftTypeIcon = "fa fa-moon-o"
                            }
                            date = stStart + ":" + stStartMin + " " + stAmPm + "-" + enEnd + ":" + enEndMin + " " + enAmPm;
                        }
                        return(
                            <td colSpan="6" style={{borderCollapse:"separate"}}>
                            <div className={`user-add-shedule-list ${shiftTypeCss}`}>
                                <h2>
                                    <a to="#" data-toggle="modal" onClick={()=>{openMoreVertDropDown(j, current, e)}} data-target="#edit_schedule">
                                    {
                                        e.location ? 
                                        <span className="username-info ">{date} {e.duration} {e.userId.jobTitle} - {e.location.name} <i className={shiftTypeIcon}></i></span>
                                        :
                                        <span className="username-info ">{date} {e.duration} {e.userId.jobTitle}   <i className={shiftTypeIcon}></i></span>
                                    }
                                        {/* <span className="username-info ">&nbsp;&nbsp;&nbsp;{date} {e.duration} {e.userId.jobTitle} - {e.location.name} &nbsp;&nbsp;&nbsp;<i className={shiftTypeIcon}></i></span> */}
                                    </a>
                                </h2>
                            </div>
                            </td>
                        )
                    })
                    :
                    <td >
                        <div className="user-add-shedule-list">
                            <NavLink to="#" data-toggle="modal" data-target="#add_schedule" onClick={openDropDown}>
                                <span><i className="fa fa-plus"></i></span>
                            </NavLink>
                        </div>
                    </td>
                }
            </tr>
            <EditModalSSEditScheduling current={current} valueIndex={iii} typeStatus={'daily'} showMoreVertDropDown={showMoreVertDropDown} openMoreVertDropDown={openMoreVertDropDown} />
            <AdminSSAddShiftModal current={current} showDropDown={showDropDown} openDropDown={openDropDown} />
        </>
    )
}

export default DailyTableRow