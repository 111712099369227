import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateLeave } from '../../../actions/leaveActions' 

const EditModalLeaveByAdmin = ({ showEditModal, openEditModal, current }) => {
    const [ values, setValues ] = useState({
        id: '',
        status: 'Accepted',
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
           ...values, [fieldName]:
           event.target.value
        })
    }
    const currentLeave = async () => {
        try {
            setValues({
                id: '',
                status: '',
            })
            setValues({
                id: current.e.id,
                status: current.e.status,
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        currentLeave()
    }, [current])
    const dispatch = useDispatch()

    const updateLeaveData = {
        leaveId: values.id,
        status: values.status
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        dispatch(updateLeave(updateLeaveData))
        openEditModal()
        setValues({
            id: '',
            status: '',
        })
    }
    return (
        <>
            <div className={`modal-backdrop fade ${showEditModal ? 'show' : 'hide'}`}></div>
                <div id="edit_leave" className={`modal custom-modal fade ${showEditModal ? 'show' : 'hide'}`} role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Leave</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openEditModal()}>
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                {
                                    current &&
                                    <>
                                        <div className="form-group">
                                            <label>Leave Type <span className="text-danger">*</span></label>
                                            <input className="form-control" readonly type="text" value={current.e.leaveType.name} disabled/>
                                        </div>
                                        <div className="form-group">
                                            <label>From <span className="text-danger">*</span></label>
                                            <div className="cal-icon">
                                                <input className="form-control" readonly type="text" value={moment(current.e.from).format("DD MMMM YYYY")} disabled/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>To <span className="text-danger">*</span></label>
                                            <div className="cal-icon">
                                                <input className="form-control" readonly type="text" value={moment(current.e.to).format("DD MMMM YYYY")} disabled/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label>Number of days <span className="text-danger">*</span></label>
                                            <input className="form-control" readonly type="text" value={current.e.numberOfDays} disabled/>
                                        </div>
                                        <div className="form-group">
                                            <label>{current.e.leaveType.name} Remaining Leaves <span className="text-danger">*</span></label>
                                            <input className="form-control" readonly type="text" value={current.remainingLeavesOfCategory} disabled/>
                                        </div>
                                        <div className="form-group">
                                            <label>Leave Reason <span className="text-danger">*</span></label>
                                            <input className="form-control" readonly type="text" value={current.e.leaveReason} disabled/>
                                        </div>
                                        <div className="form-group">
                                            <label>Leave Status <span className="text-danger">*</span></label>
                                            <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                            value={values.status}
                                            onChange={handleChange('status')}
                                            autoComplete="false">
                                                <option value="Accepted">Accepted</option>
                                                <option value="Rejected">Rejected</option>
                                                <option value="Pending">Pending</option>
                                            </select>
                                        </div>
                                        <div className="submit-section">
                                            <button className="btn btn-primary submit-btn">Save</button>
                                        </div>
                                    </>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditModalLeaveByAdmin