import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import EditModalAttendanceInfo from './EditModalAttendanceInfo'
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"

const AdminEmpAttendanceSheet = () => {
    const [showDropDown, setshowDropDown] = useState(false)
    const openDropDown = (dd) => {
        setshowDropDown((showDropDown) => !showDropDown)
        setDayData(dd)
    }
    const clock = useSelector((state) => state.clock);
    let arr = []
    let arr2 = []
    let temp;
    const month = parseInt(moment().daysInMonth())
    const today = parseInt(moment().format('DD'))
    for (let i=1; i<=month; i++){
        arr.push(i)
    }
    for (let i=1; i<=today; i++){
        arr2.push(i)
    }

    // console.log(month,arr)
    // console.log(moment().add(0, 'days').format('DD'))
    // console.log(showDropDown)
    // useEffect(() => {

        // if(clock.clockAttendance.length > 0){
        //     for (let i = 0; i< clock.clockAttendance.length; i++){
        //         for (let j = 1; j<= 31; j++){
        //     data2[1]= clock.clockAttendance[1]
        //     data2.forEach(e => {
        //         if (e.date.toString().substring(8,10) === j){
        //             arr[j] = e
        //         }
        //         else {
        //             arr[j] = {}
        //         }
        //     });
    
        //     }}
        // }
        // // for (let j=0; j<moment().daysInMonth(); j++){

        // // }
        // console.log(arr)
    // }, [clock.clockAttendance.length > 0])
    
    const [dayData, setDayData] = useState()
    return (
        <>
            <div className="row">
                <div className="col-lg-12" >
                    <div className="table-responsive">
                        <table className="table table-striped custom-table table-nowrap mb-0" >
                            <thead>
                                <tr>
                                    <th>Employee</th>
                                    {
                                        arr &&
                                        arr.length > 0 &&
                                        arr.map((current,i) => {
                                            return(
                                                <th key={i}>{current}</th>
                                            )
                                        })
                                    }
                                    {/* <th>1</th>
                                    <th>2</th>
                                    <th>3</th>
                                    <th>4</th>
                                    <th>5</th>
                                    <th>6</th>
                                    <th>7</th>
                                    <th>8</th>
                                    <th>9</th>
                                    <th>10</th>
                                    <th>11</th>
                                    <th>12</th>
                                    <th>13</th>
                                    <th>14</th>
                                    <th>15</th>
                                    <th>16</th>
                                    <th>17</th>
                                    <th>18</th>
                                    <th>19</th>
                                    <th>20</th>
                                    <th>22</th>
                                    <th>23</th>
                                    <th>24</th>
                                    <th>25</th>
                                    <th>26</th>
                                    <th>27</th>
                                    <th>28</th>
                                    <th>29</th>
                                    <th>30</th>
                                    <th>31</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {
                                clock.clockAttendance &&
                                clock.clockAttendance.length > 0 &&
                                clock.clockAttendance.map((current,i) =>{
                                    return(
                                        <tr>
                                            <td>
                                                <h2 className="table-avatar">
                                                    <NavLink to={`/profile/${current[current.length-2].userId}`}  className="avatar avatar-xs">
                                                        <img alt="" src="assetsAdmin/img/avatar-09.jpg" />
                                                        {
                                                                // COMMENT HAI EK BAAT
                                                                // current[0].profilePicUrl se bhi kaam hijata phir bhi
                                                                // current[current.length-2].profilePicUrl
                                                            current[current.length-2].profilePicUrl ?
                                                                // current.e.userId.profilePic.fileUrl ?
                                                                <img alt="profilePic" src={current[current.length-2].profilePicUrl} style={{objectFit:"fill", height:"100%"}}/>
                                                                :
                                                                <img alt="profilePic" src={defaultImg} />
                                                        }
                                                    </NavLink>
                                                    <NavLink to={`/profile/${current[current.length-2].userId}`} >{current[current.length-2].firstName + " " + current[current.length-2].lastName}</NavLink>
                                                </h2>
                                            </td>
                                            {/* javascript:void(0) ki jaga yaha # laga rhe hain */}
                                            {
                                                current[current.length-1].length > 0 ?
                                                current[current.length-1].map((c,ii) => {
                                                    // console.log( ++ii ,today, c, "Ccccccccc", c.length, ++ii === today)
                                                    return(
                                                        // c.length > 0 ?
                                                        (Object.keys(c).length !== 0) ?
                                                            <td><NavLink to="#" data-toggle="modal" onClick={()=>openDropDown(c)} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                        :
                                                        ++ii >= today ?
                                                        <td></td> :
                                                        <td><i className="fa fa-close text-danger"></i> </td>
                                                        // :
                                                        // <td><i className="fa fa-close text-danger"></i> </td>
                                                    )
                                                    // console.log(Object.keys(e).length,"kkkkkkkkkkkkkkkkkkk")
                                                })
                                                :
                                                arr2.map((d, iii) => {
                                                    return(
                                                        ++iii >= today ?
                                                        <td ></td> :
                                                        <td ><i className="fa fa-close text-danger"></i> </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                        )
                                    })
                                }            
                                                {/* <td><NavLink to="#" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td> */}
                                                {/* <td><NavLink to="javascript:void(0);" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td> */}
                                                {/* <td><NavLink to="#;" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td>
                                                    
                                                    <div className="half-day">
                                                        <span className="first-off"><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></span>
                                                        <span className="first-off"><i className="fa fa-close text-danger"></i></span>
                                                    </div>
                                                </td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><i className="fa fa-close text-danger"></i> </td>
                                                <td><i className="fa fa-close text-danger"></i> </td>
                                                <td><i className="fa fa-close text-danger"></i> </td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><i className="fa fa-close text-danger"></i> </td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td>
                                                    <div className="half-day">
                                                        <span className="first-off"><i className="fa fa-close text-danger"></i></span>
                                                        <span className="first-off"><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></span>
                                                    </div>
                                                </td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><i className="fa fa-close text-danger"></i> </td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><i className="fa fa-close text-danger"></i> </td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td> */}
                                    



                                {/* <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink className="avatar avatar-xs" to="profile.html"><img alt="" src="assetsAdmin/img/avatar-09.jpg" /></NavLink>
                                            <NavLink to="profile.html">John Doe</NavLink>
                                        </h2>
                                    </td> */}
                                    {/* javascript:void(0) ki jaga yaha # laga rhe hain */}
                                    {/* <td><NavLink to="#" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td> */}
                                    {/* <td><NavLink to="javascript:void(0);" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td> */}
                                    {/* <td><NavLink to="#;" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" onClick={openDropDown} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td>
                                        
                                        <div className="half-day">
                                            <span className="first-off"><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></span>
                                            <span className="first-off"><i className="fa fa-close text-danger"></i></span>
                                        </div>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td>
                                        <div className="half-day">
                                            <span className="first-off"><i className="fa fa-close text-danger"></i></span>
                                            <span className="first-off"><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></span>
                                        </div>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                </tr> */}
                                {/* <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink className="avatar avatar-xs" to="profile.html"><img alt="" src="assetsAdmin/img/avatar-09.jpg" /></NavLink>
                                            <NavLink to="profile.html">Richard Miles</NavLink>
                                        </h2>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink className="avatar avatar-xs" to="profile.html"><img alt="" src="assetsAdmin/img/avatar-10.jpg" /></NavLink>
                                            <NavLink to="profile.html">John Smith</NavLink>
                                        </h2>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink className="avatar avatar-xs" to="profile.html"><img alt="" src="assetsAdmin/img/avatar-05.jpg" /></NavLink>
                                            <NavLink to="profile.html">Mike Litorus</NavLink>
                                        </h2>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink className="avatar avatar-xs" to="profile.html"><img alt="" src="assetsAdmin/img/avatar-11.jpg" /></NavLink>
                                            <NavLink to="profile.html">Wilmer Deluna</NavLink>
                                        </h2>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink className="avatar avatar-xs" to="profile.html"><img alt="" src="assetsAdmin/img/avatar-12.jpg" /></NavLink>
                                            <NavLink to="profile.html">Jeffrey Warden</NavLink>
                                        </h2>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink className="avatar avatar-xs" to="profile.html"><img alt="" src="assetsAdmin/img/avatar-13.jpg" /></NavLink>
                                            <NavLink to="profile.html">Bernardo Galaviz</NavLink>
                                        </h2>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink className="avatar avatar-xs" to="profile.html"><img alt="" src="assetsAdmin/img/avatar-01.jpg" /></NavLink>
                                            <NavLink to="profile.html">Lesley Grauer</NavLink>
                                        </h2>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink className="avatar avatar-xs" to="profile.html"><img alt="" src="assetsAdmin/img/avatar-16.jpg" /></NavLink>
                                            <NavLink to="profile.html">Jeffery Lalor</NavLink>
                                        </h2>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                </tr>
                                <tr>
                                    <td>
                                        <h2 className="table-avatar">
                                            <NavLink className="avatar avatar-xs" to="profile.html"><img alt="" src="assetsAdmin/img/avatar-04.jpg" /></NavLink>
                                            <NavLink to="profile.html">Loren Gatlin</NavLink>
                                        </h2>
                                    </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><i className="fa fa-close text-danger"></i> </td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                    <td><NavLink to="#;" data-toggle="modal" data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                </tr> */}

                            </tbody>
                        </table>
                        <EditModalAttendanceInfo dayData={dayData} showDropDown={showDropDown} openDropDown={openDropDown}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminEmpAttendanceSheet