import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  clockExist,
  clockIn,
  clockUpdate,
  monthlyClockStatusById,
} from "../../../actions/clockActions";
import { toast } from "react-hot-toast";
import TimeComponent from "../../TimeComponent";

const AdminTClockCards = () => {
  const clock = useSelector((state) => state.clock);
  const auth = useSelector((state) => state.auth);
  let dateArr;
  // console.log(clock.clockData.dayClockIn)
  let hr = 0;
  let min = 0;
  let amPm = "AM";
  let hoursUsedInDecimal;
  let totalHours;
  const [clockOutReq, setClockOutReq] = useState(false);
  const [clockInReq, setClockInReq] = useState(false);
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  // const [coordinatesClockOut, setCoordinatesClockOut] = useState({
  //   lat: null,
  //   lng: null,
  // });
  const clockCoordinates = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        setCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
    //   setClockInReq(true)
  };
  const clockInUser = async () => {
    // console.log("clock in", clock, coordinates);
    if (coordinates.lat !== null && coordinates !== null) {
      // setClockInReq(true)
      if (clock.clockData !== null) {
        clockInUpdate();
        setClockInReq(false);
      } else {
        clockInButton();
        setClockInReq(false);
      }
    } else {
      // window.alert("Allow Location  this Site By Setting");
      toast.error("Allow Location to this site!");
    }
  };
  const clockOutUser = async () => {
    if (coordinates.lat !== null && coordinates !== null) {
      // setClockOutReq(true)
      clockOutButton();
    } else {
      // window.alert("Allow Location On this Site By Setting");
      toast.error("Allow Location to this site");
    }
  };
  const clockOutButton = async () => {
    const currDate = moment().format();
    // const arr = currDate.split("+");
    const arr= currDate.slice(0,-6)

    // const newCurrDate = arr[0] + ".000+00:00";
    const newCurrDate = arr + ".000+00:00";
    if (auth.user.locationRequired) {
      dispatch(
        clockUpdate({
          clockId: clock.clockData.id,
          clockOut: newCurrDate,
          longitude: coordinates.lng,
          latitude: coordinates.lat,
        })
      );
    } else {
      dispatch(
        clockUpdate({
          clockId: clock.clockData.id,
          clockOut: newCurrDate,
        })
      );
    }
  };
  // useEffect(() => {
  //     if (coordinates.lat !== null && coordinates.lng !== null)
  //     {
  //         clockOutButton()
  //         setClockOutReq(false)
  //     }
  // }, [clockOutReq])

  const clockInButton = async (e) => {
    const currDate = moment().format();
    // const arr = currDate.split("+");
    const arr= currDate.slice(0,-6)

    // const newCurrDate = arr[0] + ".000+00:00";
    const newCurrDate = arr + ".000+00:00";

    if (auth.user.locationRequired) {
      dispatch(
        clockIn({
          userId: auth.user.id,
          companyId: auth.user.companyId,
          date: newCurrDate,
          longitude: coordinates.lng,
          latitude: coordinates.lat,
        })
      );
    } else {
      dispatch(
        clockIn({
          userId: auth.user.id,
          companyId: auth.user.companyId,
          date: newCurrDate,
        })
      );
    }
  };
  const clockInUpdate = async (e) => {
    const currDate = moment().format();
    // const arr = currDate.split("+");
    const arr= currDate.slice(0,-6)

    // const newCurrDate = arr[0] + ".000+00:00";
    const newCurrDate = arr + ".000+00:00";

    if (auth.user.locationRequired) {
      dispatch(
        clockUpdate({
          clockId: clock.clockData.id,
          clockIn: newCurrDate,
          longitude: coordinates.lng,
          latitude: coordinates.lat,
        })
      );
    } else {
      dispatch(
        clockUpdate({
          clockId: clock.clockData.id,
          clockIn: newCurrDate,
        })
      );
    }
  };
  // useEffect(() => {
  //     if (coordinates.lat !== null && coordinates.lng !== null)
  //     {
  //         if (clock.clockData !== null ){
  //             clockInUpdate()
  //             setClockInReq(false)
  //         }
  //         else {
  //             clockInButton()
  //             setClockInReq(false)
  //         }
  //     }
  // },[clockInReq])
  if (clock.clockData !== null) {
    const dateForRender = new Date(clock.clockData.dayClockIn);
    dateArr = dateForRender.toString().split(" ");
    // console.log(dateArr);
    hr = parseInt(clock.clockData.dayClockIn.substring(11, 13));
    min = clock.clockData.dayClockIn.substring(14, 16);
    if (hr > 12) {
      amPm = "PM";
    }
    hoursUsedInDecimal = clock.clockData.hoursUsedInDecimal;
    totalHours = clock.clockData.totalHours;
  }
  const dispatch = useDispatch();
  const monthlyClockStatusData = async () => {
    const currDate = moment().format();
    // const arr = currDate.split("+");
    const arr= currDate.slice(0,-6)

    // const newCurrDate = arr[0] + ".000+00:00";
        const newCurrDate = arr + ".000+00:00";

    dispatch(
      monthlyClockStatusById({ userId: auth.user.id, date: newCurrDate })
    );
  };
  const checkClock = async () => {
    const dd = moment().format("YYYY-MM-DD");
    const todayRegex = dd.substring(0, 10);
    // console.log(todayRegex)
    const iden = "newDate" + todayRegex + "user" + auth.user.id;
    dispatch(clockExist({ dayIdentifier: iden }));
  };
  useEffect(() => {
    monthlyClockStatusData();
    checkClock();
    clockCoordinates();
  }, []);
  // console.log(clockInReq, clockOutReq, "<____________________")
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <div className="card punch-status">
            <div
              className="card-body"
              style={{ minHeight: "415px", maxHeight: "415px" }}
            >
              {clock.clockData !== null && (
                <>
                  <h5 className="card-title">
                    Timesheet{" "}
                    <small className="text-muted">
                      {moment().format("DD MMMM, YYYY")}
                    </small>
                  </h5>
                  <div className="punch-det">
                    <h6>Clock In at</h6>
                    <p>
                      {dateArr[0]}, {dateArr[2]} {dateArr[1]} {dateArr[3]}{" "}
                      {clock.clockData.dayClockIn.substring(11, 13)}.{min}{" "}
                      {amPm}
                    </p>
                    {/* <p>Wed, 11th Mar 2021 10.00 AM</p> */}
                  </div>
                </>
              )}

              <div className="punch-info">
                {" "}
                <TimeComponent clockData={clock.clockData} />
                {/* <div className="punch-hours"> */}
                {/* <span>3.45 hrs</span> */}
                {/* {clock.clockData !== null && (
                    <span>{clock.clockData.hoursUsed}</span>
                  )} */}
                {/* </div> */}
              </div>
              <div className="punch-btn-section">
                {/* <button type="button" className="btn btn-info">Break Start</button>
                                <button type="button" className="btn btn-primary">Clock Out</button> */}
                {!clock.clockInButton && (
                  <>
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={clockOutUser}
                    >
                      Break Start
                    </button>
                    <span>&nbsp;</span>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={clockOutUser}
                    >
                      Clock Out
                    </button>
                  </>
                )}
                {clock.clockInButton && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={clockInUser}
                  >
                    Clock-In
                  </button>
                )}
                {!clock.breakButton && (
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={clockInUser}
                  >
                    Break Resume
                  </button>
                )}
              </div>
              <div className="statistics">
                <div className="row">
                  <div className="col-md-12 col-6 text-center">
                    <div className="stats-box">
                      <p>Break</p>
                      {clock.clockData !== null && (
                        <h6>{clock.clockData.breakUsed}</h6>
                      )}
                      {/* <h6>1.21 hrs</h6> */}
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-6 text-center">
                                        <div className="stats-box">
                                            <p>Overtime</p>
                                            <h6>3 hrs</h6>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card att-statistics">
            <div
              className="card-body"
              style={{ minHeight: "415px", maxHeight: "415px" }}
            >
              <h5 className="card-title">Statistics</h5>
              <div className="stats-list">
                <div className="stats-info">
                  {clock.clockData !== null ? (
                    <>
                      <p>
                        Today{" "}
                        <strong>
                          {hoursUsedInDecimal.toString().substring(0, 4)}{" "}
                          <small>/ {totalHours} hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        {/* <div className="progress-bar bg-primary" role="progressbar" style={{ width: "31%" }} aria-valuenow="31" aria-valuemin="0" aria-valuemax="100"></div> */}
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{
                            width:
                              (hoursUsedInDecimal / totalHours) * 100 + "%",
                          }}
                          aria-valuenow="31"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <p>
                        Today{" "}
                        <strong>
                          _ <small>/ _ hrs</small>
                        </strong>
                      </p>
                      <div className="progress">
                        {/* <div className="progress-bar bg-primary" role="progressbar" style={{ width: "31%" }} aria-valuenow="31" aria-valuemin="0" aria-valuemax="100"></div> */}
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{
                            width:
                              (hoursUsedInDecimal / totalHours) * 100 + "%",
                          }}
                          aria-valuenow="31"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </>
                  )}
                  {/* <p>Today <strong>3.45 <small>/ 8 hrs</small></strong></p>
                                    <div className="progress">
                                        <div className="progress-bar bg-primary" role="progressbar" style={{width: "31%"}} aria-valuenow="31" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div> */}
                </div>
                <div className="stats-info">
                  {/* <p>This Week <strong>28 <small>/ 40 hrs</small></strong></p>
                                    <div className="progress">
                                        <div className="progress-bar bg-warning" role="progressbar" style={{width: "31%"}} aria-valuenow="31" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div> */}
                  {clock.clockStatusData !== null && (
                    <>
                      <p>
                        This Week{" "}
                        <strong>
                          {clock.clockStatusData[0].weekHoursConsumed
                            .toString()
                            .substring(0, 5)}{" "}
                          <small>
                            / {clock.clockStatusData[0].weekHours} hrs
                          </small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar bg-warning"
                          role="progressbar"
                          style={{
                            width:
                              (clock.clockStatusData[0].weekHoursConsumed /
                                clock.clockStatusData[0].weekHours) *
                                100 +
                              "%",
                          }}
                          aria-valuenow="31"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </>
                  )}
                </div>
                <div className="stats-info">
                  {/* <p>This Month <strong>90 <small>/ 160 hrs</small></strong></p>
                                    <div className="progress">
                                        <div className="progress-bar bg-success" role="progressbar" style={{width: "62%"}} aria-valuenow="62" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div> */}
                  {clock.clockStatusData !== null && (
                    <>
                      <p>
                        This Month{" "}
                        <strong>
                          {clock.clockStatusData[0].monthlyHoursConsumed
                            .toString()
                            .substring(0, 5)}{" "}
                          <small>
                            / {clock.clockStatusData[0].monthlyHours} hrs
                          </small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar bg-success"
                          role="progressbar"
                          style={{
                            width:
                              (clock.clockStatusData[0].monthlyHoursConsumed /
                                clock.clockStatusData[0].monthlyHours) *
                                100 +
                              "%",
                          }}
                          aria-valuenow="62"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </>
                  )}
                </div>
                <div className="stats-info">
                  {/* <p>Remaining <strong>90 <small>/ 160 hrs</small></strong></p>
                                    <div className="progress">
                                        <div className="progress-bar bg-danger" role="progressbar" style={{width: "62%"}} aria-valuenow="62" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div> */}
                  {clock.clockStatusData !== null && (
                    <>
                      <p>
                        Remaining{" "}
                        <strong>
                          {clock.clockStatusData[0].remainingHoursMonths
                            .toString()
                            .substring(0, 5)}{" "}
                          <small>
                            / {clock.clockStatusData[0].monthlyHours} hrs
                          </small>
                        </strong>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar bg-danger"
                          role="progressbar"
                          style={{
                            width:
                              (clock.clockStatusData[0].remainingHoursMonths /
                                clock.clockStatusData[0].monthlyHours) *
                                100 +
                              "%",
                          }}
                          aria-valuenow="62"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </>
                  )}
                </div>
                <div className="stats-info">
                  <p>
                    Overtime <strong>4</strong>
                  </p>
                  <div className="progress">
                    <div
                      className="progress-bar bg-info"
                      role="progressbar"
                      style={{ width: "22%" }}
                      aria-valuenow="22"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card recent-activity">
            <div
              className="card-body"
              style={{ minHeight: "415px", maxHeight: "415px" }}
            >
              <h5 className="card-title">Today Activity</h5>
              <ul className="res-activity-list">
                {clock.clockData !== null &&
                  clock.clockData.clockHistory.length > 0 &&
                  clock.clockData.clockHistory.map((current, i) => {
                    return (
                      <>
                        <li>
                          <p className="mb-0">Clock In at</p>
                          <p className="res-activity-time">
                            <i className="fa fa-clock-o"></i>
                            &nbsp; {current.clockIn.substring(11, 16)}{" "}
                            {parseInt(current.clockIn.substring(11, 13)) > 12
                              ? "PM"
                              : "AM"}
                          </p>
                        </li>
                        {current.clockOut && (
                          <li>
                            <p className="mb-0">Clock Out at</p>
                            <p className="res-activity-time">
                              <i className="fa fa-clock-o"></i>
                              &nbsp; {current.clockOut.substring(11, 16)}{" "}
                              {parseInt(current.clockOut.substring(11, 13)) > 12
                                ? "PM"
                                : "AM"}
                            </p>
                          </li>
                        )}
                      </>
                    );
                  })}
                {/* <li>
                                    <p className="mb-0">Clock In at</p>
                                    <p className="res-activity-time">
                                        <i className="fa fa-clock-o"></i>
                                        10.00 AM.
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-0">Clock Out at</p>
                                    <p className="res-activity-time">
                                        <i className="fa fa-clock-o"></i>
                                        11.00 AM.
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-0">Clock In at</p>
                                    <p className="res-activity-time">
                                        <i className="fa fa-clock-o"></i>
                                        11.15 AM.
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-0">Clock Out at</p>
                                    <p className="res-activity-time">
                                        <i className="fa fa-clock-o"></i>
                                        1.30 PM.
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-0">Clock In at</p>
                                    <p className="res-activity-time">
                                        <i className="fa fa-clock-o"></i>
                                        2.00 PM.
                                    </p>
                                </li>
                                <li>
                                    <p className="mb-0">Clock Out at</p>
                                    <p className="res-activity-time">
                                        <i className="fa fa-clock-o"></i>
                                        7.30 PM.
                                    </p>
                                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminTClockCards;
