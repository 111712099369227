import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
const SuperAdminClientSubsriptionTable = () => {
  const company = useSelector((state) => state.company)
  return (
    <>
    <div className="tab-content">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-table mb-0">
            <div className="card-header">
              <h4 className="card-title mb-0">Current Subscription</h4>
            </div>
            <div className="card-body">
              {/* <div className="table-responsive"> */}
                <table className="table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th>Plan</th>
                      <th>Users</th>
                      <th>Plan Duration</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Plan Price</th>
                      <th>Update Plan</th>
                    </tr>
                  </thead>
                  <tbody>
                  {   
                    company.superAdminCompanyCPC[0] &&
                    company.superAdminCompanyCPC[0].currentSubscription &&
                    // company.superAdminCompanyCPC[0].currentSubscription.length > 0 &&
                    <tr>
                      <td>{company.superAdminCompanyCPC[0].currentSubscription.currentSubscriptionPackage.planName}</td>
                      {/* current active user? ya all users? */}
                      <td>{company.superAdminCompanyCPC[0].currentSubscription.currentSubscriptionPackage.users} Users</td>
                      {
                        company.superAdminCompanyCPC[0].currentSubscription.currentSubscriptionPackageType === "yearly" ?
                        <td>1 Year </td> : <td>Monthly</td> 
                      }
                      
                      <td>{moment.unix(company.superAdminCompanyCPC[0].dates.current_period_start).format('DD MMM YYYY')}</td>
                      <td>{moment.unix(company.superAdminCompanyCPC[0].dates.current_period_start).format('DD MMM YYYY')}</td>
                      {
                      company.superAdminCompanyCPC[0].currentSubscription.currentSubscriptionPackageType === "yearly" ?
                      <td>${company.superAdminCompanyCPC[0].currentSubscription.currentSubscriptionPackage.amountYearly}</td>
                    :
                      <td>${company.superAdminCompanyCPC[0].currentSubscription.currentSubscriptionPackage.amountMonthly}</td>
                    }
                      <td>
                        <a className="btn btn-primary btn-sm">Change Plan</a>
                      </td>
                    </tr>
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  )
}
export default SuperAdminClientSubsriptionTable