import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { monthlyAttendanceSearch } from '../../../actions/clockActions'
import { tabAttendance, tabAttendanceSearch, tabCheckMonthAndYearAttendance } from '../../../actions/tabActions';
import { NavLink } from 'react-router-dom'

const AdminEmpAttendanceSearch = () => {
   const auth = useSelector((state) => state.auth);
   const dispatch = useDispatch()
   const [values, setValues] = useState({
      companyId: auth.user.companyId,
      name: '',
      month: '',
      year: '',
   })
   const handleChange = (fieldName) => (event) => {
      setValues({
         ...values, [fieldName]:
            event.target.value
      })
   }
   let searchData;
   searchData = {
      companyId: auth.user.companyId,
      name: values.name,
      month: values.month,
      year: values.year,
   }
   const [ isErr, setIsErr ] = useState(false)
   const handleClick = async (event) => {
      event.preventDefault()
      for (let key of Object.keys(searchData)) {
         if(searchData[key] === '' ){
            delete searchData[key]
         }
      }
      if (values.month === '' || values.year === '' || values.name === ''){
         setIsErr(true)
      }
      else{
         dispatch(tabCheckMonthAndYearAttendance({month:values.month, year:values.year}))
         dispatch(tabAttendanceSearch())
         dispatch(monthlyAttendanceSearch(searchData))
      }
   }
   const attendanceData = () => {
      dispatch(tabAttendance())
   }
   useEffect(() => {
     attendanceData()
   }, [])
   return (
      <>
         <div className="row filter-row">
            <div className="col-sm-6 col-md-3">
               <div className="form-group form-focus select-focus">
                  <input type="text" className="form-control floating" value={values.name} onChange={handleChange('name')}/>
                  <label className="focus-label">Employee Name
                  {
                     isErr === true && values.name === '' &&
                     <span className='text-danger'>&nbsp;<i className="fa fa-exclamation-circle"> Name can't be empty</i></span>
                  }
                  </label>
               </div>
            </div>
            <div className="col-sm-6 col-md-3">
               <div className="form-group form-focus select-focus">
                  <select className="form-select" style={{ height: "50px", fontSize: "14px", paddingBottom: "0.01rem" }}
                  value={values.month} onChange={handleChange('month')}
                  >
                     <option value=''>-</option>
                     <option value='01'>Jan</option>
                     <option value='02'>Feb</option>
                     <option value='03'>Mar</option>
                     <option value='04'>Apr</option>
                     <option value='05'>May</option>
                     <option value='06'>Jun</option>
                     <option value='07'>Jul</option>
                     <option value='08'>Aug</option>
                     <option value='09'>Sep</option>
                     <option value='10'>Oct</option>
                     <option value='11'>Nov</option>
                     <option value='12'>Dec</option>
                  </select>
                  <label className="focus-label">Select Month
                  {
                     isErr === true && values.month === '' &&
                     <span className='text-danger'>&nbsp;<i className="fa fa-exclamation-circle"> Month can't be empty</i></span>
                  }
                  </label>
               </div>
            </div>
            <div className="col-sm-6 col-md-3">
               <div className="form-group form-focus select-focus">
                  <select className="form-select" style={{ height: "50px", fontSize: "14px", paddingBottom: "0.01rem" }}
                  value={values.year} onChange={handleChange('year')}
                  >
                     <option value=''>-</option>
                     <option value='2022'>2022</option>
                     <option value='2023'>2023</option>
                     <option value='2024'>2024</option>
                     <option value='2025'>2025</option>
                  </select>
                  <label className="focus-label">Select Year
                  {
                     isErr === true && values.year === '' &&
                     <span className='text-danger'>&nbsp;<i className="fa fa-exclamation-circle"> Year can't be empty</i></span>
                  }
                  </label>
               </div>
            </div>
            <div className="col-sm-6 col-md-3">
               <a to="#" onClick={handleClick} className="btn btn-success btn-block"> Search </a>
            </div>
         </div>
      </>
   )
}
export default AdminEmpAttendanceSearch