import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import EditModalProfileCover from "../profileComponent/EditModalProfileCover";
import DeleteModal from "./DeleteModal";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";
// import { useDispatch } from 'react-redux'
// import { tableRowNumber } from '../../../actions/tabActions'
// import EditModaleditEmployee from './EditModaleditEmployee'

const AdminEmployeeTableRow = ({ current, i, dropDownRow, setDropDownRow }) => {
  // setDropDownRow(null)
  const [showDropDown, setshowDropDown] = useState(false);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  // const dispatch = useDispatch()
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
    setshowMoreVertDropDown(false);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
    setshowMoreVertDropDown(false);
  };
  // const [state, setstate] = useState({currentId:""})
  // const changeState = () => {
  //     setstate({currentId:current.id})
  // };
  return (
    <>
      {current.delete === false && (
        <tr key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}>
          <td>
            <h2 className="table-avatar">
              <NavLink to={`/profile/${current.id}`} className="avatar">
                {/* <img alt="" src="assetsAdmin/img/profiles/avatar-02.jpg" /> */}
                {current.profilePic && current.profilePic.fileUrl ? (
                  <img
                    alt="profilePic"
                    src={BUCKET_BASE_URL + current.profilePic.fileUrl}
                    style={{ objectFit: "fill", height: "100%" }}
                  />
                ) : (
                  <img alt="profilePic" src={defaultImg} />
                )}
              </NavLink>
              <NavLink to={`/profile/${current.id}`}>
                {current.firstName + " " + current.lastName}
              </NavLink>
            </h2>
          </td>
          <td>{current.employeeId}</td>
          <td>{current.email}</td>
          <td>{current.phone}</td>
          <td>{current.jobTitle}</td>
          {current.disable === false ? (
            <td className="text-success"> Active</td>
          ) : (
            <td className="text" style={{ color: "red" }}>
              Non-Active
            </td>
          )}
          <td className="text-right">
            <div className="dropdown dropdown-action">
              <a
                className="action-icon dropdown-toggle"
                data-toggle="dropdown"
                onClick={() => {
                  openMoreVertDropDown();
                  setDropDownRow(i);
                }}
                aria-expanded="false"
                style={{ cursor: "pointer" }}
              >
                <i className="material-icons">more_vert</i>
              </a>
              {
                // dropDownRow !== null &&
                // console.log(dropDownRow === i, "chal kiya raha hai") &&
                dropDownRow === i && (
                  <>
                    <div
                      className={`dropdown-menu dropdown-menu-right ${
                        showMoreVertDropDown ? "show" : "hide"
                      }`}
                    >
                      <NavLink
                        to={`/employees/${current.id}`}
                        className="dropdown-item"
                        data-toggle="modal"
                        onClick={openDropDown}
                        data-target="#edit_employee"
                      >
                        <i className="fa fa-pencil m-r-5"></i> Edit
                      </NavLink>
                      {/* <a href={`/employees/${current.id}`} className="dropdown-item" data-toggle="modal" onClick={openDropDown} data-target="#edit_employee"><i className="fa fa-pencil m-r-5"></i> Edit</a> */}
                      <a
                        className="dropdown-item"
                        data-toggle="modal"
                        onClick={openDeleteDropDown}
                        data-target="#delete_employee"
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-trash-o m-r-5"></i> Delete
                      </a>
                      <a
                        className="dropdown-item"
                        data-toggle="modal"
                        href={`/profile/${current.id}`}
                        data-target="#view_employee"
                      >
                        <i className="fa fa-eye"></i> View
                      </a>
                      {/* <button onClick={changeState}></button>
                                        <Aaaa abc={state.abc} / > */}
                    </div>
                  </>
                )
              }
            </div>
          </td>
        </tr>
      )}
      <EditModalProfileCover
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
      <DeleteModal
        current={current}
        showDeleteDropDown={showDeleteDropDown}
        openDeleteDropDown={openDeleteDropDown}
      />
    </>
  );
};
export default AdminEmployeeTableRow;
