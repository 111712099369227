// import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
// import { useParams } from 'react-router-dom'
// import { getAllLocationsWithUsers, updateLocation } from '../../../actions/locationActions'
import { updateLocation } from '../../../actions/locationActions'
import MyComponent from '../Map/Map'
// import { SERVERADDRESS } from '../../../constants'

const EditModalLPTable = ({ showDropDown, openDropDown, current}) => {
    // const auth = useSelector((state) => state.auth)
    // const location = useSelector((state) => state.location)
    const [ values, setValues ] = useState({
        id: '',
        name: '',
        address: '',
        description: '',
        phoneNumber: '',
        latitude: '',
        longitude: '',
        map: '',
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    // const params = useParams()
    // console.log("paramsparamsparamsparamsparams", params.id)
    const currentLocation = async () => {
        try {
            setValues({
                id: '',
                name: '',
                address: '',
                description: '',
                phoneNumber: '',
                latitude: '',
                longitude: '',
                map: '',
            })
            // console.log("currentcurrentcurrentcurrent",current)
        //   if(params.id){
        //       const res = await axios.post(`${SERVERADDRESS}/v1/location/getLocationByLocationId`, 
        //           {locationId: params.id})
        if(current.location){
            setValues({
                id: current.location.id,
                name: current.location.name,
                address: current.location.address,
                description: current.location.description,
                phoneNumber: current.location.phoneNumber,
                latitude: current.location.latitude,
                longitude: current.location.longitude,
                map: current.location.map,
            })
        }
        else{
            setValues({
                id: current.id,
                name: current.name,
                address: current.address,
                description: current.description,
                phoneNumber: current.phoneNumber,
                latitude: current.latitude,
                longitude: current.longitude,
                map: current.map,
            })
        }
        //   }
        } catch (error) {
          console.log(error)
        }
    }
    useEffect(() => {
        currentLocation()
    }, [current])
    
    // console.log(values)
    const dispatch = useDispatch()
    const updateLocationData = {
        // companyId: auth.user.companyId,
        _id: values.id,
        name: values.name,
        address: values.address ,
        description: values.description,
        phoneNumber: values.phoneNumber,
        map: values.map,
        latitude: values.latitude,
        longitude: values.longitude,
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        dispatch(updateLocation(updateLocationData))
        // dispatch(getAllLocationsWithUsers({companyId: auth.user.companyId}))
        // openDropDown()
        // setValues({
        //     // companyId: '',
        //     name: '',
        //     address: '',
        //     description: '',
        //     phoneNumber: '',
        //     latitude: '',
        //     longitude: '',
        //     map: '',
        // })
    }
    // useEffect(() => {
    //     dispatch(getAllLocationsWithUsers({companyId: auth.user.companyId}))
    // }, [update])

    return (
        <>
            <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
            <div className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} id="edit_plan" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Location Position</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                values &&
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <label>Location Name <span className="text-danger">*</span></label>
                                        <input className="form-control" type="text"
                                        value={values.name}
                                        onChange={handleChange('name')}/>
                                    </div>
                                    <div className="form-group">
                                        <label>Address<span className="text-danger">*</span></label>
                                        <input className="form-control" type="text"
                                        value={values.address}
                                        onChange={handleChange('address')}/>
                                    </div>
                                    {/* <div className="form-group">
                                        <label>Phone<span className="text-danger">*</span></label>
                                        <input className="form-control" type="text"
                                        value={values.phoneNumber}
                                        onChange={handleChange('phoneNumber')}/>
                                    </div> */}
                                    <div className="form-group">
                                        <label>Description <span className="text-danger">*</span></label>
                                        <textarea rows="4" className="form-control"
                                        value={values.description}
                                        onChange={handleChange('description')}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <MyComponent currLat={values.latitude} currLng={values.longitude}/>
                                        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d90325.47666039692!2d-93.33151828200064!3d44.970675595414185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b333909377bbbd%3A0x939fc9842f7aee07!2sMinneapolis%2C%20MN%2C%20USA!5e0!3m2!1sen!2s!4v1648644861491!5m2!1sen!2s" width="100%" height="250" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                        {/* <iframe src="https://www.google.com/maps/search/?api=1&query=24.9262722,67.1356391" width="100%" height="250" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                    </div>
                                    <div className="submit-section">
                                        <button className="btn btn-primary submit-btn" type="submit">Save</button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditModalLPTable