import React from 'react'
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { adminDashboard, reportShiftWeekly } from '../../../actions/adminActions';
import { useState } from 'react';
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"
import { BUCKET_BASE_URL } from '../../../constants';

const ShiftReportWeekly = ({today}) => {
    const auth = useSelector((state) => state.auth);
    const admin = useSelector((state) => state.admin);
    // const [ today, setToday ] = useState(moment())
    let date = moment(today.format('YYYY-MM-DD'))
    let dateForJS = moment(today.format('YYYY-MM-DD'))
    let compArr = []
    let dateDay = [0,1,2,3,4,5,6]
    for(let e of dateDay)(
        compArr[e] = dateForJS.startOf('week').add(++e, 'days').format('DD')
    )
    // console.log(compArr)
    // console.log("j", today, moment(), moment().format(), date, date.format('MMM DD, YYYY'))
    const dispatch = useDispatch()
    const weeklyShiftReportData = () => {
        let abcDate = moment(today).format('YYYY-MM-DD')
        // console.log(abcDate)
        dispatch(reportShiftWeekly({companyId: auth.user.companyId, 
            date: abcDate,
            type: "Weekly",
            limit: limit, page: currentPageNumber
        }))
    }
    useEffect(() => {
        weeklyShiftReportData()
    }, [today])

    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [limit, setLimit] = useState(10)
 
    const handleLimit = async (event) =>{
        setLimit(
            parseInt(event.target.value)
        )
        dispatch(reportShiftWeekly({ companyId: auth.user.companyId, date: moment(today).format('YYYY-MM-DD'), type: "Weekly", limit: event.target.value, page: 1 }))
        setCurrentPageNumber(1)
    }
    const onClickNext = async () => {
        let i= currentPageNumber
        i++;
        if (i <= admin.shiftReportWeekly[admin.shiftReportWeekly.length-5]){
            setCurrentPageNumber(currentPageNumber + 1)
            dispatch(reportShiftWeekly({ companyId: auth.user.companyId, date: moment(today).format('YYYY-MM-DD'), type: "Weekly", limit: limit, page: currentPageNumber+1 }))
        }
    }
//    console.log(currentPageNumber)
    const onClickPrev = async () => {
        let i= currentPageNumber
        i--;
        if (i >= 1){
            setCurrentPageNumber(currentPageNumber - 1)
            dispatch(reportShiftWeekly({ companyId: auth.user.companyId, date: moment(today).format('YYYY-MM-DD'), type: "Weekly", limit: limit, page: currentPageNumber -1 }))
        }
    }
    const handleChangeData = async (pageNo) => {
        setCurrentPageNumber(
            pageNo
        )
        dispatch(reportShiftWeekly({ companyId: auth.user.companyId, date: moment(today).format('YYYY-MM-DD'), type: "Weekly", limit: limit, page: pageNo }))
    }
    return (
        <>
            <div className="row miordr2">
                <div className="col-md-12">
                    <div className="table-responsive misftsch">
                        <table className="table table-striped custom-table datatable schedul-rptbl" >
                            <thead>
                                <tr>
                                    <th>All Employees</th>
                                    <th>Total</th>
                                    {
                                        date.format('dddd') !== 'Sunday' ?
                                        <>
                                            <th className="report-tbhd2 report-tbhd1st">{date.startOf('week').add(1, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(2, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(3, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(4, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(5, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(6, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(7, 'days').format('MMM DD, YYYY')}</th>
                                        </>
                                        :
                                        <>
                                            <th className="report-tbhd2 report-tbhd1st">{date.startOf('week').add(-6, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(2, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(3, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(4, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(5, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(6, 'days').format('MMM DD, YYYY')}</th>
                                            <th className="report-tbhd2">{date.startOf('week').add(7, 'days').format('MMM DD, YYYY')}</th>
                                        </>
                                    }
                                    {/* <th className="report-tbhd2">May 16, 2022</th>
                                    <th className="report-tbhd2">May 17, 2022</th>
                                    <th className="report-tbhd2">May 18, 2022</th>
                                    <th className="report-tbhd2">May 19, 2022</th>
                                    <th className="report-tbhd2">May 20, 2022</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {   
                                admin.shiftReportWeekly &&
                                admin.shiftReportWeekly.length > 0 &&
                                admin.shiftReportWeekly.map((current, i ) => {
                                    return(
                                        i < (admin.shiftReportWeekly.length - 5) &&
                                        <tr key={i}>
                                            <td>
                                                <h2 className="table-avatar">
                                                    <NavLink to={`/profile/${current.e.id}`} className="avatar">
                                                        {
                                                            current.e &&
                                                            current.e.profilePic &&
                                                            current.e.profilePic.fileUrl ?
                                                                <img alt="profilePic" src={BUCKET_BASE_URL + current.e.profilePic.fileUrl} style={{objectFit:"fill", height:"100%"}}/>
                                                                :
                                                                <img alt="profilePic" src={defaultImg} />
                                                        }
                                                    </NavLink>
                                                    <NavLink to={`/profile/${current.e.id}`}>{current.e.firstName +", "+ current.e.lastName}<span>{current.e.jobTitle}</span></NavLink>
                                                </h2>
                                            </td>
                                            <td className="report-tbhdtt1">{Math.round(current.totalHours * 100) / 100 }</td>
                                            {
                                                current.weekData.length > 0 
                                                ?
                                                current.weekData.map((e, j)=>{
                                                    let shiftType;
                                                    let shiftTypeCss = "miclsun";
                                                    let shiftTypeIcon = "fa fa-sun-o";
                                                    let empty = true
                                                    let temp;
                                                    // let start, end;
                                                    let stAmPm = "am";
                                                    let enAmPm = "am";
                                                    let date;
                                                    if (Object.keys(e).length != 0){
                                                        let stStart = parseInt(e.startTime.substring(11, 13))
                                                        let stStartMin = parseInt(e.startTime.substring(14, 16))
                                                        let enEnd = parseInt(e.endTime.substring(11, 13))
                                                        let enEndMin = parseInt(e.endTime.substring(14, 16))
                                                        if (stStartMin < 10) {
                                                            stStartMin = "0"+stStartMin
                                                        }
                                                        if (enEndMin < 10){
                                                            enEndMin = "0"+enEndMin
                                                        }
                                                        if (stStart === 0){
                                                            stStart = 12
                                                        }
                                                        if (enEnd === 0){
                                                            enEnd = 12
                                                        }
                                                        if (stStart > 12){
                                                            stStart = stStart - 12
                                                            stAmPm = "pm"
                                                        }
                                                        if (enEnd > 12){
                                                            enEnd = enEnd - 12
                                                            enAmPm = "pm"
                                                        }
                                                        if((stStart === 12 || stStart <= 5) && stAmPm == "am")
                                                        {
                                                            shiftType = "night"
                                                            shiftTypeCss="miclsmn"
                                                            shiftTypeIcon = "fa fa-moon-o"
                                                        }
                                                        else if (stStart >= 6 && stAmPm == "am"){
                                                            shiftType = "morning"
                                                            shiftTypeCss="miclsun" 
                                                            shiftTypeIcon = "fa fa-sun-o"
                                                        }
                                                        else if (stStart >= 0 && stStart <= 7 && stAmPm == "pm"){
                                                            shiftType = "evening"
                                                            shiftTypeCss="miclscl"
                                                            shiftTypeIcon = "fa fa-cloud" 
                                                        }
                                                        else if (stStart >= 8 && stAmPm == "pm"){
                                                            shiftType = "night"
                                                            shiftTypeCss="miclsmn"
                                                            shiftTypeIcon = "fa fa-moon-o"
                                                        }
                                                        else if (stStart >= 0 && stAmPm == "am"){
                                                            shiftType = "night"
                                                            shiftTypeCss="miclsmn"
                                                            shiftTypeIcon = "fa fa-moon-o"
                                                        }
                                                        date = stStart + ":" + stStartMin + " " + stAmPm + "-" + enEnd + ":" + enEndMin + " " + enAmPm;
                                                    }
                                                  return(
                                                    <>
                                                    {
                                                        Object.keys(e).length != 0
                                                        ?    
                                                        <td className="report-tbhdst1 report-tbhdfst report-tbhdhr1" style={{borderCollapse:"separate"}}>
                                                        <div className={`user-add-shedule-list ${shiftTypeCss}`} >
                                                            <h2>
                                                                <NavLink to={e.location ? `/location-view/${e.location.id}` : "#"}>
                                                                    <div >
                                                                        {/* style={{width: "100%", height: "3rem"}} yeh add karo toh class achi hogi */}
                                                                    <span className="username-info " >{date} {e.duration}</span>
                                                                    {
                                                                        e.location ?
                                                                        <span className="userrole-info">{e.userId.jobTitle} - {e.location.name} <i className={shiftTypeIcon}></i></span>
                                                                        :
                                                                        <span className="userrole-info">{e.userId.jobTitle} - <i className={shiftTypeIcon}></i></span>
                                                                    }
                                                                    {/* <span className="userrole-info">{e.userId.jobTitle} - {e.location.name} <i className={shiftTypeIcon}></i></span> */}
                                                                    </div>
                                                                </NavLink>
                                                            </h2>
                                                        </div>
                                                        </td>
                                                        :
                                                        <td className="report-tbhdst1 report-tbhdfst report-tbhdhr1">
                                                        <div className="user-add-shedule-list miclscl">
                                                            <h2>
                                                                <NavLink to="#">
                                                                {/* <span className="username-info ">{moment(e.startTime).format('h:mm a') +"-"+ moment(e.endTime).format('h:mm a')}( 14 hrs 15 mins)</span>
                                                                    <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-cloud"></i></span> */}
                                                                </NavLink>
                                                            </h2>
                                                        </div>
                                                        </td>
                                                    }
                                                    </>
                                                  )  
                                                })
                                                :
                                                [0,0,0,0,0,0,0].map(g=>{
                                                    return(
                                                        <td className="report-tbhdst1 report-tbhdfst report-tbhdhr1">
                                                        <div className="user-add-shedule-list miclscl">
                                                            <h2>
                                                                <NavLink to="#">
                                                                {/* <span className="username-info ">{moment(e.startTime).format('h:mm a') +"-"+ moment(e.endTime).format('h:mm a')}( 14 hrs 15 mins)</span>
                                                                    <span className="userrole-info">Un-Armed Officer - 66West <i className="fa fa-cloud"></i></span> */}
                                                                </NavLink>
                                                            </h2>
                                                        </div>
                                                        </td>
                                                )})}
                                        </tr>
                                    )})}
                            </tbody>
                        </table>
                        <div className="row">
                            {
                                admin.shiftReportWeekly &&
                                admin.shiftReportWeekly.length > 0 &&
                                <div className="col-sm-12 col-md-5">
                                    <label> Showing {1 + (limit * (currentPageNumber-1))} to {admin.shiftReportWeekly[admin.shiftReportWeekly.length-4] + (limit * (currentPageNumber-1))} of {admin.shiftReportWeekly[admin.shiftReportWeekly.length-2]} entries </label>
                                </div>
                            }
                            <div className="col-sm-12 col-md-7">
                                { 
                                    admin.shiftReportWeekly &&
                                    <div className="avatar-pagination">
                                        <ul className="pagination" style={{ justifyContent: 'flex-end' }}>
                                            <li className="page-item">
                                                <NavLink className="page-link" to="#" aria-label="Previous" onClick={onClickPrev}>
                                                    <span aria-hidden="true">Previous</span>
                                                    <span className="sr-only">Previous</span>
                                                </NavLink>
                                            </li>
                                            {
                                                admin.shiftReportWeekly &&
                                                admin.shiftReportWeekly.length > 0 &&
                                                admin.shiftReportWeekly[admin.shiftReportWeekly.length-1].map((current, i) => {
                                                return(
                                                    currentPageNumber === current ?
                                                    <li className="page-item active" key={i}><a className="page-link" value={current}>{current}</a></li>
                                                    :
                                                    <li className="page-item" key={i}>
                                                    <a className="page-link" 
                                                    value={current} onClick={()=>handleChangeData(current)}
                                                    >{current}</a></li>
                                                )
                                            })}
                                            <li className="page-item">
                                                <NavLink className="page-link" to="#" aria-label="Next" onClick={onClickNext}>
                                                    <span aria-hidden="true">Next</span>
                                                    <span className="sr-only">Next</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className="dataTables_length" id="DataTables_Table_0_length">
                                    <label>Show &nbsp;
                                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                                            className="select"
                                            autoComplete='false'
                                            onChange={handleLimit}>
                                            {
                                            [10,25,50,100].map((current, i) => {
                                            return(
                                                <option value={current}>{current}</option>
                                                )
                                            })}
                                        </select> entries
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ShiftReportWeekly