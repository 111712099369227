import React, { useState } from "react";
import moment from "moment/moment";
import { NavLink } from "react-router-dom";
import ModalEditAdminLeavesTableSearchRow from "./ModalEditAdminLeavesTableSearchRow";
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL } from "../../../constants";

const AdminLeavesTableSearchRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
}) => {
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const [showEditModal, setshowEditModal] = useState(false);
  // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
  // const [showApproveDropDown, setshowApproveDropDown] = useState(false)
  const [showDropDown, setshowDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const openEditModal = () => {
    setshowEditModal((showEditModal) => !showEditModal);
    setshowMoreVertDropDown(false);
  };
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  // const openApproveDropDown = () => {
  //     setshowApproveDropDown((showApproveDropDown) => !showApproveDropDown)
  // }
  // const openDeleteDropDown = () => {
  //     setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
  // }
  return (
    <>
      {
        current && (
          // current.length > 0 &&
          // current.map((current, ind) => {
          //     return(
          <>
            <tr key={i} onMouseLeave={() => setshowMoreVertDropDown(false)}>
              <td>
                <h2 className="table-avatar">
                  <NavLink
                    to={`/profile/${current.userId.id}`}
                    className="avatar"
                  >
                    {current.userId.profilePic &&
                    current.userId.profilePic.fileUrl ? (
                      <img
                        alt="profilePic"
                        src={
                          BUCKET_BASE_URL + current.userId.profilePic.fileUrl
                        }
                        style={{ objectFit: "fill", height: "100%" }}
                      />
                    ) : (
                      <img alt="profilePic" src={defaultImg} />
                    )}
                  </NavLink>
                  <NavLink to={`/profile/${current.userId.id}`}>
                    {" "}
                    {current.userId.firstName +
                      " " +
                      current.userId.lastName}{" "}
                    <span>{current.userId.jobTitle}</span>
                  </NavLink>
                </h2>
              </td>
              <td>{current.leaveType.name}</td>
              <td>{moment(current.from).format("DD MMMM YYYY")}</td>
              <td>{moment(current.to).format("DD MMMM YYYY")}</td>
              <td>{current.numberOfDays} days</td>
              <td>
                {current.leaveReason.length <= 15
                  ? current.leaveReason
                  : current.leaveReason.substring(0, 15) + "..."}
              </td>
              <td className="text-center">
                {current.status === "Pending" ? (
                  <div className="action-label">
                    <NavLink
                      to="#"
                      className="btn btn-white btn-sm btn-rounded"
                    >
                      <i className="fa fa-dot-circle-o text-purple"></i> Pending
                    </NavLink>
                  </div>
                ) : current.status === "Accepted" ? (
                  <div className="action-label">
                    <NavLink
                      to="#"
                      className="btn btn-white btn-sm btn-rounded"
                    >
                      <i className="fa fa-dot-circle-o text-success"></i>{" "}
                      Approved
                    </NavLink>
                  </div>
                ) : (
                  <div className="action-label">
                    <NavLink
                      to="#"
                      className="btn btn-white btn-sm btn-rounded"
                    >
                      <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                      Declined
                    </NavLink>
                  </div>
                )}
              </td>
              <td className="text-right">
                <div className="dropdown dropdown-action">
                  <a
                    className="action-icon dropdown-toggle"
                    data-toggle="dropdown"
                    onClick={() => {
                      openMoreVertDropDown();
                      setDropDownRow(i);
                    }}
                    aria-expanded="false"
                    style={{ cursor: "pointer" }}
                  >
                    <i className="material-icons">more_vert</i>
                  </a>
                  {dropDownRow === i && (
                    <>
                      <div
                        className={`dropdown-menu dropdown-menu-right ${
                          showMoreVertDropDown ? "show" : "hide"
                        }`}
                      >
                        <a
                          href="#"
                          className="dropdown-item"
                          data-toggle="modal"
                          onClick={openEditModal}
                          data-target="#edit_leave"
                        >
                          <i className="fa fa-pencil m-r-5"></i> Edit
                        </a>
                        {/* <a href="#" className="dropdown-item" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i> Delete</a> */}
                      </div>
                    </>
                  )}
                </div>
              </td>
            </tr>
            <ModalEditAdminLeavesTableSearchRow
              current={current}
              showEditModal={showEditModal}
              openEditModal={openEditModal}
            />
            {/* <DeleteLeaveByAdmin current={current} showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown}/> */}
          </>
        )
        // )
        // })
      }
    </>
  );
};
export default AdminLeavesTableSearchRow;
