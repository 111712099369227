import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateUserById } from '../../../actions/authActions';
import { toast } from 'react-hot-toast';

const SuperAdminSettingView = () => {
    const auth = useSelector((state) => state.auth); 
    const dispatch = useDispatch()
    const handlePhone = (fieldName) => (event) =>{  
        // console.log(event.target.value.length, event.target.value, event.target.value !== "(", values.phone.substring(4,values.phone.length), values.phone.length, values.phone.substring(0,3))
        if(event.target.value.length === 1){
            if(event.target.value !== "("){
                setValues({
                    ...values, [fieldName]:
                    "(" +event.target.value
                })
            }
        }
        else if (event.target.value.length === 4){
            if(event.target.value !== ")"){
                setValues({
                    ...values, [fieldName]:
                    event.target.value+")-" 
                })
            }
        }
        else if (event.target.value.length === 9){
            if(event.target.value !== "-"){
                setValues({
                    ...values, [fieldName]:
                    event.target.value+"-" 
                })
            }
        }
        else {
            setValues({
               ...values, [fieldName]:
               event.target.value
            })
        }
    }
    const alphabets = [
        'a','b','c','d','f','g','h','i','j','k','l', 'm','n','o','p','q','r','s','t',
        'u','v','w','x','y','z','A','B','C','D', 'F','G','H','I','J',
        'K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','+','-',
        '/','*','!','@','#','$','%','^','&','_','(',')',
        ];
    const [values, setValues] = useState({
        address: "",
        state: "",
        // country: "",
        pinCode: "",
        phone: "",
        // website: "",
        timeZone : "",
        password : "",
        passwordConfirm : "",
    })
    useEffect(() => {
        if (auth.user !== null){
            // const no = auth.user.phone
            // let noArr = no.split(" ")
            // console.log(noArr, "let noArr = no.spli")
            setValues({
                address: auth.user.address,
                state: auth.user.state,
                // country: auth.user.country,
                pinCode: auth.user.pinCode,
                phone: auth.user.phone,
                timeZone: auth.user.timeZone ? auth.user.timeZone : ''
            })
        }
    }, [auth.user])
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
                event.target.value
        })
        // passwordChk()
    }
    const userData = {
        userId: auth.user.id,
        address: values.address,
        state: values.state,
        // country: values.country,
        pinCode: values.pinCode,
        phone:  values.phone,
        password: values.password,
        timeZone: values.timeZone,
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (values.password === values.passwordConfirm){
            for (let key of Object.keys(userData)) {
                if(userData[key] === '' || userData[key] === '+'){
                    delete userData[key]
                }
            }
            dispatch(updateUserById(userData))
        }
        else {
            // alert("Password, don't match");
            toast.error("Password, don't match");
        }
    }
    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3 className="page-title">Settings</h3>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                {
                                    auth.user &&
                                        <>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Company Name <span className="text-danger"></span></label>
                                                        <input className="form-control" type="text" value="Riyo Work" disabled/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Contact Person</label>
                                                        <input className="form-control " value={auth.user.firstName +" "+ auth.user.lastName} type="text" disabled/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Address</label>
                                                        <input className="form-control " value={values.address} type="text" onChange={handleChange('address')} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label>State/Province</label>
                                                        <input type="text" className="form-control" value={values.state} onChange={handleChange('state')}/>
                                                    
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <label>Postal Code</label>
                                                        <input 
                                                        type="number"
                                                        onKeyDown={(e) => {
                                                            if (alphabets.includes(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        className="form-control" value={values.pinCode} onChange={handleChange('pinCode')}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input className="form-control" value={auth.user.email} type="email" disabled/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Phone Number</label>
                                                        {/* <input 
                                                        type="number"
                                                        onKeyDown={(e) => {
                                                            if (alphabets.includes(e.key)) {
                                                                e.preventDefault();
                                                            } 
                                                        }}
                                                        className="form-control" value={values.phone} onChange={handleChange('phone')}/>
                                                    </div> */}
                                                    <input className="form-control"
                                                        minLength="14"
                                                        maxLength="14"
                                                        required
                                                        // pattern="([0-9]{3})-[0-9]{3}-[0-9]{4}"
                                                        onKeyDown={(e) => {
                                                            if (alphabets.includes(e.key)) {
                                                                e.preventDefault();
                                                            } 
                                                            if (e.code === "Backspace" && values.phone.length === 6){
                                                                setValues({
                                                                ...values, phone:
                                                                values.phone.substring(0,4)
                                                                })
                                                            }
                                                            if (e.code === "Backspace" && values.phone.length === 2){
                                                                setValues({
                                                                ...values, phone:
                                                                ''
                                                                })
                                                            }
                                                            if (e.code === "Backspace" && values.phone.length === 10){
                                                                setValues({
                                                                ...values, phone:
                                                                values.phone.substring(0,9)
                                                                })
                                                            }
                                                        }} name="phone" 
                                                        value={values.phone}
                                                        onChange={handlePhone('phone')}
                                                        placeholder="(123)-456-7890" autocomplete="new-mobileNunmber"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Change Password</label>
                                                        <input className="form-control" type="password" value={values.password || ''} onChange={handleChange('password')}/>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Confirm Password</label>
                                                        <input className="form-control" type="password" value={values.passwordConfirm || ''} onChange={handleChange('passwordConfirm')}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="submit-section">
                                                <button className="btn btn-primary submit-btn" type='submit'>Save</button>
                                            </div>
                                        </>
                                    }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SuperAdminSettingView