import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tabReportShiftDaily, tabReportShiftWeek } from '../../../actions/tabActions'
import ShiftReportDaily from './ShiftReportDaily'
import ShiftReportWeekly from './ShiftReportWeekly'
import ShiftReportWeeklySearchTable from './ShiftReportWeeklySearchTable'

import { NavLink } from 'react-router-dom'
// import { date } from 'yup/lib/locale'

const AdminReportShiftReports = () => {
    const myState = useSelector((state => state.changeTheTab))
    // const [ viewReportShift, setViewReportShift ] = useState("weekly")
    const dispatch = useDispatch()
    const handleDailyyy = (e) => {
        dispatch(tabReportShiftDaily())
    }
    const handleWeekkk = (e) => {
        dispatch(tabReportShiftWeek())
    }
    const [ today, setToday ] = useState(moment())
    let date = moment(today.format('YYYY-MM-DD'))
    const [currentDaily, setCurrentDaily] = useState(0)
    const [currentWeekly, setCurrentWeekly] = useState(0)
    const onClickNextDaily = async () => {
        setCurrentDaily(currentDaily + 1)
        setToday(moment().add(currentDaily + 1, 'days'))
    }
    const onClickPrevDaily = () => {
        setCurrentDaily(currentDaily - 1)
        setToday(moment().add(currentDaily - 1, 'days'))
    }
    const onClickNextWeekly = async () => {
        setCurrentWeekly(currentWeekly + 7)
        setToday(moment().add(currentWeekly + 7, 'days'))
    }
    const onClickPrevWeekly = () => {
        setCurrentWeekly(currentWeekly - 7)
        setToday(moment().add(currentWeekly - 7, 'days'))
    }
    return (
        <>
            {
                myState.viewReportShift === "weekly" ?
            <>
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                            {/* <NavLink to="/scheduling-dreport" className="btn">Daily</NavLink>
                            <NavLink to="/scheduling-report" className="btn btn-primary">Weekly</NavLink> */}
                            <button
                                onClick={handleDailyyy}
                                // to="/daily-scheduling" 
                                className="btn">Daily</button>
                            <button
                                onClick={handleWeekkk}
                                // to="/daily-scheduling" 
                                className="btn"
                                style={{backgroundColor:"#9ac748", color:"white"}}>Weekly</button>
                        </div>
                    </div>
                <div className="col-md-4">
                    {/* <h3 className="card-title mt-2 text-center">14 May to 20 May, 2022</h3> */}
                    <h3 className="card-title mt-2 text-center">
                    {
                    date.format('dddd') !== 'Sunday' ? 
                    date.startOf('week').add(1, 'days').format('DD MMMM, YYYY') + " to "+ date.startOf('week').add(7, 'days').format('DD MMMM, YYYY')
                    :
                    date.startOf('week').add(-6, 'days').format('DD MMMM, YYYY') + " to "+ date.startOf('week').add(7, 'days').format('DD MMMM, YYYY')
                    }</h3>
                </div>
                <div className="col-md-4">
                    <div className="float-right">
                        <a href="#" onClick={onClickPrevWeekly} className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                        <a href="#" onClick={onClickNextWeekly} className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                    </div>
                </div>
            </div>
            <ShiftReportWeekly today={today}/>
            </>
            : 
                myState.viewReportShift === "daily" ?
            <>
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        {/* <NavLink to="/scheduling-dreport" className="btn">Daily</NavLink>
                            <NavLink to="/scheduling-report" className="btn btn-primary">Weekly</NavLink> */}
                            <button
                                onClick={handleDailyyy}
                                // to="/daily-scheduling" 
                                className="btn"
                                style={{backgroundColor:"#9ac748", color:"white"}}>Daily</button>
                            <button
                                onClick={handleWeekkk}
                                // to="/daily-scheduling" 
                                className="btn">Weekly</button>
                        </div>
                    </div>
                <div className="col-md-4">
                    <h3 className="card-title mt-2 text-center">{date.format('DD MMMM YYYY')}</h3>
                </div>
                <div className="col-md-4">
                    <div className="float-right">
                        <a href="#" onClick={onClickPrevDaily} className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                        <a href="#" onClick={onClickNextDaily} className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                    </div>
                </div>
            </div>
            <ShiftReportDaily today={today}/>
            </>
            : 
                myState.viewReportShift === "search" ?
            <>
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        {/* <NavLink to="/scheduling-dreport" className="btn">Daily</NavLink>
                            <NavLink to="/scheduling-report" className="btn btn-primary">Weekly</NavLink> */}
                            <button
                                onClick={handleDailyyy}
                                // to="/daily-scheduling" 
                                className="btn">Daily</button>
                            <button
                                onClick={handleWeekkk}
                                // to="/daily-scheduling" 
                                className="btn">Weekly</button>
                        </div>
                    </div>
                {/* <div className="col-md-4">
                    <h3 className="card-title mt-2 text-center">14 May 2022</h3>
                </div> */}
                {/* <div className="col-md-4">
                    <div className="float-right">
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink>
                    </div>
                </div> */}
            </div>
            <ShiftReportWeeklySearchTable />
            </>
            :
            <div>Loading</div>
            }
        </>
    )
}
export default AdminReportShiftReports