import React, { useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import { updateDeductionType } from '../../../actions/payrollAction'
const EditModalDeductionPayroll = ({ showDropDown, openDropDown, current}) => {
  const [ values, setValues ] = useState({
    id: '',
    name: '',
    amount: '',
  })
  const handleChange = (fieldName) => (event) =>{
  setValues({
    ...values, [fieldName]:
    event.target.value
  })
  }
  const currentDeduction = async () => {
    try {
      setValues({
        id: '',
        name: '',
        amount: '',
      })
      setValues({
        id: current.id,
        name: current.name,
        amount: current.amount,
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    currentDeduction()
  }, [])
  const dispatch = useDispatch()
  const updateDeductionData = {
    _id: values.id,
    name: values.name,
    amount: values.amount,
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    dispatch(updateDeductionType(updateDeductionData))
    // openDropDown()
    setValues({
      id: '',
      name: '',
      amount: '',
    })
  }
  return (
    <>
      <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
      <div className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} id="edit_deduction" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Deduction</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
              <div className="row"></div>
                <div className="form-group">
                  <label>Name <span className="text-danger">*</span></label>
                  <input className="form-control" type="text"
                  value={values.name}
                  onChange={handleChange('name')}/>
                </div>
                <div className="form-group">
                  <label>Unit Amount</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text">$</span>
                    </div>
                    <input type="text" className="form-control"
                    value={values.amount}
                    onChange={handleChange('amount')}/>
                    <div className="input-group-append">
                      <span className="input-group-text">.00</span>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default EditModalDeductionPayroll