import React, { forwardRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { leaveSearchFilter } from "../../../actions/leaveActions"
import { tabLeaveSearch } from '../../../actions/tabActions'
import DatePicker from "react-datepicker";
// import { NavLink } from 'react-router-dom'
const AdminLeavesFilterandSearch = () => {
    const auth = useSelector((state) => state.auth);
    const leave = useSelector((state) => state.leave);
    const dispatch = useDispatch()
    const [values, setValues] = useState({
        companyId: '',
        name: '',
        date: '',
        leaveType:'',
        from:'',
        to:'',
        status: '',
    })
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
                event.target.value
        })
    }
    const [fromDate, setFromDate] = useState(new Date(moment().format('YYYY-MM-DD')));
    const [toDate, setToDate] = useState(new Date(moment().format('YYYY-MM-DD')));
    const From = forwardRef(({ value, onClick }, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon" >
                <input className="form-control floating datetimepicker" type="text" value={value} />
            </div>
            <label className="focus-label">From</label>
        </div>
    ));
    const To = forwardRef(({ value, onClick }, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon" >
                <input className="form-control floating datetimepicker" type="text" value={value} />
            </div>
            <label className="focus-label">To</label>
        </div>
    ));
    let leaveSearch;
    // if (values.location !== ''){
    leaveSearch = {
        companyId: auth.user.companyId,
        name: values.name,
        date: moment().format('YYYY-MM-DD'),
        leaveType: values.leaveType,
        from: fromDate.toISOString().substring(0, 10),
        to: toDate.toISOString().substring(0, 10),
        status: values.status,
    }
    // console.log(toDate.toISOString(), "toDate.toISOString()")
    const [ leaveTypeObj, setLeaveTypeObj ] = useState([])
    const handleLeaveType = (fieldName) => (event) =>{
    // console.log(event.target.value)
    const abc = JSON.parse(event.target.value)
        if (abc !== null){
            if (values.leaveType.includes(abc.id) === false){
                // console.log(abc)
                setLeaveTypeObj([...leaveTypeObj,abc])
                setValues({
                    ...values, [fieldName]:
                    [...values.leaveType, abc.id]
                })
            }
        }
    }
    const handleClick = async (event) => {
        event.preventDefault()
        for (let key of Object.keys(leaveSearch)) {
            if(leaveSearch[key] === '' ){
                delete leaveSearch[key]
            }
            // console.log(key,"54121421521", leaveSearch[key])
        }
        // dispatch(tabSearch())
        dispatch(tabLeaveSearch())
        dispatch(leaveSearchFilter(leaveSearch))
    }
    return (
        <>
            <div className="row filter-row">
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                    <div className="form-group form-focus select-focus">
                        <input type="text" className="form-control floating"
                        value={values.name} onChange={handleChange('name')} />
                        <label className="focus-label">Employee Name</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                    <div className="form-group form-focus select-focus">
                        <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                        // value={values.leaveType} 
                        onChange={handleLeaveType('leaveType')}>
                            <option value=""> - </option>
                            {
                                leave.leaveSetting && 
                                leave.leaveSetting.length > 0 &&
                                leave.leaveSetting.map((current, i) => {
                                    return (
                                        current.delete === false &&
                                        <option value={JSON.stringify({id:current.id, name:current.name})} key={i+1}>{current.name}</option>
                                    )
                                })
                            }
                        </select>
                        <label className="focus-label">Leave Type</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                    <div className="form-group form-focus select-focus">
                        <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                        value={values.status} onChange={handleChange('status')}>
                            <option value=''> - </option>
                            <option value='Pending'> Pending </option>
                            <option value='Accepted'> Approved </option>
                            <option value='Rejected'> Rejected </option>
                        </select>
                        <label className="focus-label">Leave Status</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                    <div className="form-group form-focus select-focus">
                        <DatePicker
                        selected={fromDate}
                        onChange={(date) => { setFromDate(date)}}
                        customInput={<From />}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                    <div className="form-group form-focus select-focus">
                        <DatePicker
                        selected={toDate}
                        minDate={fromDate}
                        onChange={(date) => setToDate(date)}
                        customInput={<To />}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
                    <a href="#" className="btn btn-success btn-block" onClick={handleClick} > Search </a>
                </div>
            </div>
        </>
    )
}
export default AdminLeavesFilterandSearch