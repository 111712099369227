import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from 'react';
import { createLeave } from '../../../actions/leaveActions';
import moment from 'moment';
import { toast } from 'react-hot-toast';

const ModalAddLeave = ({ showDropDown, openDropDown }) => {
    const auth = useSelector((state) => state.auth);
    const leave = useSelector((state) => state.leave);
    const dispatch = useDispatch()
    const [values, setValues ] = useState({
        index: '',
        leaveTypeIndex: '',
    })
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
                event.target.value
        })
    }
    const onCLick_ = () => {
        setValues({
            ...values,
            leaveTypeIndex: ''
        })
    }
    //   console.log(values)
    let val = 0
    const [ data, setData ] = useState
    ({
        userId: '',
        leaveType: '',
        from: '',
        to: '',
        status: 'Accepted',
        leaveReason: '',
        companyId: '',
        //    dated: '',
    })
    const handleChangeData = (fieldName) => (event) => {
        setData({
            ...data, [fieldName]:
                event.target.value
        })
    }
    // console.log(values)
    // const [mxDt, setMxDt] = useState(new Date());
    const [fromDate, setFromDate] = useState(new Date(moment().format('YYYY-MM-DD')));
    const [toDate, setToDate] = useState(new Date(moment().format('YYYY-MM-DD')));
    const mxDt = new Date(fromDate);
    const From = forwardRef(({ value, onClick }, ref) => (
        <div className="cal-icon" onClick={onClick} ref={ref}>
            <input className="form-control floating datetimepicker" type="text" value={value} />
        </div>
    ));
    const To = forwardRef(({ value, onClick }, ref) => (
        <div className="cal-icon" onClick={onClick} ref={ref}>
            <input className="form-control floating datetimepicker" type="text" value={value} />
        </div>
    ));
    // console.log(values)

    const leaveData = {
        userId: values.index !== '' && leave.leaveReportUsers[parseInt(values.index)].e.id,
        leaveType: values.index !== '' && values.leaveTypeIndex !== '' && leave.leaveReportUsers[parseInt(values.index)].leaveArr[parseInt(values.leaveTypeIndex)].leaveCategoryId,
        from:  fromDate.toISOString(),
        to:  toDate.toISOString(),
        leaveReason: data.leaveReason,
        companyId: auth.user.companyId,
        status: data.status,
        dated: moment().format('YYYY-MM-DD'),
    }
    //   const leaveListData = async (e) => {
    //   dispatch(getLeaveByUserId({userId: auth.user.id}))
    //   }
    const [dateRangeError, setDateRangeError] = useState(false)
    const handleSubmit = async (event) => {
        event.preventDefault()
        if (leave.leaveReport[parseInt(values.index)].leaveArr[parseInt(values.leaveTypeIndex)].remainingLeavesDays >=
        ((((new Date(toDate.toISOString().substring(0,10)) - new Date(fromDate.toISOString().substring(0,10)) ) / (1000 * 60 * 60))/24)+1))
        {
            dispatch(createLeave(leaveData))
            openDropDown()                        
        }
        else {
            setDateRangeError(true)
            toast.error("Please select Date with range of Remaining Days")
            // alert("Please select Date with range of Remaining Days")
        }
        //   leaveListData()
    }
    return (
        <>
            <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
            <div id="add_leave" className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Leave</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Employee <span className="text-danger">*</span></label>
                                    <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                    autoComplete='false' value={values.index} onClick={onCLick_}
                                    onChange={handleChange('index')}>
                                        <option value=''>Select Employee</option>
                                        {
                                            leave.leaveReportUsers &&
                                            leave.leaveReportUsers.length > 0 &&
                                            leave.leaveReportUsers.map((current, i) => {
                                                return(
                                                    // i < (leave.leaveReportUsers.length - 5) &&
                                                    <option value={val++} key={i}>{current.e.firstName + " " + current.e.lastName}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Leave Type <span className="text-danger">*</span></label>
                                    {
                                        values.index === '' ?
                                        <select className="form-select" style={{ height: "44px", fontSize: "14px" }} disabled>
                                        <option>Select Employee</option>
                                        </select>
                                        :
                                        <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                        autoComplete='false' value={values.leaveTypeIndex}
                                        onChange={handleChange('leaveTypeIndex')}>
                                        <option value=''>Select Leave Type</option>
                                        {
                                            // leave.leaveReport &&
                                            // leave.leaveReport.length > 0 &&
                                            // leave.leaveReport[parseInt(values.index)].leaveArr &&
                                            // leave.leaveReport[parseInt(values.index)].leaveArr.length > 0 &&
                                            // leave.leaveReport[parseInt(values.index)].leaveArr.map((current, i) =>{
                                            leave.leaveReportUsers &&
                                            leave.leaveReportUsers.length > 0 &&
                                            leave.leaveReportUsers[parseInt(values.index)].leaveArr &&
                                            leave.leaveReportUsers[parseInt(values.index)].leaveArr.length > 0 &&
                                            leave.leaveReportUsers[parseInt(values.index)].leaveArr.map((current, i) =>{
                                                return(
                                                    i < (leave.leaveReport.length - 5) &&
                                                    <option value={i}>{current.name}</option>
                                                )
                                            })
                                        }
                                        </select>
                                    }
                                </div>
                                <div className="form-group">
                                    <label>From <span className="text-danger">*</span></label>
                                    {/* <div className="cal-icon"> */}
                                    {/* <input className="form-control datetimepicker" type="text" /> */}
                                        {/* <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(date)}/>
                                    </div> */}
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => setFromDate(date)}
                                        customInput={<From />}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>To <span className="text-danger">*</span></label>
                                    {/* <div className="cal-icon">
                                        <input className="form-control datetimepicker" type="text" />
                                    </div> */}
                                    {
                                        values.index === '' || 
                                        values.leaveTypeIndex === '' ?
                                        <DatePicker
                                            selected={toDate}
                                            minDate={fromDate}
                                            onChange={(date) => setToDate(date)}
                                            customInput={<To />}
                                        />
                                        :
                                        <DatePicker
                                            selected={toDate}
                                            minDate={fromDate}
                                            maxDate={mxDt.setDate(fromDate.getDate()+leave.leaveReportUsers[parseInt(values.index)].leaveArr[parseInt(values.leaveTypeIndex)].remainingLeavesDays-1)}
                                            onChange={(date) => setToDate(date)}
                                            customInput={<To />}
                                        />
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Number of days <span className="text-danger">*</span>
                                    {/* {
                                        dateRangeError === true 
                                        ?
                                        <span className="text-danger"> Please Select Date within Remaining Days</span>
                                        :
                                        <></>
                                    } */}
                                    </label>
                                    <input className="form-control" readonly type="text" value={(((new Date(toDate.toISOString().substring(0,10)) - new Date(fromDate.toISOString().substring(0,10)) ) / (1000 * 60 * 60))/24)+1}/>
                                </div>
                                <div className="form-group">
                                    <label>Remaining Leaves <span className="text-danger">*</span></label>
                                    {
                                        values.index === '' || 
                                        values.leaveTypeIndex === '' ?
                                        <input className="form-control" readonly value="Please Select Leave Type" type="text" />
                                        :
                                        <input className="form-control" readonly value={leave.leaveReportUsers[parseInt(values.index)].leaveArr[parseInt(values.leaveTypeIndex)].remainingLeavesDays} type="text" />
                                    }
                                </div>
                                <div className="form-group">
                                    <label>Leave Reason <span className="text-danger">*</span></label>
                                    <textarea rows="4" className="form-control"
                                    value={data.leaveReason}
                                    onChange={handleChangeData('leaveReason')}
                                    autoComplete="false"></textarea>
                                </div>
                                <div className="form-group">
                                    <label>Leave Status <span className="text-danger">*</span></label>
                                        <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                        value={data.status}
                                        onChange={handleChangeData('status')}
                                        autoComplete="false">
                                            <option value="Accepted">Accepted</option>
                                            <option value="Rejected">Rejected</option>
                                            <option value="Pending">Pending</option>
                                        </select>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModalAddLeave