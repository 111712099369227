import React from 'react'
import { NavLink } from 'react-router-dom'
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"
import { BUCKET_BASE_URL } from '../../../constants'

const ShiftSearchTableRow = ({current, i}) => {
    // const shift = useSelector((state) => state.shift);
    return (
        <>
            {
                current.length > 0 &&
                current.map((e,j) => {
                    // console.log(e.userId,"search mein current ") 
                    let shiftType;
                    let shiftTypeCss = "miclsun";
                    let shiftTypeIcon = "fa fa-sun-o";
                    let empty = true
                    let temp;
                    // let start, end;
                    let stAmPm = "am";
                    let enAmPm = "am";
                    let date;
                    // console.log(Object.keys(e).length)
                    if (Object.keys(e).length != 0){
                        let stStart = parseInt(e.startTime.substring(11, 13))
                        let stStartMin = parseInt(e.startTime.substring(14, 16))
                        let enEnd = parseInt(e.endTime.substring(11, 13))
                        let enEndMin = parseInt(e.endTime.substring(14, 16))
                        if (stStartMin < 10) {
                            stStartMin = "0"+stStartMin
                        }
                        if (enEndMin < 10){
                            enEndMin = "0"+enEndMin
                        }
                        if (stStart === 0){
                            stStart = 12
                        }
                        if (enEnd === 0){
                            enEnd = 12
                        }
                        if (stStart > 12){
                            stStart = stStart - 12
                            stAmPm = "pm"
                        }
                        if (enEnd > 12){
                            enEnd = enEnd - 12
                            enAmPm = "pm"
                        }
                        // console.log(stStart, stAmPm)
                        if((stStart === 12 || stStart <= 5) && stAmPm == "am")
                        {
                            shiftType = "night"
                            shiftTypeCss="miclsmn"
                            shiftTypeIcon = "fa fa-moon-o"
                        }
                        else if (stStart >= 6 && stAmPm == "am"){
                            shiftType = "morning"
                            shiftTypeCss="miclsun" 
                            shiftTypeIcon = "fa fa-sun-o"
                        }
                        else if (stStart >= 0 && stStart <= 7 && stAmPm == "pm"){
                            shiftType = "evening"
                            shiftTypeCss="miclscl"
                            shiftTypeIcon = "fa fa-cloud" 
                        }
                        else if (stStart >= 8 && stAmPm == "pm"){
                            shiftType = "night"
                            shiftTypeCss="miclsmn"
                            shiftTypeIcon = "fa fa-moon-o"
                        }
                        else if (stStart >= 0 && stAmPm == "am"){
                            shiftType = "night"
                            shiftTypeCss="miclsmn"
                            shiftTypeIcon = "fa fa-moon-o"
                        }
                        date = stStart + ":" + stStartMin + " " + stAmPm + "-" + enEnd + ":" + enEndMin + " " + enAmPm;
                    }
                    return(
                        <tr key={i}>
                            <td>
                                <h2 className="table-avatar">
                                    <NavLink to={`/profile/${e.userId.id}`} className="avatar">
                                        {
                                            e.userId &&
                                            e.userId.profilePic &&
                                            e.userId.profilePic.fileUrl ?
                                                <img alt="profilePic" src={BUCKET_BASE_URL + e.userId.profilePic.fileUrl} style={{objectFit:"fill", height:"100%"}}/>
                                                :
                                                <img alt="profilePic" src={defaultImg} />
                                        }
                                    </NavLink>
                                    <NavLink to={`/profile/${e.userId.id}`}>{e.userId.firstName +" "+ e.userId.lastName}<span>{e.userId.jobTitle}</span></NavLink>
                                </h2>
                            </td>
                            <td>
                                {e.startTime.substring(0,10)+" to "+e.endTime.substring(0,10)}
                            </td>
                            <td colSpan="6" style={{borderCollapse:"separate"}}>
                            <div className={`user-add-shedule-list ${shiftTypeCss}`}>
                                <h2>
                                    <NavLink to="#" data-toggle="modal" data-target="#edit_schedule">
                                    {
                                        e.location ? 
                                        <span className="username-info ">{date} {e.duration} {e.userId.jobTitle} - {e.location.name} <i className={shiftTypeIcon}></i></span>
                                        :
                                        <span className="username-info ">{date} {e.duration} {e.userId.jobTitle}   <i className={shiftTypeIcon}></i></span>
                                    }
                                    </NavLink>
                                </h2>
                            </div>
                            </td>
                        </tr>
                    ) 
                })
            }
        {/* <EditModalSSEditScheduling current={current} valueIndex={iii} typeStatus={'daily'} showMoreVertDropDown={showMoreVertDropDown} openMoreVertDropDown={openMoreVertDropDown} />
            <AdminSSAddShiftModal current={current} showDropDown={showDropDown} openDropDown={openDropDown} /> */}
        </>
    )
}
export default ShiftSearchTableRow