import React, { useState } from 'react'
import TimeClockReportWeekly from './TimeClockReportWeekly'
import TimeClockReportDaily from './TimeClockReportDaily'
import TimeClockReportWeeklySearchTable from './TimeClockReportWeeklySearchTable'
import moment from 'moment'
import { tabReportTimeClkDaily, tabReportTimeClkWeek } from '../../../actions/tabActions'
import { useDispatch, useSelector } from 'react-redux'

const AdminReportTimeClkReport = () => {
    // const [ viewReportTimeClk, setViewReportTimeClk ] = useState("weekly")
    const myState = useSelector((state => state.changeTheTab))
    const dispatch = useDispatch()
    const handleDaily = (e) =>{
        dispatch(tabReportTimeClkDaily())
    }
    const handleWeek = (e) =>{
        dispatch(tabReportTimeClkWeek())
    }
    const [ today, setToday ] = useState(moment())
    let date = moment(today.format('YYYY-MM-DD'))
    const [currentDaily, setCurrentDaily] = useState(0)
    const [currentWeekly, setCurrentWeekly] = useState(0)
    const onClickNextDaily = async () => {
        setCurrentDaily(currentDaily + 1)
        setToday(moment().add(currentDaily + 1, 'days'))
    }
    const onClickPrevDaily = () => {
        setCurrentDaily(currentDaily - 1)
        setToday(moment().add(currentDaily - 1, 'days'))
    }
    const onClickNextWeekly = async () => {
        setCurrentWeekly(currentWeekly + 7)
        setToday(moment().add(currentWeekly + 7, 'days'))
    }
    const onClickPrevWeekly = () => {
        setCurrentWeekly(currentWeekly - 7)
        setToday(moment().add(currentWeekly - 7, 'days'))
    }
    return (
        <>
        {
            myState.viewReportTimeClk === "weekly" ?
            <>
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                    {/* <NavLink to="/timeclk-dreport" className="btn">Daily</NavLink>
                        <NavLink to="/timeclk-report" className="btn btn-primary">Weekly</NavLink> */}
                        <button onClick={handleDaily} className="btn">Daily</button>
                            {/*  to="/daily-scheduling"  */}
                        <button onClick={handleWeek} className="btn" style={{backgroundColor:"#9ac748", color:"white"}}>Weekly</button>
                            {/* to="/daily-scheduling"  */}
                    </div>
                </div>
            <div className="col-md-4">
                <h3 className="card-title mt-2 text-center">
                    {
                        date.format('dddd') !== 'Sunday' ? 
                        date.startOf('week').add(1, 'days').format('DD MMMM, YYYY') + " to "+ date.startOf('week').add(7, 'days').format('DD MMMM, YYYY')
                        :
                        date.startOf('week').add(-6, 'days').format('DD MMMM, YYYY') + " to "+ date.startOf('week').add(7, 'days').format('DD MMMM, YYYY')
                    }
                </h3>
            </div>
                <div className="col-md-4">
                    <div className="float-right">
                        <a href="#" onClick={onClickPrevWeekly} className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                        <a href="#" onClick={onClickNextWeekly} className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                    </div>
                </div>
            </div>
            <TimeClockReportWeekly today={today}/>
            </>
            : myState.viewReportTimeClk === "daily" ?
            <>
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        <button onClick={handleDaily} className="btn" style={{backgroundColor:"#9ac748", color:"white"}}>Daily</button>
                        <button onClick={handleWeek} className="btn">Weekly</button>
                    </div>
                </div>
            <div className="col-md-4">
                <h3 className="card-title mt-2 text-center">{date.format('DD MMMM YYYY')}</h3>
            </div>
                <div className="col-md-4">
                    <div className="float-right">
                        <a href="#" onClick={onClickPrevDaily} className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                        <a href="#" onClick={onClickNextDaily} className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                    </div>
                </div>
            </div>
            <TimeClockReportDaily today={today}/>
            </>
            : myState.viewReportTimeClk === "search" ?
            <>
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        <button onClick={handleDaily} className="btn">Daily</button>
                        <button onClick={handleWeek} className="btn">Weekly</button>
                    </div>
                </div>
                {/* <div className="col-md-4">
                    <div className="float-right">
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink>
                    </div>
                </div> */}
            </div>
            <TimeClockReportWeeklySearchTable />
            </>
            : 
            <div>Loading</div>
        }
        </>
    )
}
export default AdminReportTimeClkReport