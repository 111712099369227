import React, { useEffect } from 'react'
import { getCompanyLeaveCategory } from '../../../actions/leaveActions'
import { useDispatch, useSelector } from 'react-redux'
import AdminLSRow from './AdminLSRow'

const AdminLS = () => {
    // const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const leave = useSelector((state) => state.leave)
    const dispatch = useDispatch()
    const leaveSetting = async (e) => {
        dispatch(getCompanyLeaveCategory({companyId: auth.user.companyId }))
    }
    useEffect(()=>{
        leaveSetting()
    }, [])
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                {
                    leave.leaveSetting &&
                    leave.leaveSetting.length > 0 &&
                    leave.leaveSetting.map((current, i) => {
                        return (
                            <AdminLSRow current={current} i={i}/>        
                        )
                    })
                }
                </div>
            </div>
        </>
    )
}
export default AdminLS