import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import moment from 'moment';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { updateMailById, updateMailByIdSeen } from '../actions/mailActions';
// import { NavLink } from 'react-router-dom'
// import { Editor } from 'react-draft-wysiwyg'
// import { EditorState } from 'draft-js';
const MessageBoxDesign = () => {
    const mail = useSelector((state) => state.mail);
    const auth = useSelector((state) => state.auth);
    const params = useParams();
    const [showEditor, setshowEditor] = useState(false)
    const [ values, setValues ] = useState({
        mailId : '',
        sender : '',
        message : ''
    })
    useEffect(() => {
        if (params.id) {
            setValues({
                ...values, mailId:
                params.id
            })
        } 
    }, [params.id]);
    const openEditor = () => {
        setshowEditor(true)
    }
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    // console.log(values)
    const dispatch = useDispatch()
    // const updateMail = async (idMail) => {
    //     dispatch(updateMailById
    //         ({
    //         mailId: idMail, 
    //         sender: auth.user.id, 
    //         message: values.message,
    //     }))
    // }
    const messageData = {
        mailId: values.mailId, 
        sender: auth.user.id,
        message: values.message,
    }

    // onContentStateChange: Function = (contentState) => {
    //     this.setValues({
    //         ...values, message:contentState,
    //     });
    //   }
    // const onContentStateChange = async (contentState) => {
    //     console.log("first")
    //     setValues({
    //         ...values, message:'', 
    //     })
    // }
    // const [editorState, setEditorState] = useState(
    //     () => EditorState.createEmpty(),
    //   );
    //   console.log(editorState, 'kyyyyuuuuuunnnnn aajjjkkklllllll khuuuwwwwbbbbbbb')
    //   console.log(EditorState, 'kyyyyuuuuuunnnnn aajjjkkklllllll khuuuwwwwbbbbbbb22222')

    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        dispatch(updateMailById(messageData))
        setValues({
            message:'', 
        })
    }
    let val = 1
    const messageSeen = () => {
        dispatch(updateMailByIdSeen({
            mailId: params.id,
            userId: auth.user.id
        }))
    }
    useEffect(() => {
        if (mail && mail.singleMail){
            mail.singleMail.messages[mail.singleMail.messages.length-1].view.forEach(e => {
                if(e.receiver.id === auth.user.id && e.messageSeen === false){
                    messageSeen()
                }
            })
        }
    }, [mail.singleMail])
    return (
        <>
        <div className="row">
        {
        mail.singleMail &&
        mail.singleMail.messages &&
        mail.singleMail.messages.length > 0 &&
        mail.singleMail.messages.map((current, i) =>{
            return(
                <div className="col-sm-12" >
                    <div className="card mb-0">
                        <div className="card-body">
                            <div className="mailview-content">
                                <div className="mailview-header">
                                    <div className="row">
                                        <div className="col-sm-9">
                                            <div className="text-ellipsis m-b-10">
                                                <span className="mail-view-title">{mail.singleMail.subject}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <div className="mail-view-action">
                                                <div className="btn-group">
                                                    {/* <button type="button" className="btn btn-white btn-sm" data-toggle="tooltip" title="Delete"> <i className="fa fa-trash-o"></i></button> */}
                                                    {/* <button type="button" className="btn btn-white btn-sm" data-toggle="tooltip" title="Reply"> <i className="fa fa-reply"></i></button> */}
                                                    {/* <button type="button" className="btn btn-white btn-sm" data-toggle="tooltip" title="Forward"> <i className="fa fa-share"></i></button> */}
                                                </div>
                                                {/* <button type="button" className="btn btn-white btn-sm" data-toggle="tooltip" title="Print"> <i className="fa fa-print"></i></button> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sender-info">
                                        <div className="sender-img">
                                            <img width="40" alt="" src="assets/img/profiles/avatar-02.jpg" className="rounded-circle" />
                                        </div>
                                        <div className="receiver-details float-left">
                                            <span className="sender-name">{current.sender.firstName + " " + current.sender.lastName} 
                                            {/* (<NavLink to="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="650f0a0d0b0f0a0025001d04081509004b060a08">[email&#160;protected]</NavLink>)</span> */}
                                            </span>
                                            <span className="receiver-name">
                                                to: &nbsp;
                                                <span>
                                                {
                                                mail.singleMail &&
                                                mail.singleMail.condition ?
                                                mail.singleMail.condition :
                                                current.view && 
                                                current.view.length > 0 &&
                                                current.view.map((e, ind) =>{
                                                    return (
                                                    (
                                                        current.view.length -1) === ind ? e.receiver.firstName + " " : e.receiver.firstName + ", "
                                                    )
                                                    })}    
                                                </span>
                                            </span>
                                        </div>
                                        <div className="mail-sent-time">
                                            <span className="mail-time">{moment(current.timestamps).format('lll')}</span>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                                <div className="mailview-inner">
                                    <p>{current.message}</p>
                                </div>
                            </div>
                            <div className="mail-attachments">
                                {/* <p><i className="fa fa-paperclip"></i> 2 Attachments - <NavLink to="#">View all</NavLink> | <NavLink to="#">Download all</NavLink></p> */}
                                <ul className="attachments clearfix">
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                )})}
                <div className="mailview-footer">
                    <div className="row">
                        {
                        mail.singleMail &&
                        mail.singleMail.condition ?
                        <></> :
                        <div className="col-sm-6 left-action">
                            <button onClick={openEditor} type="button" className="btn btn-white"><i className="fa fa-reply"></i> Reply</button>
                        </div>
                        }
                        <div className="col-sm-6 right-action"></div>
                    </div>
                    <br />
                    {   
                    showEditor === true &&
                    <div className="form-group">
                    {/* <Editor
                        placeholder="Enter your message here"
                        // toolbarClassName="toolbarClassName"
                        // wrapperClassName="wrapperClassName"
                        // editorClassName="editorClassName"
                        // ************************************
                        // onContentStateChange={() => onContentStateChange()}
                        // km abh nh hua oper sey
                        // editorState={editorState}
                        // onEditorStateChange={setEditorState}
                        // wrapperClassName="wrapper-class"
                        // editorClassName="editor-class"
                        // toolbarClassName="toolbar-class"
                        /> */}
                    <textarea
                        className="form-control"
                        placeholder="Type a message..."
                        autoComplete="false" value={values.message}
                        onChange={handleChange('message')}
                        style={{height: '300px'}}/>
                        <br />
                    <button type="button" className="btn btn-white" 
                        onClick={handleSubmit}>
                        <i className="fa fa-paper-plane"></i> Send 
                    </button>
                    </div>
                    }
                </div>
            </div>
            <br /><br />
        </>
    )
}
export default MessageBoxDesign