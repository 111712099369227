import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import AutoComplete from './AutoComplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { clickLatLng, clickMap } from '../../../actions/locationActions';
import { useDispatch } from 'react-redux'
// import {  useSelector } from 'react-redux'

const containerStyle = {
  width: '100%',
  height: '40vh'
};

function MyComponent({currLat, currLng, searchNeeded}) {
  const dispatch = useDispatch()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDv5TZ4_il1sukhMFSFChda0UHcL0js0Zg"
  })
  const [mapApiLoaded, setMapApiLoaded] = useState(true);
    const [mapInstance, setMapInstance] = useState(null);
    const [mapApi, setMapApi] = useState(null);
    const [address, setAddress] = useState("");
    const [center, setCenter] = useState([]);
    const [places, setPlaces] = useState([]);
    const [zoom, setZoom] = useState(15);
    const [draggable, setDraggable] = useState(true)
    const [lat, setLat] = useState(null)
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng:null,
  });
  // console.log("currLat", typeof(currLat) )
  useEffect(() => {
    setCurrentLocation()
    if (typeof(currLat) === "number"){
      // console.log("number hai bhai")
      existLocation()
    }
  },[])
  useEffect(() => {
    existLocation()
  },[currLat])
  const existLocation = (() => {
    setCenter({
      lat: currLat,
      lng: currLng,
    })
    setCoordinates({
      lat: currLat,
      lng: currLng
    })
    dispatch(clickLatLng(currLat,currLng))
    dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+currLat+","+currLng))
  })
  const onMarkerInteraction = (childKey, childProps, mouse) => {
    setDraggable(false);
    setCoordinates({
      lat: mouse.lat,
      lng: mouse.lng
    })
    dispatch(clickLatLng(mouse.lat,mouse.lng))
    dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+mouse.lat+","+mouse.lng))
};
const onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
  setDraggable(true);       
};
function apiHasLoaded(map, maps){
  setMapApiLoaded(true)
  setMapInstance(map)
  setMapApi(maps)
};

const addPlace = (place) => {
  setPlaces([place]);
  setCoordinates({
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
  });
  // console.log(place, "place data")
  // dispatch(clickLatLng(coordinates.lat,coordinates.lng))
  dispatch(clickLatLng(place.geometry.location.lat(),place.geometry.location.lng()))
  dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+place.geometry.location.lat()+","+place.geometry.location.lng()))
};
const setCurrentLocation = () => {
  if (coordinates.lat === null){
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
          // setCenter([position.coords.latitude, position.coords.longitude])
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          })
          setCoordinates({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          // console.log(position.coords.latitude, position.coords.longitude)
      });
    }
  }
  if(currLat){
    if(typeof(currLat) === "number"){
      setCenter({
        lat: currLat,
        lng: currLng,
      })
      setCoordinates({
        lat: currLat,
        lng: currLng
      })
    }
  }
}
const setLocationInRedux = () => {
  dispatch(clickLatLng(coordinates.lat,coordinates.lng))
  dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+coordinates.lat+","+coordinates.lng)) 
  if(typeof(currLat) === "number"){
    dispatch(clickLatLng(currLat,currLng))
    dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+currLat+","+currLng))
  }         
}
// useEffect(() => {
//   setCurrentLocation()
// },[])
useEffect(() => {
  setLocationInRedux()
},[coordinates.lat, coordinates.lng])

const handleSelect = async (value) => {
  const results = await geocodeByAddress(value);
  const latLng = await getLatLng(results[0]);
  setAddress(value);
  // addPlace(value)
  setCoordinates(latLng);
  setCenter(latLng)
  // console.log("chal gaya mein", latLng)
  // dispatch(clickLatLng(coordinates.lat,coordinates.lng))
  dispatch(clickLatLng(latLng.lat,latLng.lng))
  dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+coordinates.lat+","+coordinates.lng))
};

  const _onClick = (value) => {
    // console.log(value.latLng.lat)
    // console.log(value.latLng.lat(),"Sttt")
    setCoordinates({
      lat: value.latLng.lat(),
      lng: value.latLng.lng(),
    });
    // dispatch(clickLatLng(coordinates.lat,coordinates.lng))
    dispatch(clickLatLng(value.latLng.lat(),value.latLng.lng()))
    dispatch(clickMap("https://www.google.com/maps/search/?api=1&query="+coordinates.lat+","+coordinates.lng))
  };

  const [map, setMap] = React.useState(null)

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  // console.log(coordinates)
  return isLoaded ? (
        <>
        {
          mapApiLoaded && (
          <div>
            {/* { !searchNeeded &&  */}
            <AutoComplete
              map={mapInstance}
              mapApi={mapApi}
              addplace={addPlace}
              handleSelect={handleSelect}
              coordinates={coordinates}
              address={address}
              setAddress={setAddress}
            />
             {/* } */}
          </div>
        )
      }
      
<GoogleMap
mapContainerStyle={containerStyle}
center={center}
zoom={15}
onUnmount={onUnmount}
onClick={_onClick}
>
<Marker
  position={coordinates}
/>
</GoogleMap>
        </>
  ) : <></>
}
export default MyComponent