import moment from "moment";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

function TimeComponent({ clockData }) {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [totalTimeInSec, setTotalTimeInSec] = useState(
    clockData?clockData.hoursUsedInDecimal * 60 * 60:0
  );
  const [lastClockIn, setLastClockIn] = useState(null);
  const timeInterval = useRef(null);

  useEffect(() => {
    if (clockData) {
      // setLastClockIn(clockData?.clockHistory[clockData?.clockHistory.length - 1]);
      if (clockData.clockedIn) {
        if (clockData.clockHistory&&clockData.clockHistory.length === [0]) {
          setTotalTimeInSec(clockData.hoursUsedInDecimal * 60 * 60);
        } else {
          const lastClockHistory =
            clockData.clockHistory[clockData.clockHistory.length - 1];
          const currentDate = new Date().toISOString().split("T")[0];
          const currentTime = new Date().toTimeString().slice(0, 8);
          const lastClockInDate = new Date(
            lastClockHistory.clockIn
          ).toDateString();
          const lastClockInTime = new Date(lastClockHistory.clockIn)
            .toTimeString()
            .slice(0, 8);
          const timeDiff =
            new Date(currentDate + "T" + currentTime + ".000Z").getTime() -
            new Date(lastClockInDate + " " + lastClockInTime).getTime();
          setTotalTimeInSec(
            timeDiff / 1000 + clockData.hoursUsedInDecimal * 60 * 60
          );
        }

        handleStart();
      } else {
        clearInterval(timeInterval.current);
      }
    }else{
    //     setTotalTimeInSec(
    //         0
    //       );
        

    //     handleStart();
    //   } else {
    //     clearInterval(timeInterval.current);
    //   }
    }

    return () => {
      clearInterval(timeInterval.current);
    };
  }, [clockData]);

  //   console.log(lastClockIn);
  useEffect(() => {
    // const hoursUsedInDecimal = clockData.hoursUsedInDecimal * 60 * 60;

    setHours(Math.floor((totalTimeInSec / (60 * 60)) % 24));
    setMinutes(Math.floor((totalTimeInSec / 60) % 60));
    setSeconds(Math.floor(totalTimeInSec % 60));
  }, [totalTimeInSec]);

  //   console.log("time==>", totalTimeInSec);
  //   console.log("time formatted==>", hours, minutes, seconds);

  const handleStart = () => {
    timeInterval.current = setInterval(() => {
      setTotalTimeInSec((prevTime) => prevTime + 1);
    }, 1000);
  };

  //   useEffect(() => {
  //     const interval = setInterval(
  //       () => setTotalTimeInSec((prevTime) => prevTime + 1),
  //       1000
  //     );

  //     return () => clearInterval(interval);
  //   }, []);

  return (
    <div className="punch-info">
      <div
        className={`punch-hours d-flex justify-content-evenly p-3 ${
          clockData?.clockedIn ? "clocked-in" : ""
        }`}
      >
        {/* {clockData !== null && <span>{clockData.hoursUsed}</span>} */}
        <span>{hours.toString().length == 2 ? hours : `0${hours}`} </span>
        <span>:</span>
        <span>{minutes.toString().length == 2 ? minutes : `0${minutes}`}</span>
        <span>:</span>
        <span>{seconds.toString().length == 2 ? seconds : `0${seconds}`}</span>
      </div>
    </div>
  );
}

export default TimeComponent;
