import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { updateUserLoginInfo, userLogout } from "../../actions/authActions";

const AdminSideBar = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  
  const navigateUser = () => {
    // dispatch(updateUserLoginInfo({
    //     _id: auth.activeUserCountry.id,
    //     loginStatus:false
    // }))
    navigate("/login");
  };
  const logout = async (e) => {
    auth && auth.activeUserCountry
      ? await dispatch(
          userLogout(
            { refreshToken: auth.tokens.refresh.token },
            navigateUser,
            auth.activeUserCountry.id
          )
        )
      : await dispatch(
          userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
        );
    // await dispatch(userLogout({refreshToken: auth.tokens.refresh.token}, navigateUser, auth.activeUserCountry.id))
  };
  const [showAdministratorDropDown, setshowAdministratorDropDown] =
    useState(false);
  const [showReportDropDown, setshowReportDropDown] = useState(false);
  const openReportDropDown = () => {
    setshowReportDropDown((showReportDropDown) => !showReportDropDown);
    setshowAdministratorDropDown(false);
  };
  // console.log(showReportDropDown)
  const openAdministratorDropDown = () => {
    setshowAdministratorDropDown(
      (showAdministratorDropDown) => !showAdministratorDropDown
    );
    setshowReportDropDown(false);
  };
  return (
    <>
      <div className="main-wrapper">
        <div className="sidebar" id="sidebar">
          {/* <div className="slimScrollRail" style={{overflowY: "scroll"}}> */}
          {/* style={{position: "relative", overflow: "show", width: "100%", height: "421px"}}> */}
          {/* <div className="sidebar-inner slimscroll" style={{position: "relative", overflow: "show", width: "100%", height: "421px"}}> */}
          {/* <div className="sidebar-inner slimscroll" > */}
          <div className="scrollbar scrollbar-primary">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li>
                  <NavLink to="/dashboard">
                    <i className="la la-dashboard"></i> <span> Dashboard</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/employees">
                    <i className="la la-user"></i> <span>All Employees</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/chat">
                    <i className="la la-wechat"></i> <span>Chat</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/time-clock">
                    <i className="la la-user-clock"></i>
                    <span>Time Clock</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/shift-scheduling">
                    <i className="la la-users"></i>
                    <span>Shift &amp; Schedule</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/tracking">
                    <i className="la la-location-arrow"></i>
                    <span>Tracking</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/leaves">
                    <i className="la la-external-link-square"></i>
                    <span>Leaves </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/training">
                    <i className="la la-graduation-cap"></i>{" "}
                    <span> Training </span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/payroll-items">
                    <i className="la la-money"></i> <span> Payroll </span>
                  </NavLink>
                </li>
                <li className="submenu">
                  <NavLink to="#" onClick={openReportDropDown}>
                    <i className="la la-th-list"></i>
                    <span>Reports</span> <span className="menu-arrow"></span>
                  </NavLink>
                  <ul
                    // style={{ display: "block" }}
                    className={`${showReportDropDown ? "show" : "hide"}`}
                  >
                    {/* la la-th-list hataya ha kyu k 2 bar dikh rha ha  */}
                    {/* <li><NavLink to="/scheduling-report"><i className="la la-file-invoice"></i> <span>Schedule Reports</span></NavLink></li> */}
                    <li>
                      <NavLink to="/shift-report">
                        <i className="la la-file-invoice"></i>{" "}
                        <span>Shift Reports</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/timeclk-report">
                        <i className="la la-file-invoice"></i>{" "}
                        <span>Time Clock Reports</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/attend-report">
                        <i className="la la-file-invoice"></i>{" "}
                        <span>Attendance Reports</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <NavLink to="#" onClick={openAdministratorDropDown}>
                    <i className="las la-toolbox"></i>
                    <span>Administration</span>{" "}
                    <span className="menu-arrow"></span>
                  </NavLink>
                  <ul
                    // style={{ display: "none" }}>
                    className={`${showAdministratorDropDown ? "show" : "hide"}`}
                  >
                    {/* las la-toolbox simarly */}
                    <li>
                      <NavLink to="/locations">
                        <i className="la la-map-marker"></i>{" "}
                        <span>Locations</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/file-manager">
                        <i className="la la-folder"></i> <span>My Files</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/policies">
                        <i className="la la-file-pdf-o"></i>{" "}
                        <span>Policies</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/users">
                        <i className="la la-user-plus"></i> <span>Users</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/subscriptions-company">
                        <i className="la la-hand-o-up"></i>{" "}
                        <span> Subscriptions </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/settings">
                        <i className="la la-cog"></i> <span> Settings </span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a style={{ cursor: "pointer" }} onClick={logout}>
                    <i className="la la-power-off"></i> <span>Logout</span>
                  </a>
                </li>
                {/* <li><NavLink to="/index"><i className="la la-power-off"></i> <span>Logout</span></NavLink></li> */}
              </ul>
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminSideBar;
