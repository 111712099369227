import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import EmployeeScheduleDaily from './EmployeeScheduleDaily'
import EmployeeScheduleMonthly from './EmployeeScheduleMonthly'
import EmployeeScheduleWeekly from './EmployeeScheduleWeekly'
import { tabDaily, tabMonthly, tabWeekly } from '../../../actions/tabActions'
import moment from 'moment'
import EmployeeShiftandScheduleSearchTable from './EmployeeShiftandScheduleSearchTable'
import { shiftPageData } from '../../../actions/shiftActions'
// import EmployeeScheduleSearch from './EmployeeScheduleSearch'
// import EmployeeTitle from '../EmployeeTitle'

const EmployeeScheduleViewBar = () => {
//     // const [ viewCalender, setViewCalender ] = useState("weekly")
//     // const handleDaily = (e) => {
//     //     setViewCalender("daily")
//     // }
//     // const handleWeek = (e) => {
//     //     setViewCalender("weekly")
//     // }
//     // const handleMonthly= (e) => {
//     //     setViewCalender("monthly")
//     // }
//     const myState = useSelector((state => state.changeTheTab))
//     console.log("***********", myState.viewTheShift)
//     const dispatch = useDispatch()
//     const [showDropDown, setshowDropDown] = useState(false)
//     const openDropDown = () => {
//         setshowDropDown((showDropDown) => !showDropDown)
//     }
//     console.log(showDropDown)
//     const [ today2, setToday2 ] = useState(moment())
//     let date2 = moment(today2.format('YYYY-MM-DD'))
//     // var currDate = new Date(moment().format('YYYY-MM-DD'));
//     var currDate = new Date(today2.format('YYYY-MM-DD'));
//     var today = new Date(moment().format('YYYY-MM-DD'));
//     var firstDate, lastDate;
//     // let dateAction
//     // let tempW1, tempW2, tempM, tempT;
//     // tempT = currDate.toString().split(" ")
//     var weekFirst
//     var weekLast
//     // var weekP
//     if (today2.format('dddd') !== 'Sunday'){
//         weekFirst = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+1));
//         weekLast = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+7));
//     }
//     else {
//         weekFirst = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()-6));
//         weekLast = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+7));
//     }
//     // tempW1 = weekFirst.toString().split(" ")
//     // tempW2 = weekLast.toString().split(" ")

//     // let weekdate = []
//     let monthdate = []
//     let dailydate = []
//     let temp, arr, arr2, i;
//     arr2 = today.toString().split(" ");
//     dailydate.push({day: arr2[0], date: arr2[2]})

//     // let arr
//     // let i;
//                     // // old logic
//                     // for (i = 1; i < 8; i++){
//                     //     // temp = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()))
//                     //     // arr = temp.toString().split(" ");
//                     //     // console.log(arr[2])
//                     //     if (today2.format('dddd') !== 'Sunday'){
//                     //         weekP = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()-6));
//                     //     }
//                     //     else {
//                     //         weekP = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()-6));
//                     //     }
//                     //     temp = new Date(weekP.setDate(weekP.getDate()-weekP.getDay()+i))

//                     //                 // // old logic
//                     //                 // currDate = new Date(today2.format('YYYY-MM-DD'));
//                     //                 // // console.log(currDate)
//                     //                 // temp = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+i))
//                     //                 // // console.log(temp)
//                     //     arr = temp.toString().split(" ");
//                     //     // console.log(arr)
//                     //     weekdate.push({day: arr[0], date: arr[2]})
//                     // }
//                     // console.log(weekdate,"88")
//                     //     var month = currDate.getMonth()+1;
//                     //   var year = currDate.getFullYear();
//                     //    firstDate = new Date(year, month-1);
//                     // //   tempM = firstDate.toString().split(" ")
//                     //    lastDate = new Date(year, month, 0);
//                     //   temp = lastDate.toString().split(" ")
//                     //   let limit = temp[2]
//                     //   limit = parseInt(limit)
//                     //   for (i = 0; i < limit; i++){
//                     //       firstDate = new Date(year, month-1);
//                     //       temp = new Date(firstDate.setDate(firstDate.getDate()+i))
//                     //       arr = temp.toString().split(" ");
//                     //       monthdate.push({day: arr[0], date:arr[2]})
//                     //   }

//     // const endOfMonth = date2.endOf('month').format('DD')
//     // const startOfMonth = date2.startOf('month').format('DD')
//     // let datesArrLength = []
//     // for (let i=0; i<parseInt(endOfMonth); i++){
//     //     datesArrLength.push(i)
//     // }

// // console.log(monthdate)

    const [ today, setToday ] = useState(moment())
    let date = moment(today.format('YYYY-MM-DD'))
    let date2 = moment(today.format('YYYY-MM-DD'))
    const myState = useSelector((state => state.changeTheTab))
    // console.log("***********", myState.viewTheShift)
    const dispatch = useDispatch()

    // const [showDropDown, setshowDropDown] = useState(false)
    // const openDropDown = () => {
    //     setshowDropDown((showDropDown) => !showDropDown)
    // }
    // console.log(showDropDown)
    const auth = useSelector((state) => state.auth)
    const [currentDaily, setCurrentDaily] = useState(0)
    const [currentWeekly, setCurrentWeekly] = useState(0)
    const [currentMonthly, setCurrentMonthly] = useState(0)
    const onClickNextDaily = async () => {
        // console.log(currentDaily)
        setCurrentDaily(currentDaily + 1)
        // console.log(currentDaily+1)
        setToday(moment().add(currentDaily + 1, 'days'))
        // dispatch(shiftPageData({companyId: auth.user.companyId, date: moment().add(currentDaily + 1, 'days').format('YYYY-MM-DD')}))
        dispatch(shiftPageData({userId: auth.user.id, date: moment().add(currentDaily + 1, 'days').format('YYYY-MM-DD')}))
    }
    const onClickPrevDaily = () => {
        // console.log(currentDaily)
        setCurrentDaily(currentDaily - 1)
        // console.log(currentDaily-1)
        setToday(moment().add(currentDaily - 1, 'days'))
        // dispatch(shiftPageData({companyId: auth.user.companyId, date: moment().add(currentDaily - 1, 'days').format('YYYY-MM-DD')}))
        dispatch(shiftPageData({userId: auth.user.id, date: moment().add(currentDaily - 1, 'days').format('YYYY-MM-DD')}))
    }
    const onClickNextWeekly = async () => {
        // console.log(currentWeekly)
        setCurrentWeekly(currentWeekly + 7)
        // console.log(currentWeekly+1)
        setToday(moment().add(currentWeekly + 7, 'days'))
        // dispatch(shiftPageData({companyId: auth.user.companyId, date: moment().add(currentWeekly + 7, 'days').format('YYYY-MM-DD')}))
        dispatch(shiftPageData({userId: auth.user.id, date: moment().add(currentWeekly + 7, 'days').format('YYYY-MM-DD')}))
    }
    const onClickPrevWeekly = () => {
        setCurrentWeekly(currentWeekly - 7)
        setToday(moment().add(currentWeekly - 7, 'days'))
        // dispatch(shiftPageData({companyId: auth.user.companyId, date: moment().add(currentWeekly - 7, 'days').format('YYYY-MM-DD')}))
        dispatch(shiftPageData({userId: auth.user.id, date: moment().add(currentWeekly - 7, 'days').format('YYYY-MM-DD')}))
    }
    const onClickNextMonthly = async () => {
        setCurrentMonthly(currentMonthly + 1)
        setToday(moment().add(currentMonthly + 1, 'months'))
        // dispatch(shiftPageData({companyId: auth.user.companyId, date: moment().add(currentMonthly + 1, 'months').format('YYYY-MM-DD')}))
        dispatch(shiftPageData({userId: auth.user.id, date: moment().add(currentMonthly + 1, 'months').format('YYYY-MM-DD')}))
    }
    const onClickPrevMonthly = () => {
        setCurrentMonthly(currentMonthly - 1)
        setToday(moment().add(currentMonthly - 1, 'months'))
        // dispatch(shiftPageData({companyId: auth.user.companyId, date: moment().add(currentMonthly - 1, 'months').format('YYYY-MM-DD')}))
        dispatch(shiftPageData({userId: auth.user.id, date: moment().add(currentMonthly - 1, 'months').format('YYYY-MM-DD')}))
    }
    return (
        <>
        {
            myState.viewTheShift === "weekly" ? 
            <>
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        <button
                            onClick={() => dispatch(tabDaily())}
                            // to="/daily-scheduling" 
                            className="btn">Daily</button>
                        <button
                            onClick={() => dispatch(tabWeekly())}
                            // to="/daily-scheduling" 
                            className="btn"
                            style={{backgroundColor:"#9ac748", color:"white"}}>Weekly</button>
                        <button
                            onClick={() => dispatch(tabMonthly())}
                            // to="/daily-scheduling" 
                            className="btn">Monthly
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                    <h3 className="card-title mt-2 text-center"> 
                    {/* {weekFirst.getDate()+ " " + weekFirst.toLocaleString('default', { month: 'long' }) + " " + 
                    "to " + weekLast.getDate()+ " " + weekLast.toLocaleString('default', { month: 'long' }) + " "}
                    {weekFirst.getFullYear() === weekLast.getFullYear() ?
                    weekFirst.getFullYear() : weekFirst.getFullYear()+"-"+weekLast.getFullYear()
                    }  */}
                                {/* { 
                                    date2.format('dddd') !== 'Sunday' ? date2.startOf('week').add(1, 'days').format('DD MMMM YYYY') : date2.startOf('week').add(-6, 'days').format('DD MMMM YYYY') 
                                }
                                    &nbsp; to &nbsp;
                                {
                                    date2.format('dddd') !== 'Sunday' ? date2.startOf('week').add(7, 'days').format('DD MMMM YYYY') : date2.startOf('week').add(0, 'days').format('DD MMMM YYYY')
                                } */}
                    {   
                        date.format('dddd') !== 'Sunday' ? date.startOf('week').add(1, 'days').format('DD MMMM YYYY') : date.startOf('week').add(-6, 'days').format('DD MMMM YYYY') 
                    }
                        &nbsp; to &nbsp;
                    {
                        date.format('dddd') !== 'Sunday' ? date.startOf('week').add(7, 'days').format('DD MMMM YYYY') : date.startOf('week').add(0, 'days').format('DD MMMM YYYY')
                    }
                    {/* {tempW1[2]} {tempW1[1]}  */}
                    {/* to  */}
                    {/* {tempW2[2]} {tempW2[1]}, 
                    {tempW1[3] === tempW2[3] ?
                    " "+tempW1[3] : " "+tempW1[3]-tempW2[3]} */}
                    </h3>
                </div>
                <div className="col-md-4">
                    <div className="float-right">
                        {/* <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink> */}
                        <a to="#" onClick={onClickPrevWeekly} className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                        <a to="#" onClick={onClickNextWeekly} className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                    </div>
                </div>
            </div>
            {/* <EmployeeScheduleWeekly weekFirst={weekFirst}/> */}
            {/* <EmployeeScheduleWeekly dateForTableData={today2}/> */}
            <EmployeeScheduleWeekly dateForTableData={date2}/>
            </>
            : myState.viewTheShift === "daily" ? 
            <>
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        <button
                            onClick={() => dispatch(tabDaily())}
                            className="btn"
                            style={{backgroundColor:"#9ac748", color:"white"}}>Daily</button>
                        <button
                            onClick={() => dispatch(tabWeekly())}
                            className="btn">Weekly</button>
                        <button
                            onClick={() => dispatch(tabMonthly())}
                            className="btn">Monthly
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                    {/* <h3 className="card-title mt-2 text-center">{today.getDate() + " " + today.toLocaleString('default', { month: 'long' }) + " " + today.getFullYear()}</h3> */}
                                { /* <h3 className="card-title mt-2 text-center">{date2.format('DD MMM YYYY')}</h3> */}
                    <h3 className="card-title mt-2 text-center">{date.format('DD MMM YYYY')}</h3>
                </div>

                <div className="col-md-4">
                    <div className="float-right">
                        {/* <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink> */}
                        <a to="#" onClick={onClickPrevDaily} className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                        <a to="#" onClick={onClickNextDaily} className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                    </div>
                </div>
            </div>
            {/* <EmployeeScheduleDaily dailydate={dailydate}/> */}
            {/* <EmployeeScheduleDaily dateForTableData={today2}/> */}
            <EmployeeScheduleDaily dateForTableData={date2}/>
            </> 
            : myState.viewTheShift === "monthly" ? 
            <>
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        <button
                            onClick={() => dispatch(tabDaily())}
                            className="btn">Daily</button>
                        <button
                            onClick={() => dispatch(tabWeekly())}
                            className="btn">Weekly</button>
                        <button
                            onClick={() => dispatch(tabMonthly())}
                            className="btn"
                            style={{backgroundColor:"#9ac748", color:"white"}}>Monthly
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                            {/* old logic */}
                            {/* <h3 className="card-title mt-2 text-center">{firstDate.toLocaleString('default', { month: 'long' }) + " " + firstDate.getFullYear()}</h3> */}
                            { /* <h3 className="card-title mt-2 text-center">{date2.format('MMMM YYYY')}</h3> */}
			        <h3 className="card-title mt-2 text-center">{date.format('MMMM YYYY')}</h3>
                </div>
                <div className="col-md-4">
                    <div className="float-right">
                        {/* <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink> */}
                        <a to="#" onClick={onClickPrevMonthly} className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                        <a to="#" onClick={onClickNextMonthly} className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                    </div>
                </div>
            </div>
            {/* <EmployeeScheduleMonthly startOfMonth={startOfMonth} endOfMonth={endOfMonth} datesArrLength={datesArrLength} date2={date2}/> */}
            {/* <EmployeeScheduleMonthly dateForTableData={today2}/> */}
            <EmployeeScheduleMonthly dateForTableData={date2}/>
            </>
            : myState.viewTheShift === "thesearch" ? 
            <>
            <div className="row mitblrow">
                <div className="col-md-4">
                    <div className="float-left">
                        <button
                            onClick={() => dispatch(tabDaily())}
                            className="btn">Daily</button>
                        <button
                            onClick={() => dispatch(tabWeekly())}
                            className="btn">Weekly</button>
                        <button
                            onClick={() => dispatch(tabMonthly())}
                            className="btn">Monthly
                        </button>
                    </div>
                </div>
                <div className="col-md-4">
                    {/* <h3 className="card-title mt-2 text-center">{date2.format('MMMM YYYY')}</h3> */}
    			    <h3 className="card-title mt-2 text-center">Searched Data</h3>
                </div>
                <div className="col-md-4">
                    <div className="float-right">
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></NavLink>
                        <NavLink to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></NavLink>
                    </div>
                </div>
            </div>
            <EmployeeShiftandScheduleSearchTable />
            </>
            :
            <div>nothing</div>
            } 
        </>
    )
}
export default EmployeeScheduleViewBar







// kaam ka code

// const [ viewCalender, setViewCalender ] = useState("weekly")
//     const [ dailyUpdate, setDailyUpdate ] = useState(0)
//     const [ weeklyUpdate, setWeeklyUpdate ] = useState(0)
//     const [ monthUpdate, setMonthUpdate ] = useState(0)
//     let dataArr;
//     var currDate = new Date("2022-07-29");
//     var today = new Date("2022-07-29");
//     var month = currDate.getMonth()+1;
//     let cond;
//   var year = currDate.getFullYear();
//     var firstDate, lastDate;
//     const handleDaily = (e) => {
//         setViewCalender("daily")
//     }
//     const handleWeek = (e) => {
//         setViewCalender("weekly")
//     }
//     const handleMonthly= (e) => {
//         setViewCalender("monthly")
//     }
//     const p = async () => {
//         console.log("currDate")
//     }
//     const handleDailyUpdateInc = async (e) => {

//         setDailyUpdate(dailyUpdate + 1)
//         currDate = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+dailyUpdate));
//         console.log(currDate)
//     }
//     const handleWeeklyUpdateInc = async (e) => {
//         setWeeklyUpdate(weeklyUpdate + 7)
//         currDate = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+weeklyUpdate));
//         console.log(currDate)
//         for (i = 1; i < 8; i++){
//             // temp = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()))
//             // arr = temp.toString().split(" ");
//             // console.log(arr[2])
//              currDate = new Date("2022-07-29");
//             // console.log(currDate)
//             temp = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+i))
//             // console.log(temp)
//             arr = temp.toString().split(" ");
//             // console.log(arr)
//             cond.push({day: arr[0], date: arr[2]})
//         }
// console.log("conddddddddddddddddddddddddddddd", cond)

//     }
//     const handleMonthUpdateInc = async (e) => {
//         setMonthUpdate(monthUpdate + 1)
//         month = currDate.getMonth()+1 + monthUpdate;
//         if (month > 12){
//             month = 1
//             year = currDate.getFullYear()+1;
//             setMonthUpdate(0)
//         }
//         else {
//             year = currDate.getFullYear();
//         }
//         firstDate = new Date(year, month-1);
//         console.log(month, year)
//         lastDate = new Date(year, month, 0);
//         console.log(firstDate, lastDate)
//     }
//     const handleDailyUpdateDec = async (e) => {
//         setDailyUpdate(dailyUpdate - 1)
//         console.log(dailyUpdate)
//         currDate = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+dailyUpdate));
//         console.log(currDate)
        
//     }
//     const handleWeeklyUpdateDec = async (e) => {
//         setWeeklyUpdate(weeklyUpdate - 7)
//         currDate = new Date(currDate.setDate(currDate.getDate()-currDate.getDay()+weeklyUpdate));
//         console.log(currDate)
//     }
//     const handleMonthUpdateDec = async (e) => {
//         setMonthUpdate(monthUpdate -1)
//         month = currDate.getMonth()+1+monthUpdate;
//         if (month < 0){
//             month = 11
//             year = currDate.getFullYear()-1;
//             setMonthUpdate(0)
//         }
//         else {
//             year = currDate.getFullYear();
//         }
//         year = currDate.getFullYear();
//         firstDate = new Date(year, month-1);
//         lastDate = new Date(year, month, 0);
//         console.log(month, year)
//         console.log(firstDate, lastDate)
//     }