import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { getAllMonthlySalaryByCompanyId } from '../../../actions/payrollAction'
import { useDispatch, useSelector } from 'react-redux'
import SearchPEmployeeSalariesRow from './SearchPEmployeeSalariesRow'
const SearchPEmployeeSalariesTable = () => {
   const auth = useSelector((state) => state.auth)
   const admin = useSelector((state) => state.admin)
   const [ dropDownRow, setDropDownRow ] = useState(null)

   const dispatch = useDispatch()

   const salaryCalender = async (e) => {
      dispatch(getAllMonthlySalaryByCompanyId({companyId: auth.user.companyId }))
   }
   useEffect(() => {
      salaryCalender()
   }, [])
   return (
      <>
         <div className="row">
            <div className="col-md-12">
                  <table className="table table-striped custom-table datatable">
                     <thead>
                        <tr>
                           <th>Employee</th>
                           <th>Employee ID</th>
                           <th>Phone</th>
                           <th>Join Date</th>
                           <th>Salary</th>
                           <th>Payslip</th>
                           <th className="text-right">Action</th>
                        </tr>
                     </thead>
                     <tbody>
                     {
                        admin.searchSalaryCalendarData &&
                        admin.searchSalaryCalendarData.length > 0 &&
                        admin.searchSalaryCalendarData.map((curr, ind) => {
                        return(
                           curr.length > 0 &&
                           curr.map((current,i) => {
                              return (
                                 <SearchPEmployeeSalariesRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                              )
                           })
                        )
                     })
                     }
                     </tbody>
                  </table>
               {/* </div> */}
            </div>
         </div>
      </>
   )
}
export default SearchPEmployeeSalariesTable


// search pay pagination lagani hai toh

{/* <div className="row">
      <div className="col-sm-12 col-md-6">
         <div className="dataTables_length" id="DataTables_Table_0_length">
            <label>Show &nbsp;
                  <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                     className="select">
                     <option value="10">10</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option>
                  </select> entries
            </label>
         </div>
      </div>
</div> */}

{/* <div className="row">
      <div className="col-sm-12 col-md-5">
         <label> Showing 1 to 10 of 10 entries </label>
      </div>
      <div className="col-sm-12 col-md-7">
         <div className="avatar-pagination">
            <ul className="pagination" style={{ justifyContent: 'flex-end' }}>
                  <li className="page-item">
                     <NavLink className="page-link" to="#" aria-label="Previous">
                        <span aria-hidden="true">Previous</span>
                        <span className="sr-only">Previous</span>
                     </NavLink>
                  </li>
                  <li className="page-item active"><NavLink className="page-link" to="#">1</NavLink></li>
                  <li className="page-item"><NavLink className="page-link" to="#">2</NavLink></li>
                  <li className="page-item">
                     <NavLink className="page-link" to="#" aria-label="Next">
                        <span aria-hidden="true">Next</span>
                        <span className="sr-only">Next</span>
                     </NavLink>
                  </li>
            </ul>
         </div>
      </div>
</div> */}
