import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updatePolicy } from '../../../actions/policyAction' 
const EditModalPolicies = ({ showDropDown, openDropDown, current }) => {
    const [ values, setValues ] = useState({
        id: '',
        name: '',
        description: '',
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    const currentPolicies = async () => {
        try {
            setValues({
                id: '',
                name: '',
                description: '',
            })
            setValues({
                id: current.id,
                name: current.name,
                description: current.description,
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        currentPolicies()
    }, [])
    const dispatch = useDispatch()
    const updatePoliciesData = {
        _id: values.id,
        name: values.name,
        description: values.description,
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        dispatch(updatePolicy(updatePoliciesData))
        setValues({
           id: '',
           name: '',
           description: '',
        })
    }
    // console.log(values, "policy ban nhi rhi, policy ban gyeee hai")
    return (
        <>
        <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
            <div className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} id="edit_policy" role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Policy</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Policy Name <span className="text-danger">*</span></label>
                                    <input className="form-control" type="text"
                                    value={values.name}
                                    onChange={handleChange('name')}/>
                                </div>
                                <div className="form-group">
                                    <label>Description <span className="text-danger">*</span></label>
                                    <textarea className="form-control" rows="4"
                                    value={values.description}
                                    onChange={handleChange('description')}></textarea>
                                </div>
                                {/* <div className="form-group">
                                    <label>Upload Policy <span className="text-danger">*</span></label>
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="edit_policy_upload" />
                                        <label className="custom-file-label" for="edit_policy_upload">Choose file</label>
                                    </div>
                                </div> */}
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn">Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditModalPolicies