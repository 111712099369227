import React, { useEffect, useState } from 'react'
import { forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import { updateTraining } from '../../../actions/trainingActions'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
const EditTrainingModal = ({ showDropDown, openDropDown, current }) => {
    const [ fromDate, setFromDate ] = useState(new Date(moment().format('YYYY-MM-DD')))
    const [ toDate, setToDate ] = useState(new Date(moment().format('YYYY-MM-DD')))
    // console.log(fromDate)
    // console.log(toDate)
    const From = forwardRef(({ value, onClick }, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon">
                <input className="form-control floating datatimepicker" type="text" value={value}/>
            </div>
            <label className="focus-label">From</label>
        </div>
    ))
    const To = forwardRef(({ value, onClick}, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon">
                <input className="form-control floating datatimepicker" type="text" value={value}/>
            </div>
            <label className="focus-label">To</label>
        </div>
    ))
    const [ values, setValues ] = useState({
        id: '',
        companyId: '',
        trainingName: '',
        trainingType: '',
        attachedFiles: '',
        startDate: '',
        endDate: '',
        description: '',
        status: ''
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
           ...values, [fieldName]:
           event.target.value
        })
    }
    // console.log(values)
    const currentTraining = async () => {
        try {
           setValues({
                id: '',
                companyId: '',
                trainingName: '',
                trainingType: '',
                attachedFiles: '',
                startDate: '',
                endDate: '',
                description: '',
                status: ''
            })
            setValues({
                id: current.id,
                //   companyId: current.companyId,
                trainingName: current.trainingName,
                trainingType: current.trainingType,
                //   attachedFiles: current.attachedFiles,
                startDate: current.startDate,
                endDate: current.endDate,
                description: current.description,
                status: current.status,
            })
        } catch (error) {
          console.log(error)
        }
    }
    useEffect(() => {
        currentTraining()
    }, [])
    const dispatch = useDispatch()
    const updateTrainingData = {
        _id: values.id,
        // companyId: values.companyId,
        trainingName: values.trainingName,
        trainingType: values.trainingType,
        // attachedFiles: values.attachedFiles,
        startDate: fromDate.toISOString(),
        endDate: toDate.toISOString(),
        description: values.description,
        status: values.status === "true" ? true : false ,
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        for (let key of Object.keys(updateTrainingData)) {
            // console.log(key,"54121421521", updateTrainingData[key])
            if(updateTrainingData[key] === '' ){
                delete updateTrainingData[key]
            }
        }
        dispatch(updateTraining(updateTrainingData))
        setValues({
            id: '',
            companyId: '',
            trainingName: '',
            trainingType: '',
            attachedFiles: '',
            startDate: '',
            endDate: '',
            description: '',
            status: ''
        })
    }
    // console.log(values, "training edit k liya tayarrrrrrrrrrrr")
    return (
        <>
            <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
                <div id="edit_training" className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Training List</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Training Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" 
                                            autoComplete="false" value={values.trainingName}
                                            onChange={handleChange('trainingName')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Training Type</label>
                                            <input className="form-control" type="text" 
                                            autoComplete="false" value={values.trainingType}
                                            onChange={handleChange('trainingType')}
                                            />
                                        </div>
                                        {/* <br />
                                        {
                                            values.trainingType === "" ?
                                            <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                                            autoComplete="false" value={values.trainingType = "Node Training"}
                                            onChange={handleChange('trainingType')} 
                                            >
                                                <option >Node Training</option>
                                                <option >Swift Training</option>
                                            </select>
                                            :
                                            <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                                            autoComplete="false" value={values.trainingType}
                                            onChange={handleChange('trainingType')} >
                                                <option >Node Training</option>
                                                <option >Swift Training</option>
                                            </select>
                                        } 
                                    </div> */}
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>Start Date <span className="text-danger">*</span></label>
                                        <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)} customInput={<From />}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>End Date <span className="text-danger">*</span></label>
                                        <DatePicker selected={toDate} onChange={(date) => setToDate(date)} customInput={<To />}/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    {/* <div className="form-group">
                                        <label className="col-form-label">Attach Files <span className="text-danger">*</span></label>
                                        <input className="form-control" type="file" 
                                        autoComplete="false" value={values.attachedFiles}
                                        onChange={handleChange('attachedFiles')}
                                        style={{width: "440px"}}
                                        />
                                    </div> */}
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label>Description <span className="text-danger">*</span></label>
                                        <textarea className="form-control" rows="4"
                                        autoComplete="false" value={values.description}
                                        onChange={handleChange('description')}
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label className="col-form-label">Status</label>
                                        {
                                        values.status === "" ?
                                        <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                                        autoComplete="false" value={values.status = "true"}
                                        onChange={handleChange('status')}
                                        >
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </select>
                                        :
                                        <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                                        autoComplete="false" value={values.status}
                                        onChange={handleChange('status')}>    
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </select>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="submit-section">
                                <button className="btn btn-primary submit-btn">Submit</button>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditTrainingModal