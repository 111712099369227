import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllLocationsWithUsers, getCompanyLocations, removeLocation } from '../../../actions/locationActions'

const DeleteLocationModal = ({ showDeleteDropDown, openDeleteDropDown, current }) => {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth)
    const [dataUpdate, setDataUpdate] = useState({
        _id: '',
        status: false,
    })
    // console.log("delete mein current", current.location)
    const valueUpdate = () => {
        if(current !== undefined){
            if(current.location){
                setDataUpdate({
                    _id: current.location.id,
                    status: false,
                })
            }
            else {
                setDataUpdate({
                    _id: current.id,
                    status: false,
                })
            }
        }
        // console.log(dataUpdate, "dataUpdate")
    }
    useEffect(() => {
        valueUpdate()
        // console.log(dataUpdate, "dataUpdate")
    }, [current])
    const allLocationData = async () =>{
        dispatch(getCompanyLocations({companyId: auth.user.companyId}))
        dispatch(getAllLocationsWithUsers({companyId: auth.user.companyId}))
    }
    const handleSubmit = async () => {
        dispatch(removeLocation(dataUpdate))
        openDeleteDropDown()
        setTimeout(() => 
            allLocationData()
        , 1000);
    }
    return (
        <>
        <div className={`modal-backdrop fade ${showDeleteDropDown ? 'show' : 'hide'}`}></div>
            <div className={`modal custom-modal fade ${showDeleteDropDown ? 'show' : 'hide'}`} id="delete_warn" role="dialog"> 
                {/* modal custom-modal fade */}
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-header">
                                <h3>Delete Location</h3>
                                <p>Are you sure want to delete?</p>
                            </div>
                            <div className="modal-btn delete-action">
                                <div className="row">
                                    <div className="col-6">
                                        {/* <a href="javascript:void(0);" className="btn btn-primary continue-btn">Delete</a> */}
                                        <a className="btn btn-primary continue-btn" onClick={handleSubmit}>Delete</a>
                                    </div>
                                    <div className="col-6">
                                        {/* <a href="javascript:void(0);" data-dismiss="modal" className="btn btn-primary cancel-btn">Cancel</a> */}
                                        <a data-dismiss="modal" className="btn btn-primary cancel-btn" onClick={() => openDeleteDropDown()}>Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeleteLocationModal