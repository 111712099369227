import React from "react";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SAdminSendMsgToUser from "../../components/superAdminCompoents/superAdminMessageCompoents/SAdminSendMsgToUser";
const SuperAdminComposetoUserPage = () => {
  return (
    <>
      {/* <SuperAdminHeader />
      <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="To All Users" />
            <SAdminSendMsgToUser />
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminComposetoUserPage;
