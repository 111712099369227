import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import DeleteTrainingModal from './DeleteTrainingModal'
import EditTrainingModal from './EditTrainingModal'
const TrainingTableRow = ({current, i, dropDownRow, setDropDownRow}) => {
    const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const [showDropDown, setshowDropDown] = useState(false)
    const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    const dispatch = useDispatch()
    // const openMoreVertDropDown = () => {
        // setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    // }
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    }
    const openDeleteDropDown = () => {
        setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
    }
    return (
        <>
            <tr key={i}>
                <td>{++i}</td>
                <td>{current.trainingName}</td>
                <td>{current.trainingType}</td>
                <td>
                    {/* <ul className="team-members">
                        <li>
                            <NavLink to="#" title="Bernardo Galaviz" data-toggle="tooltip"><img alt="" src="assetsAdmin/img/avatar-21.jpg" /></NavLink>
                        </li>
                        <li>
                            <NavLink to="#" title="Richard Miles" data-toggle="tooltip"><img alt="" src="assetsAdmin/img/avatar-21.jpg" /></NavLink>
                        </li>
                        <li className="dropdown avatar-dropdown">
                            <NavLink to="#" className="all-users dropdown-toggle" data-toggle="dropdown" aria-expanded="false">+15</NavLink>
                            <div className="dropdown-menu dropdown-menu-right">
                                <div className="avatar-group">
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                    <NavLink className="avatar avatar-xs" to="#">
                                        <img alt="" src="assetsAdmin/img/avatar-21.jpg" />
                                    </NavLink>
                                </div>
                            </div>
                        </li>
                    </ul> */}
                </td>
                <td>{current.startDate.substring(0, 10)} - {current.endDate.substring(0, 10)}</td>
                <td>{current.description.length <= 20 ? current.description : current.description.substring(0,20)+"..."}</td>
                <td>
                    {/* status ki kiya need hai jani? */}
                    {/* <NavLink className="btn btn-white btn-sm btn-rounded" to="#" data-toggle="dropdown" aria-expanded="false"> {current.status === true ? "Active" : "Non-Active"} <i className="fa fa-circle-o text-success"></i> </NavLink> */}
                    {
                        current.status === true ?
                        <NavLink className="btn btn-white btn-sm btn-rounded" to="#" data-toggle="dropdown" aria-expanded="false">&nbsp;&nbsp;&nbsp;&nbsp;Active&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-circle-o text-success"></i> </NavLink>
                        :
                        <NavLink className="btn btn-white btn-sm btn-rounded" to="#" data-toggle="dropdown" aria-expanded="false">Non-Active <i className="fa fa-circle-o text-danger"></i> </NavLink>
                    }
                </td>
                <td className="text-right miinl">
                    <div className="dropdown dropdown-action">
                        {/* <a className="dropdown-item" to="#" data-toggle="modal" data-target="#edit_plan"><i className="fa fa-map-marker m-r-5"></i></a> */}
                        <a className="dropdown-item" to="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></a>
                        <a className="dropdown-item" to="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></a>
                    </div>
                </td>
            </tr>
            <EditTrainingModal current={current} showDropDown={showDropDown} openDropDown={openDropDown}/>
            <DeleteTrainingModal current={current} showDeleteDropDown={showDeleteDropDown} openDeleteDropDown={openDeleteDropDown}/>
        </>
    )
}
export default TrainingTableRow