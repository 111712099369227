import React from 'react'
// import "../../assetsAdmin/css/bootstrap.min.css"
// import "../../assetsAdmin/css/bootstrap-datetimepicker.min.css"
// import "../../assetsAdmin/css/dataTables.bootstrap4.min.css"
// import "../../assetsAdmin/css/font-awesome.min.css"
// import "../../assetsAdmin/css/fullcalendar.min.css"
// import "../../assetsAdmin/css/jquery.circliful.css"
// import "../../assetsAdmin/css/line-awesome.min.css"
// import "../../assetsAdmin/css/morris.css"
// import "../../assetsAdmin/css/select2.min.css"
// import "../../assetsAdmin/css/style.css"
// import "../../assetsAdmin/css/tagsinput.css"
// import "./App.css"
import AdminHeader from '../../components/adminComponents/AdminHeader'
import AdminSideBar from '../../components/adminComponents/AdminSideBar'
import AdminTittle from '../../components/adminComponents/AdminTittle'
import AdminSSFilter from '../../components/adminComponents/adminSScheduling/AdminSSFilter'
import AdminSSchedulingViewBar from '../../components/adminComponents/adminSScheduling/AdminSSchedulingViewBar'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyLocations } from '../../actions/locationActions'
import { useEffect } from 'react'
import { getShiftAllUsers } from '../../actions/adminActions'
import { allUsersWithoutPaginate } from '../../actions/userActions'
import moment from 'moment'
// import { getBreakruleByCompanyId } from '../../actions/breakruleAction'

const AdminSSchedulingView = () => {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const locationData = async (e) => {
        dispatch(getCompanyLocations({companyId: auth.user.companyId}))
    }
    // const breakRulesData = async (e) => {
    //     console.log("heeelllooo testinggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg")
    //     dispatch(getBreakruleByCompanyId({companyId: auth.user.companyId}))
    // }
    const usersShiftsData = async () =>{
        dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().format('YYYY-MM-DD'), limit:10, page:1}))
    }
    const allUsersDataWithoutPagination = async () => {
        dispatch(allUsersWithoutPaginate({companyId: auth.user.companyId}))
    }
    
    useEffect(() => {
        locationData()
        usersShiftsData()
        allUsersDataWithoutPagination()
        // console.log("heeelllooo testinggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggggg")
        // breakRulesData()
    },[])
    return (
        <>
            {/* <AdminHeader />
            <AdminSideBar /> */}
            <div className="main-wrapper">
                <div className="page-wrapper">
                    <div className="content container-fluid">
                        <AdminTittle title="Shift &amp; Schedule" SScheduling/>
                        <AdminSSFilter />
                        <AdminSSchedulingViewBar />
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminSSchedulingView