import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import AdminUserRow from './AdminUserRow'
import { allUsersQuery, allUsers, getActiveUsers, getDisabledUsers, userById } from '../../../actions/userActions'
import { lastId } from '../../../actions/userActions'

const AdminUser = () => {
    const myState = useSelector((state => state.changeTheTab))
    const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const admin = useSelector((state) => state.admin)
    let val = 1
    let pageNumber = 1
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [limit, setLimit] = useState(10)
    const [ dropDownRow, setDropDownRow ] = useState(null)
    const dispatch = useDispatch()
    
    // const allUsersData = async () =>{
    //     dispatch(allUsers({companyId: auth.user.companyId}))
    // }
    const lastIdData = async () =>{
        dispatch(lastId({companyId: auth.user.companyId}))
    }
    const userTablee = async (e) => {
        dispatch(allUsersQuery({companyId: auth.user.companyId}, limit, currentPageNumber ))
    }
    useEffect(() => {
        // allUsersData()
        lastIdData()
        userTablee()
    },[])

    const handleLimit = async (event) =>{
        setLimit(
            parseInt(event.target.value)
        )
        dispatch(allUsersQuery({companyId: auth.user.companyId}, event.target.value, 1 ))
        setCurrentPageNumber(1)
    }
    const onClickNext = async () => {
        let i= currentPageNumber
        i++;
        if (i <= user.allUsers.totalPages){
            setCurrentPageNumber(currentPageNumber + 1)
            // console.log(currentPageNumber)
            dispatch(allUsersQuery({companyId: auth.user.companyId}, limit, currentPageNumber+1))
        }
    }
    const onClickPrev = async () => {
        let i= currentPageNumber
        i--;
        if (i >= 1){
            setCurrentPageNumber(currentPageNumber - 1)
            dispatch(allUsersQuery({ companyId: auth.user.companyId}, limit, currentPageNumber - 1 ))
        }
    }
    const handleChangeData = (pageNo) => {
        setCurrentPageNumber(
            pageNo
        )
        dispatch(allUsersQuery({companyId: auth.user.companyId}, limit, pageNo))
    }
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="dataTables_length" id="DataTables_Table_0_length">
                        <label>Show &nbsp;
                            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                            className="select" 
                            autoComplete='false'
                            onChange={handleLimit}>
                            {
                                [10,25,50,100].map((current, i) => {
                                    return(
                                        <option value={current}>{current}</option>
                                    )
                                })
                            }
                            </select> entries
                        </label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {/* <div className="table-responsive"> */}
                        <table className="table table-striped custom-table datatable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Company</th>
                                    <th>Joining Date</th>
                                    <th>Role</th>
                                    <th className="text-right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                user.allUsers.results &&
                                user.allUsers.results.length > 0 &&
                                user.allUsers.results.map((current, i) => {
                                    return(
                                    <AdminUserRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                                    )
                                })
                            }    
                            </tbody>
                        </table>
                    {/* </div> */}
                </div>
            </div>
            <div className="row">
                {
                    user.allUsers &&
                    <div className="col-sm-12 col-md-5">
                        <label> Showing {1 + (limit * (currentPageNumber-1))} to { user.allUsers.displayingResults + (limit * (currentPageNumber-1))} of { user.allUsers.totalResults } entries </label>
                    </div>
                }
                <div className="col-sm-12 col-md-7">
                    <div className="avatar-pagination">
                        <ul className="pagination" style={{ justifyContent: 'flex-end' }}>
                            <li className="page-item">
                                <NavLink className="page-link" to="#" aria-label="Previous" onClick={onClickPrev}>
                                <span aria-hidden="true">Previous</span>
                                <span className="sr-only">Previous</span>
                                </NavLink>
                            </li>
                            {
                                user.allUsers &&
                                user.allUsers.paginationArr &&
                                user.allUsers.paginationArr.length > 0 &&
                                user.allUsers.paginationArr.map((current, i) => {
                                    return(
                                        currentPageNumber === current ?
                                        <li className="page-item active" key={i}><a className="page-link" value={current}>{current}</a></li>
                                        :
                                        <li className="page-item" 
                                        key={i}>
                                        <a className="page-link" 
                                        value={current} onClick={ () => handleChangeData(current) }>{current}</a>
                                        </li>
                                    )
                                })
                            }
                        1    <li className="page-item">
                                <NavLink className="page-link" to="#" aria-label="Next" onClick={onClickNext}>
                                <span aria-hidden="true">Next</span>
                                <span className="sr-only">Next</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminUser