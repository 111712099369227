import moment from 'moment';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import EditModalProfileCover from './EditModalProfileCover'
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"
import { BUCKET_BASE_URL } from '../../../constants';
const ProfileCover = () => {
    // const { isFetching, isSuccess, isError, errorMessage, firstName, email } = useSelector(
    //     // userSelector
    //   )
    const user = useSelector((state) => state.user); 
    const [showDropDown, setshowDropDown] = useState(false)
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    }
    // console.log(showDropDown)
    return (
        <>
            <div className="card mb-0">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="profile-view">
                                <div className="profile-img-wrap">
                                    <div className="profile-img">
                                        <NavLink to="#">
                                        {
                                        user.userById &&
                                        user.userById.user &&
                                        user.userById.user.profilePic &&
                                        user.userById.user.profilePic.fileUrl ?
                                            <img alt="profilePic" src={BUCKET_BASE_URL + user.userById.user.profilePic.fileUrl} />
                                            :
                                            <img alt="profilePic" src={defaultImg} />
                                        }
                                        </NavLink>
                                    </div>
                                </div>
                                <div className="profile-basic">
                                {
                                    user.userById &&
                                    user.userById.user &&
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="profile-info-left">
                                                <h4 className="user-name m-t-0 mb-0"><strong>Full Name:</strong> {user.userById.user.firstName}, {user.userById.user.lastName}</h4>
                                                <div className="staff-id"><strong>Employee ID :</strong> {user.userById.user.employeeId} </div>
                                                <div className="staff-id"><strong>Wage:</strong> ${user.userById.user.hourlyWage} per hour </div>
                                                <div className="staff-id"><strong>Joining Date :</strong> {moment(user.userById.user.startingDate).format("Do MMMM YYYY")}</div>
                                                <div className="staff-msg"><NavLink className="btn btn-custom" to="/inbox">Send Message</NavLink></div>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <ul className="personal-info">
                                                <li>
                                                    <div className="title">Phone:</div>
                                                    <div className="text"><NavLink to="#"> {user.userById.user.phone} </NavLink> 
                                                    {/* <span className="text-success miphcn">  Confirmed <i className="fa fa-check-circle text-success"></i></span> */}
                                                    </div>
                                                </li>
                                                <li>    
                                                    <div className="title">Email:</div>
                                                    <div className="text"><NavLink to="#"><span> {user.userById.user.email} </span></NavLink> { user.userById.user.isEmailVerified === true ? <span className="text-success miphcn">  Confirmed <i className="fa fa-check-circle text-success"></i></span> : " "}</div>
                                                </li>
                                                <li>
                                                    <div className="title">Address:</div>
                                                    <div className="text">{user.userById.user.address}, {user.userById.user.state}, {user.userById.user.country}</div>
                                                </li>
                                                <li>
                                                    <div className="title">Positions:</div>
                                                    <div className="text milocpmn">
                                                        <div className="miprflps">
                                                            <NavLink to="#" className="avatar">
                                                                <div className="ylwclr"></div>
                                                            </NavLink>
                                                            <NavLink to=""> {user.userById.user.jobTitle} </NavLink>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                }
                                </div>
                                <div className="pro-edit">
                                    <NavLink to="#" className="edit-icon" data-toggle="modal" onClick={openDropDown} data-target="#profile_info"><i className="fa fa-pencil"></i></NavLink></div>
                                </div>
                            <EditModalProfileCover showDropDown={showDropDown} openDropDown={openDropDown} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProfileCover