import {
    LOCATION_REQUEST,
    LOCATION_SUCCESS,
    LOCATION_FAIL,
    LOCATION_CREATE_REQUEST,
    LOCATION_CREATE_SUCCESS,
    LOCATION_CREATE_FAIL,
    LOCATION_WITH_USERS_REQUEST,
    LOCATION_WITH_USERS_SUCCESS,
    LOCATION_WITH_USERS_FAIL,
    LOCATION_UPDATE_REQUEST,
    LOCATION_UPDATE_SUCCESS,
    LOCATION_UPDATE_FAIL,
    LOCATION_REMOVE_REQUEST,
    LOCATION_REMOVE_SUCCESS,
    LOCATION_REMOVE_FAIL,
    LOCATION_BY_ID_REQUEST,
    LOCATION_BY_ID_SUCCESS,
    LOCATION_BY_ID_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const locationReducer = (
    state = {
        location: {
            location: null,
            locationWithUsers: null,
            lat: null,
            lng: null,
            map: null,
            locationById: null,
        },
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                location: null,
                locationWithUsers: null,
                lat: null,
                lng: null,
                map: null,
                locationById: null,
            };

        // USER AUTH
        case LOCATION_REQUEST : 
            return {
                ...state,
            };
        case LOCATION_SUCCESS :
            return {
                ...state,
                location: action.payload,
            };
        case LOCATION_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case LOCATION_WITH_USERS_REQUEST : 
            return {
                ...state,
            };
        case LOCATION_WITH_USERS_SUCCESS :
            return {
                ...state,
                locationWithUsers: action.payload,
            };
        case LOCATION_WITH_USERS_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case LOCATION_CREATE_REQUEST : 
            return {
                ...state,
            };
        case LOCATION_CREATE_SUCCESS :
            return {
                ...state,
                location: [...state.location, action.payload],
                locationWithUsers: [...state.locationWithUsers, {location:action.payload}],
            };
        case LOCATION_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case LOCATION_UPDATE_REQUEST : 
            return {
                ...state,
            };
        case LOCATION_UPDATE_SUCCESS :
            return {
                ...state,
                location: {...state.location, results:state.location.results.map ((x) =>
                x.id === action.payload.id ? action.payload : x
                )},
                // locationWithUsers: [...state.locationWithUsers,{...state.locationWithUsers, location:action.payload}],
                locationWithUsers: state.locationWithUsers.map((x) =>
                x.location.id === action.payload.id ? {users: x.users, location : action.payload} : x
                ),
                // location: state.location.map((x) =>
                // x.location.id === action.payload.id ? action.payload : x
                // ),

                //         ,{...state.locationWithUsers, location:action.payload}],
                //         state.vendorStore.items.map((x) =>
                //     x._id == action.payload._id ? action.payload : x
                //   ),
            };
        case LOCATION_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        

        case LOCATION_REMOVE_REQUEST : 
            return {
                ...state,
            };
        case LOCATION_REMOVE_SUCCESS :
            return {
                ...state,
                // location: state.location.map ((x) =>
                // x.id === action.payload.id ? action.payload : x
                // ),
                // locationWithUsers: [...state.locationWithUsers,{...state.locationWithUsers, location:action.payload}],
                // locationWithUsers: state.locationWithUsers.map((x) =>
                // x.location.id === action.payload.id ? {users: x.users, location : action.payload} : x
                // ),
                // location: state.location.map((x) =>
                // x.location.id === action.payload.id ? action.payload : x
                // ),

                //         ,{...state.locationWithUsers, location:action.payload}],
                //         state.vendorStore.items.map((x) =>
                //     x._id == action.payload._id ? action.payload : x
                //   ),
            };
        case LOCATION_REMOVE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case LOCATION_BY_ID_REQUEST : 
            return {
                ...state,
                locationById: null,
            };
        case LOCATION_BY_ID_SUCCESS :
            return {
                ...state,
                locationById: action.payload,
            };
        case LOCATION_BY_ID_FAIL :
            return {
                ...state,
                error: action.payload,
            };


            //
        case "CLICK_LATLNG" :
            return {
                ...state,
                lat: action.lat,
                lng: action.lng,
            };
        case "CLICK_MAP" :
            return {
                ...state,
                map: action.map,
            };
    
        default:
            return state;
    }
}