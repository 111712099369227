import React, { forwardRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { tabReportShiftSearch } from '../../../actions/tabActions';
import { reportShiftSearch } from '../../../actions/adminActions';
// import { NavLink } from 'react-router-dom'

const SearchShiftReport = () => {
    const auth = useSelector((state) => state.auth);
    const locationTemp = useSelector((state) => state.location);
    let location = locationTemp.location
    const dispatch = useDispatch()
    const [values, setValues] = useState({
        name: '',
        location: ''
    })
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
                event.target.value
        })
    }
    const [fromDate, setFromDate] = useState(new Date(moment().format('YYYY-MM-DD')));
    const [toDate, setToDate] = useState(new Date(moment().format('YYYY-MM-DD')));
    const mxDt = new Date(fromDate);
    const From = forwardRef(({ value, onClick }, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon" >
                <input className="form-control floating datetimepicker" type="text" value={value} />
            </div>
            <label className="focus-label">From</label>
        </div>
    ));
    const To = forwardRef(({ value, onClick }, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon" >
                <input className="form-control floating datetimepicker" type="text" value={value} />
            </div>
            <label className="focus-label">To</label>
        </div>
    ));
    let searchData;
    // if (values.location !== ''){
    searchData = {
        companyId: auth.user.companyId,
        name: values.name,
        date: moment().format('YYYY-MM-DD'),
        location: values.location,
        from: fromDate.toISOString().substring(0, 10),
        to: toDate.toISOString().substring(0, 10)
    }
    // }
    // else {
    //     searchData = {
    //         companyId: auth.user.companyId,
    //         name: values.name,
    //         date: moment().format('YYYY-MM-DD'),
    //         from: fromDate.toISOString(),
    //         to: toDate.toISOString()
    //     }
    // }

    // console.log(toDate.toISOString(), moment(toDate.toISOString().substring(0, 10)).format(), moment().format(), "toDate.toISOString()")
    const handleClick = async (event) => {
        event.preventDefault()
        // if (searchData.location === ''){
        //     delete searchData.location
        // }
        // if (searchData.from === ''){
        //     delete searchData.from
        // }
        // if (searchData.to === ''){
        //     delete searchData.to
        // }
        for (let key of Object.keys(searchData)) {
            if(searchData[key] === '' ){
                delete searchData[key]
            }
            // console.log(key,"54121421521", searchData[key])
        }
        // dispatch(tabSearch())
        // console.log("first")
        dispatch(tabReportShiftSearch())
        dispatch(reportShiftSearch(searchData))
        // console.log("first2")
    }
    return (
        <>
            <div className="row filter-row">
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus select-focus">
                        <input type="text" className="form-control floating" value={values.name} onChange={handleChange('name')}/>
                        <label className="focus-label">Employee</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus select-focus">
                    <select className="form-select" style={{ height: "50px", fontSize: "13px", paddingBottom: "0.01rem" }}
                        type="text"
                        autoComplete="false" value={values.location}
                        onChange={handleChange('location')}>
                            <option value=''>All Locations</option>
                            {
                                location &&
                                location.length > 0 &&
                                location.map((current, i) => {
                                    return (
                                        <option value={current.id} key={i+1}>{current.name}</option>
                                    )
                                })
                            }
                        </select>
                        <label className="focus-label">Location</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-2">
                    <div className="form-group form-focus select-focus">
                        <DatePicker
                            selected={fromDate}
                            onChange={(date) => {
                                setFromDate(date)
                                setToDate(date)
                            }}
                            customInput={<From />}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-md-2">
                    <div className="form-group form-focus select-focus">
                        <DatePicker
                            selected={toDate}
                            minDate={fromDate}
                            maxDate={mxDt.setDate(fromDate.getDate()+6)}
                            onChange={(date) => setToDate(date)}
                            customInput={<To />}
                        />
                        {/* <div className="form-group form-focus focused">
                            <div className="cal-icon">
                                <input className="form-control floating datetimepicker" type="text" />
                            </div>
                            <label className="focus-label">To</label>
                        </div> */}
                    </div>
                </div>
                <div className="col-sm-6 col-md-2">
                    <a onClick={handleClick} to="#" className="btn btn-success btn-block"> Search </a>
                </div>
            </div>
        </>
    )
}
export default SearchShiftReport