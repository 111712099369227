import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getOvertimeShiftByUser } from '../../../actions/overtimeActions'
import moment from 'moment'
// import ModalEditOvertime from './ModalEditOvertime'

const OvertimeTable = () => {
    // const [showDropDown, setshowDropDown] = useState(false)
    // const openDropDown = () => {
    //     setshowDropDown((showDropDown) => !showDropDown)
    // }
    const auth = useSelector((state) => state.auth);
    const overtime = useSelector((state) => state.overtime);
    // const overtimeTable = useSelector((state) => state.overtimeTable);
    const dispatch = useDispatch()
    const overtimeTableData = async (e) => {
        dispatch(getOvertimeShiftByUser({userId: auth.user.id}))
    }
    useEffect(() => {
       overtimeTableData()
    },[])
    let val=0;
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    {/* <div className="table-responsive"> */}
                        <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>OT Date</th>
                                    <th className="text-center">OT Hours</th>
                                    <th>OT Type</th>
                                    <th>Description</th>
                                    <th className="text-center">Status</th>
                                    <th>Approved by</th>
                                    <th className="text-right"></th>
                                </tr>
                            </thead>
                            <tbody>
                            { 
                            overtime.overtimeTable &&
                            overtime.overtimeTable.length > 0 &&
                            overtime.overtimeTable.map((current, i) => {
                                return (
                                    <tr>
                                        <td>{++val}</td>
                                        <td>
                                            <h2 className="table-avatar blue-link">
                                                <NavLink to="/profile" className="avatar"><img alt="" src="assets/img/avatar-02.jpg" /></NavLink>
                                                <NavLink to="/profile">{auth.user.firstName} {auth.user.lastName}</NavLink>
                                            </h2>
                                        </td>
                                        <td>{moment(current.date).format("DD-MMMM-YYYY")}</td>
                                        <td className="text-center">{current.hours}</td>
                                        <td>{current.overtimeType.name}</td>
                                        <td>{current.description}</td>
                                        <td className="text-center">
                                            <div className="action-label">
                                                <NavLink className="btn btn-white btn-sm btn-rounded" to="#">
                                                    <i className="fa fa-dot-circle-o text-purple"></i> {current.status}
                                                </NavLink>
                                            </div>
                                        </td>
                                        <td>
                                            <h2 className="table-avatar">
                                                {/* <NavLink to="/profile" className="avatar avatar-xs"><img src="assets/img/avatar-09.jpg" alt="" /></NavLink> */}
                                                <NavLink to="#">-</NavLink>
                                            </h2>
                                        </td>
                                        <td className="text-right">
                                        {/* <div className="dropdown dropdown-action"> */}
                                        {/* <i className="fa fa-pencil m-r-5" onClick={openDropDown}> </i> */}
                                        {/* </div>  */}
                                        </td>
                                        {/* <ModalEditOvertime showDropDown={showDropDown} openDropDown={openDropDown}/> */}
                                    </tr>
                                )
                            })}
                            </tbody>
                            </table>
                    {/* </div> */}
                </div>
            </div>
        </>
    )
}
export default OvertimeTable