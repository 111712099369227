import React, { forwardRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addTraining } from '../../../actions/trainingActions'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'

const AddNewTrainingModal = ({ showDropDown, openDropDown }) => {
    const auth = useSelector((state) => state.auth)
    const training = useSelector((state) => state.training)
    const user = useSelector((state) => state.user)
    const [ fromDate, setFromDate ] = useState(new Date(moment().format('YYYY-MM-DD')))
    const [ toDate, setToDate ] = useState(new Date(moment().format('YYYY-MM-DD')))
    // console.log(fromDate)
    // console.log(toDate)
    const From = forwardRef(({ value, onClick }, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon">
                <input className="form-control floating datatimepicker" type="text" value={value}/>
            </div>
            <label className="focus-label">From</label>
        </div>
    ))
    const To = forwardRef(({ value, onClick}, ref) => (
        <div className="form-group form-focus focused" onClick={onClick} ref={ref}>
            <div className="cal-icon">
                <input className="form-control floating datatimepicker" type="text" value={value}/>
            </div>
            <label className="focus-label">To</label>
        </div>
    ))
    const [ values, setValues ] = useState({
        trainingName: '',
        trainingType: '',
        attachedFiles: '',
        startDate: '',
        endDate: '',
        description: '',
        companyId: '',
        status: ''
    })
    const handleChange = (fieldName) => (event) => {
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    // console.log(values)
    const dispatch = useDispatch()
    const trainingData = {
        companyId: auth.user.companyId,
        assignUserId: auth.user.id,
        trainingName: values.trainingName,
        trainingType: values.trainingType,
        attachedFiles: values.attachedFiles,
        startDate: fromDate.toISOString(),
        endDate: toDate.toISOString(),
        description: values.description,
        status: values.status === "true" ? true : false ,
    }   
    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        dispatch(addTraining(trainingData))
        openDropDown()
    }
    return (
        <>
        <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
            <div id="add_training" className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add New Training</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Training Name <span className="text-danger">*</span></label>
                                            <input className="form-control" type="text" 
                                            autoComplete="false" value={values.trainingName}
                                            onChange={handleChange('trainingName')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Training Type</label>
                                            <input className="form-control" type="text" 
                                            autoComplete="false" value={values.trainingType}
                                            onChange={handleChange('trainingType')}
                                            />
                                        </div>
                                    {/* <br />
                                    {
                                        values.trainingType === "" ?
                                        <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                                        autoComplete="false" value={values.trainingType = "Node Training"}
                                        onChange={handleChange('trainingType')} >
                                            <option >Node Training</option>
                                            <option >Swift Training</option>
                                        </select>
                                        :
                                        <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                                        autoComplete="false" value={values.trainingType}
                                        onChange={handleChange('trainingType')} >
                                            <option >Node Training</option>
                                            <option >Swift Training</option>
                                        </select>
                                            }
                                        </div> */}
                                    </div>
                                    
                                    {/* YAHAN KA CODE NEECHA BHJ DIYA HAI JO PEHLY LIKHA THA THAKAY ACHA LAGA  */}
                                    {/* ********************************************************************** */}
                                    
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Start Date <span className="text-danger">*</span></label>
                                        {/* <div className="cal-icon">
                                                <input className="form-control datetimepicker" type="text"
                                                autoComplete="false" value={values.startDate}
                                                onChange={handleChange('startDate')}
                                                />
                                            </div> */}
                                            <DatePicker selected={fromDate} onChange={(date) => setFromDate(date)} customInput={<From />}/>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>End Date <span className="text-danger">*</span></label>
                                            <DatePicker selected={toDate} onChange={(date) => setToDate(date)} customInput={<To />}/>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Attach Files <span className="text-danger">*</span></label>
                                            <input className="form-control" type="file" 
                                            autoComplete="false" value={values.attachedFiles}
                                            onChange={handleChange('attachedFiles')}
                                            style={{width: "440px"}}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Description <span className="text-danger">*</span></label>
                                            <textarea className="form-control" rows="4"
                                            autoComplete="false" value={values.description}
                                            onChange={handleChange('description')}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="col-form-label">Status</label>
                                            {
                                            values.status === "" ?
                                            <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                                            autoComplete="false" value={values.status = "true"}
                                            onChange={handleChange('status')}>    
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </select>
                                            :
                                            <select className="form-select" style={{ height: "50px", fontSize: "14px" }}
                                            autoComplete="false" value={values.status}
                                            onChange={handleChange('status')}>    
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </select>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn" type="submit" >Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AddNewTrainingModal
        
{/* <div className="col-sm-6">
    <div className="form-group">
        <label className="col-form-label">Attach Files <span className="text-danger">*</span></label>
        <input className="form-control" type="file" 
        autoComplete="false" value={values.attachedFiles}
        onChange={handleChange('attachedFiles')}
        />
    </div>
</div> */}