import React, { useEffect, useState } from 'react'
import AdminEmployeeSideMenu from './AdminEmployeeSideMenu'
import { useDispatch, useSelector } from 'react-redux'
// import { allUsers, allUsersQuery, getActiveUsers, getDisabledUsers, userById } from '../../../actions/userActions'
import { allUsers, allUsersQuery, getActiveUsers, getDisabledUsers } from '../../../actions/userActions'
import AdminEmployeeTableRow from './AdminEmployeeTableRow'
import { lastId } from '../../../actions/userActions'
// import {NavLink} from 'react-router-dom'
// import EditModaleditEmployee from './EditModaleditEmployee'
// import { tabAllStaff } from '../../../actions/tabActions'
// import { tabActive } from '../../../actions/tabActions'
// import { tabNonActive } from '../../../actions/tabActions'

const AdminEmployeeTable = () => {

    const myState = useSelector((state => state.changeTheTab))
    // console.log("***********", myState)
    // const active = async (e) => {
    //     dispatch(getActiveUsers({companyId: auth.user.companyId}, 1))
    // }
    // const allUsersData = async (e) => {
    //     dispatch(allUsers({companyId: auth.user.companyId}))
    // }
    // const nonActive = async (e) => {
    //     dispatch(getDisabledUsers({companyId: auth.user.companyId}, 1))
    // }
    // useEffect(()=>{
    //     allUsersData()
    //     active()
    //     nonActive()
    //  }, [])

    const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const admin = useSelector((state) => state.admin)
    // let val = 1
    // let pageNumber = 1
    // const [showDropDown, setshowDropDown] = useState(false)
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [limit, setLimit] = useState(10)
    // const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false)
    const dispatch = useDispatch()
    const onClickNext = async () => {
        let i= currentPageNumber
        i++;
        if (i <= user.allUsers.totalPages){
            setCurrentPageNumber(currentPageNumber + 1)
            // console.log(currentPageNumber)
            dispatch(allUsersQuery({companyId: auth.user.companyId}, 10, currentPageNumber+1))
        }
    //     else{
    //     setCurrentPageNumber(currentPageNumber)
    // }
    }
    // console.log(currentPageNumber)
    const onClickPrev = async () => {
        let i= currentPageNumber
        i--;
        if (i >= 1){
            setCurrentPageNumber(currentPageNumber - 1)
            dispatch(allUsersQuery({companyId: auth.user.companyId}, 10, currentPageNumber-1))
        }
    }
    const handleChangeData = async (pageNo) => {
        setCurrentPageNumber(
            pageNo
        )
        dispatch(allUsersQuery({companyId: auth.user.companyId}, 10, pageNo))
    }
    const onClickNextActive = async () => {
        let i= currentPageNumber
        i++;
        if (i <= user.allUsers.totalPages){
            setCurrentPageNumber(currentPageNumber + 1)
            // console.log(currentPageNumber)
            dispatch(getActiveUsers({companyId: auth.user.companyId}, currentPageNumber+1))
        }
    }
    const onClickPrevActive = async () => {
        let i= currentPageNumber
        i--;
        if (i >= 1){
            setCurrentPageNumber(currentPageNumber - 1)
            dispatch(getActiveUsers({companyId: auth.user.companyId}, currentPageNumber-1))
        }
    }
    const handleChangeDataActive = async (pageNo) => {
        setCurrentPageNumber(
            pageNo
        )
        dispatch(getActiveUsers({companyId: auth.user.companyId}, pageNo))
    }
    const onClickNextInActive = async () => {
        let i= currentPageNumber
        i++;
        if (i <= user.allUsers.totalPages){
            setCurrentPageNumber(currentPageNumber + 1)
            // console.log(currentPageNumber)
            dispatch(getDisabledUsers({companyId: auth.user.companyId}, currentPageNumber+1))
        }
    }
    const onClickPrevInActive = async () => {
        let i= currentPageNumber
        i--;
        if (i >= 1){
            setCurrentPageNumber(currentPageNumber - 1)
            dispatch(getDisabledUsers({companyId: auth.user.companyId}, currentPageNumber-1))
        }
    }
    const handleChangeDataInActive = async (pageNo) => {
        setCurrentPageNumber(
            pageNo
        )
        dispatch(getDisabledUsers({companyId: auth.user.companyId}, pageNo))
    }
    // const openMoreVertDropDown = () => {
    //     setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown)
    // }
    const allUsersData = async () =>{
        dispatch(allUsers({companyId: auth.user.companyId}))
    }
    const lastIdData = async () =>{
        dispatch(lastId({companyId: auth.user.companyId}))
    }
    useEffect(() => {
        allUsersData()
        lastIdData()
    },[])
    // const openDropDown = () => {
    //     setshowDropDown((showDropDown) => !showDropDown)
    // }
    // const onClickUser = async (id) => {
    //     dispatch(userById({userId: id}))
    // }
    // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    // const openDeleteDropDown = () => {
    //     setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
    // }
    // console.log(showDeleteDropDown)
    // console.log(showDropDown)
    const [ dropDownRow, setDropDownRow ] = useState(null)
    return (
        <>
            <div className="row"> </div>
            <AdminEmployeeSideMenu />
            <br />
            <br />
                <div className="col-md-12">
                    {/* <div className="table-responsive mitbhdr"> */}
                        <table className="table table-striped custom-table datatable">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Employee ID</th>
                                    <th>Email</th>
                                    <th>Mobile</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                    <th className="text-right no-sort">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {   
                                    myState.viewAllEmployeeSideMenu === "allstaff" ?
                                    user.allUsers &&
                                    user.allUsers.results &&
                                    user.allUsers.results.length > 0 &&
                                    user.allUsers.results.map((current, i) => {
                                        return(
                                            <AdminEmployeeTableRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                                        )
                                    })
                                    :
                                    myState.viewAllEmployeeSideMenu === "active" ?
                                    user.active &&
                                    user.active.results &&
                                    user.active.results.length > 0 &&
                                    user.active.results.map((current, i) => {
                                        return(
                                            <AdminEmployeeTableRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                                            // <tr key={i}>
                                            //     <td>
                                            //         <h2 className="table-avatar">
                                            //             <NavLink to={`/profile/${current.id}`} className="avatar"><img alt="" src="assetsAdmin/img/profiles/avatar-02.jpg" /></NavLink>
                                            //             <NavLink to={`/profile/${current.id}`}>{current.firstName + " " + current.lastName}</NavLink>
                                            //         </h2>
                                            //     </td>
                                            //     <td>{current.employeeId}</td>
                                            //     <td>{current.email}</td>
                                            //     <td>{current.phone}</td>
                                            //     <td>{current.jobTitle}</td>
                                            //     {current.disable === false ?
                                            //     <td className="text-success"> Active</td>
                                            //     :
                                            //     <td className="text" style={{color : "red"}}>Non-Active</td>
                                            //     }
                                            //         <td className="text-right">
                                            //         <div className="dropdown dropdown-action">
                                            //             <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={openMoreVertDropDown} aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                            //                 <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                                            //                 <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_employee"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                                            //                 <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_employee"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                            //                 <EditModaleditEmployee showDropDown={showDropDown} openDropDown={openDropDown} />
                                            //             </div>
                                            //         </div>
                                            //     </td>
                                            // </tr>
                                        )
                                    })
                                    :
                                    myState.viewAllEmployeeSideMenu === "nonactive" ?
                                    user.nonActive &&
                                    user.nonActive.results &&
                                    user.nonActive.results.length > 0 &&
                                    user.nonActive.results.map((current, i) => {
                                        return(
                                            <AdminEmployeeTableRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                                            // <tr key={i}>
                                            //     <td>
                                            //         <h2 className="table-avatar">
                                            //             <NavLink to={`/profile/${current.id}`} className="avatar"><img alt="" src="assetsAdmin/img/profiles/avatar-02.jpg" /></NavLink>
                                            //             <NavLink to={`/profile/${current.id}`}>{current.firstName + " " + current.lastName}</NavLink>
                                            //         </h2>
                                            //     </td>
                                            //     <td>{current.employeeId}</td>
                                            //     <td>{current.email}</td>
                                            //     <td>{current.phone}</td>
                                            //     <td>{current.jobTitle}</td>
                                            //     {current.disable === false ?
                                            //     <td className="text-success"> Active</td>
                                            //     :
                                            //     <td className="text" style={{color : "red"}}>Non-Active</td>
                                            //     }
                                            //         <td className="text-right">
                                            //         <div className="dropdown dropdown-action">
                                            //             <a href="#" className="action-icon dropdown-toggle" data-toggle="dropdown" onClick={openMoreVertDropDown} aria-expanded="false"><i className="material-icons">more_vert</i></a>
                                            //                 <div className={`dropdown-menu dropdown-menu-right ${showMoreVertDropDown ? 'show' : 'hide'}`}>
                                            //                 <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_employee"><i className="fa fa-pencil m-r-5"></i> Edit</a>
                                            //                 <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_employee"><i className="fa fa-trash-o m-r-5"></i> Delete</a>
                                            //                 <EditModaleditEmployee showDropDown={showDropDown} openDropDown={openDropDown} />
                                            //             </div>
                                            //         </div>
                                            //     </td>
                                            // </tr>
                                        )
                                    })
                                    :
                                    myState.viewAllEmployeeSideMenu === "search" ?
                                    admin.searchEmployee &&
                                    admin.searchEmployee.length > 0 &&
                                    admin.searchEmployee.map((current, i) => {
                                        return(
                                            <AdminEmployeeTableRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                                        )
                                    })
                                    :
                                    <></>
                                    }
                            </tbody>
                        </table>
                        <div className="row">
                            <div className="col-sm-12 col-md-5">
                                {   
                                    myState.viewAllEmployeeSideMenu !== "search" &&
                                    myState.viewAllEmployeeSideMenu === "allstaff" ?
                                    user.allUsers &&
                                    <label> Showing {1 + (limit * (currentPageNumber-1))} to {user.allUsers.displayingResults + (limit * (currentPageNumber-1))} of {user.allUsers.totalResults} entries </label>
                                    :
                                    myState.viewAllEmployeeSideMenu === "active" ?
                                    user.active &&
                                    <label> Showing {1 + (limit * (currentPageNumber-1))} to {user.active.displayingResults + (limit * (currentPageNumber-1))} of {user.active.totalResults} entries </label>
                                    :
                                    myState.viewAllEmployeeSideMenu === "nonactive" ?
                                    user.nonActive &&
                                    <label> Showing {1 + (limit * (currentPageNumber-1))} to {user.nonActive.displayingResults + (limit * (currentPageNumber-1))} of {user.nonActive.totalResults} entries </label>
                                    :
                                    <></>
                                }
                            </div>
                            { 
                            myState.viewAllEmployeeSideMenu !== "search" &&
                            <div className="col-sm-12 col-md-7">
                                <div className="avatar-pagination">
                                    <ul className="pagination" style={{justifyContent: 'flex-end'}}>
                                        <li className="page-item">
                                            {
                                                myState.viewAllEmployeeSideMenu === "allstaff" ?
                                                <a className="page-link" aria-label="Previous" onClick={onClickPrev} style={{cursor: "pointer"}}>
                                                    <span aria-hidden="true">Previous</span>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                                :
                                                myState.viewAllEmployeeSideMenu === "active" ?
                                                <a className="page-link" aria-label="Previous" onClick={onClickPrevActive} style={{cursor: "pointer"}}>
                                                    <span aria-hidden="true">Previous</span>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                                :
                                                myState.viewAllEmployeeSideMenu === "nonactive" ?
                                                <a className="page-link" aria-label="Previous" onClick={onClickPrevInActive} style={{cursor: "pointer"}}>
                                                    <span aria-hidden="true">Previous</span>
                                                    <span className="sr-only">Previous</span>
                                                </a>
                                                :
                                                <></>
                                            }
                                        </li>
                                        {   
                                            myState.viewAllEmployeeSideMenu === "allstaff" ?
                                            user.allUsers &&
                                            user.allUsers.paginationArr &&
                                            user.allUsers.paginationArr.length > 0 &&
                                            user.allUsers.paginationArr.map((current, i) => {
                                                return(
                                                    // active for highlight
                                                    currentPageNumber === current ?
                                                    <li className="page-item active" key={i}><a className="page-link" value={current}>{current}</a></li>
                                                    :
                                                    <li className="page-item" key={i}><a className="page-link" 
                                                    value={current} onClick={()=>handleChangeData(current)}
                                                    // value={current}
                                                    // onChange={handleChangeData)}
                                                    >{current}</a></li>
                                                )
                                            })
                                            :
                                            myState.viewAllEmployeeSideMenu === "active" ?
                                            user.active &&
                                            user.active.paginationArr &&
                                            user.active.paginationArr.length > 0 &&
                                            user.active.paginationArr.map((current, i) => {
                                                return(
                                                    // active for highlight
                                                    currentPageNumber === current ?
                                                    <li className="page-item active" key={i}><a className="page-link" value={current}>{current}</a></li>
                                                    :
                                                    <li className="page-item" key={i}><a className="page-link" 
                                                    value={current} onClick={()=>handleChangeDataActive(current)}
                                                    // value={current}
                                                    // onChange={handleChangeData)}
                                                    >{current}</a></li>
                                                )
                                            })
                                            :
                                            myState.viewAllEmployeeSideMenu === "nonactive" ?
                                            user.nonActive &&
                                            user.nonActive.paginationArr &&
                                            user.nonActive.paginationArr.length > 0 &&
                                            user.nonActive.paginationArr.map((current, i) => {
                                                return(
                                                    // active for highlight
                                                    currentPageNumber === current ?
                                                    <li className="page-item active" key={i}><a className="page-link" value={current}>{current}</a></li>
                                                    :
                                                    <li className="page-item" key={i}><a className="page-link" 
                                                    value={current} onClick={()=>handleChangeDataInActive(current)}
                                                    // value={current}
                                                    // onChange={handleChangeData)}
                                                    >{current}</a></li>
                                                )
                                            })
                                            :
                                            <></>
                                        }
                                        <li className="page-item">
                                            {
                                                myState.viewAllEmployeeSideMenu === "allstaff" ?
                                                <a className="page-link" aria-label="Next" onClick={onClickNext} style={{cursor: "pointer"}}>
                                                    <span aria-hidden="true">Next</span>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                                :
                                                myState.viewAllEmployeeSideMenu === "active" ?
                                                <a className="page-link" aria-label="Next" onClick={onClickNextActive} style={{cursor: "pointer"}}>
                                                    <span aria-hidden="true">Next</span>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                                :
                                                myState.viewAllEmployeeSideMenu === "nonactive" ?
                                                <a className="page-link" aria-label="Next" onClick={onClickNextInActive} style={{cursor: "pointer"}}>
                                                    <span aria-hidden="true">Next</span>
                                                    <span className="sr-only">Next</span>
                                                </a>
                                                :
                                                <></>
                                            }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            }
                        </div>
                    {/* </div> */}
                </div>
            {/* </div> */}
        </>
    )
}
export default AdminEmployeeTable