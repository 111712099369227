import React from 'react'
// import avatar2 from "../../assets/img/avatar-02.jpg"
import EmployeeHeader from '../../components/employeeComponents/EmployeeHeader'
import EmployeeSidebar from '../../components/employeeComponents/EmployeeSidebar'
import EmployeeMain from '../../components/employeeComponents/employeeHome/EmployeeMain'
import { getMailByUserId } from '../../actions/mailActions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const EmployeeHome = () => {
   const auth = useSelector((state) => state.auth)
   const dispatch = useDispatch()
   const mailData = async () => {
      dispatch(getMailByUserId({userId: auth.user.id}))
   }
   useEffect(() => {
      mailData()
   },[])
   return (
      <>
      {/* <EmployeeHeader />
      <EmployeeSidebar /> */}
      <EmployeeMain />
      </>
   )
}
export default EmployeeHome