import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import EditModalAttendanceInfo from './EditModalAttendanceInfo'
import defaultImg from "../../../assetsAdmin/img/avatarProfile.png"
import { BUCKET_BASE_URL } from '../../../constants'

const SearchAdminEmpAttendanceSheet = () => {
    const [showDropDown, setshowDropDown] = useState(false)
    const changeTheTab = useSelector((state => state.changeTheTab))
    const openDropDown = (dd) => {
        setshowDropDown((showDropDown) => !showDropDown)
        setDayData(dd)
    }
    const clock = useSelector((state) => state.clock);
    // let arr = []
    // let arr2 = []
    // let temp;
    // const month = parseInt(moment().daysInMonth())
    // const today = parseInt(moment().format('DD'))
    // for (let i=1; i<=month; i++){
    //     arr.push(i)
    // }
    // for (let i=1; i<=today; i++){
    //     arr2.push(i)
    // }
    let date = moment(changeTheTab.monthYearCheck.year+"-"+changeTheTab.monthYearCheck.month+"-01")
    let endOfMonth = date.endOf('month').format('DD')
    // let startOfMonth = date.startOf('month').format('DD')
    let datesArrLength = []
    for (let i=0; i<parseInt(endOfMonth); i++){
        datesArrLength.push(i)
    }
    // console.log(datesArrLength)
    const [dayData, setDayData] = useState()
    return (
        <>  
            <div className="row">
                <div className="col-lg-12" >
                    <div className="table-responsive">
                        <table className="table table-striped custom-table table-nowrap mb-0" >
                            <thead>
                                <tr>
                                    <th>Employee</th>
                                    {/* {
                                        clock.searchData &&
                                        clock.searchData.length > 0 &&
                                        clock.searchData[0].attendanceHistory &&
                                        clock.searchData[0].attendanceHistory.length > 0 &&
                                        clock.searchData[0].attendanceHistory.map((current,i) => {
                                            return(
                                                <th key={i}>{++i}</th>
                                            )
                                        })
                                    } */}
                                    {
                                        datesArrLength.length > 0 &&
                                        datesArrLength.map((current,i) =>{
                                            return(
                                                <th key={i}>{date.startOf('month').add(i, 'days').format('DD')}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                clock.searchData &&
                                clock.searchData.length > 0 &&
                                clock.searchData.map((current,i) =>{
                                    return(
                                            <tr key={i}>
                                                <td>
                                                    <h2 className="table-avatar">
                                                        <NavLink to={`/profile/${current.user.id}`}  className="avatar avatar-xs">
                                                            <img alt="" src="assetsAdmin/img/avatar-09.jpg" />
                                                            {
                                                                current.user.profilePic &&
                                                                current.user.profilePic.fileUrl ?
                                                                    <img alt="profilePic" src={BUCKET_BASE_URL + current.user.profilePic.fileUrl} style={{objectFit:"fill", height:"100%"}}/>
                                                                    :
                                                                    <img alt="profilePic" src={defaultImg} />
                                                            }
                                                        </NavLink>
                                                        <NavLink to={`/profile/${current.user.id}`} >{current.user.firstName + " " + current.user.lastName}</NavLink>
                                                    </h2>
                                                </td>
                                                {
                                                    parseInt(changeTheTab.monthYearCheck.month) === parseInt(moment().format('MM')) &&
                                                    parseInt(changeTheTab.monthYearCheck.year) === parseInt(moment().format('YYYY')) ?
                                                        current.attendanceHistory &&
                                                        current.attendanceHistory.length > 0 &&
                                                        current.attendanceHistory.map((c,ii) => {
                                                            return(
                                                                ii < (parseInt(moment().format('DD'))-1)
                                                                ?
                                                                    (Object.keys(c).length !== 0) ?
                                                                    <td><NavLink to="#" data-toggle="modal" onClick={()=>openDropDown(c)} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                                    :
                                                                    <td><i className="fa fa-close text-danger"></i> </td>
                                                                :
                                                                <td ></td>
                                                            )
                                                        })
                                                        :
                                                        current.attendanceHistory &&
                                                        current.attendanceHistory.length > 0 &&
                                                        current.attendanceHistory.map((c,ii) => {
                                                            return(
                                                                (Object.keys(c).length !== 0) ?
                                                                <td><NavLink to="#" data-toggle="modal" onClick={()=>openDropDown(c)} data-target="#attendance_info"><i className="fa fa-check text-success"></i></NavLink></td>
                                                                :
                                                                <td><i className="fa fa-close text-danger"></i> </td>
                                                            )
                                                        })
                                                    
                                                }
                                            </tr>
                                        )
                                    })
                            }
                            </tbody>
                        </table>
                        <EditModalAttendanceInfo dayData={dayData} showDropDown={showDropDown} openDropDown={openDropDown}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SearchAdminEmpAttendanceSheet