import React from 'react'
import moment from 'moment'
const SAdminFileManagerModalViewDetail = ({ showViewDetail, openViewDetail, current}) => {
  return (
    <>
      <div className={`modal-backdrop fade ${showViewDetail ? 'show' : 'hide'}`}></div>
      <div className={`modal custom-modal fade ${showViewDetail ? 'show' : 'hide'}`} id="delete_warn" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">File Details</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openViewDetail()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>File Name</label>
                  <input className="form-control" type="text" disabled
                    value={current.fileName} />
                </div>
                <div className="form-group">
                  <label>File Size</label>
                  <input className="form-control" type="text" disabled
                    value={current.size} />
                </div>
                <div className="form-group">
                  <label>Created On</label>
                  <input className="form-control" type="text" disabled
                    value={moment(current.uploadDate).format('DD MMM h:mm a')} />
                </div>
                <div className="form-group">
                  <label>Created By</label>
                  <input className="form-control" type="text" disabled
                    value={current.userId.firstName + " " + current.userId.lastName} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default SAdminFileManagerModalViewDetail