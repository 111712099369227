import React from "react";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
// import SAdminFileManager from '../../components/superAdminCompoents/superAdminFileManagerAdministrator/SAdminFileManager'
import SAdminFileManagerMain from "../../components/superAdminCompoents/superAdminFileManagerAdministrator/SAdminFileManagerMain";
const SuperAdminFileManagerPage = () => {
  return (
    <>
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="File Manager" />
            {/* <SAdminFileManager /> */}
            <SAdminFileManagerMain />
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminFileManagerPage;
