import React, { useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateFile } from '../../../actions/fileManagerActions';

const RenameFileModal = ({ showDropDown, openDropDown, current}) => {
  const auth = useSelector((state) => state.auth);
  const [ values, setValues ] = useState({
    id: '',
    fileName: '',
  })
  const handleChange = (fieldName) => (event) =>{
    setValues({
      ...values, [fieldName]:
      event.target.value
    })
  }
  const [ fileOldName, setFileOldName ] = useState()
  const currentDeduction = async () => {
    try {
      setValues({
        id: '',
        name: '',
        amount: '',
      })
      setValues({
        id: current.id,
        fileName: current.fileName.split(".")[0],
      })
      setFileOldName(current.fileName.split(".")[1])
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    currentDeduction()
  }, [])
  const dispatch = useDispatch()
  const updateFileData = {
    _id: values.id,
    fileName: values.fileName+"."+fileOldName,
    identifier: auth.user.companyId+values.fileName+"."+fileOldName,
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    if(values.fileName !== ''){
      dispatch(updateFile(updateFileData))
    }
    openDropDown()
  }
//    console.log(values, "akkhein teriii ")
  return (
    <>
      <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
      <div className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} id="edit_deduction" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Rename File</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row"></div>
                <div className="form-group">
                  <label>FileName </label>
                  <input className="form-control" type="text"
                  value={values.fileName}
                  onChange={handleChange('fileName')}/>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RenameFileModal