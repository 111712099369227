import React, { useState } from 'react'
import AdminSSWeeklyTable from './AdminSSWeeklyTable'
import AdminSSDailyTable from './AdminSSDailyTable'
import AdminSSMonthlyTable from './AdminSSMonthlyTable'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import { tabDaily, tabMonthly, tabWeekly } from '../../../actions/tabActions'
import ShiftSearchTable from './ShiftSearchTable';
import { getShiftAllUsers } from '../../../actions/adminActions';

const AdminSSchedulingViewBar = () => {
    // const [ viewCalender, setViewCalender ] = useState("weekly")
    // const handleDaily = (e) => {
    //     setViewCalender("daily")
    // }
    // const handleWeek = (e) => {
    //     setViewCalender("weekly")
    // }
    // const () => dispatch(tabMonthly())= (e) => {
    //     setViewCalender("monthly")
    // }
    const [ today, setToday ] = useState(moment())
    let date = moment(today.format('YYYY-MM-DD'))
    const myState = useSelector((state => state.changeTheTab))
    // console.log("***********", myState.viewTheShift)
    const dispatch = useDispatch()

    // const [showDropDown, setshowDropDown] = useState(false)
    // const openDropDown = () => {
    //     setshowDropDown((showDropDown) => !showDropDown)
    // }
    // console.log(showDropDown)
    const auth = useSelector((state) => state.auth)
    const [currentDaily, setCurrentDaily] = useState(0)
    const [currentWeekly, setCurrentWeekly] = useState(0)
    const [currentMonthly, setCurrentMonthly] = useState(0)
    const onClickNextDaily = async () => {
        // console.log(currentDaily)
        setCurrentDaily(currentDaily + 1)
        // console.log(currentDaily+1)
        setToday(moment().add(currentDaily + 1, 'days'))
        // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentDaily + 1, 'days').format('YYYY-MM-DD')}))
        dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentDaily + 1, 'days').format('YYYY-MM-DD'), limit:10, page:1}))
    }
    const onClickPrevDaily = () => {
        // console.log(currentDaily)
        setCurrentDaily(currentDaily - 1)
        // console.log(currentDaily-1)
        setToday(moment().add(currentDaily - 1, 'days'))
        // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentDaily - 1, 'days').format('YYYY-MM-DD')}))
        dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentDaily - 1, 'days').format('YYYY-MM-DD'), limit:10, page:1}))
    }
    const onClickNextWeekly = async () => {
        // console.log(currentWeekly)
        setCurrentWeekly(currentWeekly + 7)
        // console.log(currentWeekly+1)
        setToday(moment().add(currentWeekly + 7, 'days'))
        // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentWeekly + 7, 'days').format('YYYY-MM-DD')}))
        dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentWeekly + 7, 'days').format('YYYY-MM-DD'), limit:10, page:1}))
    }
    const onClickPrevWeekly = () => {
        setCurrentWeekly(currentWeekly - 7)
        setToday(moment().add(currentWeekly - 7, 'days'))
        // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentWeekly - 7, 'days').format('YYYY-MM-DD')}))
        dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentWeekly - 7, 'days').format('YYYY-MM-DD'), limit:10, page:1}))
    }
    const onClickNextMonthly = async () => {
        setCurrentMonthly(currentMonthly + 1)
        setToday(moment().add(currentMonthly + 1, 'months'))
        // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentMonthly + 1, 'months').format('YYYY-MM-DD')}))
        dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentMonthly + 1, 'months').format('YYYY-MM-DD'), limit:10, page:1}))
    }
    const onClickPrevMonthly = () => {
        setCurrentMonthly(currentMonthly - 1)
        setToday(moment().add(currentMonthly - 1, 'months'))
        // dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentMonthly - 1, 'months').format('YYYY-MM-DD')}))
        dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: moment().add(currentMonthly - 1, 'months').format('YYYY-MM-DD'), limit:10, page:1}))
    }
    
    return (
        <>
        {
        myState.viewTheShift === "weekly" ?
        <>
        <div className="row mitblrow">
            <div className="col-md-4">
                <div className="float-left">
                    <button
                        onClick={() => dispatch(tabDaily())}
                        // to="/daily-scheduling" 
                        className="btn">Daily</button>
                    <button
                        onClick={() => dispatch(tabWeekly())}
                        // to="/daily-scheduling" 
                        className="btn"
                        // btn btn-primary
                        style={{backgroundColor:"#9ac748", color:"white"}}>Weekly</button>
                    <button
                        onClick={() => dispatch(tabMonthly())}
                        // to="/daily-scheduling" 
                        className="btn">Monthly</button>
            {/* <a to="/daily-scheduling" className="btn">Daily</a>
            <a to="/shift-scheduling" className="btn btn-primary">Weekly</a>
            <a to="/monthly-scheduling" className="btn">Monthly</a> */}
                </div>
            </div>
            <div className="col-md-4">
                <h3 className="card-title mt-2 text-center">
                    { 
                        date.format('dddd') !== 'Sunday' ? date.startOf('week').add(1, 'days').format('DD MMMM YYYY') : date.startOf('week').add(-6, 'days').format('DD MMMM YYYY') 
                    }
                        &nbsp; to &nbsp;
                    {
                        date.format('dddd') !== 'Sunday' ? date.startOf('week').add(7, 'days').format('DD MMMM YYYY') : date.startOf('week').add(0, 'days').format('DD MMMM YYYY')
                    }
                    &nbsp;
                    {/* {
                         date.format('dddd') !== 'Sunday' ?
                            date.startOf('week').add(1, 'days').format('YYYY') === date.startOf('week').add(7, 'days').format('YYYY') ? "date.startOf('week').add(1, 'days').format('YYYY')" : date.startOf('week').add(1, 'days').format('YYYY') + "-" + date.startOf('week').add(7, 'days').format('YYYY')
                        :
                            date.startOf('week').add(-6, 'days').format('YYYY') !== date.startOf('week').add(0, 'days').format('YYYY') ? date.startOf('week').add(-6, 'days').format('YYYY') : date.startOf('week').add(0, 'days').format('DD MM YYYY') + "-" + date.startOf('week').add(0, 'days').format('DD MM YYYY')
                    } */}
                        {/* moment().endOf('week').format('DD MMMM') */}
                         {/* 27 August to 5 September, 2021 */}
                    </h3>
            </div>
            <div className="col-md-4">
                <div className="float-right">
                    <a to="#" onClick={onClickPrevWeekly} className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                    <a to="#" onClick={onClickNextWeekly} className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                </div>
            </div>
        </div>
        <AdminSSWeeklyTable dateForTableData={today}/>
        </>
        : 
        myState.viewTheShift === "daily" ?
        <>
        <div className="row mitblrow">
            <div className="col-md-4">
                <div className="float-left">
                    <button
                        onClick={() => dispatch(tabDaily())}
                        className="btn"
                        style={{backgroundColor:"#9ac748", color:"white"}}>Daily</button>
                    <button
                        onClick={() => dispatch(tabWeekly())}
                        className="btn">Weekly</button>
                    <button
                        onClick={() => dispatch(tabMonthly())}
                        className="btn">Monthly</button>
                </div>
            </div>
            <div className="col-md-4">
                <h3 className="card-title mt-2 text-center">{date.format('DD MMM YYYY')}</h3>
            </div>
            <div className="col-md-4">
                <div className="float-right">
                    <a to="#" onClick={onClickPrevDaily} className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                    <a to="#" onClick={onClickNextDaily} className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                </div>
            </div>
        </div>
        <AdminSSDailyTable dateForTableData={today}/>
        </>
        : 
        myState.viewTheShift === "monthly" ?
        <>
        <div className="row mitblrow">
            <div className="col-md-4">
	    		<div className="float-left">
                    <button
                        onClick={() => dispatch(tabDaily())}
                        className="btn">Daily</button>
                    <button
                        onClick={() => dispatch(tabWeekly())}
                        className="btn">Weekly</button>
                    <button
                        onClick={() => dispatch(tabMonthly())}
                        className="btn"
                        style={{backgroundColor:"#9ac748", color:"white"}}>Monthly</button>
                </div>
			</div>
			<div className="col-md-4">
			    <h3 className="card-title mt-2 text-center">{date.format('MMMM YYYY')}</h3>
			</div>
			<div className="col-md-4">
			    <div className="float-right">
				    <a to="#" onClick={onClickPrevMonthly} className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                    <a to="#" onClick={onClickNextMonthly} className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                </div>
			</div>
        </div>
        <AdminSSMonthlyTable dateForTableData={today}/>
        </>
        :
        myState.viewTheShift === "thesearch" ?
        <>
        <div className="row mitblrow">
            <div className="col-md-4">
	    		<div className="float-left">
                    <button
                        onClick={() => dispatch(tabDaily())}
                        className="btn">Daily</button>
                    <button
                        onClick={() => dispatch(tabWeekly())}
                        className="btn">Weekly</button>
                    <button
                        onClick={() => dispatch(tabMonthly())}
                        className="btn">Monthly</button>
                </div>
			</div>
			<div className="col-md-4">
			    <h3 className="card-title mt-2 text-center">Searched Data</h3>
			</div>
			<div className="col-md-4">
			    {/* <div className="float-right">
				    <a to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-left"></i></a>
                    <a to="#" className="btn btn-primary"><i className="fa fa-chevron-circle-right"></i></a>
                </div> */}
			</div>
        </div>
        <ShiftSearchTable/> 
        </>
        : 
        <div>nothing</div>
        } 
    </>
    )
}
export default AdminSSchedulingViewBar