import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { employeeSearchFilter } from '../../../actions/adminActions'
import { tabSearch } from '../../../actions/tabActions'

const AdminEmployeeFandS = () => {
    const auth = useSelector((state) => state.auth)
    const [ values, setValues ] = useState({
        name:'',
        employeeId: '',
        jobTitle: '',
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    const dispatch = useDispatch()
    const employeeData = {
        companyId: auth.user.companyId,
        name:values.name,
        employeeId: values.employeeId,
        jobTitle: values.jobTitle,
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        if (employeeData.name === ''){
            delete employeeData.name
        }
        if (employeeData.employeeId === ''){
            delete employeeData.employeeId
        }
        if (employeeData.jobTitle === ''){
            delete employeeData.jobTitle
        }
        dispatch(tabSearch())
        dispatch(employeeSearchFilter(employeeData))
    }
    return (
        <>
            <div className="row filter-row">
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus select-focus">
                        <input type="text" className="form-control floating" value={values.employeeId} onChange={handleChange('employeeId')}/>
                        <label className="focus-label">Employee ID</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus select-focus">
                        <input type="text" className="form-control floating" value={values.name} onChange={handleChange('name')}/>
                        <label className="focus-label">Employee Name</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <div className="form-group form-focus select-focus">
                        <input type="text" className="form-control floating" value={values.jobTitle} onChange={handleChange('jobTitle')}/>
                        <label className="focus-label">Job Title</label>
                    </div>
                </div>
                <div className="col-sm-6 col-md-3">
                    <a to="#" className="btn btn-success btn-block" onClick={handleSubmit}> Search </a>
                </div>
            </div>
        </>
    )
}
export default AdminEmployeeFandS
