// import React, { useEffect, useState } from 'react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllLocationsWithUsers } from '../../../actions/locationActions'
import EditModalLPTable from './EditModalLPTable'
import LocationPositionCardRow from './LocationPositionCardRow'

const AdminLocationPositionCards = () => {
    const auth = useSelector((state) => state.auth)
    const location = useSelector((state) => state.location)
    // const [showDropDown, setshowDropDown] = useState(false)
    // const openDropDown = () => {
    //     setshowDropDown((showDropDown) => !showDropDown)
    // }
    // console.log("showDropDownshowDropDown", showDropDown)

    // const [showDeleteDropDown, setshowDeleteDropDown] = useState(false)
    // const openDeleteDropDown = () => {
    //     setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown)
    // }
    // console.log(showDeleteDropDown)
    const dispatch = useDispatch()
    const locationData = async (e) => {
        dispatch(getAllLocationsWithUsers({companyId: auth.user.companyId}))
    }
    useEffect(()=>{
        locationData()
    }, [])
    return (
        <>
            <div className="row">
                <div className="col-lg-10 mx-auto">
                    {   
                        location.locationWithUsers &&
                        location.locationWithUsers.length > 0 &&
                        location.locationWithUsers.map((current, i) => {
                            return(
                                <LocationPositionCardRow current={current} i={i}/>
                            )
                        })
                    }
                </div>
            </div>
            <EditModalLPTable />
        </>
    )
}
export default AdminLocationPositionCards


// <div className="tab-content milocpmn" key={i}>
//     <div className="tab-pane fade active show">
//         <div className="row"> 
//             <div className="col-md-12">
//                 <div className="card card-table mb-0">
//                     <div className="card-header">
//                         <h4 className="card-title mb-0">{current.location.name}</h4>
//                         <div className="row">
//                             <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
//                                 <div className="card dash-widget">
//                                     <div className="card-body">
//                                         <span className="dash-widget-icon"><i className="fa fa-map-marker"></i></span>
//                                         <div className="dash-widget-info text-left">
//                                             <h3>Address:</h3>
//                                             <span>{current.location.address}</span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
//                                 <div className="card dash-widget">
//                                     <div className="card-body">
//                                         <span className="dash-widget-icon"><i className="fa fa-clock-o"></i></span>
//                                         <div className="dash-widget-info text-left">
//                                             <h3>Timezone:</h3>
//                                             {/* (UTC -06:00) America/Chicago         yeh span se andr likha hua tha  */}
//                                             <span></span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
//                                 <div className="card dash-widget">
//                                     <div className="card-body">
//                                         <span className="dash-widget-icon"><i className="fa fa-phone"></i></span>
//                                         <div className="dash-widget-info text-left">
//                                             <h3>Phone Number:</h3>
//                                             {/* 612-724-2222         yeh span se andr likha hua tha  */}
//                                             <span></span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
//                                 <div className="card dash-widget">
//                                     <div className="card-body">
//                                         <span className="dash-widget-icon"><i className="fa fa-clock-o"></i></span>
//                                         <div className="dash-widget-info text-left">
//                                             <h3>Break Rule:</h3>
//                                             {/* None         yeh span se andr likha hua tha  */}
//                                             <span></span>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="card-body">
//                         <div className="table-responsive">
//                             <table className="table table-hover table-center mb-0">
//                                 <thead>
//                                     <tr>
//                                         <th>Name</th>
//                                         <th>Position</th>
//                                         <th className="text-right">Actions</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {   current.users &&
//                                         current.users.length > 0 &&
//                                         current.users.map((e, index) => {
//                                             return(
//                                                 <tr key={index}>
//                                                 <td className="sorting_1">
//                                                     <h2 className="table-avatar">
//                                                         <a href="#" className="avatar">
//                                                             <div className="grnclr"></div>
//                                                         </a>
//                                                         <a>{e.firstName + " " + e.lastName}</a>
//                                                     </h2>
//                                                 </td>
//                                                 <td>
//                                                     {e.jobTitle}
//                                                 </td>
//                                                 <td className="text-right">
//                                                     <div className="dropdown dropdown-action">
//                                                         <a className="dropdown-item" href="#" data-toggle="modal" data-target="#edit_plan" ><i className="fa fa-map-marker m-r-5"></i></a>
//                                                         <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDropDown} data-target="#edit_plan"><i className="fa fa-pencil m-r-5"></i></a>
//                                                         <a className="dropdown-item" href="#" data-toggle="modal" onClick={openDeleteDropDown} data-target="#delete_approve"><i className="fa fa-trash-o m-r-5"></i></a>
//                                                     </div>
//                                                 </td>
//                                                 </tr>
//                                         )})}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>