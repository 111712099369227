import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateLeave } from '../../../actions/leaveActions'
// updateLeave awaga
// import { updatePolicy } from '../../../actions/policyAction' 
const ModalEditAdminLeavesTableSearchRow = ({ showEditModal, openEditModal, current }) => {
    const [ values, setValues ] = useState({
        id: '',
        status: 'Accepted',
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    const currentLeave = async () => {
        try {
            setValues({
                id: '',
                status: '',
            })
            setValues({
                id: current.id,
                status: current.status,
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        currentLeave()
    }, [current])
    const dispatch = useDispatch()
    const updateLeaveData = {
        leaveId: values.id,
        status: values.status
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        dispatch(updateLeave(updateLeaveData))
        openEditModal()
        setValues({
            id: '',
            status: '',
        })
    }
    return (
        <>
            <div className={`modal-backdrop fade ${showEditModal ? 'show' : 'hide'}`}></div>
            <div id="edit_leave" className={`modal custom-modal fade ${showEditModal ? 'show' : 'hide'}`} role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title">Edit Leave</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openEditModal()}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            {
                                current &&
                                <>
                                    <div className="form-group">
                                        <label>Leave Type <span className="text-danger">*</span></label>
                                        <input className="form-control" readonly type="text" value={current.leaveType.name} disabled/>
                                        {/* <select className="form-select">
                                            <option>Select Leave Type</option>
                                            <option>Casual Leave 12 Days</option>
                                        </select> */}
                                    </div>
                                    <div className="form-group">
                                        <label>From <span className="text-danger">*</span></label>
                                        <div className="cal-icon">
                                            <input className="form-control" readonly type="text" value={moment(current.from).format("DD MMMM YYYY")} disabled/>
                                            {/* <input className="form-control datetimepicker" type="text"/> */}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>To <span className="text-danger">*</span></label>
                                        <div className="cal-icon">
                                            <input className="form-control" readonly type="text" value={moment(current.to).format("DD MMMM YYYY")} disabled/>
                                            {/* <input className="form-control datetimepicker" type="text"/> */}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Number of days <span className="text-danger">*</span></label>
                                        <input className="form-control" readonly type="text" value={current.numberOfDays} disabled/>
                                        {/* <input className="form-control" readonly type="text" value="2"/> */}
                                    </div>
                                    <div className="form-group">
                                        <label>{current.leaveType.name} Remaining Leaves <span className="text-danger">*</span></label>
                                        <input className="form-control" readonly type="text" value={current.remainingLeavesOfCategory} disabled/>
                                    </div>
                                    <div className="form-group">
                                        <label>Leave Reason <span className="text-danger">*</span></label>
                                        <input className="form-control" readonly type="text" value={current.leaveReason} disabled/>
                                        {/* <textarea rows="4" className="form-control">Going to hospital</textarea> */}
                                    </div>
                                    <div className="form-group">
                                        <label>Leave Status <span className="text-danger">*</span></label>
                                            <select className="form-select" style={{ height: "44px", fontSize: "14px" }}
                                            value={values.status}
                                            onChange={handleChange('status')}
                                            autoComplete="false">
                                                <option value="Accepted">Accepted</option>
                                                <option value="Rejected">Rejected</option>
                                                <option value="Pending">Pending</option>
                                            </select>
                                    </div>
                                    <div className="submit-section">
                                        <button className="btn btn-primary submit-btn">Save</button>
                                    </div>
                                </>
                            }
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ModalEditAdminLeavesTableSearchRow