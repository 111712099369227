import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux'
import { useDispatch, useSelector } from "react-redux";
// import { getMailByMailId } from '../../actions/mailActions'
import moment from "moment";
import defaultImg from "../assetsAdmin/img/avatarProfile.png";
import { BUCKET_BASE_URL, CHAT_SELECT_SUCCESS } from "../constants";
import { readNotificationAction } from "../actions/notificationActions";

export default function HeaderNotificationModel({
  showDropDownMessage,
  openDropDownMessage,
}) {
  const dispatch = useDispatch();

  const notifications = useSelector((state) => state.notifications);
  const auth = useSelector((state) => state.auth);
  const [windowWidth, setWindowWidth] = useState(0);
  // console.log(windowWidth==576,"windowWidth==576");
  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setWindowWidth(window.innerWidth);
    });
  }, [window.innerWidth]);
  // const dispatch = useDispatch()
  // const mailIndividual = async (idMail) => {
  //    dispatch(getMailByMailId({mailId: idMail}))
  // }
  let arr = [];
  //  let data = [
  //      {
  //          username: 'Richard Mile',
  //          message: 'Lorem ipsum dollor',
  //          time: 4
  //      },
  //    ]

  function formatTime(dateTime) {
    const timeNow = new Date();
    const messageTime = new Date(dateTime);
    const secondsDiff = Math.round((timeNow - messageTime) / 1000);
    const hoursDiff = Math.round(secondsDiff / 60 / 60);

    let time = "";
    if (secondsDiff < 60) {
      time = "Just Now";
    } else {
      if (secondsDiff < 600) {
        time = Math.round(secondsDiff / 60) + " mins";
      } else {
        if (hoursDiff > 24) {
          time = moment(messageTime).format("ddd, hh:mm a");
        } else {
          // console.log("messageTIme==>", messageTime);
          time = moment(messageTime).format("hh:mm a");
        }
      }
    }
    return time;
  }

  const clickHandler = (notificationId, chat) => {
    const notification = notifications.list.find((x) => x.id == notificationId);
    if (!notification.readBy.find((x) => x.readerId === auth.user.id))
      dispatch(
        readNotificationAction({ notificationId, readerId: auth.user.id })
      );
    dispatch({
      type: CHAT_SELECT_SUCCESS,
      payload: chat,
    });
    openDropDownMessage();
    // setShowDropDownMessage(false);
  };
  return (
    <div
      className={`dropdown-menu-message dropdown-menu notifications ${
        showDropDownMessage ? "show" : ".hide"
      } `}
    >
      <div className="topnav-dropdown-header">
        <span className="notification-title">Notifications</span>
        {/* abhi set krna hai */}
        {/* <Link to="" className="clear-noti"> Clear All </Link> */}
      </div>
      <div className="noti-content">
        <ul className="notification-list">
          {notifications.loading ? (
            <div id="loader-wrapper">
              <div id="loader">
                <div className="loader-ellips">
                  <span className="loader-ellips__dot"></span>
                  <span className="loader-ellips__dot"></span>
                  <span className="loader-ellips__dot"></span>
                  <span className="loader-ellips__dot"></span>
                </div>
              </div>
            </div>
          ) : (
            notifications.list.length === 0 && (
              <p className="text-center my-5">
                You don't have any notifications yet!{" "}
              </p>
            )
          )}
          {notifications.list
            // .filter((x) => x.sender.id !== auth.user.id)
            .sort((a, b) => moment(a.dateTime).isBefore(b.dateTime))
            .map((item, i) => {
              // console.log(item.id)
              arr = [];
              return (
                <li
                  className={`notification-message ${
                    item.readBy.find((x) => x.readerId === auth.user.id)
                      ? ""
                      : "bg-gray"
                  }`}
                  key={i}
                >
                  {/* <a > */}
                  <Link
                    to={`/chat`}
                    onClick={() => clickHandler(item.id, item.chat)}
                  >
                    <div className="list-item">
                      <div className="list-left">
                        <span className="avatar">
                          <img
                            alt="Profile Picture"
                            src={
                              item.chat.isGroup
                                ? "/group-icon.png"
                                : item.sender.profilePic
                                ? BUCKET_BASE_URL +
                                  item.sender.profilePic.fileUrl
                                : defaultImg
                            }
                            style={{ objectFit: "fill", height: "100%" }}
                          />
                        </span>
                      </div>
                      <div className="list-body">
                        {/* { */}
                        <div className="message-author">
                          <span className="title">
                            {item.chat.isGroup ? (
                              <>
                                {/* <b>
                                {item.sender.firstName} {item.sender.lastName}
                              </b> */}
                                New message in <b>{item.chat.chatName}</b>
                              </>
                            ) : (
                              <>
                                <b>
                                  {item.sender.firstName} {item.sender.lastName}
                                </b>{" "}
                                sent you a message
                              </>
                            )}
                          </span>
                          {/* } */}
                          <span className="message-time">
                            {formatTime(item.chat.latestMessage.dateTime)}
                            {/* {moment().format("DD-MM-YYYY") ===
                        moment(item.chat.latestMessage.dateTime).format(
                          "DD-MM-YYYY"
                        )
                          ? moment(item.chat.latestMessage.dateTime).format(
                              "LT"
                            )
                          : moment(item.chat.latestMessage.dateTime).format(
                              "DD MMM"
                            )} */}
                          </span>
                        </div>
                        <div className="clearfix"></div>
                        <span className="message-content">
                          {item.chat.isGroup && (
                            <>
                              <b>
                                {item.sender.firstName} {item.sender.lastName}:{" "}
                              </b>
                            </>
                          )}
                          {item.chat.latestMessage.message}
                        </span>
                      </div>
                    </div>
                  </Link>
                  {/* </a> */}
                </li>
              );
            })}
        </ul>
      </div>
      {/* <div className="topnav-dropdown-footer">
        <Link to="/inbox">View all Messages</Link>
      </div> */}
    </div>
  );
}
