import React from 'react'
const CompanyDeleteModal = ({ showDeleteDropDown, openDeleteDropDown }) => {
    return (
        <>
            <div className={`modal-backdrop fade ${showDeleteDropDown ? 'show' : 'hide'}`}></div>
            <div className={`modal custom-modal fade ${showDeleteDropDown ? 'show' : 'hide'}`} id="delete_warn" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-header">
                                <h3>Delete Company</h3>
                                <p>Are you sure want to delete?</p>
                            </div>
                            <div className="modal-btn delete-action">
                                <div className="row">
                                    <div className="col-6">
                                        <a href="#" className="btn btn-primary continue-btn">Delete</a>
                                    </div>
                                    <div className="col-6">
                                        <a href="#" data-dismiss="modal" className="btn btn-primary cancel-btn" onClick={() => openDeleteDropDown()}>Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CompanyDeleteModal