import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeaderNotificationModel from "../HeaderNotificationModel";
import { userLogout } from "../../actions/authActions";
import defaultImg from "../../assetsAdmin/img/avatarProfile.png";
import logonew from "../../assetsAdmin/img/footerlogoresize.png";
import { useIdleTimer } from "react-idle-timer";
import { BUCKET_BASE_URL } from "../../constants";
// import { useEffect } from 'react'

const AdminHeader = () => {
  // const onPrompt = () => {
  //     window.alert("onPrompt")
  //   // Fire a Modal Prompt
  // }

  const onIdle = () => {
    logout();
    setTimeout(() => window.alert("session timeout"), 5000);
    // window.alert("session timeout")
    // Close Modal Prompt
    // Do some idle action like log out your user
  };

  const onActive = (event) => {
    // window.alert("onActive")
    // Close Modal Prompt
    // Do some active action
  };

  // const onAction = (event) => {
  //     window.alert("onAction")
  //   // Do something when a user triggers a watched event
  // }

  const {
    start,
    reset,
    activate,
    pause,
    resume,
    isIdle,
    isPrompted,
    isLeader,
    getTabId,
    getRemainingTime,
    getElapsedTime,
    getLastIdleTime,
    getLastActiveTime,
    getTotalIdleTime,
    getTotalActiveTime,
  } = useIdleTimer({
    //   onPrompt,
    onIdle,
    onActive,
    //   onAction,
    timeout: 1000 * 60 * 10,
    //   timeout: 1000 * 10,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      // 'blur'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    //   crossTab: false,
    crossTab: true,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });
  const dispatch = useDispatch();
  const [showDropDownMessage, setshowDropDownMessage] = useState(false);
  const [opensidedrawer, setopensidedrawer] = useState(false);
  const [openDrop, setopenDrop] = useState(false);
  // const [showDropDown, setshowDropDown] = useState(false)
  // const [showDropDownFlag, setshowDropDownFlag] = useState(false)
  const auth = useSelector((state) => state.auth);
  const mail = useSelector((state) => state.mail);
  const notifications = useSelector((state) => state.notifications);

  // const openDropDown = () => {
  // setshowDropDown((state) => !state)
  // setshowDropDownMessage(false)
  // setshowDropDownFlag(false)
  // setopenDrop(false)
  // }
  // const openDropDownFlag = () => {
  // setshowDropDownFlag((state) => !state)
  // setshowDropDown(false)
  // setshowDropDownMessage(false)
  // setopenDrop(false)
  // }
  const openDropDownMessage = () => {
    setshowDropDownMessage((state) => !state);
    setopenDrop(false);
    // setshowDropDown(false)
    // setshowDropDownFlag(false)
  };
  const toggleDriver = () => {
    setopensidedrawer((state) => !state);
  };
  const openDropDownMenu = () => {
    setopenDrop((state) => !state);
    setshowDropDownMessage(false);
    // setshowDropDown(false)
    // setshowDropDownFlag(false)
  };
  // const [ timeOut, setTimeOut ] = useState(0)
  // useEffect(()=>{
  //     setInterval(() =>
  //         setTimeOut((timeOut) => timeOut+1)
  //     , 10000);
  // },[])
  // console.log(timeOut, "timeOut")
  // useEffect(()=>{
  //     if (timeOut >= 10){
  //         window.alert("session timeout")
  //         logout()
  //     }
  // },[timeOut])

  let navigate = useNavigate();
  const navigateUser = () => {
    // dispatch(updateUserLoginInfo({
    //     _id: auth.activeUserCountry.id,
    //     loginStatus:false
    // }))
    navigate("/login");
  };
  const logout = async (e) => {
    // console.log("ghkhkhkjhkjhkhk,,hkjhkjh,,jgjh")
    auth && auth.activeUserCountry
      ? await dispatch(
          userLogout(
            { refreshToken: auth.tokens.refresh.token },
            navigateUser,
            auth.activeUserCountry.id
          )
        )
      : await dispatch(
          userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
        );
  };
  const [showAdministratorDropDown, setshowAdministratorDropDown] =
    useState(false);
  const [showReportDropDown, setshowReportDropDown] = useState(false);
  const openAdministratorDropDown = () => {
    setshowAdministratorDropDown(
      (showAdministratorDropDown) => !showAdministratorDropDown
    );
    setshowReportDropDown(false);
  };
  const openReportDropDown = () => {
    setshowReportDropDown((showReportDropDown) => !showReportDropDown);
    setshowAdministratorDropDown(false);
  };
  //   window.onclick = setshowDropDownMessage()
  // document.
  let j = 0;
  let jj = 0;
  return (
    <>
      <div className="main-wrapper">
        <div className="header">
          <div className="header-left">
            <NavLink to="/dashboard" className="logo">
              {/* <img src={logonew} height="24.5" alt=""/> */}
              <img src={logonew} height="33" alt="" />
            </NavLink>
          </div>
          {/* <NavLink id="toggle_btn" to="">
                        <span className="bar-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </NavLink> */}
          <div className="page-title-box">
            <h3>Riyo Work</h3>
          </div>
          {/* yeh line commit ki hai because waisi line dalskhein */}
          {/* <NavLink id="mobile_btn" className="mobile_btn" to="#sidebar"><i className="fa fa-bars"></i></NavLink> */}
          <NavLink
            id="mobile_btn"
            className="mobile_btn"
            to="#"
            onClick={toggleDriver}
          >
            <i className="fa fa-bars"></i>
          </NavLink>
          <ul className="nav user-menu">
            {/* flag ka code neecha  */}
            {/* <li key={jj className="nav-item dropdown">
                                <NavLink to="#" className="dropdown-toggle nav-link" data-toggle="dropdown" onClick={openDropDown}>
                                    <i className="fa fa-bell-o"></i> <span className="badge badge-pill">3</span>
                                </NavLink>
                                <HeaderNotificationModel showDropDown={showDropDown} />
                                </li> */}
            <li key={jj++} className="nav-item dropdown">
              {/* <NavLink to="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                                    <i className="fa fa-comment-o"></i> <span className="badge badge-pill">8</span>
                                </NavLink> */}

              <NavLink
                to="#"
                className="dropdown-toggle nav-link"
                data-toggle="dropdown"
                onClick={openDropDownMessage}
              >
                <i className="fa fa-comment-o"></i>
                {notifications.list.length > 0 && (
                  <span className="badge badge-pill">
                    {notifications.list.length -
                      notifications.list.filter((x) =>
                        x.readBy.find((y) => y.readerId == auth.user.id)
                      ).length}
                  </span>
                )}
              </NavLink>
              <HeaderNotificationModel
                showDropDownMessage={showDropDownMessage}
                openDropDownMessage={openDropDownMessage}
              />
              {/* <HeaderMessageModel showDropDownMessage={showDropDownMessage} /> */}
            </li>
            <li
              key={jj++}
              className="nav-item dropdown has-arrow main-drop"
              onClick={openDropDownMenu}
            >
              <NavLink
                to="#"
                className="dropdown-toggle nav-link"
                data-toggle="dropdown"
              >
                <span className="avatar">
                  {auth.user && auth.user.profilePic ? (
                    auth.user.profilePic.fileUrl && (
                      <>
                        <img
                          alt="Profile Pic"
                          src={BUCKET_BASE_URL + auth.user.profilePic.fileUrl}
                          style={{ objectFit: "fill", height: "100%" }}
                        />
                      </>
                    )
                  ) : (
                    <img alt="" src={defaultImg} />
                  )}
                  <span
                    className="status online"
                    style={{
                      float: "right",
                      marginTop: "-0.6rem",
                      position: "relative",
                      border: "2px solid #fff",
                    }}
                  ></span>
                </span>
                <span>Admin</span>
              </NavLink>
              <div
                className={`dropdown-menu dropdown-profile ${
                  openDrop ? "show" : "hide"
                }`}
              >
                <NavLink className="dropdown-item" to="/admin-profile">
                  My Profile
                </NavLink>
                <NavLink className="dropdown-item" to="/settings">
                  Settings
                </NavLink>
                {/* abhi set krna hai */}
                <a
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={logout}
                >
                  Logout
                </a>
              </div>
            </li>
          </ul>
          <div className="dropdown mobile-user-menu" onClick={openDropDownMenu}>
            <NavLink
              to="#"
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </NavLink>
            <div
              className={`dropdown-menu dropdown-menu-right ${
                openDrop ? "show" : "hide"
              }`}
            >
              <NavLink className="dropdown-item" to="/admin-profile">
                My Profile
              </NavLink>
              <NavLink className="dropdown-item" to="/settings">
                Settings
              </NavLink>
              <a
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={logout}
              >
                Logout
              </a>
              {/* <NavLink className="dropdown-item" to="/login">Logout</NavLink> */}
            </div>
          </div>
          {/* ********************************************** */}

          {/* yeh side drawer ka code hai jab window minimize ya choti choti ha toh nazar ata hai */}
          <div
            className={`sidebar ${
              opensidedrawer ? "showsidebar" : "hidesidebar"
            }`}
            id="sidebar"
          >
            <div className="scrollbar scrollbar-primary">
              <div id="sidebar-menu" className="sidebar-menu">
                <ul>
                  <li key={jj++}>
                    <NavLink to="/dashboard">
                      <i className="la la-dashboard"></i>
                      <span> Dashboard</span>
                    </NavLink>
                  </li>
                  <li key={jj++}>
                    <NavLink to="/employees">
                      <i className="la la-user"></i> <span>All Employees</span>
                    </NavLink>
                  </li>
                  <li key={jj++}>
                    <NavLink to="/time-clock">
                      <i className="la la-user-clock"></i>
                      <span>Time Clock</span>
                    </NavLink>
                  </li>
                  <li key={jj++}>
                    <NavLink to="/shift-scheduling">
                      <i className="la la-users"></i>
                      <span>Shift &amp; Schedule</span>
                    </NavLink>
                  </li>
                  <li key={jj++}>
                    <NavLink to="/tracking">
                      <i className="la la-location-arrow"></i>
                      <span>Tracking</span>
                    </NavLink>
                  </li>
                  <li key={jj++}>
                    <NavLink to="/leaves">
                      <i className="la la-external-link-square"></i>
                      <span>Leaves </span>
                    </NavLink>
                  </li>
                  <li key={jj++}>
                    <NavLink to="/training">
                      <i className="la la-graduation-cap"></i>{" "}
                      <span> Training </span>
                    </NavLink>
                  </li>
                  <li key={jj++}>
                    <NavLink to="/payroll-items">
                      <i className="la la-money"></i> <span> Payroll </span>
                    </NavLink>
                  </li>
                  <li key={jj++} className="submenu">
                    <NavLink to="#" onClick={openReportDropDown}>
                      <i className="la la-th-list"></i>
                      <span>Reports</span> <span className="menu-arrow"></span>
                    </NavLink>
                    <ul className={`${showReportDropDown ? "show" : "hide"}`}>
                      <li key={jj++}>
                        <NavLink to="/shift-report">
                          <i className="la la-file-invoice"></i>{" "}
                          <span>Shift Reports</span>
                        </NavLink>
                      </li>
                      <li key={jj++}>
                        <NavLink to="/timeclk-report">
                          <i className="la la-file-invoice"></i>{" "}
                          <span>Time Clock Reports</span>
                        </NavLink>
                      </li>
                      <li key={jj++}>
                        <NavLink to="/attend-report">
                          <i className="la la-file-invoice"></i>{" "}
                          <span>Attendance Reports</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li key={jj++} className="submenu">
                    <NavLink to="#" onClick={openAdministratorDropDown}>
                      <i className="las la-toolbox"></i>
                      <span>Administration</span>{" "}
                      <span className="menu-arrow"></span>
                    </NavLink>
                    <ul
                      className={`${
                        showAdministratorDropDown ? "show" : "hide"
                      }`}
                    >
                      <li key={jj++}>
                        <NavLink to="/locations">
                          <i className="la la-map-marker"></i>{" "}
                          <span>Locations</span>
                        </NavLink>
                      </li>
                      <li key={jj++}>
                        <NavLink to="/policies">
                          <i className="la la-file-pdf-o"></i>{" "}
                          <span>Policies</span>
                        </NavLink>
                      </li>
                      <li key={jj++}>
                        <NavLink to="/users">
                          <i className="la la-user-plus"></i> <span>Users</span>
                        </NavLink>
                      </li>
                      <li key={jj++}>
                        <NavLink to="/subscriptions-company">
                          <i className="la la-hand-o-up"></i>{" "}
                          <span> Subscriptions </span>
                        </NavLink>
                      </li>
                      <li key={jj++}>
                        <NavLink to="/settings">
                          <i className="la la-cog"></i> <span> Settings </span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li key={jj++}>
                    <a style={{ cursor: "pointer" }} onClick={logout}>
                      <i className="la la-power-off"></i> <span>Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`sidebar-overlay ${opensidedrawer ? "opened" : ""}`}
      ></div>
    </>
  );
};
export default AdminHeader;

{
  /* flag  */
}
{
  /* <li key={jj++} className="nav-item dropdown has-arrow flag-nav">
    <NavLink className="nav-link dropdown-toggle" data-toggle="dropdown" onClick={openDropDownFlag} to="#" role="button">
        <img src="assetsAdmin/img/us.png" alt="" height="20"/> <span>English</span>
    </NavLink>
    <div className="dropdown-menu dropdown-menu-right">
        <NavLink to="" className="dropdown-item">
            <img src="assetsAdmin/img/us.png" alt="" height="16"/> English
        </NavLink>
        <NavLink to="" className="dropdown-item">
            <img src="assetsAdmin/img/fr.png" alt="" height="16"/> French
        </NavLink>
        <NavLink to="" className="dropdown-item">
            <img src="assetsAdmin/img/es.png" alt="" height="16"/> Spanish
        </NavLink>
        <NavLink to="" className="dropdown-item">
            <img src="assetsAdmin/img/de.png" alt="" height="16"/> German
        </NavLink>
    </div>
</li> */
}
