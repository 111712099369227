// BACKEND SERVER
// export const SERVERADDRESS = "/api";
// export const SERVERADDRESS = "https://api.riyowork.com/api";
// export const SOCKETADDRESS = "https://www.riyowork.com/api";
// export const SERVERADDRESS = "https://riyowork.com/api";
// export const SERVERADDRESS = "https://riyowork.herokuapp.com/api";

// export const SERVERADDRESS = "http://localhost:5000/api";
// export const SERVERADDRESS =
//   "https://riyowork-backend-161dd1396239.herokuapp.com/api";

export const SERVERADDRESS =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/api"
    : "https://riyowork-backend-161dd1396239.herokuapp.com/api";

// export const SOCKETADDRESS =
//   "https://riyowork-backend-161dd1396239.herokuapp.com";

export const SOCKETADDRESS =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "https://riyowork-backend-161dd1396239.herokuapp.com";

// Storage Bucket
export const BUCKET_BASE_URL =
  "https://pub-73015d2687184e93a7c705a5755bc877.r2.dev/";

export const BUCKET_ENDPOINT =
  "https://5aa5d988e1bde0d278ab9f851dccfa85.r2.cloudflarestorage.com";
export const BUCKET_NAME = "riyoworkportal";
export const BUCKET_REGION = "auto";
export const BUCKET_ACCESS_KEY_ID = "42517f1906a1e312810ee9ed7a0307ab";
export const BUCKET_SECRET_KEY =
  "5dcddf9ff5cb2705f6ac2fbf65712c825b9969278e4a1efb551bd8f98e79f2ee";

// USER LOGIN CONST
export const USER_AUTH_REQUEST = "USER_AUTH_REQUEST";
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_AUTH_FAIL = "USER_AUTH_FAIL";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";
export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const COMPANY_AUTH_REQUEST = "COMPANY_AUTH_REQUEST";
export const COMPANY_AUTH_SUCCESS = "COMPANY_AUTH_SUCCESS";
export const COMPANY_AUTH_FAIL = "COMPANY_AUTH_FAIL";
export const FORGOT_PASSWORD_CREATE_REQUEST = "FORGOT_PASSWORD_CREATE_REQUEST";
export const FORGOT_PASSWORD_CREATE_SUCCESS = "FORGOT_PASSWORD_CREATE_SUCCESS";
export const FORGOT_PASSWORD_CREATE_FAIL = "FORGOT_PASSWORD_CREATE_FAIL";
export const RESET_PASSWORD_CREATE_REQUEST = "RESET_PASSWORD_CREATE_REQUEST";
export const RESET_PASSWORD_CREATE_SUCCESS = "RESET_PASSWORD_CREATE_SUCCESS";
export const RESET_PASSWORD_CREATE_FAIL = "RESET_PASSWORD_CREATE_FAIL";
export const SEND_VERIFICATION_EMAIL_REQUEST =
  "SEND_VERIFICATION_EMAIL_REQUEST";
export const SEND_VERIFICATION_EMAIL_SUCCESS =
  "SEND_VERIFICATION_EMAIL_SUCCESS";
export const SEND_VERIFICATION_EMAIL_FAIL = "SEND_VERIFICATION_EMAIL_FAIL";
export const GET_TOKEN_CREATE_REQUEST = "GET_TOKEN_CREATE_REQUEST";
export const GET_TOKEN_CREATE_SUCCESS = "GET_TOKEN_CREATE_SUCCESS";
export const GET_TOKEN_CREATE_FAIL = "GET_TOKEN_CREATE_FAIL";
export const USER_LOGIN_INFO_CREATE_REQUEST = "USER_LOGIN_INFO_CREATE_REQUEST";
export const USER_LOGIN_INFO_CREATE_SUCCESS = "USER_LOGIN_INFO_CREATE_SUCCESS";
export const USER_LOGIN_INFO_CREATE_FAIL = "USER_LOGIN_INFO_CREATE_FAIL";
export const UPDATE_USER_LOGIN_INFO_REQUEST = "UPDATE_USER_LOGIN_INFO_REQUEST";
export const UPDATE_USER_LOGIN_INFO_SUCCESS = "UPDATE_USER_LOGIN_INFO_SUCCESS";
export const UPDATE_USER_LOGIN_INFO_FAIL = "UPDATE_USER_LOGIN_INFO_FAIL";

// SHIFT DATA CONST
export const SHIFT_DATA_REQUEST = "SHIFT_DATA_REQUEST";
export const SHIFT_DATA_SUCCESS = "SHIFT_DATA_SUCCESS";
export const SHIFT_DATA_FAIL = "SHIFT_DATA_FAIL";
export const SHIFT_DASBOARD_REQUEST = "SHIFT_DASBOARD_REQUEST";
export const SHIFT_DASBOARD_SUCCESS = "SHIFT_DASBOARD_SUCCESS";
export const SHIFT_DASBOARD_FAIL = "SHIFT_DASBOARD_FAIL";
export const SHIFT_PROFILE_REQUEST = "SHIFT_PROFILE_REQUEST";
export const SHIFT_PROFILE_SUCCESS = "SHIFT_PROFILE_SUCCESS";
export const SHIFT_PROFILE_FAIL = "SHIFT_PROFILE_FAIL";
export const SHIFT_SEARCH_REQUEST = "SHIFT_SEARCH_REQUEST";
export const SHIFT_SEARCH_SUCCESS = "SHIFT_SEARCH_SUCCESS";
export const SHIFT_SEARCH_FAIL = "SHIFT_SEARCH_FAIL";
export const SHIFT_UPDATE_REQUEST = "SHIFT_UPDATE_REQUEST";
export const SHIFT_UPDATE_SUCCESS = "SHIFT_UPDATE_SUCCESS";
export const SHIFT_UPDATE_FAIL = "SHIFT_UPDATE_FAIL";

// LOCATION DATA CONST
export const LOCATION_REQUEST = "LOCATION_REQUEST";
export const LOCATION_SUCCESS = "LOCATION_SUCCESS";
export const LOCATION_FAIL = "LOCATION_FAIL";
export const LOCATION_WITH_USERS_REQUEST = "LOCATION_WITH_USERS_REQUEST";
export const LOCATION_WITH_USERS_SUCCESS = "LOCATION_WITH_USERS_SUCCESS";
export const LOCATION_WITH_USERS_FAIL = "LOCATION_WITH_USERS_FAIL";
export const LOCATION_CREATE_REQUEST = "LOCATION_CREATE_REQUEST";
export const LOCATION_CREATE_SUCCESS = "LOCATION_CREATE_SUCCESS";
export const LOCATION_CREATE_FAIL = "LOCATION_CREATE_FAIL";
export const LOCATION_UPDATE_REQUEST = "LOCATION_UPDATE_REQUEST";
export const LOCATION_UPDATE_SUCCESS = "LOCATION_UPDATE_SUCCESS";
export const LOCATION_UPDATE_FAIL = "LOCATION_UPDATE_FAIL";
export const LOCATION_REMOVE_REQUEST = "LOCATION_REMOVE_REQUEST";
export const LOCATION_REMOVE_SUCCESS = "LOCATION_REMOVE_SUCCESS";
export const LOCATION_REMOVE_FAIL = "LOCATION_REMOVE_FAIL";
export const LOCATION_BY_ID_REQUEST = "LOCATION_BY_ID_REQUEST";
export const LOCATION_BY_ID_SUCCESS = "LOCATION_BY_ID_SUCCESS";
export const LOCATION_BY_ID_FAIL = "LOCATION_BY_ID_FAIL";

// TRAINING DATA CONST
export const TRAINING_REQUEST = "TRAINING_REQUEST";
export const TRAINING_SUCCESS = "TRAINING_SUCCESS";
export const TRAINING_FAIL = "TRAINING_FAIL";

// CLOCK IN CONST
export const CLOCK_IN_REQUEST = "CLOCK_IN_REQUEST";
export const CLOCK_IN_SUCCESS = "CLOCK_IN_SUCCESS";
export const CLOCK_IN_FAIL = "CLOCK_IN_FAIL";
export const CLOCK_OUT_REQUEST = "CLOCK_OUT_REQUEST";
export const CLOCK_OUT_SUCCESS = "CLOCK_OUT_SUCCESS";
export const CLOCK_OUT_FAIL = "CLOCK_OUT_FAIL";
export const CLOCK_EXIST_REQUEST = "CLOCK_EXIST_REQUEST";
export const CLOCK_EXIST_SUCCESS = "CLOCK_EXIST_SUCCESS";
export const CLOCK_EXIST_FAIL = "CLOCK_EXIST_FAIL";
export const CLOCK_STATUS_REQUEST = "CLOCK_STATUS_REQUEST";
export const CLOCK_STATUS_SUCCESS = "CLOCK_STATUS_FAIL";
export const CLOCK_STATUS_FAIL = "CLOCK_EXIST_FAIL";
export const CLOCK_MONTHLY_DATA_REQUEST = "CLOCK_MONTHLY_DATA_REQUEST";
export const CLOCK_MONTHLY_DATA_SUCCESS = "CLOCK_MONTHLY_DATA_SUCCESS";
export const CLOCK_MONTHLY_DATA_FAIL = "CLOCK_MONTHLY_DATA_FAIL";
export const CLOCK_ATTENDANCE_REQUEST = "CLOCK_ATTENDANCE_REQUEST";
export const CLOCK_ATTENDANCE_SUCCESS = "CLOCK_ATTENDANCE_SUCCESS";
export const CLOCK_ATTENDANCE_FAIL = "CLOCK_ATTENDANCE_FAIL";
export const ADMIN_CLOCK_OUT_REQUEST = "ADMIN_CLOCK_OUT_REQUEST";
export const ADMIN_CLOCK_OUT_SUCCESS = "ADMIN_CLOCK_OUT_SUCCESS";
export const ADMIN_CLOCK_OUT_FAIL = "ADMIN_CLOCK_OUT_FAIL";
export const MONTHLY_ATTENDANCE_SEARCH_REQUEST =
  "MONTHLY_ATTENDANCE_SEARCH_REQUEST";
export const MONTHLY_ATTENDANCE_SEARCH_SUCCESS =
  "MONTHLY_ATTENDANCE_SEARCH_SUCCESS";
export const MONTHLY_ATTENDANCE_SEARCH_FAIL = "MONTHLY_ATTENDANCE_SEARCH_FAIL";

// USER DATA CONST
export const ANNIVARSARY_REQUEST = "ANNIVARSARY_REQUEST";
export const ANNIVARSARY_SUCCESS = "ANNIVARSARY_SUCCESS";
export const ANNIVARSARY_FAIL = "ANNIVARSARY_FAIL";
export const COMPANY_USERS_REQUEST = "COMPANY_USERS_REQUEST";
export const COMPANY_USERS_SUCCESS = "COMPANY_USERS_SUCCESS";
export const COMPANY_USERS_FAIL = "COMPANY_USERS_FAIL";
export const COMPANY_USERS_QUERY_REQUEST = "COMPANY_USERS_QUERY_REQUEST";
export const COMPANY_USERS_QUERY_SUCCESS = "COMPANY_USERS_QUERY_SUCCESS";
export const COMPANY_USERS_QUERY_FAIL = "COMPANY_USERS_QUERY_FAIL";
export const USER_NON_ACTIVE_REQUEST = "USER_NON_ACTIVE_REQUEST";
export const USER_NON_ACTIVE_SUCCESS = "USER_NON_ACTIVE_SUCCESS";
export const USER_NON_ACTIVE_FAIL = "USER_NON_ACTIVE_FAIL";
export const USER_ACTIVE_REQUEST = "USER_ACTIVE_REQUEST";
export const USER_ACTIVE_SUCCESS = "USER_ACTIVE_SUCCESS";
export const USER_ACTIVE_FAIL = "USER_ACTIVE_FAIL";
export const USER_BY_ID_REQUEST = "USER_BY_ID_REQUEST";
export const USER_BY_ID_SUCCESS = "USER_BY_ID_SUCCESS";
export const USER_BY_ID_FAIL = "USER_BY_ID_FAIL";
export const ALL_USER_BY_ID_REQUEST = "ALL_USER_BY_ID_REQUEST";
export const ALL_USER_BY_ID_SUCCESS = "ALL_USER_BY_ID_SUCCESS";
export const ALL_USER_BY_ID_FAIL = "ALL_USER_BY_ID_FAIL";
export const EMPLOYEE_LAST_ID_REQUEST = "EMPLOYEE_LAST_ID_REQUEST";
export const EMPLOYEE_LAST_ID_SUCCESS = "EMPLOYEE_LAST_ID_SUCCESS";
export const EMPLOYEE_LAST_ID_FAIL = "EMPLOYEE_LAST_ID_FAIL";
export const UPDATE_USER_BY_ADMIN_REQUEST = "UPDATE_USER_BY_ADMIN_REQUEST";
export const UPDATE_USER_BY_ADMIN_SUCCESS = "UPDATE_USER_BY_ADMIN_SUCCESS";
export const UPDATE_USER_BY_ADMIN_FAIL = "UPDATE_USER_BY_ADMIN_FAIL";
export const REMOVE_USER_BY_ADMIN_REQUEST = "REMOVE_USER_BY_ADMIN_REQUEST";
export const REMOVE_USER_BY_ADMIN_SUCCESS = "REMOVE_USER_BY_ADMIN_SUCCESS";
export const REMOVE_USER_BY_ADMIN_FAIL = "REMOVE_USER_BY_ADMIN_FAIL";
export const GET_ALL_COMPANY_ADMIN_REQUEST = "GET_ALL_COMPANY_ADMIN_REQUEST";
export const GET_ALL_COMPANY_ADMIN_SUCCESS = "GET_ALL_COMPANY_ADMIN_SUCCESS";
export const GET_ALL_COMPANY_ADMIN_FAIL = "GET_ALL_COMPANY_ADMIN_FAIL";

// SALARY DATA CONST
export const SALARY_REQUEST = "SALARY_REQUEST";
export const SALARY_SUCCESS = "SALARY_SUCCESS";
export const SALARY_FAIL = "SALARY_FAIL";
export const SALARY_CREATE_REQUEST = "SALARY_CREATE_REQUEST";
export const SALARY_CREATE_SUCCESS = "SALARY_CREATE_SUCCESS";
export const SALARY_CREATE_FAIL = "SALARY_CREATE_FAIL";
export const SALARY_STRUCTURE_UPDATE_REQUEST =
  "SALARY_STRUCTURE_UPDATE_REQUEST";
export const SALARY_STRUCTURE_UPDATE_SUCCESS =
  "SALARY_STRUCTURE_UPDATE_SUCCESS";
export const SALARY_STRUCTURE_UPDATE_FAIL = "SALARY_STRUCTURE_UPDATE_FAIL";

// OVERTIME DATA CONST
export const OVERTIME_REQUEST = "OVERTIME_REQUEST";
export const OVERTIME_SUCCESS = "OVERTIME_SUCCESS";
export const OVERTIME_FAIL = "OVERTIME_FAIL";
export const OVERTIME_TABLE_REQUEST = "OVERTIME_TABLE_REQUEST";
export const OVERTIME_TABLE_SUCCESS = "OVERTIME_TABLE_SUCCESS";
export const OVERTIME_TABLE_FAIL = "OVERTIME_TABLE_FAIL";

// LEAVE DATA CONST
export const LEAVE_ANNUAL_STATUS_REQUEST = "LEAVE_ANNUAL_STATUS_REQUEST";
export const LEAVE_ANNUAL_STATUS_SUCCESS = "LEAVE_ANNUAL_STATUS_SUCCESS";
export const LEAVE_ANNUAL_STATUS_FAIL = "LEAVE_ANNUAL_STATUS_FAIL";

export const LEAVE_REQUEST = "LEAVE_REQUEST";
export const LEAVE_SUCCESS = "LEAVE_SUCCESS";
export const LEAVE_FAIL = "LEAVE_FAIL";

export const LEAVE_CREATE_REQUEST = "LEAVE_CREATE_REQUEST";
export const LEAVE_CREATE_SUCCESS = "LEAVE_CREATE_SUCCESS";
export const LEAVE_CREATE_FAIL = "LEAVE_CREATE_FAIL";
export const ALL_LEAVE_QUERY_REQUEST = "ALL_LEAVE_QUERY_REQUEST";
export const ALL_LEAVE_QUERY_SUCCESS = "ALL_LEAVE_QUERY_SUCCESS";
export const ALL_LEAVE_QUERY_FAIL = "ALL_LEAVE_QUERY_FAIL";
export const LEAVE_REPORT_REQUEST = "LEAVE_REPORT_REQUEST";
export const LEAVE_REPORT_SUCCESS = "LEAVE_REPORT_SUCCESS";
export const LEAVE_REPORT_FAIL = "LEAVE_REPORT_FAIL";
export const LEAVE_SETTING_REQUEST = "LEAVE_SETTING_REQUEST";
export const LEAVE_SETTING_SUCCESS = "LEAVE_SETTING_SUCCESS";
export const LEAVE_SETTING_FAIL = "LEAVE_SETTING_FAIL";
export const LEAVE_REPORT_USER_REQUEST = "LEAVE_REPORT_USER_REQUEST";
export const LEAVE_REPORT_USER_SUCCESS = "LEAVE_REPORT_USER_SUCCESS";
export const LEAVE_REPORT_USER_FAIL = "LEAVE_REPORT_USER_FAIL";

// LEAVE CATEGORY CONST ---> CREATE LEAVE
export const LEAVE_CATEGORY_CREATE_REQUEST = "LEAVE_CATEGORY_CREATE_REQUEST";
export const LEAVE_CATEGORY_CREATE_SUCCESS = "LEAVE_CATEGORY_CREATE_SUCCESS";
export const LEAVE_CATEGORY_CREATE_FAIL = "LEAVE_CATEGORY_CREATE_FAIL";

export const ADMIN_LEAVE_CARDS_DATA_REQUEST = "ADMIN_LEAVE_CARDS_DATA_REQUEST";
export const ADMIN_LEAVE_CARDS_DATA_SUCCESS = "ADMIN_LEAVE_CARDS_DATA_SUCCESS";
export const ADMIN_LEAVE_CARDS_DATA_FAIL = "ADMIN_LEAVE_CARDS_DATA_FAIL";

export const LEAVE_UPDATE_REQUEST = "LEAVE_UPDATE_REQUEST";
export const LEAVE_UPDATE_SUCCESS = "LEAVE_UPDATE_SUCCESS";
export const LEAVE_UPDATE_FAIL = "LEAVE_UPDATE_FAIL";

export const REMOVE_LEAVE_REQUEST = "REMOVE_LEAVE_REQUEST";
export const REMOVE_LEAVE_SUCCESS = "REMOVE_LEAVE_SUCCESS";
export const REMOVE_LEAVE_FAIL = "REMOVE_LEAVE_FAIL";
export const ADMIN_CREATED_LEAVE_UPDATE_REQUEST =
  "ADMIN_CREATED_LEAVE_UPDATE_REQUEST";
export const ADMIN_CREATED_LEAVE_UPDATE_SUCCESS =
  "ADMIN_CREATED_LEAVE_UPDATE_SUCCESS";
export const ADMIN_CREATED_LEAVE_UPDATE_FAIL =
  "ADMIN_CREATED_LEAVE_UPDATE_FAIL";
export const LEAVE_SEARCH_REQUEST = "LEAVE_SEARCH_REQUEST";
export const LEAVE_SEARCH_SUCCESS = "LEAVE_SEARCH_SUCCESS";
export const LEAVE_SEARCH_FAIL = "LEAVE_SEARCH_FAIL";

// MAIL DATA CONST
export const MAIL_REQUEST = "MAIL_REQUEST";
export const MAIL_SUCCESS = "MAIL_SUCCESS";
export const MAIL_FAIL = "MAIL_FAIL";
export const MAIL_BY_ID_REQUEST = "MAIL_BY_ID_REQUEST";
export const MAIL_BY_ID_SUCCESS = "MAIL_BY_ID_SUCCESS";
export const MAIL_BY_ID_FAIL = "MAIL_BY_ID_FAIL";
export const MAIL_CREATE_REQUEST = "MAIL_CREATE_REQUEST";
export const MAIL_CREATE_SUCCESS = "MAIL_CREATE_SUCCESS";
export const MAIL_CREATE_FAIL = "MAIL_CREATE_FAIL";
export const UPDATE_MAIL_BY_ID_REQUEST = "UPDATE_MAIL_BY_ID_REQUEST";
export const UPDATE_MAIL_BY_ID_SUCCESS = "UPDATE_MAIL_BY_ID_SUCCESS";
export const UPDATE_MAIL_BY_ID_FAIL = "UPDATE_MAIL_BY_ID_FAIL";
export const UPDATE_MAILSEEN_BY_ID_REQUEST = "UPDATE_MAILSEEN_BY_ID_REQUEST";
export const UPDATE_MAILSEEN_BY_ID_SUCCESS = "UPDATE_MAILSEEN_BY_ID_SUCCESS";
export const UPDATE_MAILSEEN_BY_ID_FAIL = "UPDATE_MAILSEEN_BY_ID_FAIL";

// ADMIN DASHBOARD CARD CONST
export const ADMIN_DASHBOARD_CARD_REQUEST = "ADMIN_DASHBOARD_CARD_REQUEST";
export const ADMIN_DASHBOARD_CARD_SUCCESS = "ADMIN_DASHBOARD_CARD_SUCCESS";
export const ADMIN_DASHBOARD_CARD_FAIL = "ADMIN_DASHBOARD_CARD_FAIL";
export const ADMIN_REPORT_SHIFT_WEEKLY_REQUEST =
  "ADMIN_REPORT_SHIFT_WEEKLY_REQUEST";
export const ADMIN_REPORT_SHIFT_WEEKLY_SUCCESS =
  "ADMIN_REPORT_SHIFT_WEEKLY_SUCCESS";
export const ADMIN_REPORT_SHIFT_WEEKLY_FAIL = "ADMIN_REPORT_SHIFT_WEEKLY_FAIL";
export const ADMIN_REPORT_SHIFT_DAILY_REQUEST =
  "ADMIN_REPORT_SHIFT_DAILY_REQUEST";
export const ADMIN_REPORT_SHIFT_DAILY_SUCCESS =
  "ADMIN_REPORT_SHIFT_DAILY_SUCCESS";
export const ADMIN_REPORT_SHIFT_DAILY_FAIL = "ADMIN_REPORT_SHIFT_DAILY_FAIL";
export const ADMIN_USERPAYMENTINFO_REQUEST = "ADMIN_USERPAYMENTINFO_REQUEST";
export const ADMIN_USERPAYMENTINFO_SUCCESS = "ADMIN_USERPAYMENTINFO_SUCCESS";
export const ADMIN_USERPAYMENTINFO_FAIL = "ADMIN_USERPAYMENTINFO_FAIL";
export const ADMIN_REPORT_TIME_CLOCK_WEEKLY_REQUEST =
  "ADMIN_REPORT_TIME_CLOCK_WEEKLY_REQUEST";
export const ADMIN_REPORT_TIME_CLOCK_WEEKLY_SUCCESS =
  "ADMIN_REPORT_TIME_CLOCK_WEEKLY_SUCCESS";
export const ADMIN_REPORT_TIME_CLOCK_WEEKLY_FAIL =
  "ADMIN_REPORT_TIME_CLOCK_WEEKLY_FAIL";
export const ADMIN_REPORT_TIME_CLOCK_DAILY_REQUEST =
  "ADMIN_REPORT_TIME_CLOCK_DAILY_REQUEST";
export const ADMIN_REPORT_TIME_CLOCK_DAILY_SUCCESS =
  "ADMIN_REPORT_TIME_CLOCK_DAILY_SUCCESS";
export const ADMIN_REPORT_TIME_CLOCK_DAILY_FAIL =
  "ADMIN_REPORT_TIME_CLOCK_DAILY_FAIL";
export const ADMIN_SHIFTALLUSERS_REQUEST = "ADMIN_SHIFTALLUSERS_REQUEST";
export const ADMIN_SHIFTALLUSERS_SUCCESS = "ADMIN_SHIFTALLUSERS_SUCCESS";
export const ADMIN_SHIFTALLUSERS_FAIL = "ADMIN_SHIFTALLUSERS_FAIL";
export const SEARCH_EMPLOYEE_REQUEST = "SEARCH_EMPLOYEE_REQUEST";
export const SEARCH_EMPLOYEE_SUCCESS = "SEARCH_EMPLOYEE_SUCCESS";
export const SEARCH_EMPLOYEE_FAIL = "SEARCH_EMPLOYEE_FAIL";
export const SEARCH_REPORT_TIMECLOCK_REQUEST =
  "SEARCH_REPORT_TIMECLOCK_REQUEST";
export const SEARCH_REPORT_TIMECLOCK_SUCCESS =
  "SEARCH_REPORT_TIMECLOCK_SUCCESS";
export const SEARCH_REPORT_TIMECLOCK_FAIL = "SEARCH_REPORT_TIMECLOCK_FAIL";
export const SEARCH_SALARYCALENDAR_DATA_REQUEST =
  "SEARCH_SALARYCALENDAR_DATA_REQUEST";
export const SEARCH_SALARYCALENDAR_DATA_SUCCESS =
  "SEARCH_SALARYCALENDAR_DATA_SUCCESS";
export const SEARCH_SALARYCALENDAR_DATA_FAIL =
  "SEARCH_SALARYCALENDAR_DATA_FAIL";
export const SEARCH_REPORT_SHIFT_REQUEST = "SEARCH_REPORT_SHIFT_REQUEST";
export const SEARCH_REPORT_SHIFT_SUCCESS = "SEARCH_REPORT_SHIFT_SUCCESS";
export const SEARCH_REPORT_SHIFT_FAIL = "SEARCH_REPORT_SHIFT_FAIL";

// TRAINING CONST
export const TRAINING_CREATE_REQUEST = "TRAINING_CREATE_REQUEST";
export const TRAINING_CREATE_SUCCESS = "TRAINING_CREATE_SUCCESS";
export const TRAINING_CREATE_FAIL = "TRAINING_CREATE_FAIL";

export const TRAINING_UPDATE_REQUEST = "TRAINING_UPDATE_REQUEST";
export const TRAINING_UPDATE_SUCCESS = "TRAINING_UPDATE_SUCCESS";
export const TRAINING_UPDATE_FAIL = "TRAINING_UPDATE_FAIL";
export const REMOVE_TRAINING_REQUEST = "REMOVE_TRAINING_REQUEST";
export const REMOVE_TRAINING_SUCCESS = "REMOVE_TRAINING_SUCCESS";
export const REMOVE_TRAINING_FAIL = "REMOVE_TRAINING_FAIL";

// CREATE HOLIDAY CONST
export const HOLIDAY_CREATE_REQUEST = "HOLIDAY_CREATE_REQUEST";
export const HOLIDAY_CREATE_SUCCESS = "HOLIDAY_CREATE_SUCCESS";
export const HOLIDAY_CREATE_FAIL = "HOLIDAY_CREATE_FAIL";
export const HOLIDAY_TABLE_REQUEST = "HOLIDAY_TABLE_REQUEST";
export const HOLIDAY_TABLE_SUCCESS = "HOLIDAY_TABLE_SUCCESS";
export const HOLIDAY_TABLE_FAIL = "HOLIDAY_TABLE_FAIL";
export const HOLIDAY_REQUEST = "HOLIDAY_REQUEST";
export const HOLIDAY_SUCCESS = "HOLIDAY_SUCCESS";
export const HOLIDAY_FAIL = "HOLIDAY_FAIL";
export const HOLIDAY_UPDATE_REQUEST = "HOLIDAY_UPDATE_REQUEST";
export const HOLIDAY_UPDATE_SUCCESS = "HOLIDAY_UPDATE_SUCCESS";
export const HOLIDAY_UPDATE_FAIL = "HOLIDAY_UPDATE_FAIL";
export const REMOVE_HOLIDAY_REQUEST = "REMOVE_HOLIDAY_REQUEST";
export const REMOVE_HOLIDAY_SUCCESS = "REMOVE_HOLIDAY_SUCCESS";
export const REMOVE_HOLIDAY_FAIL = "REMOVE_HOLIDAY_FAIL";

// ADMIN ADD NEW EMPLOYEE
export const EMPLOYEE_CREATE_REQUEST = "EMPLOYEE_CREATE_REQUEST";
export const EMPLOYEE_CREATE_SUCCESS = "EMPLOYEE_CREATE_SUCCESS";
export const EMPLOYEE_CREATE_FAIL = "EMPLOYEE_CREATE_FAIL";

// ADMIN ADD NEW EMPLOYEE
export const POLICY_CREATE_REQUEST = "POLICY_CREATE_REQUEST";
export const POLICY_CREATE_SUCCESS = "POLICY_CREATE_REQUEST";
export const POLICY_CREATE_FAIL = "POLICY_CREATE_REQUEST";
export const POLICY_TABLE_REQUEST = "POLICY_TABLE_REQUEST";
export const POLICY_TABLE_SUCCESS = "POLICY_TABLE_SUCCESS";
export const POLICY_TABLE_FAIL = "POLICY_TABLE_FAIL";
export const POLICY_UPDATE_REQUEST = "POLICY_UPDATE_REQUEST";
export const POLICY_UPDATE_SUCCESS = "POLICY_UPDATE_SUCCESS";
export const POLICY_UPDATE_FAIL = "POLICY_UPDATE_FAIL";
export const REMOVE_POLICY_REQUEST = "REMOVE_POLICY_REQUEST";
export const REMOVE_POLICY_SUCCESS = "REMOVE_POLICY_SUCCESS";
export const REMOVE_POLICY_FAIL = "REMOVE_POLICY_FAIL";

// AMDIN ADD NEW BREAKRULE
export const BREAKRULE_CREATE_REQUEST = "BREAKRULE_CREATE_REQUEST";
export const BREAKRULE_CREATE_SUCCESS = "BREAKRULE_CREATE_SUCCESS";
export const BREAKRULE_CREATE_FAIL = "BREAKRULE_CREATE_FAIL";
export const BREAKRULE_REQUEST = "BREAKRULE_REQUEST";
export const BREAKRULE_SUCCESS = "BREAKRULE_SUCCESS";
export const BREAKRULE_FAIL = "BREAKRULE_FAIL";
export const BREAKRULE_UPDATE_REQUEST = "BREAKRULE_UPDATE_REQUEST";
export const BREAKRULE_UPDATE_SUCCESS = "BREAKRULE_UPDATE_SUCCESS";
export const BREAKRULE_UPDATE_FAIL = "BREAKRULE_UPDATE_FAIL";
export const BREAKRULE_REMOVE_REQUEST = "BREAKRULE_REMOVE_REQUEST";
export const BREAKRULE_REMOVE_SUCCESS = "BREAKRULE_REMOVE_SUCCESS";
export const BREAKRULE_REMOVE_FAIL = "BREAKRULE_REMOVE_FAIL";

//  AMDIN ADD NEW SHIFT AND SCHEDULE
export const SHIFT_CREATE_REQUEST = "SHIFT_CREATE_REQUEST";
export const SHIFT_CREATE_SUCCESS = "SHIFT_CREATE_SUCCESS";
export const SHIFT_CREATE_FAIL = "SHIFT_CREATE_FAIL";

// PAYROLL ADD NEW ADDITION ADD NEW OVERTIME ADD NEW DEDUCTION
export const ADDITION_CREATE_REQUEST = "ADDITION_CREATE_REQUEST";
export const ADDITION_CREATE_SUCCESS = "ADDITION_CREATE_SUCCESS";
export const ADDITION_CREATE_FAIL = "ADDITION_CREATE_FAIL";
export const OVERTIME_CREATE_REQUEST = "OVERTIME_CREATE_REQUEST";
export const OVERTIME_CREATE_SUCCESS = "OVERTIME_CREATE_SUCCESS";
export const OVERTIME_CREATE_FAIL = "OVERTIME_CREATE_FAIL";
export const DEDUCTION_CREATE_REQUEST = "DEDUCTION_CREATE_REQUEST";
export const DEDUCTION_CREATE_SUCCESS = "DEDUCTION_CREATE_SUCCESS";
export const DEDUCTION_CREATE_FAIL = "DEDUCTION_CREATE_FAIL";
export const PAYROLL_ADDITION_TABLE_REQUEST = "ADDITION_TABLE_REQUEST";
export const PAYROLL_ADDITION_TABLE_SUCCESS = "ADDITION_TABLE_SUCCESS";
export const PAYROLL_ADDITION_TABLE_FAIL = "ADDITION_TABLE_FAIL";
export const PAYROLL_OVERTIME_TABLE_REQUEST = "PAYROLL_OVERTIME_TABLE_REQUEST";
export const PAYROLL_OVERTIME_TABLE_SUCCESS = "PAYROLL_OVERTIME_TABLE_SUCCESS";
export const PAYROLL_OVERTIME_TABLE_FAIL = "PAYROLL_OVERTIME_TABLE_FAIL";
export const PAYROLL_DEDUCTION_TABLE_REQUEST =
  "PAYROLL_DEDUCTION_TABLE_REQUEST";
export const PAYROLL_DEDUCTION_TABLE_SUCCESS =
  "PAYROLL_DEDUCTION_TABLE_SUCCESS";
export const PAYROLL_DEDUCTION_TABLE_FAIL = "PAYROLL_DEDUCTION_TABLE_FAIL";
export const MONTHLY_SALARY_DATA_CREATE_REQUEST =
  "MONTHLY_SALARY_DATA_CREATE_REQUEST";
export const MONTHLY_SALARY_DATA_CREATE_SUCCESS =
  "MONTHLY_SALARY_DATA_CREATE_SUCCESS";
export const MONTHLY_SALARY_DATA_CREATE_FAIL =
  "MONTHLY_SALARY_DATA_CREATE_FAIL";
export const PAYROLL_ADDITION_UPDATE_REQUEST =
  "PAYROLL_ADDITION_UPDATE_REQUEST";
export const PAYROLL_ADDITION_UPDATE_SUCCESS =
  "PAYROLL_ADDITION_UPDATE_SUCCESS";
export const PAYROLL_ADDITION_UPDATE_FAIL = "PAYROLL_ADDITION_UPDATE_FAIL";
export const PAYROLL_OVERTIME_UPDATE_REQUEST =
  "PAYROLL_OVERTIME_UPDATE_REQUEST";
export const PAYROLL_OVERTIME_UPDATE_SUCCESS =
  "PAYROLL_OVERTIME_UPDATE_SUCCESS";
export const PAYROLL_OVERTIME_UPDATE_FAIL = "PAYROLL_OVERTIME_UPDATE_FAIL";
export const PAYROLL_DEDUCTION_UPDATE_REQUEST =
  "PAYROLL_DEDUCTION_UPDATE_REQUEST";
export const PAYROLL_DEDUCTION_UPDATE_SUCCESS =
  "PAYROLL_DEDUCTION_UPDATE_SUCCESS";
export const PAYROLL_DEDUCTION_UPDATE_FAIL = "PAYROLL_DEDUCTION_UPDATE_FAIL";
export const PAYROLL_ADDITION_REMOVE_REQUEST =
  "PAYROLL_ADDITION_REMOVE_REQUEST";
export const PAYROLL_ADDITION_REMOVE_SUCCESS =
  "PAYROLL_ADDITION_REMOVE_SUCCESS";
export const PAYROLL_ADDITION_REMOVE_FAIL = "PAYROLL_ADDITION_REMOVE_FAIL";
export const PAYROLL_OVERTIME_REMOVE_REQUEST =
  "PAYROLL_OVERTIME_REMOVE_REQUEST";
export const PAYROLL_OVERTIME_REMOVE_SUCCESS =
  "PAYROLL_OVERTIME_REMOVE_SUCCESS";
export const PAYROLL_OVERTIME_REMOVE_FAIL = "PAYROLL_OVERTIME_REMOVE_FAIL";
export const PAYROLL_DEDUCTION_REMOVE_REQUEST =
  "PAYROLL_DEDUCTION_REMOVE_REQUEST";
export const PAYROLL_DEDUCTION_REMOVE_SUCCESS =
  "PAYROLL_DEDUCTION_REMOVE_SUCCESS";
export const PAYROLL_DEDUCTION_REMOVE_FAIL = "PAYROLL_DEDUCTION_REMOVE_FAIL";
export const EMPLOYEE_SALARY_UPDATE_REQUEST = "EMPLOYEE_SALARY_UPDATE_REQUEST";
export const EMPLOYEE_SALARY_UPDATE_SUCCESS = "EMPLOYEE_SALARY_UPDATE_SUCCESS";
export const EMPLOYEE_SALARY_UPDATE_FAIL = "EMPLOYEE_SALARY_UPDATE_FAIL";
export const EMPLOYEE_SALARY_REMOVE_REQUEST = "EMPLOYEE_SALARY_REMOVE_REQUEST";
export const EMPLOYEE_SALARY_REMOVE_SUCCESS = "EMPLOYEE_SALARY_REMOVE_SUCCESS";
export const EMPLOYEE_SALARY_REMOVE_FAIL = "EMPLOYEE_SALARY_REMOVE_FAIL";

//  ADMIN SALARY CALENDER CONS
export const SALARY_CALENDER_REQUEST = "SALARY_CALENDER_REQUEST";
export const SALARY_CALENDER_SUCCESS = "SALARY_CALENDER_SUCCESS";
export const SALARY_CALENDER_FAIL = "SALARY_CALENDER_FAIL";

// ADMIN ADD NEW MONTHLY SALARY
export const MONTHLY_SALARY_CREATE_REQUEST = "MONTHLY_SALARY_CREATE_REQUEST";
export const MONTHLY_SALARY_CREATE_SUCCESS = "MONTHLY_SALARY_CREATE_SUCCESS";
export const MONTHLY_SALARY_CREATE_FAIL = "MONTHLY_SALARY_CREATE_FAIL";

// ADMIN AND EMPLOYEE FILE MANAGER
export const FILEMANAGER_TABLE_REQUEST = "FILEMANAGER_TABLE_REQUEST";
export const FILEMANAGER_TABLE_SUCCESS = "FILEMANAGER_TABLE_SUCCESS";
export const FILEMANAGER_TABLE_FAIL = "FILEMANAGER_TABLE_FAIL";
export const FILEMANAGER_UPDATE_REQUEST = "FILEMANAGER_UPDATE_REQUEST";
export const FILEMANAGER_UPDATE_SUCCESS = "FILEMANAGER_UPDATE_SUCCESS";
export const FILEMANAGER_UPDATE_FAIL = "FILEMANAGER_UPDATE_FAIL";
export const REMOVE_FILEMANAGER_REQUEST = "REMOVE_FILEMANAGER_REQUEST";
export const REMOVE_FILEMANAGER_SUCCESS = "REMOVE_FILEMANAGER_SUCCESS";
export const REMOVE_FILEMANAGER_FAIL = "REMOVE_FILEMANAGER_FAIL";
export const USER_RECENT_FILES_REQUEST = "USER_RECENT_FILES_REQUEST";
export const USER_RECENT_FILES_SUCCESS = "USER_RECENT_FILES_SUCCESS";
export const USER_RECENT_FILES_FAIL = "USER_RECENT_FILES_FAIL";

// **************************************************************************
// SUPER ADMIN CONSTANT
// COMPANY CONST
export const SUPER_ADMIN_COMPANY_REQUEST = "SUPER_ADMIN_COMPANY_REQUEST";
export const SUPER_ADMIN_COMPANY_SUCCESS = "SUPER_ADMIN_COMPANY_SUCCESS";
export const SUPER_ADMIN_COMPANY_FAIL = "SUPER_ADMIN_COMPANY_FAIL";
export const SUPER_ADMIN_COMPANY_PROFILE_COVER_REQUEST =
  "SUPER_ADMIN_COMPANY_PROFILE_COVER_REQUEST";
export const SUPER_ADMIN_COMPANY_PROFILE_COVER_SUCCESS =
  "SUPER_ADMIN_COMPANY_PROFILE_COVER_SUCCESS";
export const SUPER_ADMIN_COMPANY_PROFILE_COVER_FAIL =
  "SUPER_ADMIN_COMPANY_PROFILE_COVER_FAIL";
export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";
export const REMOVE_COMPANY_REQUEST = "REMOVE_COMPANY_REQUEST";
export const REMOVE_COMPANY_SUCCESS = "REMOVE_COMPANY_SUCCESS";
export const REMOVE_COMPANY_FAIL = "REMOVE_COMPANY_FAIL";
export const NEWLEAD_REQUEST = "NEWLEAD_REQUEST";
export const NEWLEAD_SUCCESS = "NEWLEAD_SUCCESS";
export const NEWLEAD_FAIL = "NEWLEAD_FAIL";
export const CREATE_NEWLEAD_REQUEST = "CREATE_NEWLEAD_REQUEST";
export const CREATE_NEWLEAD_SUCCESS = "CREATE_NEWLEAD_SUCCESS";
export const CREATE_NEWLEAD_FAIL = "CREATE_NEWLEAD_FAIL";
export const CREATE_COMPANY_REQUEST = "CREATE_COMPANY_REQUEST";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL";
export const SUBSCRIPTION_REQUEST = "SUBSCRIPTION_REQUEST";
export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS";
export const SUBSCRIPTION_FAIL = "SUBSCRIPTION_FAIL";
export const SUBSCRIPTION_UPDATE_REQUEST = "SUBSCRIPTION_UPDATE_REQUEST";
export const SUBSCRIPTION_UPDATE_SUCCESS = "SUBSCRIPTION_UPDATE_SUCCESS";
export const SUBSCRIPTION_UPDATE_FAIL = "SUBSCRIPTION_UPDATE_FAIL";
export const SUPER_ADMIN_INVOICES_QUERY_COMPANY_REQUEST =
  "SUPER_ADMIN_INVOICES_QUERY_COMPANY_REQUEST";
export const SUPER_ADMIN_INVOICES_QUERY_COMPANY_SUCCESS =
  "SUPER_ADMIN_INVOICES_QUERY_COMPANY_SUCCESS";
export const SUPER_ADMIN_INVOICES_QUERY_COMPANY_FAIL =
  "SUPER_ADMIN_INVOICES_QUERY_COMPANY_FAIL";
export const SUPER_ADMIN_DASHBOARD_CARD_REQUEST =
  "SUPER_ADMIN_DASHBOARD_CARD_REQUEST";
export const SUPER_ADMIN_DASHBOARD_CARD_SUCCESS =
  "SUPER_ADMIN_DASHBOARD_CARD_SUCCESS";
export const SUPER_ADMIN_DASHBOARD_CARD_FAIL =
  "SUPER_ADMIN_DASHBOARD_CARD_FAIL";
export const SUPER_ADMIN_ADMIN_LOGIN_INFO_REQUEST =
  "SUPER_ADMIN_ADMIN_LOGIN_INFO_REQUEST";
export const SUPER_ADMIN_ADMIN_LOGIN_INFO_SUCCESS =
  "SUPER_ADMIN_ADMIN_LOGIN_INFO_SUCCESS";
export const SUPER_ADMIN_ADMIN_LOGIN_INFO_FAIL =
  "SUPER_ADMIN_ADMIN_LOGIN_INFO_FAIL";
export const GET_CONTACTUS_REQUEST = "GET_CONTACTUS_REQUEST";
export const GET_CONTACTUS_SUCCESS = "GET_CONTACTUS_SUCCESS";
export const GET_CONTACTUS_FAIL = "GET_CONTACTUS_FAIL";
export const GET_CONTACTUS_BY_ID_REQUEST = "GET_CONTACTUS_BY_ID_REQUEST";
export const GET_CONTACTUS_BY_ID_SUCCESS = "GET_CONTACTUS_BY_ID_SUCCESS";
export const GET_CONTACTUS_BY_ID_FAIL = "GET_CONTACTUS_BY_ID_FAIL";
export const CREATE_CONTACTUS_MSG_REQUEST = "CREATE_CONTACTUS_MSG_REQUEST";
export const CREATE_CONTACTUS_MSG_SUCCESS = "CREATE_CONTACTUS_MSG_SUCCESS";
export const CREATE_CONTACTUS_MSG_FAIL = "CREATE_CONTACTUS_MSG_FAIL";
export const SUPER_ADMIN_SEARCH_COMPANY_REQUEST =
  "SUPER_ADMIN_SEARCH_COMPANY_REQUEST";
export const SUPER_ADMIN_SEARCH_COMPANY_SUCCESS =
  "SUPER_ADMIN_SEARCH_COMPANY_SUCCESS";
export const SUPER_ADMIN_SEARCH_COMPANY_FAIL =
  "SUPER_ADMIN_SEARCH_COMPANY_FAIL";

// PACKAGE CONST
export const PACKAGE_REQUEST = "PACKAGE_REQUEST";
export const PACKAGE_SUCCESS = "PACKAGE_SUCCESS";
export const PACKAGE_FAIL = "PACKAGE_FAIL";
export const PACKAGE_UPDATE_REQUEST = "PACKAGE_UPDATE_REQUEST";
export const PACKAGE_UPDATE_SUCCESS = "PACKAGE_UPDATE_SUCCESS";
export const PACKAGE_UPDATE_FAIL = "PACKAGE_UPDATE_FAIL";
export const PACKAGE_CREATE_REQUEST = "PACKAGE_CREATE_REQUEST";
export const PACKAGE_CREATE_SUCCESS = "PACKAGE_CREATE_SUCCESS";
export const PACKAGE_CREATE_FAIL = "PACKAGE_CREATE_FAIL";

// Chat Constants
export const CHAT_CREATE_REQUEST = "CHAT_CREATE_REQUEST";
export const CHAT_CREATE_SUCCESS = "CHAT_CREATE_SUCCESS";
export const CHAT_CREATE_FAIL = "CHAT_CREATE_FAIL";

export const GROUP_CREATE_REQUEST = "GROUP_CREATE_REQUEST";
export const GROUP_CREATE_SUCCESS = "GROUP_CREATE_SUCCESS";
export const GROUP_CREATE_FAIL = "GROUP_CREATE_FAIL";

export const CHATS_GET_REQUEST = "CHATS_GET_REQUEST";
export const CHATS_GET_SUCCESS = "CHATS_GET_SUCCESS";
export const CHATS_GET_FAIL = "CHATS_GET_FAIL";

export const READ_CHAT_REQUEST = "READ_CHAT_REQUEST";
export const READ_CHAT_SUCCESS = "READ_CHAT_SUCCESS";
export const READ_CHAT_FAIL = "READ_CHAT_FAIL";

export const CHAT_SELECT_REQUEST = "CHAT_SELECT_REQUEST";
export const CHAT_SELECT_SUCCESS = "CHAT_SELECT_SUCCESS";
export const CHAT_SELECT_FAIL = "CHAT_SELECT_FAIL";

export const MESSAGE_GET_REQUEST = "MESSAGE_GET_REQUEST";
export const MESSAGE_GET_SUCCESS = "MESSAGE_GET_SUCCESS";
export const MESSAGE_GET_FAIL = "MESSAGE_GET_FAIL";

export const MESSAGE_SEND_REQUEST = "MESSAGE_SEND_REQUEST";
export const MESSAGE_SEND_SUCCESS = "MESSAGE_SEND_SUCCESS";
export const MESSAGE_SEND_FAIL = "MESSAGE_SEND_FAIL";

export const MESSAGE_RECEIVE_SUCCESS = "MESSAGE_RECEIVE_SUCCESS";

export const READ_MESSAGES_REQUEST = "READ_MESSAGES_REQUEST";
export const READ_MESSAGES_SUCCESS = "READ_MESSAGES_SUCCESS";
export const READ_MESSAGES_FAIL = "READ_MESSAGES_FAIL";

export const SOCKET_CONNECTED = "SOCKET_CONNECTED";
export const SOCKET_DISCONNECT = "SOCKET_DISCONNECT";

export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";

export const NOTIFICATION_RECEIVE_SUCCESS = "NOTIFICATION_RECEIVE_SUCCESS";

export const READ_NOTIFICATIONS_REQUEST = "READ_NOTIFICATIONS_REQUEST";
export const READ_NOTIFICATIONS_SUCCESS = "READ_NOTIFICATIONS_SUCCESS";
export const READ_NOTIFICATIONS_FAIL = "READ_NOTIFICATIONS_FAIL";
