import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import AdminLeavesTableSearchRow from './AdminLeavesTableSearchRow'
const AdminLeavesTableSearch = () => {
    const leave = useSelector((state) => state.leave)
    const [ dropDownRow, setDropDownRow ] = useState(null)
    const [ closeDropDownRow, setCloseDropDownRow ] = useState(null)
    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <table className="table table-striped custom-table mb-0 datatable">
                        <thead>
                            <tr>
                                <th>Employee</th>
                                <th>Leave Type</th>
                                <th>From</th>
                                <th>To</th>
                                <th>No of Days</th>
                                <th>Reason</th>
                                <th className="text-center">Status</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            leave.leaveSearch &&
                            leave.leaveSearch.length > 0 &&
                            leave.leaveSearch.map((curr, ind) => {
                                return(
                                    curr &&
                                    curr.length > 0 &&
                                    curr.map((current, i) => {
                                        return(
                                            <AdminLeavesTableSearchRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow} closeDropDownRow={closeDropDownRow} setCloseDropDownRow={setCloseDropDownRow}/>
                                        )
                                    })
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default AdminLeavesTableSearch
