import axios from "axios"
import {
    POLICY_CREATE_REQUEST,
    POLICY_CREATE_SUCCESS,
    POLICY_CREATE_FAIL,
    POLICY_TABLE_REQUEST,
    POLICY_TABLE_SUCCESS,
    POLICY_TABLE_FAIL,
    POLICY_UPDATE_REQUEST,
    POLICY_UPDATE_SUCCESS,
    POLICY_UPDATE_FAIL,
    REMOVE_POLICY_REQUEST,
    REMOVE_POLICY_SUCCESS,
    REMOVE_POLICY_FAIL,
    SERVERADDRESS,
} from "../constants"

// ADMIN ADD NEW POLICY
export const addPolicy = (obj) => async ( dispatch ) => {
    dispatch({
        type: POLICY_CREATE_REQUEST,
    })
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/policy/createPolicy`, obj)
        dispatch({
            type: POLICY_CREATE_SUCCESS,
            payload: data,
        })
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: POLICY_CREATE_FAIL,
            payload: err,
        })
    }
}


// ADMIN GET POLICY TABLE
export const getCompanyPolicy = (obj, pageNum) => async (dispatch) => {
    dispatch({
        type: POLICY_TABLE_REQUEST,
    });
    try {
        const { data } = await axios.post(`${SERVERADDRESS}/v1/policy/getCompanyPolicy`, obj);
        localStorage.setItem("policyTable", JSON.stringify(data));
        dispatch({
            type: POLICY_TABLE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: POLICY_TABLE_FAIL,
            payload: err,
        });
    }
};



// UPDATE POLICY
export const updatePolicy = (obj) => async (dispatch) => {
    dispatch({
        type: POLICY_UPDATE_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/policy/updatePolicy`, obj);
        dispatch({
            type: POLICY_UPDATE_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: POLICY_UPDATE_FAIL,
            payload: err,
        });
    }
};


// REMOVE USER BY ADMIN
export const removePolicy = (obj) => async (dispatch) => {
    dispatch({
        type: REMOVE_POLICY_REQUEST,
    });
    try {
        const { data } = await axios.put(`${SERVERADDRESS}/v1/policy/updatePolicy`, obj);
        dispatch({
            type: REMOVE_POLICY_SUCCESS,
            payload: data,
        });
    }
    catch (err) {
        console.log(err)
        dispatch({
            type: REMOVE_POLICY_FAIL,
            payload: err,
        });
    }
};