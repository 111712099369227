import {
    POLICY_CREATE_REQUEST,
    POLICY_CREATE_SUCCESS,
    POLICY_CREATE_FAIL,
    POLICY_TABLE_REQUEST,
    POLICY_TABLE_SUCCESS,
    POLICY_TABLE_FAIL,
    POLICY_UPDATE_REQUEST,
    POLICY_UPDATE_SUCCESS,
    POLICY_UPDATE_FAIL,
    REMOVE_POLICY_REQUEST,
    REMOVE_POLICY_SUCCESS,
    REMOVE_POLICY_FAIL,
    USER_LOGOUT_SUCCESS,
} from "../constants"

export const policyReducer = (
    state = {
        policy : {
            // policy: null,
            policyTable: null,
        }
    },
    action
) => {
        switch (action.type) {

        // USER LOGOUT
        case USER_LOGOUT_SUCCESS:
            return {
                policyTable: null,
            };

        // POLICY CREATE
        case POLICY_CREATE_REQUEST :
            return {
                ...state,
            };
        case POLICY_CREATE_SUCCESS :
            return {
                ...state,
                policyTable: [...state.policyTable, action.payload],
            };
        case POLICY_CREATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case POLICY_TABLE_REQUEST : 
            return {
                ...state,
            };
        case POLICY_TABLE_SUCCESS :
            return {
                ...state,
                policyTable : action.payload,
            };
        case POLICY_TABLE_FAIL :
            return {
                ...state,
                error: action.payload,
            };


        case POLICY_UPDATE_REQUEST :
            return {
                ...state,
            };
        case POLICY_UPDATE_SUCCESS :
            return {
                ...state,
                // policyTable: [...state.policyTable, action.payload],
                policyTable: {...state.policyTable, results:state.policyTable.results.map((x) =>
                x.id === action.payload.id ? action.payload : x
                )},
            };
        case POLICY_UPDATE_FAIL :
            return {
                ...state,
                error: action.payload,
            };

        case REMOVE_POLICY_REQUEST : 
            return {
                ...state,
            };
        case REMOVE_POLICY_SUCCESS :
            return {
                ...state,
            // policyTable: [...state.policyTable, action.payload],
            //     policyTable: state.policyTable.map((x) =>
            //     x.id === action.payload.id ? action.payload : x
            //   ),
                policyTable: state.policyTable.filter((x) => x.id !== action.payload.id),
            };
        case REMOVE_POLICY_FAIL :
            return {
                ...state,
                error: action.payload,
            };

    
        default:
            return state;
    }
}