import React, { useEffect } from 'react'
import { getBreakruleByCompanyId } from '../../../actions/breakruleAction'
import { useDispatch, useSelector } from 'react-redux'
import BreakRulesTableRow from './BreakRulesTableRow'
const BreakRules = () => {
   // const user = useSelector((state) => state.user)
   const auth = useSelector((state) => state.auth)
   const breakrule = useSelector((state) => state.breakrule)
   const dispatch = useDispatch()
   const breakRules = async (e) => {
      dispatch(getBreakruleByCompanyId({companyId: auth.user.companyId }))
   }
   useEffect(() => {
      breakRules()
   }, [])
   return (
      <>
         <div className="row">
            <div className="col-lg-12">
            {
               breakrule.breakRules &&
               breakrule.breakRules.length > 0 &&
               breakrule.breakRules.map((current, i) => {
                  return (
                     <BreakRulesTableRow current={current} i={i}/>
                  )
               })
            }
            </div>
         </div>
      </>
   )
}
export default BreakRules