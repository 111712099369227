import React, { useEffect } from "react";
import SuperAdminHeader from "../../components/superAdminCompoents/SuperAdminHeader";
import AdminTittle from "../../components/adminComponents/AdminTittle";
import SuperAdminSidebar from "../../components/superAdminCompoents/SuperAdminSidebar";
import SuperAdminDashboardCards from "../../components/superAdminCompoents/superAdminDashboard/SuperAdminDashboardCards";
import SuperAdminShiftCards from "../../components/superAdminCompoents/superAdminDashboard/SuperAdminShiftCards";
import SuperAdminExtraCards from "../../components/superAdminCompoents/superAdminDashboard/SuperAdminExtraCards";
import SuperAdminInvoiceTable from "../../components/superAdminCompoents/superAdminDashboard/SuperAdminInvoiceTable";
import {
  cardsDetails,
  getStripeInovicesQueryAllCompanies,
} from "../../actions/superAdminCompanyActions";
import { getMailByUserId } from "../../actions/mailActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const SuperAdminDashboard = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const invoiceData = async () => {
    dispatch(getStripeInovicesQueryAllCompanies());
  };
  const cardData = async () => {
    dispatch(
      cardsDetails({
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
      })
    );
  };
  const mailData = async () => {
    dispatch(getMailByUserId({ userId: auth.user.id }));
  };
  useEffect(() => {
    invoiceData();
    cardData();
    mailData();
  }, []);

  return (
    <>
      {/* <SuperAdminHeader /> */}
      {/* <SuperAdminHeader />
            <SuperAdminSidebar /> */}
      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <AdminTittle title="Welcome Super Admin!" />
            <SuperAdminDashboardCards />
            <SuperAdminShiftCards />
            <SuperAdminExtraCards />
            <SuperAdminInvoiceTable />
          </div>
        </div>
      </div>
    </>
  );
};
export default SuperAdminDashboard;
