import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import AddNewTrainingModal from './adminTraining/AddNewTrainingModal'
import AdminAPModal from './adminPoliciesView/AdminAPModal'
import AdminAUModal from './adminUserView/AdminAUModal'  
import AdminSSAddShiftModal from './adminSScheduling/AdminSSAddShiftModal'
import AdminEmployeeMenu from './adminAllEmployeeComponents/AdminEmployeeMenu'
import AdminPayrollandEmployeesSal from './adminPayrollItems/AdminPayrollandEmployeeSal'
import AdminTClk from './adminTimeClock/AdminTClk'
import AdminLeaveNavItem from './adminLeaveComponents/AdminLeaveNavItem'
import AdminLandFMTab from './adminLocationAdministration/AdminLandFMTab'
import AdminSubscriptionMenu from './adminSubscriptionCompany/AdminSubscriptionMenu'
import CompAndSubsNavItem from '../superAdminCompoents/superAdminCompanies/CompAndSubsNavItem'
import SAdminAddPolicyModal from '../superAdminCompoents/superAdminPoliciesAdministration/SAdminAddPolicyModal'

const AdminTittle = ({title, admin, payroll, training, employee, leave, location, timeclockpage, policyyy, userr, SScheduling, subscription, ComposeMessage,
    CompAndSubsNav, SAdminAddPolicy, SuperAdminComposeMessage, SAdminComposeMsgAllAdmin, SAdminComposeMsgAllUsers,

}) => {
    const [showDropDown, setshowDropDown] = useState(false)
    const openDropDown = () => {
        setshowDropDown((showDropDown) => !showDropDown)
    }
    // console.log(showDropDown)
    return (
        <>
            { admin &&
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12">
                            <h3 className="page-title">Welcome Admin!</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ul>
                        </div>
                    </div>
                </div>} 

            { !admin &&
                <div className="page-header">
                    <div className="row align-items-center"> 
                        {/* neeche wale classname="col" ko dynamic krna hai */}
                        <div className="col">
                            <h3 className="page-title">{title}</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/dashboard">Dashboard</NavLink></li>
                                <li className="breadcrumb-item active">{title}</li>
                            </ul>
                        </div>

            { training &&
            <>
                <div className="col-auto float-right ml-auto">
                    <NavLink to="#" className="btn add-btn" data-toggle="modal" data-target="#add_training" onClick={openDropDown}><i className="fa fa-plus"></i> Add New </NavLink>
                </div>
                <AddNewTrainingModal showDropDown={showDropDown} openDropDown={openDropDown}/>
            </>
            }

            { policyyy &&
            <>
                <div className="col-auto float-right ml-auto">
                    <NavLink to="#" className="btn add-btn" data-toggle="modal" data-target="#add_policy" onClick={openDropDown}><i className="fa fa-plus"></i> Add Policy</NavLink>
                </div>
                <AdminAPModal showDropDown={showDropDown} openDropDown={openDropDown}/>
            </> 
            }
            { userr &&
            <>
                <div className="col-auto float-right ml-auto">
                    <NavLink to="#" className="btn add-btn" data-toggle="modal" data-target="#add_user" onClick={openDropDown}><i className="fa fa-plus"></i> Add User</NavLink>
                </div>
                <AdminAUModal showDropDown={showDropDown} openDropDown={openDropDown}/> 
            </>
            }
            { SScheduling &&
            <>
                <div className="col-auto float-right ml-auto">
                    {/* <NavLink to="calendar.html" className="btn add-btn"><i className="la la-calendar"></i> Calender</NavLink> */}
                    <NavLink to="#" className="btn add-btn m-r-5 m-l-5" data-toggle="modal" onClick={openDropDown} data-target="#add_schedule"> Add Shifts</NavLink>
                </div>
                <AdminSSAddShiftModal showDropDown={showDropDown} openDropDown={openDropDown} />
            </> 
            }
            { ComposeMessage &&
            <>
                <div className="col-auto float-right ml-auto">
                    <NavLink to="/compose" className="btn add-btn"><i className="fa fa-plus"></i> Compose</NavLink>
                </div>
            </> 
            }
            { SuperAdminComposeMessage &&
            <>
                <div className="col-auto float-right ml-auto">
                    <NavLink to="/compose-message" className="btn add-btn"><i className="fa fa-plus"></i> Compose</NavLink>
                </div>
            </> 
            }
            { SAdminComposeMsgAllAdmin &&
            <>
                <div className="col-auto float-right ml-auto">
                    <NavLink to="/compose-message-to-admin" className="btn add-btn"><i className="fa fa-plus"></i>To All Admin</NavLink>
                </div>
            </> 
            }
            { SAdminComposeMsgAllUsers &&
            <>
                <div className="col-auto float-right ml-auto">
                    <NavLink to="/compose-message-to-users" className="btn add-btn"><i className="fa fa-plus"></i>To All Users</NavLink>
                </div>
            </> 
            }
            { payroll &&
            <>
                <AdminPayrollandEmployeesSal />
            </>
            }
            { employee &&
            <>
                <AdminEmployeeMenu />
            </>
                }

            { location &&
            <>
                <AdminLandFMTab />
            </>
                }
            { leave &&
            <>
                <AdminLeaveNavItem />
            </>
            }
            { timeclockpage &&
            <>
                <AdminTClk />
            </>
            }
            { subscription &&
            <>
                <AdminSubscriptionMenu />
            </>
            }
            {/* super admin kay title aur button k liya */}
            { CompAndSubsNav &&
            <>
                <CompAndSubsNavItem />
            </>
            }
            { SAdminAddPolicy &&
            <>
                <div className="col-auto float-right ml-auto">
                    <NavLink to="#" className="btn add-btn" data-toggle="modal" data-target="#add_policy" onClick={openDropDown}><i className="fa fa-plus"></i> Add Policy</NavLink>
                </div>
                <SAdminAddPolicyModal showDropDown={showDropDown} openDropDown={openDropDown}/>
            </> 
            }  
                </div>
            </div>
            }                
        </>
    )
}
export default AdminTittle


{/* <div className="page-header">
        <div className="row">
            <div className="col">
                <h3 className="page-title">{title}</h3>
                <ul className="breadcrumb">
                    <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
                    <li className="breadcrumb-item active">{title}</li>
                </ul>
            </div> */}