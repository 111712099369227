import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addPolicy } from '../../../actions/policyAction'
// import { authReducer } from '../../../reducers/authReducer'
const AdminAPModal = ({ showDropDown, openDropDown }) => {
    const auth = useSelector((state) => state.auth)
    const [ values, setValues ] = useState({
        companyId: '',
        name: '',
        description: ''
    // upload image ******
    })
    const handleChange = (fieldName) => (event) =>{
        setValues({
            ...values, [fieldName]:
            event.target.value
        })
    }
    // console.log(values)
    const dispatch = useDispatch()
    const policyData = {
        companyId: auth.user.companyId,
        name: values.name,
        description: values.description,
        createdDate: moment().format('YYYY-MM-DD')
    // upload image ******
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        // console.log("first")
        dispatch(addPolicy(policyData))
        // openDropDown()
        setValues({
            companyId: '',
            name: '',
            description: ''
         // upload image ******
        })
    }
    return (
        <>
            <div className={`modal-backdrop fade ${showDropDown ? 'show' : 'hide'}`}></div>
            <div id="add_policy" className={`modal custom-modal fade ${showDropDown ? 'show' : 'hide'}`} role="dialog">
                {/* modal custom-modal fade comiting for display purpose  */}
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Policy</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => openDropDown()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}> 
                                <div className="form-group">
                                    <label>Policy Name <span className="text-danger">*</span></label>
                                    <input className="form-control" type="text"
                                    autoComplete="false" value={values.name}
                                    onChange={handleChange('name')}/>
                                    </div>
                                <div className="form-group">
                                    <label>Description <span className="text-danger">*</span></label>
                                    <textarea className="form-control" rows="4"
                                    autoComplete="false" value={values.description}
                                    onChange={handleChange('description')}></textarea>
                                </div>
                                {/* depart commit kr k neecha krdya hai */}
                                {/* <div className="form-group">
                                    <label>Upload Policy <span className="text-danger">*</span></label>
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" id="policy_upload"/>
                                            <label className="custom-file-label" for="policy_upload">Choose file</label>
                                    </div>
                                </div> */}
                                <div className="submit-section">
                                    <button className="btn btn-primary submit-btn">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminAPModal