import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { getCompanyPolicy } from '../../../actions/policyAction'
import { useDispatch, useSelector } from 'react-redux'
import AdminPoliciesRow from './AdminPoliciesRow'
// import moment from 'moment'
// import EditModalPolicies from './EditModalPolicies'
const AdminPolicies = () => {
    // const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const policy = useSelector((state) => state.policy)
    const [ dropDownRow, setDropDownRow ] = useState(null)
    const dispatch = useDispatch()
    const policyTable = async (e) => {
        dispatch(getCompanyPolicy({companyId: auth.user.companyId, limit: limit, page: currentPageNumber }))
    }
    useEffect(() => {
        policyTable()
    }, [])
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const [limit, setLimit] = useState(10)
    const handleLimit = async (event) =>{
        setLimit(
            parseInt(event.target.value)
        )
        dispatch(getCompanyPolicy({companyId: auth.user.companyId, limit: event.target.value, page: 1 }))
        setCurrentPageNumber(1)
    }
    const onClickNext = async () => {
        let i= currentPageNumber
        i++;
        if (i <= policy.policyTable.totalPages){
            setCurrentPageNumber(currentPageNumber + 1)
            dispatch(getCompanyPolicy({ companyId: auth.user.companyId, limit: limit, page: currentPageNumber + 1 }))
        }
    }
    const onClickPrev = async () => {
        let i= currentPageNumber
        i--;
        if (i >= 1){
            setCurrentPageNumber(currentPageNumber - 1)
            dispatch(getCompanyPolicy({ companyId: auth.user.companyId, limit: limit, page: currentPageNumber - 1 }))
        }
    }
    const handleChangeData = async (pageNo) => {
        setCurrentPageNumber(
            pageNo
        )
        dispatch(getCompanyPolicy({companyId: auth.user.companyId, limit: limit, page: pageNo }))
    }
    return (
        <>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="dataTables_length" id="DataTables_Table_0_length">
                        <label>Show &nbsp;
                            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                            className="select" 
                            autoComplete='false'
                            onChange={handleLimit}>
                            {
                            [10,25,50,100].map((current, i) => {
                            return(
                                <option value={current}>{current}</option>
                                )
                            })}
                            </select> entries
                        </label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {/* <div className="table-responsive"> */}
                        <table className="table table-striped custom-table mb-0 datatable">
                            <thead>
                                <tr>
                                    <th style={{ width: "30px" }}>#</th>
                                    <th>Policy Name </th>
                                    {/* <th>Department </th> */}
                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Description </th>
                                    <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Created </th>
                                    <th className="text-right">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                policy.policyTable &&
                                policy.policyTable.results &&
                                policy.policyTable.results.length > 0 &&
                                policy.policyTable.results.map((current, i) =>{ 
                                    return (
                                    <AdminPoliciesRow current={current} i={i} dropDownRow={dropDownRow} setDropDownRow={setDropDownRow}/>
                                )})}
                            </tbody>
                        </table>
                        {/* </div> */}
                        <br />
                        <div className="row">
                        {
                            policy.policyTable &&
                            <div className="col-sm-12 col-md-5">
                                <label> Showing {1 + (limit * (currentPageNumber-1))} to { policy.policyTable.displayingResults + (limit * (currentPageNumber-1))} of { policy.policyTable.totalResults } entries </label>
                            </div>
                        }
                        <div className="col-sm-12 col-md-7">
                            <div className="avatar-pagination">
                                <ul className="pagination" style={{ justifyContent: 'flex-end' }}>
                                    <li className="page-item">
                                        <NavLink className="page-link" to="#" aria-label="Previous" onClick={onClickPrev}>
                                            <span aria-hidden="true">Previous</span>
                                            <span className="sr-only">Previous</span>
                                        </NavLink>
                                    </li>
                                        {
                                    policy.policyTable &&
                                    policy.policyTable.paginationArr &&
                                    policy.policyTable.paginationArr.length > 0 &&
                                    policy.policyTable.paginationArr.map((current, i) => {
                                        return(
                                            currentPageNumber === current ?
                                            <li className="page-item active" key={i}><a className="page-link" value={current}>{current}</a></li>
                                            :
                                            <li className="page-item" 
                                            key={i}>
                                            <a className="page-link" 
                                            value={current} onClick={ () => handleChangeData(current) }>{current}</a>
                                            </li>
                                        )
                                    })}
                                    <li className="page-item">
                                        <NavLink className="page-link" to="#" aria-label="Next" onClick={onClickNext}>
                                            <span aria-hidden="true">Next</span>
                                            <span className="sr-only">Next</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AdminPolicies