import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { NavLink } from 'react-router-dom'
import { allUsers, getActiveUsers, getDisabledUsers } from '../../../actions/userActions'
import { tabAllStaff } from '../../../actions/tabActions'
import { tabActive } from '../../../actions/tabActions'
import { tabNonActive } from '../../../actions/tabActions'

const AdminEmployeeSideMenu = () => {
    const myState = useSelector((state => state.changeTheTab))
    // const user = useSelector((state) => state.user)
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const allUsersData = async (e) => {
        dispatch(allUsers({companyId: auth.user.companyId}))
    }
    const active = async (e) => {
        dispatch(getActiveUsers({companyId: auth.user.companyId}, 1))
    }
    const nonActive = async (e) => {
        dispatch(getDisabledUsers({companyId: auth.user.companyId}, 1))
    }
    useEffect(()=>{
        allUsersData()
        active()
        nonActive()
    }, [])
    return (
        <>
            {
                myState.viewAllEmployeeSideMenu === "allstaff" ?
                <div className="page-menu">
                    <div className="row">
                        <div className="col-sm-12">
                            <ul className="nav nav-tabs nav-tabs-bottom">
                                <li className="nav-item"> <a style={{cursor: "pointer"}} className="nav-link active" onClick={() => dispatch(tabAllStaff())} data-toggle="tab" to="#all-staff" >All Staff</a> </li>
                                <li className="nav-item"> <a style={{cursor: "pointer"}} className="nav-link " onClick={() => dispatch(tabActive())} data-toggle="tab" to="#active"  >Active</a> </li>
                                <li className="nav-item"> <a style={{cursor: "pointer"}} className="nav-link " onClick={() => dispatch(tabNonActive())} data-toggle="tab" to="#non-active">Non-Active</a> </li>
                            </ul>
                        </div>
                    </div>
                </div>
                :
                myState.viewAllEmployeeSideMenu === "active" ?
                <div className="page-menu">
                    <div className="row">
                        <div className="col-sm-12">
                            <ul className="nav nav-tabs nav-tabs-bottom">
                                <li className="nav-item"> <a style={{cursor: "pointer"}} className="nav-link " onClick={() => dispatch(tabAllStaff())} data-toggle="tab" to="#all-staff" >All Staff</a> </li>
                                <li className="nav-item"> <a style={{cursor: "pointer"}} className="nav-link active" onClick={() => dispatch(tabActive())} data-toggle="tab" to="#active"  >Active</a> </li>
                                <li className="nav-item"> <a style={{cursor: "pointer"}} className="nav-link " onClick={() => dispatch(tabNonActive())} data-toggle="tab" to="#non-active">Non-Active</a> </li>
                            </ul>
                        </div>
                    </div>
                </div>
                :
                myState.viewAllEmployeeSideMenu === "nonactive" ?
                <div className="page-menu">
                    <div className="row">
                        <div className="col-sm-12">
                            <ul className="nav nav-tabs nav-tabs-bottom">
                                <li className="nav-item"> <a style={{cursor: "pointer"}} className="nav-link " onClick={() => dispatch(tabAllStaff())} data-toggle="tab" to="#all-staff" >All Staff</a> </li>
                                <li className="nav-item"> <a style={{cursor: "pointer"}} className="nav-link " onClick={() => dispatch(tabActive())} data-toggle="tab" to="#active"  >Active</a> </li>
                                <li className="nav-item"> <a style={{cursor: "pointer"}} className="nav-link active" onClick={() => dispatch(tabNonActive())} data-toggle="tab" to="#non-active">Non-Active</a> </li>
                            </ul>
                        </div>
                    </div>
                </div>
                // :
                // myState.viewAllEmployeeSideMenu === "search" ?
                // <div className="page-menu">
                //     <div className="row">
                //         <div className="col-sm-12">
                //             <ul className="nav nav-tabs nav-tabs-bottom">
                //                 <li className="nav-item"> <a className="nav-link " onClick={() => dispatch(tabAllStaff())} data-toggle="tab" to="#all-staff" >All Staff</a> </li>
                //                 <li className="nav-item"> <a className="nav-link " onClick={() => dispatch(tabActive())} data-toggle="tab" to="#active"  >Active</a> </li>
                //                 <li className="nav-item"> <a className="nav-link " onClick={() => dispatch(tabNonActive())} data-toggle="tab" to="#non-active">Non-Active</a> </li>
                //             </ul>
                //         </div>
                //     </div>
                // </div>
                // :     
            :
            <div className="page-menu">
                <div className="row">
                    <div className="col-sm-12">
                        <ul className="nav nav-tabs nav-tabs-bottom">
                            <li className="nav-item"> <a className="nav-link " onClick={() => dispatch(tabAllStaff())} data-toggle="tab" to="#all-staff" >All Staff</a> </li>
                            <li className="nav-item"> <a className="nav-link " onClick={() => dispatch(tabActive())} data-toggle="tab" to="#active"  >Active</a> </li>
                            <li className="nav-item"> <a className="nav-link " onClick={() => dispatch(tabNonActive())} data-toggle="tab" to="#non-active">Non-Active</a> </li>
                        </ul>
                    </div>
                </div>
            </div>
            }
        </>
    )
}
export default AdminEmployeeSideMenu