import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { getShiftAllUsers } from '../../../actions/adminActions'

const PaginationAndNextPrev = ({dateForPagination}) => {
    const [currentPageNumber, setCurrentPageNumber] = useState(1)
    const auth = useSelector((state) => state.auth)
    const admin = useSelector((state) => state.admin)
    const [limit, setLimit] = useState(10)
    const dispatch = useDispatch()
    const handleLimit = async (event) =>{
        setLimit(
            parseInt(event.target.value)
        )
        dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: dateForPagination.format('YYYY-MM-DD'), limit: event.target.value, page: 1 }))
        setCurrentPageNumber(1)
    }
    const onClickNext = async () => {
        let i= currentPageNumber
        i++;
        if (i <= admin.usersShifts[admin.usersShifts.length-5]){
            setCurrentPageNumber(currentPageNumber + 1)
            dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: dateForPagination.format('YYYY-MM-DD'), limit: limit, page: currentPageNumber+1 }))
        }
    }
    const onClickPrev = async () => {
        let i= currentPageNumber
        i--;
        if (i >= 1){
            setCurrentPageNumber(currentPageNumber - 1)
            dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: dateForPagination.format('YYYY-MM-DD'), limit: limit, page: currentPageNumber-1 }))
        }
    }
    const handleChangeData = async (pageNo) => {
        setCurrentPageNumber(
            pageNo
        )
        dispatch(getShiftAllUsers({companyId: auth.user.companyId, date: dateForPagination.format('YYYY-MM-DD'), limit: limit, page: pageNo }))
    }
    return (
        <>
        <div className="row">
            <div className="col-sm-12 col-md-5">
                {
                    admin.usersShifts &&
                    admin.usersShifts.length > 0 &&
                    <label> Showing {1 + (limit * (currentPageNumber-1))} to {admin.usersShifts[admin.usersShifts.length-4] + (limit * (currentPageNumber-1))} of {admin.usersShifts[admin.usersShifts.length-2]} entries </label>
                }
                <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>Show &nbsp;
                        <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0"
                            className="select"
                            autoComplete='false'
                            onChange={handleLimit}>
                            {
                            [10,25,50,100].map((current, i) => {
                            return(
                                <option value={current}>{current}</option>
                                )
                            })
                            }
                        </select> entries
                    </label>
                </div>
            </div>
            <div className="col-sm-12 col-md-7">
                <div className="avatar-pagination">
                    <ul className="pagination" style={{ justifyContent: 'flex-end' }}>
                        <li className="page-item">
                            <NavLink className="page-link" to="#" aria-label="Previous" onClick={onClickPrev}>
                                <span aria-hidden="true">Previous</span>
                                <span className="sr-only">Previous</span>
                            </NavLink>
                        </li>
                        {/* <li className="page-item active"><NavLink className="page-link" to="#">1</NavLink></li> */}
                        {
                            admin.usersShifts &&
                            admin.usersShifts.length > 0 &&
                            admin.usersShifts[admin.usersShifts.length-1].map((current, i) => {
                                return(
                                    currentPageNumber === current ?
                                    <li className="page-item active" key={i}><a className="page-link" value={current}>{current}</a></li>
                                    :
                                    <li className="page-item" key={i}>
                                    <a className="page-link" 
                                    value={current} onClick={()=>handleChangeData(current)}
                                    >{current}</a></li>
                                )
                            })
                        }
                        {/* <li className="page-item"><NavLink className="page-link" to="#">2</NavLink></li> */}
                        <li className="page-item">
                            <NavLink className="page-link" to="#" aria-label="Next" onClick={onClickNext}>
                                <span aria-hidden="true">Next</span>
                                <span className="sr-only">Next</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        {/* </div> */}
        {/* <div className="row"> */}
            
        </div>
        </>
    )
}

export default PaginationAndNextPrev