import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../actions/authActions";

const EmployeeSidebar = () => {
  const auth = useSelector((state) => state.auth);
  // const leave = useSelector((state) => state.leave);

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const navigateUser = () => {
    navigate("/login");
  };
  const logout = async (e) => {
    await dispatch(
      userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
    );
  };
  return (
    <>
      <div className="main-wrapper">
        <div className="sidebar" id="sidebar">
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li>
                  {/* <NavLink to="/dashboard" className="noti-dot"><i className="la la-dashboard"></i> <span> Dashboard</span></NavLink> */}
                  <NavLink to="/dashboard">
                    <i className="la la-dashboard"></i> <span> Dashboard</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/shift-scheduling">
                    <i className="la la-users"></i>
                    <span>Shift &amp; Schedule</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/chat">
                    <i className="la la-wechat"></i>
                    <span>Chat</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/attendance">
                    <i className="la la-clock-o"></i>
                    <span>Time Clock</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/leaves">
                    <i className="la la-external-link-square"></i>
                    <span>Leaves </span>
                    {/* <span className="badge badge-pill bg-primary float-right">{leave.leaveList && leave.leaveList.length }</span> */}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/training">
                    <i className="la la-graduation-cap"></i>{" "}
                    <span> Training </span>
                  </NavLink>
                </li>
                <li>
                  <a style={{ cursor: "pointer" }} onClick={logout}>
                    <i className="la la-power-off"></i> <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeSidebar;
