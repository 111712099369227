import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../actions/authActions";
import SuperAdminHeaderMessageModal from "./superAdminMessageCompoents/SuperAdminHeaderMessageModal";
import defaultImg from "../../assetsAdmin/img/avatarProfile.png";
import logonew from "../../assetsAdmin/img/footerlogoresize.png";
import { BUCKET_BASE_URL } from "../../constants";
import HeaderNotificationModel from "../HeaderNotificationModel";

const SuperAdminHeader = () => {
  // const [showDropDown, setshowDropDown] = useState(false)
  const dispatch = useDispatch();
  const [showDropDownMessage, setshowDropDownMessage] = useState(false);
  const [opensidedrawer, setopensidedrawer] = useState(false);
  const [openDrop, setopenDrop] = useState(false);
  const auth = useSelector((state) => state.auth);
  const notifications = useSelector((state) => state.notifications);

  // const openDropDown = () => {
  // setshowDropDown((state) => !state)
  // setshowDropDownMessage(false)
  // setopenDrop(false)
  // }
  const openDropDownMessage = () => {
    setshowDropDownMessage((state) => !state);
    setopenDrop(false);
  };
  const toggleDriver = () => {
    setopensidedrawer((state) => !state);
  };
  const openDropDownMenu = () => {
    setopenDrop((state) => !state);
    setshowDropDownMessage(false);
  };
  let navigate = useNavigate();
  const navigateUser = () => {
    navigate("/login");
  };
  const logout = async (e) => {
    await dispatch(
      userLogout({ refreshToken: auth.tokens.refresh.token }, navigateUser)
    );
  };
  const [showAdministratorDropDown, setshowAdministratorDropDown] =
    useState(false);
  const [showReportDropDown, setshowReportDropDown] = useState(false);
  const openAdministratorDropDown = () => {
    setshowAdministratorDropDown(
      (showAdministratorDropDown) => !showAdministratorDropDown
    );
    setshowReportDropDown(false);
  };
  const openReportDropDown = () => {
    setshowReportDropDown((showReportDropDown) => !showReportDropDown);
    setshowAdministratorDropDown(false);
  };
  const mail = useSelector((state) => state.mail);
  let j = 0;
  return (
    <>
      <div className="main-wrapper">
        <div className="header">
          <div className="header-left">
            <NavLink to="/dashboard" className="logo">
              <img src={logonew} height="33" alt="" />
            </NavLink>
          </div>
          <div className="page-title-box">
            <h3>Riyo Work</h3>
          </div>
          <NavLink
            id="mobile_btn"
            className="mobile_btn"
            to="#"
            onClick={toggleDriver}
          >
            <i className="fa fa-bars"></i>
          </NavLink>
          <ul className="nav user-menu">
            <li className="nav-item dropdown">
              <NavLink
                to="#"
                className="dropdown-toggle nav-link"
                data-toggle="dropdown"
                onClick={openDropDownMessage}
              >
                <i className="fa fa-comment-o"></i>
                {notifications.list.length > 0 && (
                  <span className="badge badge-pill">
                    {notifications.list.length -
                      notifications.list.filter((x) =>
                        x.readBy.find((y) => y.readerId == auth.user.id)
                      ).length}
                  </span>
                )}
                {/* <span className="badge badge-pill">8</span> */}
              </NavLink>
              {/* <SuperAdminHeaderMessageModal showDropDownMessage={showDropDownMessage}/> */}
              <HeaderNotificationModel
                showDropDownMessage={showDropDownMessage}
                openDropDownMessage={openDropDownMessage}
              />
            </li>
            <li
              className="nav-item dropdown has-arrow main-drop"
              onClick={openDropDownMenu}
            >
              <NavLink
                to="#"
                className="dropdown-toggle nav-link"
                data-toggle="dropdown"
              >
                <span className="avatar">
                  {auth.user && auth.user.profilePic ? (
                    auth.user.profilePic.fileUrl && (
                      <>
                        <img
                          alt="Profile Pic"
                          src={BUCKET_BASE_URL + auth.user.profilePic.fileUrl}
                          style={{ objectFit: "fill", height: "100%" }}
                        />
                      </>
                    )
                  ) : (
                    <img alt="" src={defaultImg} />
                  )}
                  <span
                    className="status online"
                    style={{
                      float: "right",
                      marginTop: "-0.6rem",
                      position: "relative",
                      border: "2px solid #fff",
                    }}
                  ></span>
                </span>
                <span>Super Admin</span>
              </NavLink>
              <div
                className={`dropdown-menu dropdown-profile ${
                  openDrop ? "show" : "hide"
                }`}
              >
                <NavLink className="dropdown-item" to="/admin-profile">
                  My Profile
                </NavLink>
                <NavLink className="dropdown-item" to="/settings">
                  Settings
                </NavLink>
                <a
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={logout}
                >
                  Logout
                </a>
              </div>
            </li>
          </ul>
          <div className="dropdown mobile-user-menu" onClick={openDropDownMenu}>
            <NavLink
              to="#"
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </NavLink>
            <div
              className={`dropdown-menu dropdown-menu-right ${
                openDrop ? "show" : "hide"
              }`}
            >
              <NavLink className="dropdown-item" to="/admin-profile">
                My Profile
              </NavLink>
              <NavLink className="dropdown-item" to="/settings">
                Settings
              </NavLink>
              <a
                className="dropdown-item"
                style={{ cursor: "pointer" }}
                onClick={logout}
              >
                Logout
              </a>
            </div>
          </div>
          <div
            className={`sidebar ${
              opensidedrawer ? "showsidebar" : "hidesidebar"
            }`}
            id="sidebar"
          >
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                <li>
                  <NavLink to="/dashboard">
                    <i className="la la-dashboard"></i> <span> Dashboard</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/companies">
                    <i className="la la-industry"></i> <span> Companies</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/chat">
                    <i className="la la-wechat"></i> <span>Chat</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/admin-login-info">
                    <i className="la la-users"></i>{" "}
                    <span> Admin Login Info</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact-us-queries">
                    <i className="la la-comment"></i>{" "}
                    <span> Contact-Us Queries</span>
                  </NavLink>
                </li>
                <li className="submenu">
                  <NavLink to="#" onClick={openAdministratorDropDown}>
                    <i className="las la-toolbox"></i>
                    <span>Administration</span>{" "}
                    <span className="menu-arrow"></span>
                  </NavLink>
                  <ul
                    className={`${showAdministratorDropDown ? "show" : "hide"}`}
                  >
                    <li>
                      <NavLink to="/file-manager">
                        <i className="la la-folder"></i> <span>My Files</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/policies">
                        <i className="la la-file-pdf-o"></i>{" "}
                        <span>Policies</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/users">
                        <i className="la la-user-plus"></i> <span>Users</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/subscriptions-company">
                        <i className="la la-hand-o-up"></i>{" "}
                        <span> Subscriptions </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/settings">
                        <i className="la la-cog"></i> <span> Settings </span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a style={{ cursor: "pointer" }} onClick={logout}>
                    <i className="la la-power-off"></i> <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      <div
        className={`sidebar-overlay ${opensidedrawer ? "opened" : ""}`}
      ></div>
    </>
  );
};
export default SuperAdminHeader;
